import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosData, DictionaryData } from '../ResidenceCreate/ResidenceCreateDialog';
import { Dictionary } from '../../models/Dictionary';
import $ from 'jquery';

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ResidenceCreateService } from '../ResidenceCreate/ResidenceCreateService';
import { DocumentService } from '../Documents/DocumentService';
import { DocumentTemplate } from 'app/models/DocumentTemplate';

export class DocumentTemplateData {
  rezult: boolean;
  documentTemplateRow: DocumentTemplate;
}

export class FolderData {

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
  id: string;
  name : string;
}

@Component({
  selector: 'app-modalWindows-DocumentTemplateSelect',
  templateUrl: './DocumentTemplateSelectDialog.html',
  styleUrls: ['./DocumentTemplateSelectDialog.css']
})
export class DocumentTemplateSelectDialog {

  selectedTemplate: DocumentTemplate = null;

  docTemplates: DocumentTemplate[] = [];

  folders: FolderData[]  = [];
  selectedFolderID: string = null;

  constructor(private service: DocumentService, public dialogRef: MatDialogRef<DocumentTemplateSelectDialog>, @Inject(MAT_DIALOG_DATA) public data: DocumentTemplateData) {
    this.data.rezult = true;
    var _that = this;

    this.service.getDocumentTemplates(true).subscribe((data: DocumentTemplate[]) => {
      _that.docTemplates = data;
      _that.initFolders();
    });
  }

  selectTemplate(template: DocumentTemplate) {
    this.selectedTemplate = template;
  }

  folderChanged() {
    this.selectedTemplate = null;
  }

  initFolders() {
    let distinctArr: DocumentTemplate[] = this.docTemplates.filter((thing, i, arr) =>
      arr.findIndex(t => t.documentTemplateFolderRef === thing.documentTemplateFolderRef && t.documentTemplateFolderRef != null) === i );

    this.folders.push(new FolderData(null, "Без папки"));
    for (let d of distinctArr) {
      this.folders.push(new FolderData(d.documentTemplateFolderRef, d.documentTemplateFolderName));
    }
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

  onYesClick(): void {
    this.data.rezult = true;
    this.data.documentTemplateRow = this.selectedTemplate;
    //
    this.dialogRef.close(this.data);
  }

  DocInFolder(data: DocumentTemplate) {
    return data.documentTemplateFolderRef == this.selectedFolderID ? "inline-block" : "none";
  }

}

