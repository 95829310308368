import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { Token } from '../../models/Token';
import { Router, CanActivate } from '@angular/router';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'charset': 'UTF-8',
        'Access-Control-Allow-Origin': '*'
    })
}

@Injectable()
export class LoginService 
{

    constructor(private http: HttpClient, private router: Router) 
    {
        let t = localStorage.getItem('token');//from storage when login
        if (t != null) {
            this.setToken(JSON.parse(t));
        }
    }

    public UserToken: Token = new Token();

    public Companies: any = [];
    public SelectedCompany: any = null;

    public SelectedCompanyStorageGuidString: string = "WebHospitalSelectedCompanyStorageGuidString";

    async setToken(token: Token) {
        localStorage.setItem('token', JSON.stringify(token));

        this.UserToken.userLogin = token.userLogin;
        this.UserToken.userPassword = token.userPassword;
        this.UserToken.userPasswordEncryptJS = token.userPasswordEncryptJS;
        this.UserToken.userToken = token.userToken;
        this.UserToken.permissions = token.permissions;
        this.UserToken.userIsAuthorized = token.userIsAuthorized;

        this.UserToken.userEhealthLogin = token.userEhealthLogin;
        this.UserToken.userEhealthPassword = token.userEhealthPassword;
        this.UserToken.userEhealthToken = token.userEhealthToken;
        this.UserToken.userEhealthTokenDate = token.userEhealthTokenDate;
        this.UserToken.userEhealthIsAuthorized = token.userEhealthIsAuthorized;

        this.UserToken.documentEditorDate = token.documentEditorDate;
        this.UserToken.documentEditorToken = token.documentEditorToken;

        await this.setCompanies();
    }

    clearToken() {
        this.UserToken = new Token();
        localStorage.removeItem('token');
        this.SelectedCompany = null;
        this.Companies = [];
    }

    getUserPermissions() {
        if (!this.UserToken.permissions) return null;

        let obj = JSON.parse(this.UserToken.permissions);

        if (obj != null && obj.Security) {
            return obj.Security;
        }

        return obj;
    }

    public CompaniesIsLoaded: boolean = false;
    async setCompanies() {
        let rezult: any = [];
        let permissions = this.getUserPermissions();

        if (permissions == null ||
            permissions.Company_access == "null" ||
            permissions.Company_access.Company == "null") 
        {
            this.Companies = rezult;
            this.CompaniesIsLoaded = true;
        }
        else {
            rezult.push(permissions.Company_access.Company);

            if (permissions.Company_access.Company.CompanyID) {
                this.SelectedCompany = rezult[0];
                this.Companies = rezult;
                this.CompaniesIsLoaded = true;
            }
            else 
            {
                this.Companies = rezult[0];

                let st: any = await this.getStorage(this.SelectedCompanyStorageGuidString);
                if (st) {
                    let selected = rezult[0].find(c => c.CompanyID == st.toString());
                    if (selected)
                        this.SelectedCompany = selected;
                    else
                        this.SelectedCompany = rezult[0][0];
                }
                else
                    this.SelectedCompany = rezult[0][0];

                this.CompaniesIsLoaded = true;
            }
        }
    }

    GetIdentity<T>(route: string): Observable<T> {
        return this.http.get<T>(route, { headers: this.getHeaders() }).pipe(
            map((response: T) => { return response }),
            catchError(err => { return Observable.throw(err); }));
    }

    async getStorage(storageName: string) {
        return await this.GetIdentity<number>("/api/values/GetStorage/" + storageName).toPromise();
    }

    setStorage(storageName: string, value: number): Observable<number> {
        return this.GetIdentity<number>("/api/values/SetStorage/" + storageName + '/' + value);
    }


    getToken(token: Token): Observable<Token> {
        let route: string;
        route = "/api/Login/GetToken";

        return this.http.post<Token>(route, token, httpOptions)
            .pipe(map((response: Token) => { return response }));
    }

    getWindowsUserName(): Observable<Token> {
        let route: string;
        route = "/api/Login/GetWindowsUserName";
        let data: string = "";

        return this.http.post<Token>(route, data, httpOptions)
            .pipe(map((response: Token) => { return response; }));
    }

    authorize(): Observable<Token> {
        let route: string;
        route = "/api/Login/Authorized";

        return this.http.post<Token>(route, new Token(), { headers: this.getHeaders() })
            .pipe(map((response: Token) => { return response }),
                catchError(err => {
                    this.clearToken();
                    this.router.navigate(['']);
                    return Observable.throw(
                        'Expired Token');}));
    }

    authorizeEhealth(login: string, password: string, guid: string, url: string): Observable<object> {
        let route: string;
        route = url + "token";


        let body: string;
        body = "grant_type=password&username=" + login + "@@@" + guid + "&password=" + password;

        return this.http.post<object>(route, body, { headers: this.getEhealthAuthHeaders() })
            .pipe(map((response: object) => {
                return response;
            }),
                catchError(err => {

                    this.UserToken.userEhealthToken = null;
                    this.UserToken.userEhealthTokenDate = null;
                    this.UserToken.userEhealthLogin = null;
                    this.UserToken.userPassword = null;
                    alert(err.error.error);
                    return Observable.throw('Expired Token');
                }));

    }



    getHeaders() {
        let token: string = this.UserToken.userToken;
        let headers = new HttpHeaders();
        headers = headers
            .set('Content-Type', 'application/json')
            .set('charset', 'UTF-8')
            .set('Access-Control-Allow-Origin', '*')
            .set('Authorization', 'Bearer ' + token);
        return headers;
    }

    getEhealthAuthHeaders() {
        let headers = new HttpHeaders();
        headers = headers
            .set('Content-Type', 'application/x-www-form-urlencoded')
        return headers;
    }


    private loginSbj = new Subject();//
    // 
    loginBind() { return this.loginSbj.asObservable(); }
    // 
    login() { this.loginSbj.next(); }



    private updateSeqByCompanySbj = new Subject();//
    // 
    updateSeqByCompanyBind() { return this.updateSeqByCompanySbj.asObservable(); }
    // 
    updateSeqByCompany() { this.updateSeqByCompanySbj.next(); }

    private updatePatientByCompanySbj = new Subject();
    updatePatientByCompanyBind() { return this.updatePatientByCompanySbj.asObservable(); }
    updatePatientByCompany() { this.updatePatientByCompanySbj.next(); }

}
