

export class EHServiceDispense {

  constructor() {}

  public eServiceDispenseID: number;

  public ehPatientRef: number;

  public ehPatientGuid: string;

  public ehEmployeeGuid: string;

  public ehServiceRequestGuid: string;

  public ehServiceDispenseProcessID: string;

  public ehServiceDispenseNumber: string;

  public ehServiceDispenseGuid: string;
}
