export class ServiceData {


    public id: string;
    public name: string;
    public code: string;
    public serviceCodeName: string;
    public children: ServiceData[];
    public serviceIsDisabled: boolean;
    public serviceIsBooking: boolean;
    public serviceIsSelectedWithChildren: boolean;
    public servicePriceRate: number;
    public servicePriceIsRequired: boolean;
}
