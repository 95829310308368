import { BedData } from "./BedData";

export class DropBedData {

  constructor() {
  }

  //1 from venuepatients to bed
  //2 from waitingList to bed
  //3 from bed to bed
  //...
  public index: number;

  public bed: BedData;
}
