import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { Patient } from '../models/Patient';
import { Sequence } from '../models/Sequence';
import { Venue } from '../models/Venue';
import { DepartmentData } from '../models/DepartmentData';
import { RoomData } from '../models/RoomData';
import { DatePipe } from '@angular/common';
import { BedData } from '../models/BedData';
import { Account } from '../models/Account';
import { Dictionary } from '../models/Dictionary';
import { DropBedData } from '../models/DropBedData';
import { Residence } from '../models/Residence';
import { ResidenceCategory } from '../models/ResidenceCategory';
import { AuthGuard } from '../auth/auth.guard';
import { User } from 'app/models/User';
import dragula from 'dragula';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { JournalData } from 'app/models/JournalData';
import { Course } from 'app/models/Course';
import { Stationary } from 'app/models/Stationary';
import { Prescription } from 'app/models/Prescription';
import { Booking } from 'app/models/Booking';
import { Document } from 'app/models/Document';
import { ServiceData } from 'app/models/ServiceData';
import { Service } from 'app/models/Service';
import { Token } from 'app/models/Token';
import { LoginService } from 'app/modalWindows/Login/LoginService';


@Injectable()
export class DoctorService {
    constructor(private http: HttpClient, private authGuard: AuthGuard, private lservice: LoginService) { }

    getHeaders() 
    {
        let token: string = this.lservice.UserToken.userToken;
        let userlogin: string = this.lservice.UserToken.userLogin;
        let headers = new HttpHeaders();
        headers = headers
            .set('Content-Type', 'application/json')
            .set('charset', 'UTF-8')
            .set('Access-Control-Allow-Origin', '*')
            .set('Authorization', 'Bearer ' + token)
            .set('userlogin', userlogin);
        return headers;
    }

    GetIdentity<T>(route: string): Observable<T> {
        return this.http.get<T>(route, { headers: this.getHeaders() }).pipe(
            map((response: T) => { return response }),
            catchError(err => { return this.authGuard.handleError(err); }));
    }

    getSequences(type: string): Observable<Sequence[]> {
        let CompanyGUID: string = null;
        if (this.lservice.SelectedCompany != null)
            CompanyGUID = this.lservice.SelectedCompany.CompanyGUID;

        return this.GetIdentity<Sequence[]>("/api/sequence/GetSecuences/" + type + '/' + CompanyGUID);
    }

    getPatientsForDoctor2(id: string): Observable<BedData[]> {
        return this.GetIdentity<BedData[]>("/api/DepartmentData/GetPatientsForDoctor2/" + id);
    }

    getPatientsForDoctor1(): Observable<BedData[]> {
        let companyGUID: string = null;

        if (this.lservice.SelectedCompany != null)
            companyGUID = this.lservice.SelectedCompany.CompanyGUID;

        return this.GetIdentity<BedData[]>("/api/DepartmentData/GetPatientsForDoctor1/" + companyGUID);
    }

    getPatientStationaries(courseID: number): Observable<Stationary[]> {
        return this.GetIdentity<Stationary[]>("/api/DepartmentData/GetPatientStationaries/" + courseID);
    }

    getPrescriptions(residenceRef: number): Observable<Prescription[]> {
        return this.GetIdentity<Prescription[]>("/api/Prescription/GetPrescriptions/" + residenceRef);
    }

    getPrescriptionTemplates(): Observable<Prescription[]> {
        return this.GetIdentity<Prescription[]>("/api/Prescription/GetPrescriptionTemplates/");
    }

    getOtherBookings(residenceRef: number): Observable<Booking[]> {
        return this.GetIdentity<Booking[]>("/api/Prescription/GetOtherBookings/" + residenceRef);
    }

    getServiceData(accountTypeId: number, tpRef: number): Observable<ServiceData[]> {
        return this.GetIdentity<ServiceData[]>("/api/Values/GetServiceData/" + accountTypeId + '/' + tpRef);
    }

    getStorage(storageName: string): Observable<number> {
        return this.GetIdentity<number>("/api/values/GetStorage/" + storageName);
    }

    getDiagramJson(bookingId: number): Observable<any> {
        return this.GetIdentity<any>("/api/values/GetDiagramJson/" + bookingId);
    }

    getDictionary(name: string): Observable<Dictionary[]> {
        let route: string;
        route = "/api/dictionary/GetByName/" + name;

        return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
            map((response: Dictionary[]) => { return response }),
            catchError(err => { return this.authGuard.handleError(err); }));
    }

    getdocuments(patientRef: number, courseRef: number): Observable<Document[]> {
        let route: string;
        route = "/api/Document/GetDocuments/" + patientRef + '/' + courseRef;

        return this.http.get<Document[]>(route, { headers: this.getHeaders() }).pipe(
            map((response: Document[]) => { return response }),
            catchError(err => { return this.authGuard.handleError(err); }));
    }

    getcources(patientID: number): Observable<Course[]> {
        let route: string;
        route = "/api/Course/GetPatientCourses/" + patientID;

        return this.http.get<Course[]>(route, { headers: this.getHeaders() }).pipe(
            map((response: Course[]) => { return response }),
            catchError(err => { return this.authGuard.handleError(err); }));
    }


    //updateBooking
    //POST need subscription
    //
    //

    CheckParalelWork(): Observable<Token> {
        let route: string;
        route = "/api/login/CheckParalelWork";

        let body: Token = new Token();
        body.userLogin = this.lservice.UserToken.userLogin;
        body.userToken = this.lservice.UserToken.userToken;

        return this.http.post<Token>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Token) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    updateBooking(body: Booking): Observable<Booking> {
        let route: string;
        route = "/api/prescription/UpdateBooking";

        return this.http.post<Booking>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Booking) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    createPrescription(body: Prescription): Observable<Prescription> {
        let route: string;
        route = "/api/prescription/CreatePrescription";

        return this.http.post<Prescription>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Prescription) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    updatePrescription(body: Prescription): Observable<Prescription> {
        let route: string;
        route = "/api/prescription/UpdatePrescription";

        return this.http.post<Prescription>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Prescription) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    stopPrescription(body: Prescription): Observable<Prescription> {
        let route: string;
        route = "/api/prescription/StopPrescription";

        return this.http.post<Prescription>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Prescription) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    stopAllPrescriptions(body: Prescription): Observable<Prescription> {
        let route: string;
        route = "/api/prescription/StopAllPrescriptions";

        return this.http.post<Prescription>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Prescription) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    createService(body: Service): Observable<Service> {
        let route: string;
        route = "/api/values/CreateService";

        return this.http.post<Service>(route, body, { headers: this.getHeaders() }).pipe(
            map((response: Service) => { return response }),
            catchError(err => {
                return this.authGuard.handleError(err);
            }));
    }

    //////////////////
    //subscribtions
    private hidePopSbj = new Subject();//
    // call onse for calendar component subscribtion
    popHided() { return this.hidePopSbj.asObservable(); }
    // call from day
    popHide() { this.hidePopSbj.next(); }

}
