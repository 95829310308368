import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthGuard } from '../auth/auth.guard';
import { Document } from '../models/Document';
import { JsonDocumentData } from 'app/models/JsonDocumentData';
import { DatePipe } from '@angular/common';
import { Venue } from 'app/models/Venue';
import { LoginService } from '../modalWindows/Login/LoginService';
import { DocumentTemplate } from 'app/models/DocumentTemplate';
import { Booking } from 'app/models/Booking';

@Injectable()
export class NurseService {
  getHeaders() {
    let token: string = this.lservice.UserToken.userToken;
    let userlogin: string = this.lservice.UserToken.userLogin;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('charset', 'UTF-8')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer ' + token)
      .set('userlogin', userlogin);
    return headers;
  }


  constructor(private http: HttpClient, private authGuard: AuthGuard, private lservice: LoginService) { }

  GetIdentity<T>(route: string): Observable<T> {
    return this.http.get<T>(route, { headers: this.getHeaders() }).pipe(
      map((response: T) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getBookingsByCourse(courseID: number, date: string): Observable<Booking[]> {
    let route: string;
    route = "/api/Booking/GetBookingsByCourse/" + courseID + '/' + date;

    return this.http.get<Booking[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Booking[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getBookingsBySequence(sequenceID: number, date: string): Observable<Booking[]> {
    let route: string;
    route = "/api/Booking/GetBookingsBySequence/" + sequenceID + '/' + date;

    return this.http.get<Booking[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Booking[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  //////////////////
  //subscribtions


  

}









