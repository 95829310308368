import { Component, Inject, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData, PrescriptionAddData } from 'app/llp/llp.component';
import { DoctorService } from 'app/doctor/doctor.service';
import { Dictionary } from 'app/models/Dictionary';
import moment from 'moment';
import { BookingDetailsData } from 'app/nurse/nursePatients/nursePatients.component';
import { LoginService } from '../../Login/LoginService';




@Component({
  selector: 'app-modalWindows-BookingDetails',
  templateUrl: './BookingDetailsDialog.html',
  styleUrls: ['./BookingDetailsDialog.css'],
  providers: [DatePipe],
})

export class BookingDetailsDialog {

  myOptionsColor = {
    'theme': 'light'
  }

  data: BookingDetailsData;

  selectedPrescription: Prescription = null;

  constructor(public dialogRef: MatDialogRef<BookingDetailsDialog>, @Inject(MAT_DIALOG_DATA)
  public d: BookingDetailsData, private datePipe: DatePipe, public dialog: MatDialog,
    public service: DoctorService, private lservice: LoginService)
  {
    this.data = d;
    this.data.comment = d.booking.bookingDesc;
  }

  formatTime(date: Date) {
    if (date) {
      return this.datePipe.transform(date, "HH:mm")
    }
    return "";
  }

  ngOnInit() {
    
  }

  AllowCMPBooking() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.SecurityActions_Booking_Execution_Complete == 'True')
      return true;
    else return false;
  }

  AllowCNCBooking() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.SecurityActions_Booking_Execution_Cancel == 'True')
      return true;
    else return false;
  }

  onYesClick(id: number): void {

    this.data.rezult = true;
    if (id == 1) this.data.newStatus = "CMP";
    else if (id == 2) this.data.newStatus = "CNC";
    this.dialogRef.close(this.data);
  
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

}

