import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import $ from 'jquery';
import { DiagnosisSelectDialog } from '../DiagnosisSelect/DiagnosisSelectDialog';
import { ConfirmDialogData } from '../../calendar/Day/day.component';
import { DocumentViewerDialogData } from '../ResidenceCreate/ResidenceCreateDialog';
import { DOCUMENT } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-modalWindows-DocumentViewer',
  templateUrl: './DocumentViewerDialog.html',
  styleUrls: ['./DocumentViewerDialog.css'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class DocumentViewerDialog {

  data: DocumentViewerDialogData;
  html: SafeHtml[] = [];

  p: number = 1;


  constructor(public dialogRef: MatDialogRef<DocumentViewerDialog>, @Inject(MAT_DIALOG_DATA) data: DocumentViewerDialogData,
    private sanitizer: DomSanitizer)
  {
    this.data = data;

    if (data.content)
      data.content.forEach(el => this.html.push(this.sanitizer.bypassSecurityTrustHtml(el)));
   
  }

  ngOnInit() {
  }


  onYesClick(): void {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }
}

