import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientComponent } from '../../../patient/patient.component';
import { DocumentEditData, DictionaryData } from '../../ResidenceCreate/ResidenceCreateDialog';
import { DictionarySelectDialog } from '../../DictionarySelect/DictionarySelectDialog';
import { DocumentService } from '../DocumentService';
import { JsonDocumentData } from 'app/models/JsonDocumentData';
import { Config } from '../../../Config';
import { Dictionary } from '../../../models/Dictionary';
import { Document } from 'app/models/Document';
import { Venue } from 'app/models/Venue';

@Component({
  selector: 'Document003Dialog',
  templateUrl: './Document003Dialog.html',
  styleUrls: ['./Document003Dialog.css']
})

export class Document003Dialog {

  data: DocumentEditData;

  datetest: Date = null;

  json: JsonDocumentData;

  //custom arrays from json
  genders: any = [];
  documents: any = [];
  gospTypes: any = [];
  rezults: any = [];
  insuranseExist: any = [];
  blood: any = [];
  rhesus: any = [];
  povtor_hosp: any = [];
  povtor_dniv: any = [];
  travma: any = [];
  rezist: any = [];
  whereExist: any = [];
  diagnozTypes: any = [];
  operationTypes: any = [];
  workAbilirty: any = [];
  lRezult: any = [];

  //nasted custom arrays //uglu custom cast hardcode for this
  treatmentTypes: any = [];
  specialTypes: any = [];
  lasers: any = [];
  combi: any = [];

  //dictionaries
  diagnosis: Dictionary[] = [];
  countries: Dictionary[] = [];
  departments: Dictionary[] = [];
  INFdepartments: Dictionary[] = [];
  arrivalTypes: Dictionary[] = [];
  usersShort: Dictionary[] = [];
  kodProc2: Dictionary[] = [];

  //msls
  //special med
  dropdownSettings = {};

  specials = [];
  specialsSelected = [];

  laser = [];
  laserSelected = [];


  comb = [];
  combSelected = [];


  constructor(public service: DocumentService, public dialogRef: MatDialogRef<Document003Dialog>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public d: DocumentEditData, private _config: Config, private datePipe: DatePipe) {
    this.data = d;

    this.dropdownSettings = {
      singleSelection: false,
      text: "Оберіть ",
      enableSearchFilter: false,
      classes: "myclass custom-class",
      enableCheckAll: false,
    };  

  }


  ngOnInit() {
    var _that = this;
    let guid: string = this._config.get("003TemplateStaticGuid");

    //1 wait json
    this.service.getDocumentJson(this.data.patient.patientID, guid, this.data.document).subscribe((data: JsonDocumentData) => {

      _that.json = data;

      this.service.getDictionary("Place of Birth").subscribe((data: Dictionary[]) => {  _that.countries = data; });
      this.service.getDictionary("Відділення").subscribe((data: Dictionary[]) => { _that.departments = data; });
      this.service.getDictionary("Відділення інфраструктури").subscribe((data: Dictionary[]) => { _that.INFdepartments = data; });
      this.service.getDictionary("StationaryArrivalType").subscribe((data: Dictionary[]) => { _that.arrivalTypes = data; });
      this.service.getDictionary("Користувачі (короткі імена)").subscribe((data: Dictionary[]) => { _that.usersShort = data; });
      this.service.getDictionary("OPERATIONS").subscribe((data: Dictionary[]) => { _that.kodProc2 = data; });

      //init custom arrays from json
      _that.documents = Object.entries(data.Root.Structure['Node37BE3278-644F-4F3F-87BB-72A118C0CD60Node'].ns);
      _that.gospTypes = Object.entries(data.Root.Structure['Node4D0CD259-2C92-4D8C-878F-140EB986E3D2Node'].ns);
      _that.genders = Object.entries(data.Root.Structure['Node1A39A36D-08D7-45AD-95CD-352AE0E97771Node'].ns);
      _that.rezults = Object.entries(data.Root.Structure['Node799779D2-02DA-4350-BD43-9E075B999D09Node'].ns);
      _that.blood = Object.entries(data.Root.Structure['NodeC449B7A5-DF0C-4F0D-83FB-C77AE29BC8F3Node'].ns);
      _that.rhesus = Object.entries(data.Root.Structure['Node1D3A42A3-EA64-41F1-9C5C-3C563FA9AF73Node'].ns);
      _that.povtor_hosp = Object.entries(data.Root.Structure['Node24579C4E-1932-4974-9634-9A09EA37B164Node'].ns);
      _that.povtor_dniv = Object.entries(data.Root.Structure['Node4200DEB1-74A3-4BB3-AAA8-EF699E221DC9Node'].ns);
      _that.travma = Object.entries(data.Root.Structure['NodeAB648AD0-16FD-442A-835C-F9CAB67AD881Node'].ns);
      _that.rezist = Object.entries(data.Root.Structure["Node61630293-515A-4B1B-94AA-62B2618BD844Node"].ns);
      _that.whereExist = Object.entries(data.Root.Structure['Node7EA52F76-1A61-4481-8C10-9F1E6CD466CBNode'].ns);
      _that.diagnozTypes = Object.entries(data.Root.Structure['Node8C517F98-8712-4926-8A35-A6BB57C27866Node'].ns
      ['NodeCFBD90FA-108D-46BC-B386-E6F43BDFE770Node'].ns);
      _that.operationTypes = Object.entries(data.Root.Structure['Node7C0A17C4-9556-4602-8261-0B329DF72CEBNode']
        .ns['Node70B682A4-742E-4EAC-A946-13A3F7E39E58Node'].ns);
      _that.workAbilirty = Object.entries(data.Root.Structure['Node19D42E77-7954-4016-8724-7DD3AEECCD9CNode'].ns);
      _that.lRezult = Object.entries(data.Root.Structure['NodeD4305601-FA0A-4413-8F0E-282FB4BF7B53Node'].ns);
      _that.insuranseExist = Object.entries(data.Root.Structure['Node1CB803D6-3B66-455C-B8D3-D985F786DD61Node'].ns);

      _that.treatmentTypes = Object.entries(data.Root.Structure['Node255829F6-B5CD-4843-B48C-2980F41CB110Node'].ns).sort();
      _that.specialTypes = Object.entries(data.Root.Structure['NodeAF8238FC-E2DC-4AAC-AD41-3169266238ECNode'].ns).sort();
      _that.lasers = Object.entries(data.Root.Structure['Node985CCC8A-3B01-41D6-86F1-CD2921376E8ANode'].ns).sort();
      _that.combi = Object.entries(data.Root.Structure['Node2207CBC4-FEF8-4686-A128-4A6F8DEF042BNode'].ns).sort();

      _that.initMSLs('NodeAF8238FC-E2DC-4AAC-AD41-3169266238ECNode', _that.specialTypes, this.specials, this.specialsSelected);
      _that.initMSLs('Node985CCC8A-3B01-41D6-86F1-CD2921376E8ANode', _that.lasers, this.laser, this.laserSelected);
      _that.initMSLs('Node2207CBC4-FEF8-4686-A128-4A6F8DEF042BNode', _that.combi, this.comb, this.combSelected);

      //db has custom not supportable input so must to cast for two ways
      this.service.parseDate(this.json, this.datePipe, 'NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode', true);
      this.service.parseDate(this.json, this.datePipe, 'NodeE650B8EA-89DC-4B01-BB37-3D35CCA2158ANode', true);
      this.service.parseDate(this.json, this.datePipe, 'Node863AE8B0-E211-4CE4-8190-30FB5508674FNode', true);
      this.service.parseDate(this.json, this.datePipe, 'Node85D87459-38A9-4408-A06B-F687C90ADDF5Node', true);
      this.service.parseDate(this.json, this.datePipe, 'Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node', true);
      this.service.parseDate(this.json, this.datePipe, 'Node8F7F2632-7D96-4D38-B9CC-7D5E53D1AF95Node', true);//vil
      this.service.parseDate(this.json, this.datePipe, 'Node54632AEC-30B6-46A1-8B71-D5FB2797DD0ENode', true);//17 vasserman
      this.service.parseDate(this.json, this.datePipe, 'NodeB974268F-0D06-4138-8E5D-D42313991CBBNode', true);//20
      this.service.parseDate(this.json, this.datePipe, 'NodeDCA4D223-9E6B-4FDE-AB44-46E10E21AA03Node', true);
      this.service.parseTableDate(this.json, this.datePipe, 'NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node','Node7C0A17C4-9556-4602-8261-0B329DF72CEBNode' , true);
      this.service.parseTableDate(this.json, this.datePipe, 'Node45E034BB-520F-40EE-9D97-2ECD33F3B76DNode', 'Node9BCDCE67-0253-43CA-828D-4F31BC3D85AANode', true);
      this.service.parseTableDate(this.json, this.datePipe, 'Node9718249F-E13B-45AE-AD13-000C28412B09Node', 'Node9BCDCE67-0253-43CA-828D-4F31BC3D85AANode',  true);

      //2 get diagnozis
      this.service.getDiagnozis().subscribe((data: Dictionary[]) => {
        _that.diagnosis = data;
        _that.autocompleteFields(_that.json);
      });

    });

  }

  initMSLs(node: string, options: any, items: any, itemsSelected: any) {

    if (this.json.Root.DocumentValues[node].Value.items != null
      && typeof this.json.Root.DocumentValues[node].Value.items.item === 'string') {
      this.json.Root.DocumentValues[node].Value.items = { item: [this.json.Root.DocumentValues[node].Value.items.item] };
    }

    for (let i = 0; i < options.length; i++) {
      items.push({ id: i, itemName: options[i][1].Caption, guid: options[i][0]});

      let str: string = options[i][0];
      str = (str.split('Node').join('')).toLowerCase();

      if (this.json.Root.DocumentValues[node].Value.items != null && this.json.Root.DocumentValues[node].Value.items.item
        .find(r => r == str)) {
        itemsSelected.push({ id: i, itemName: options[i][1].Caption, guid: options[i][0]});
      }
    }
  }

  onItemSelect(item: any, node: string) {
    let str: string = item.guid;
    str = (str.split('Node').join('')).toLowerCase();

    if (this.json.Root.DocumentValues[node].Value.items == null) {
      this.json.Root.DocumentValues[node].Value.items = { item: [] };
    }
    this.json.Root.DocumentValues[node].Value.items.item.push(str);
    
  }
  OnItemDeSelect(item: any, node: string) {
    let str: string = item.guid;
    str = (str.split('Node').join('')).toLowerCase();

    if (this.json.Root.DocumentValues[node].Value.items == null) {
      this.json.Root.DocumentValues[node].Value.items = { item: [] };
    }

    const index = this.json.Root.DocumentValues[node].Value.items.item.indexOf(str, 0);
    if (index > -1) {
      this.json.Root.DocumentValues[node].Value.items.item.splice(index, 1);
    }

    //this.json.Root.DocumentValues[node].Value.items.item.pop(str);
  }

  mslIsSelected(node: string, guid: string) {
    if (this.json.Root.DocumentValues[node].Value != null && this.json.Root.DocumentValues[node].Value.items != null
      && this.json.Root.DocumentValues[node].Value.items.item.includes(guid)) {
      return true;
    } else return false;
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  autocompleteFields(json: JsonDocumentData) {
    if (this.data.residence != null && this.data.patient != null) {

      if (this.json.Root.DocumentValues['NodeE784ACC7-542E-4E6C-A96E-1DA6218002D4Node'].Value == null) {
        if (this.data.residence.venueRef != null) {
          this.service.getDepartment(this.data.residence).subscribe((data: Venue) =>
          {
            this.json.Root.DocumentValues['NodeE784ACC7-542E-4E6C-A96E-1DA6218002D4Node'].Value = data.venueName;
          });
        }
      }

      if (this.json.Root.DocumentValues['Node40CD33C7-BB93-4B16-AF18-A677ABBCE02FNode'].Value == null)
      {
        if (this.data.residence.courseArrivalType != null) {
          this.service.parseDictionary("Node40CD33C7-BB93-4B16-AF18-A677ABBCE02FNode",
            this.arrivalTypes.find(r => r.dictionaryID == this.data.residence.courseArrivalType), null, this.json);
        }
      }

      if (this.json.Root.DocumentValues['Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node'].Value == null)
        this.json.Root.DocumentValues['Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node'].Value = this.data.residence.residenceStartDate;

      //different values need swich case
      if (this.json.Root.DocumentValues['Node1A39A36D-08D7-45AD-95CD-352AE0E97771Node'].Value == null && this.data.patient.patientSexRef != null) {
        if (this.data.patient.patientSexRef == "MAL") {
          this.json.Root.DocumentValues['Node1A39A36D-08D7-45AD-95CD-352AE0E97771Node'].Value = this.genders[1][1].Caption;
        }
        else if (this.data.patient.patientSexRef == "FEM") {
          this.json.Root.DocumentValues['Node1A39A36D-08D7-45AD-95CD-352AE0E97771Node'].Value = this.genders[0][0].Caption;
        }
      }

      if (this.json.Root.DocumentValues['Node544982C4-7DB3-4CE4-9B19-DFF630423440Node'].Value == null)
        this.json.Root.DocumentValues['Node544982C4-7DB3-4CE4-9B19-DFF630423440Node'].Value = this.data.patient.patientName;

      if (this.json.Root.DocumentValues['NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode'].Value == null)
        this.json.Root.DocumentValues['NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode'].Value = this.data.patient.patientBirthDate;

      if (this.json.Root.DocumentValues['Node151BA937-3CC3-4243-B058-537A420C4EC7Node'].Value == null)
        this.json.Root.DocumentValues['Node151BA937-3CC3-4243-B058-537A420C4EC7Node'].Value = this.data.patient.patientAge;

      if (this.json.Root.DocumentValues['Node3AAD5003-1D01-4421-B1C9-973F7C18EED9Node'].Value == null)
        this.json.Root.DocumentValues['Node3AAD5003-1D01-4421-B1C9-973F7C18EED9Node'].Value = this.data.patient.patientAddress;

      //diagnoz has diff structure
      if (this.json.Root.DocumentValues['Node691639CE-F828-4D6E-B75E-DB0DD11750EBNode'].Value == null && this.data.residence.residenceDiagnosis1 != null) {
        this.service.parseDictionary("Node691639CE-F828-4D6E-B75E-DB0DD11750EBNode",
          this.diagnosis.find(r => r.dictionaryCode == this.data.residence.residenceDiagnosis1),null, this.json);
      }
    }
  }

  onYesClick(): void {

    this.service.parseDate(this.json, this.datePipe, 'NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode');
    this.service.parseDate(this.json, this.datePipe, 'NodeE650B8EA-89DC-4B01-BB37-3D35CCA2158ANode');
    this.service.parseDate(this.json, this.datePipe, 'Node863AE8B0-E211-4CE4-8190-30FB5508674FNode');
    this.service.parseDate(this.json, this.datePipe, 'Node85D87459-38A9-4408-A06B-F687C90ADDF5Node');
    this.service.parseDate(this.json, this.datePipe, 'Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node');
    this.service.parseDate(this.json, this.datePipe, 'Node8F7F2632-7D96-4D38-B9CC-7D5E53D1AF95Node');//vil
    this.service.parseDate(this.json, this.datePipe, 'Node54632AEC-30B6-46A1-8B71-D5FB2797DD0ENode');//17 vasserman
    this.service.parseDate(this.json, this.datePipe, 'NodeB974268F-0D06-4138-8E5D-D42313991CBBNode');//20
    this.service.parseDate(this.json, this.datePipe, 'NodeDCA4D223-9E6B-4FDE-AB44-46E10E21AA03Node');
    this.service.parseTableDate(this.json, this.datePipe, 'NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node','Node7C0A17C4-9556-4602-8261-0B329DF72CEBNode');
    this.service.parseTableDate(this.json, this.datePipe, 'Node45E034BB-520F-40EE-9D97-2ECD33F3B76DNode','Node9BCDCE67-0253-43CA-828D-4F31BC3D85AANode');
    this.service.parseTableDate(this.json, this.datePipe, 'Node9718249F-E13B-45AE-AD13-000C28412B09Node','Node9BCDCE67-0253-43CA-828D-4F31BC3D85AANode');

    this.data.rezult = true;

    if (this.data.document == null) this.data.document = new Document(null);

    this.data.jsonToSave = JSON.stringify(this.json);
    this.data.document.json = JSON.stringify(this.json);

    this.dialogRef.close(this.data);

  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }


  getDateColor(date: Date) {
    if (date != null) {
      return "#28a745";
    } else {
      return "#B2B2B2"
    }
  }


  calcCount() {
    if (this.json.Root.DocumentValues['Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node'].Value
      && this.json.Root.DocumentValues['NodeB974268F-0D06-4138-8E5D-D42313991CBBNode'].Value) {

      var date1 = new Date(this.json.Root.DocumentValues['Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node'].Value);
      var date2 = new Date(this.json.Root.DocumentValues['NodeB974268F-0D06-4138-8E5D-D42313991CBBNode'].Value);

      var diff = date2.getTime() - date1.getTime();
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      this.json.Root.DocumentValues['Node230967B3-7BEF-49AB-A815-151DE5256514Node'].Value = diffDays;

      return diffDays;
    }
    else {
      return "";
    }
  }


  //add row to first table
  AddAdditionalDiagnozRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['NodeD6297384-8270-4241-966C-820A30F8EF51Node'], structure);
    Object.assign(this.json.Root.DocumentValues['Node5B27DC48-2BF7-4500-93F4-6F2F98F50D02Node'], structure1);
    Object.assign(this.json.Root.DocumentValues['NodeCFBD90FA-108D-46BC-B386-E6F43BDFE770Node'], structure2);
  }

  RemoveAdditionalDiagnozRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['NodeD6297384-8270-4241-966C-820A30F8EF51Node'][path] ;
      delete this.json.Root.DocumentValues['Node5B27DC48-2BF7-4500-93F4-6F2F98F50D02Node'][path];
      delete this.json.Root.DocumentValues['NodeCFBD90FA-108D-46BC-B386-E6F43BDFE770Node'][path];
    }
  }

  //add row to second table
  AddOperationRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };
    var structure3 = {}; structure3[path] = { Value: null };
    var structure4 = {}; structure4[path] = { Value: null };
    var structure5 = {}; structure5[path] = { Value: null };
    var structure6 = {}; structure6[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node'], structure);
    Object.assign(this.json.Root.DocumentValues['NodeA0C0AD05-6956-4609-B618-87ACD5A2276FNode'], structure1);
    Object.assign(this.json.Root.DocumentValues['Node70B682A4-742E-4EAC-A946-13A3F7E39E58Node'], structure2);
    Object.assign(this.json.Root.DocumentValues['Node1FDA9861-F485-4C27-B742-A71ECA77BB14Node'], structure3);
    Object.assign(this.json.Root.DocumentValues['Node8B4E96BB-0129-48C8-B580-169D591E2220Node'], structure4);
    Object.assign(this.json.Root.DocumentValues['Node51A6C792-5076-4730-B4E6-C02F6BED4294Node'], structure5);
    Object.assign(this.json.Root.DocumentValues['Node7F080367-5478-46E8-B560-7EC073912B29Node'], structure6);
  }


  RemoveOperationRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node'][path];
      delete this.json.Root.DocumentValues['NodeA0C0AD05-6956-4609-B618-87ACD5A2276FNode'][path];
      delete this.json.Root.DocumentValues['Node70B682A4-742E-4EAC-A946-13A3F7E39E58Node'][path];
      delete this.json.Root.DocumentValues['Node1FDA9861-F485-4C27-B742-A71ECA77BB14Node'][path];

      delete this.json.Root.DocumentValues['Node8B4E96BB-0129-48C8-B580-169D591E2220Node'][path];
      delete this.json.Root.DocumentValues['Node51A6C792-5076-4730-B4E6-C02F6BED4294Node'][path];
      delete this.json.Root.DocumentValues['Node7F080367-5478-46E8-B560-7EC073912B29Node'][path];
    }
  }

  //add row to third table
  AddWorkRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['Node0E9D13EC-3332-4A16-BA09-A7E930789C46Node'], structure);
    Object.assign(this.json.Root.DocumentValues['Node45E034BB-520F-40EE-9D97-2ECD33F3B76DNode'], structure1);
    Object.assign(this.json.Root.DocumentValues['Node9718249F-E13B-45AE-AD13-000C28412B09Node'], structure2);
  }


  RemoveWorkRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['Node0E9D13EC-3332-4A16-BA09-A7E930789C46Node'][path];
      delete this.json.Root.DocumentValues['Node45E034BB-520F-40EE-9D97-2ECD33F3B76DNode'][path];
      delete this.json.Root.DocumentValues['Node9718249F-E13B-45AE-AD13-000C28412B09Node'][path];
    }
  }

  onDictionatyClick(node: string, dicNeme: string, index: number = null) {
    this.openDialog(dicNeme).subscribe(result => {

      if (result != null && result.rezult && result.dictionaryRow)//if success in modal dialog
      {
        this.service.parseDictionary(node, result.dictionaryRow, index, this.json);
        // bind data to needed object
      }
      else {
        // something if false (pressed no)
      }
    });
  }

  openDialog(dicName: string): Observable<DictionaryData> {

    let d: DictionaryData = new DictionaryData();
    d.dictionaryName = dicName;

    const dialogRef = this.dialog.open(DictionarySelectDialog, {
      width: '600px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

  eventHandler(event) {
    event.returnValue = false;
    event.preventDefault();
  }
}

