import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import $ from 'jquery';
import { DiagnosisSelectDialog } from '../DiagnosisSelect/DiagnosisSelectDialog';
import { ConfirmDialogData } from '../../calendar/Day/day.component';
import { DocumentViewerDialogData, EHFrameData } from '../ResidenceCreate/ResidenceCreateDialog';
import { DOCUMENT } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-modalWindows-EHFrame',
  templateUrl: './EHFrameDialog.html'
})
export class EHFrameDialog {

  data: EHFrameData;
  isDocument: boolean = true;

  constructor(public dialogRef: MatDialogRef<EHFrameDialog>, @Inject(MAT_DIALOG_DATA) data: EHFrameData,
    private sanitizer: DomSanitizer)
  {
    this.data = data;
    this.isDocument = data.isDocument;
  }

  getURL() {
    if (this.data != null) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    }
  }

  ngOnInit() {

  }

  onYesClick(): void {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }
}

