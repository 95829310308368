import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { UiSwitchModule } from 'angular2-ui-switch'
import { NurseComponent } from './nurse/nurse.component';
import { DiagramDynamicDialog } from './modalWindows/llp/DiagramDynamic/DiagramDynamicDialog';
import { BookingDetailsDialog } from './modalWindows/Nurse/BookingDetails/BookingDetailsDialog';
import { LoginFailedDialog } from './modalWindows/LoginFailed/LoginFailedDialog';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PatientComponent } from './patient/patient.component';
import { LLPComponent } from './llp/llp.component';
import { NursePatientsComponent } from './nurse/nursePatients/nursePatients.component';
import { NurseAllComponent } from './nurse/nurseAll/nurseAll.component';
import { PrescriptionPopComponent } from './llp/PrescriptionPop/PrescriptionPop.component';
import { BookingStatusPopComponent } from './llp/BookingStatusPop/BookingStatusPop.component';
import { CalendarViewerComponent } from './llp/CalendarViewer/CalendarViewer.component';
import { SearchByNumberComponent } from './Ehealth/searchByNumber/searchByNumber.component';
import { SearchByPredicateComponent } from './Ehealth/searchByPredicate/searchByPredicate.component';
import { EHealthViewComponent } from './Ehealth/eHealthView/eHealthView.component';
import { HomeComponent } from './home/home.component';

import { CalendarComponent } from './calendar/calendar.component';
import { DoctorComponent } from './doctor/doctor.component';

import { DayComponent } from './calendar/Day/day.component';
import { ResidenceMainComponent } from './modalWindows/ResidenceCreate/ResidenceMain/residence.main';
import { ResidenceChangeDepartmentComponent } from './modalWindows/ResidenceCreate/ResidenceChangeDep/residence.changeDep';
import { ResidenceDeceaseComponent } from './modalWindows/ResidenceCreate/ResidenceDecease/residence.decease';
import { ResidenceDischargeComponent } from './modalWindows/ResidenceCreate/ResidenceDischarge/residence.discharge';
import { ResidenceRejectComponent } from './modalWindows/ResidenceCreate/ResidenceReject/residence.reject';
import { TimeLineComponent } from './calendar/TimeLine/timeline.component';
import { DataService } from './calendar/calendar.service';
import { NurseService } from './nurse/nurse.service';
import { DoctorService } from './doctor/doctor.service';
import { responsiveService } from './responsiveService';
import { ResidenceCreateService } from './modalWindows/ResidenceCreate/ResidenceCreateService';
import { LoginService } from './modalWindows/Login/LoginService';
import { DocumentEditorService } from './DocumentEditor/DocumentEditorService';
import { EhealthService } from './Ehealth/EhealthService';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResidenceCreateDialog } from './modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { addToQueueDialog } from './modalWindows/EHealth/addToQueue/addToQueueDialog';
import { syncPatientDialog } from './modalWindows/EHealth/syncPatient/syncPatientDialog';
import { PatientToSequenceCreateDialog } from './modalWindows/PatientToSequenceCreate/PatientToSequenceCreateDialog';
import { PatientEditDialog } from './modalWindows/PatientEdit/PatientEditDialog';
import { PrescriptionEditDialog } from './modalWindows/llp/PrescriptionEdit/PrescriptionEditDialog';
import { PrescriptionAddDialog } from './modalWindows/llp/PrescriptionAdd/PrescriptionAddDialog';
import { PrescriptionSelectDialog } from './modalWindows/llp/PrescriptionSelect/PrescriptionSelectDialog';
import { AddServiceDialog } from './modalWindows/llp/AddService/AddServiceDialog';
import { SelectServiceDialog } from './modalWindows/llp/SelectService/SelectServiceDialog';
import { CalendarViewerDialog } from './modalWindows/llp/CalendarViewer/CalendarViewerDialog';
import { LoginDialog } from './modalWindows/Login/LoginDialog';
import { PatientSearchDialog } from './modalWindows/PatientSearch/PatientSearchDialog';
import { PatientToSequenceDialog } from './modalWindows/PatientToSequence/PatientToSequenceDialog';
import { ConfirmDialog } from './modalWindows/Confirm/ConfirmDialog';
import { DocumentViewerDialog } from './modalWindows/DocumentViewer/DocumentViewerDialog';
import { DocumentTemplateSelectDialog } from './modalWindows/DocumentTemplateSelect/DocumentTemplateSelectDialog';
import { EHFrameDialog } from './modalWindows/EHFrame/EHFrameDialog';
import { ConfirmPlannedDialog } from './modalWindows/ConfirmPlanned/ConfirmPlannedDialog';
import { Document003Dialog } from './modalWindows/Documents/003/Document003Dialog';
import { UserProfileDialog } from './modalWindows/UserProfile/UserProfileDialog';
import { UserJournalsDialog } from './modalWindows/UserJournals/UserJournalsDialog';
import { Document066Dialog } from './modalWindows/Documents/066/Document066Dialog';
import { SequenceSelectDialog } from './modalWindows/SequenceSelect/SequenceSelectDialog';
import { DiagnosisSelectDialog } from './modalWindows/DiagnosisSelect/DiagnosisSelectDialog';
import { DictionarySelectDialog } from './modalWindows/DictionarySelect/DictionarySelectDialog';
import { DayViewFilterDialog } from './modalWindows/DayViewFilter/DayViewFilterDialog';
import { searchPipe } from './modalWindows/DiagnosisSelect/DiagnosisSelectDialog';
import { FilterUniquePipe } from './nurse/nursePatients/nursePatients.component';
import { searchPatientPipe } from './doctor/doctor.component';
import { HighlightSearch } from './modalWindows/llp/SelectService/SelectServiceDialog';
import { searchTemplatePipe } from './modalWindows/llp/PrescriptionSelect/PrescriptionSelectDialog';
import { AuthGuard } from '../app/auth/auth.guard';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LOCALE_ID } from '@angular/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';

import { DecimalPipe, DatePipe } from "@angular/common";
import { registerLocaleData } from '@angular/common';
import localeUKR from '@angular/common/locales/uk';
import { Injectable } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { DocumentService } from './modalWindows/Documents/DocumentService';
import { Config } from './Config';

import { DragulaModule } from 'ng2-dragula';
import { PopoverModule } from 'ngx-smart-popover';

import { NgxPrintModule } from 'ngx-print';
import { TreeModule } from '@circlon/angular-tree-component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';



FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  interactionPlugin,
  resourceTimelinePlugin
]);

export const MY_MOMENT_FORMATS = {
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
    datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
    timePickerInput: {hour: 'numeric', minute: 'numeric'},
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
    /*
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
     */
};


registerLocaleData(localeUKR);

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'Відмінити';

  /** A label for the set button */
  setBtnLabel= 'Застосувати'
};



@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    PatientComponent,
    LLPComponent,
    PrescriptionPopComponent,
    BookingStatusPopComponent,
    CalendarViewerComponent,
    SearchByNumberComponent,
    SearchByPredicateComponent,
    EHealthViewComponent,
    HomeComponent,
    CalendarComponent,
    NurseComponent,
    DoctorComponent,
    DayComponent,
    TimeLineComponent,
    NurseAllComponent,
    NursePatientsComponent,
    ResidenceCreateDialog,
    addToQueueDialog,
    syncPatientDialog,
    PatientEditDialog,
    PrescriptionEditDialog,
    DiagramDynamicDialog,
    BookingDetailsDialog,
    LoginFailedDialog,
    PrescriptionAddDialog,
    PrescriptionSelectDialog,
    AddServiceDialog,
    SelectServiceDialog,
    CalendarViewerDialog,
    PatientToSequenceCreateDialog,
    ResidenceMainComponent,
    ResidenceChangeDepartmentComponent,
    ResidenceDeceaseComponent,
    ResidenceDischargeComponent,
    ResidenceRejectComponent,
    DiagnosisSelectDialog,
    DictionarySelectDialog,
    DayViewFilterDialog,
    ConfirmDialog,
    DocumentViewerDialog,
    DocumentTemplateSelectDialog,
    EHFrameDialog,
    ConfirmPlannedDialog,
    Document003Dialog,
    Document066Dialog,
    UserProfileDialog,
    UserJournalsDialog,
    SequenceSelectDialog,
    LoginDialog,
    PatientSearchDialog,
    PatientToSequenceDialog,
    searchPipe,
    FilterUniquePipe,
    searchPatientPipe,
    HighlightSearch,
    searchTemplatePipe
  ],

  entryComponents: [
    ResidenceCreateDialog,
    addToQueueDialog,
    syncPatientDialog,
    PatientEditDialog,
    DiagramDynamicDialog,
    PrescriptionEditDialog,
    BookingDetailsDialog,
    LoginFailedDialog,
    PrescriptionAddDialog,
    PrescriptionSelectDialog,
    AddServiceDialog,
    SelectServiceDialog,
    CalendarViewerDialog,
    PatientToSequenceCreateDialog,
    DiagnosisSelectDialog,
    DictionarySelectDialog,
    DayViewFilterDialog,
    ConfirmDialog,
    DocumentViewerDialog,
    DocumentTemplateSelectDialog,
    EHFrameDialog,
    ConfirmPlannedDialog,
    Document003Dialog,
    Document066Dialog,
    UserProfileDialog,
    UserJournalsDialog,
    SequenceSelectDialog,
    LoginDialog,
    PatientSearchDialog,
    PatientToSequenceDialog
  ],

  imports: [
    DragulaModule.forRoot(),
    TreeModule,
    PopoverModule,
    NgbModule,
    BrowserModule,
    FullCalendarModule ,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    NoopAnimationsModule,
    NgxPaginationModule,
    GooglePlaceModule,
    TooltipModule,
    UiSwitchModule,
    NgxPrintModule,
    AngularMultiSelectModule,
    ChartsModule,

    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'dictionarySelectDialog', component: DictionarySelectDialog, canActivate: [AuthGuard] },
      { path: 'dayViewFilterDialog', component: DayViewFilterDialog, canActivate: [AuthGuard] },
      { path: 'diagnosisSelectDialog', component: DiagnosisSelectDialog, canActivate: [AuthGuard] },
      { path: 'confirmDialog', component: ConfirmDialog, canActivate: [AuthGuard] },
      { path: 'documentViewerDialog', component: DocumentViewerDialog, canActivate: [AuthGuard] },
      { path: 'documentTemplateSelectDialog', component: DocumentTemplateSelectDialog, canActivate: [AuthGuard] },
      { path: 'eHFrameDialog', component: EHFrameDialog, canActivate: [AuthGuard] },
      { path: 'sequenceSelectDialog', component: SequenceSelectDialog, canActivate: [AuthGuard] },
      { path: 'confirmPlannedDialog', component: ConfirmPlannedDialog, canActivate: [AuthGuard] },
      { path: 'document003Dialog', component: Document003Dialog, canActivate: [AuthGuard] },
      { path: 'document066Dialog', component: Document066Dialog, canActivate: [AuthGuard] },
      { path: 'userProfileDialog', component: UserProfileDialog, canActivate: [AuthGuard] },
      { path: 'userJournalsDialog', component: UserJournalsDialog, canActivate: [AuthGuard] },
      { path: 'loginDialog', component: LoginDialog },
      { path: 'patientSearchDialog', component: PatientSearchDialog, canActivate: [AuthGuard] },
      { path: 'patientToSequenceDialog', component: PatientToSequenceDialog, canActivate: [AuthGuard] },
      { path: 'residencecreatedialog', component: ResidenceCreateDialog, canActivate: [AuthGuard] },
      { path: 'addToQueueDialog', component: addToQueueDialog, canActivate: [AuthGuard] },
      { path: 'syncPatientDialog', component: syncPatientDialog, canActivate: [AuthGuard] },
      { path: 'patientEditDialog', component: PatientEditDialog, canActivate: [AuthGuard] },
      { path: 'prescriptionEditDialog', component: PrescriptionEditDialog, canActivate: [AuthGuard] },
      { path: 'prescriptionAddDialog', component: PrescriptionAddDialog, canActivate: [AuthGuard] },
      { path: 'prescriptionSelectDialog', component: PrescriptionSelectDialog, canActivate: [AuthGuard] },
      { path: 'addServiceDialog', component: AddServiceDialog, canActivate: [AuthGuard] },
      { path: 'selectServiceDialog', component: SelectServiceDialog, canActivate: [AuthGuard] },
      { path: 'calendarViewerDialog', component: CalendarViewerDialog, canActivate: [AuthGuard] },
      { path: 'diagramDynamicDialog', component: DiagramDynamicDialog, canActivate: [AuthGuard] },
      { path: 'bookingDetailsDialog', component: BookingDetailsDialog, canActivate: [AuthGuard] },
      { path: 'loginFailedDialog', component: LoginFailedDialog, canActivate: [AuthGuard] },
      { path: 'patientToSequenceCreateDialog', component: PatientToSequenceCreateDialog, canActivate: [AuthGuard] },
      { path: 'main', outlet: 'residence', component: ResidenceMainComponent, canActivate: [AuthGuard] },
      { path: 'changeDep', outlet: 'residence', component: ResidenceChangeDepartmentComponent, canActivate: [AuthGuard] },
      { path: 'decease', outlet: 'residence', component: ResidenceDeceaseComponent, canActivate: [AuthGuard] },
      { path: 'discharge', outlet: 'residence', component: ResidenceDischargeComponent, canActivate: [AuthGuard] },
      { path: 'reject', outlet: 'residence', component: ResidenceRejectComponent, canActivate: [AuthGuard] },
      { path: 'nurse', component: NurseComponent, canActivate: [AuthGuard], data: { componentid: 'nurse' } },
      { path: 'doctor', component: DoctorComponent, canActivate: [AuthGuard], data: { componentid: 'doctor' } },
      {
        path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard], data: {componentid: 'calendar'},
        children: [
          {
            outlet: "view", // <--- Named outlet.
            path: "day",
            component: DayComponent
          },
          {
            outlet: "view", // <--- Named outlet.
            path: "timeline",
            component: TimeLineComponent
          }
        ]
      },

    ])
  ],
  providers:
    [DataService, DoctorService, ResidenceCreateService, LoginService, EhealthService, responsiveService, DocumentEditorService, AuthGuard, DocumentService, NurseService, Config, DatePipe,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: MatDialogRef, useValue: {} },
      { provide: MAT_DIALOG_DATA, useValue: [] },
      { provide: LOCALE_ID, useValue: "uk" },
      { provide: OwlDateTimeIntl, useClass: DefaultIntl },
      { provide: OWL_DATE_TIME_LOCALE, useValue: 'uk' },
      { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class MaterialModule { }
