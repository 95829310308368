import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicData } from 'app/llp/llp.component';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import moment from 'moment';
import 'moment/locale/uk'  // without this line it didn't work
import { DoctorService } from 'app/doctor/doctor.service';
moment.locale('uk')


@Component({
  selector: 'app-modalWindows-DiagramDynamicDialog',
  templateUrl: './DiagramDynamicDialog.html',
  styleUrls: ['./DiagramDynamicDialog.css']
})

export class DiagramDynamicDialog {


  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  public lineChartOptions: (ChartOptions & { annotation?: any }) = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{

        ticks: {
          //min: 0
        }
      }],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM D', // This is the default
            },
          },
        },
      ]
    }
  };

    public lineChartColors: any[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];

  public lineChartData: ChartDataSets[] = [];
   /* [
      {
        label: 'Dataset 1',
        data: [
          { x: new Date('2020-03-30'), y: 3 },
          { x: new Date('2021-07-01'), y: 7 },

        ]
      },
      {
        label: 'Dataset 2',
        data: [
          { x: new Date('2020-04-30'), y: 5 },
          { x: new Date('2020-9-08'), y: 10 },

        ]

      },
    ];*/


  data: DynamicData;

  constructor(public dialogRef: MatDialogRef<DiagramDynamicDialog>, @Inject(MAT_DIALOG_DATA)
  public d: DynamicData, private datePipe: DatePipe, public dialog: MatDialog,
    private service: DoctorService) {
    this.data = d;

    moment.locale('uk');

    this.service.getDiagramJson(d.booking.bookingID).subscribe((data: any) => {
      this.lineChartData = data;
    });
  }


  onYesClick(): void
  {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
   
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

}

