import { Component, Input, ViewChild } from '@angular/core';
import $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Config } from '../../app/Config';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { BedData } from 'app/models/BedData';
import { Prescription } from 'app/models/Prescription';
import { DoctorService } from 'app/doctor/doctor.service';
import { Booking } from 'app/models/Booking';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PrescriptionEditDialog } from 'app/modalWindows/llp/PrescriptionEdit/PrescriptionEditDialog';
import { CalendarViewerDialog } from 'app/modalWindows/llp/CalendarViewer/CalendarViewerDialog';
import { AddServiceDialog } from 'app/modalWindows/llp/AddService/AddServiceDialog';
import { PopoverDirective } from 'ngx-smart-popover';
import { PrescriptionAddDialog } from 'app/modalWindows/llp/PrescriptionAdd/PrescriptionAddDialog';
import { PrescriptionSelectDialog } from 'app/modalWindows/llp/PrescriptionSelect/PrescriptionSelectDialog';
import { ConfirmDialogData } from 'app/calendar/Day/day.component';
import { ConfirmDialog } from 'app/modalWindows/Confirm/ConfirmDialog';
import { ServiceData } from 'app/models/ServiceData';
import { Service } from 'app/models/Service';
import { Document } from 'app/models/Document';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { Token } from 'app/models/Token';
import { DocumentService } from 'app/modalWindows/Documents/DocumentService';
import { DocumentViewerDialogData } from 'app/modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { DocumentViewerDialog } from 'app/modalWindows/DocumentViewer/DocumentViewerDialog';
import { Residence } from 'app/models/Residence';
import { Course } from 'app/models/Course';
import { DiagramDynamicDialog } from 'app/modalWindows/llp/DiagramDynamic/DiagramDynamicDialog';
import { responsiveService } from 'app/responsiveService';

export class DynamicData {
    rezult: boolean;
  booking: Booking;
}

export class PrescriptionEditData {
    rezult: boolean;
    stop = false;
    currentPrescription: Prescription;
    prescriptions: Prescription[] = [];
    selectedDate: Date;
    canEdit: boolean;
}

export class PrescriptionAddData {
    rezult: boolean;
    newPrescription: Prescription;
    templatePrescription: Prescription;
    copyName: boolean = true;
}

export class ServiceAddData {
    rezult: boolean;
    selectedService: ServiceData;
    serviceToSave: Service;
    patient: Patient;
    residence: Residence;
    selectedTPRef: number;
    selectedAccountTypeRef: number;
    serviceSearchText: string;
}


@Component({
    selector: 'app-llp',
    templateUrl: './llp.component.html',
    styleUrls: ['./llp.component.css']
})

export class LLPComponent {

    popoverHideSubscribtion: Subscription;

    private _selectedPatient: BedData;
    private _selectedCourse: number;
    private _selectedStationary: number;//residenceID
    private _canEditLLP: boolean;
    prevDate: Date;
    nextDate: Date;

    private _selectedResidence: Residence;

    private _selectedCourseRow: Course;

    prescriptions: Prescription[] = [];

    bookings: Booking[] = [];

    documents: Document[];

    @Input() set selectedPatient(value: BedData) {
        this._selectedPatient = value;
    }

    @Input('selected-Course') set selectedCourse(value: number) { this._selectedCourse = value; }

    @Input('canEdit-LLP') set canEditLLP(value: boolean) { this._canEditLLP = value; }

    @Input('selected-Stationary') set selectedStationary(value: number) {
        this._selectedStationary = value;
        console.log(this._selectedStationary);
        this.residenceChange(value);
    }

    @Input('selected-Residence') set selectedResidence(value: Residence) {
        this._selectedResidence = value;
    }

    @Input('selected-CourseRow') set selectedCourseRow(value: Course) {
        this._selectedCourseRow = value;
    }

    public isTablet: boolean;

    constructor(private router: Router, _config: Config, private datePipe: DatePipe,
        private service: DoctorService,
        private lservice: LoginService,
        private documentService: DocumentService,
        private responsiveService: responsiveService,
        private sanitizer: DomSanitizer, public dialog: MatDialog) {
        this.prevDate = new Date();
        this.nextDate = new Date();
        this.prevDate.setDate(this.prevDate.getDate() - 3);
        this.nextDate.setDate(this.nextDate.getDate() + 3);
        this.collapseDesc = false;
    }

    ngOnInit() {
        this.popoverHideSubscribtion = this.service.popHided().subscribe(r => this.hidePopover());
        this.onResize();
        this.responsiveService.checkWidth();
    }

    onResize() {
        var _that = this;
        this.responsiveService.getTabletStatus().subscribe(isTablet => {
            _that.isTablet = isTablet;
            this.isTablet = isTablet;
        });
    }

    AllowAddService() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null &&
            permissions.SecurityActions_ServiceList_acAssignServices == 'True')
            return true;
        else return false;
    }

    AllowCancel() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null &&
            permissions.SecurityActions_Booking_Execution_Cancel == 'True')
            return true;
        else return false;
    }

    popover: any;
    showPop(pop) {
        this.popover = pop;
    }

    hidePopover() {
        this.popover.hide();
    }

    collapse: boolean = false;
    collapseB: boolean = false;
    collapseDesc: boolean = false;

    residenceChange(residenceId: number) {
        this.prescriptions = [];
        this.bookings = [];
        if (residenceId == null) return;

        var _that = this;

        this.service.getPrescriptions(residenceId).subscribe((data: Prescription[]) => {
            _that.prescriptions = data;
            _that.sortPrescriptions();
            _that.collapse = false;
        });

        this.service.getOtherBookings(residenceId).subscribe((data: Booking[]) => {
            _that.bookings = data;
            _that.sortBookings();
            _that.collapseB = false;
        });
    }

    refreshData() {
        if (this._selectedResidence) {
            this.residenceChange(this._selectedResidence.residenceID);
        }
    }

    //1 = a-z
    //2 = date
    sortMode: string = "2";

    sortPrescriptions() {
        if (this.sortMode == "2")
            this.prescriptions = this.prescriptions.sort((a, b) => (a.prescriptionStartDate > b.prescriptionStartDate ? -1 : 1));
        else if (this.sortMode == "1") this.prescriptions.sort((a, b) => a.prescriptionMedicationName.localeCompare(b.prescriptionMedicationName, 'uk-ua'));
    }


    //1 = a-z
    //2 = date
    sortBookingsMode: string = "2";

    sortBookings() {
        if (this.sortBookingsMode == "2")
            this.bookings = this.bookings.sort((a, b) => (a.bookingDate > b.bookingDate ? -1 : 1));
        else if (this.sortBookingsMode == "1")
            this.bookings = this.bookings.sort((a, b) => this.getBookingPathName(a).localeCompare(this.getBookingPathName(b), 'uk-ua'));
    }



    getPrescriptionDesc(p: Prescription) {
        return p.drugFrequencyKindName + "; " + p.drugRoutingKindName + ";";
    }

    getPrescriptionName(p: Prescription) {
        if (p.prescriptionDose == null || p.prescriptionDose == 1)
            return p.prescriptionMedicationName;
        else
            return p.prescriptionMedicationName + '*' + p.prescriptionDose;
    }

    TodayDate() {
        this.prevDate = new Date();
        this.nextDate = new Date();
        this.prevDate.setDate(this.prevDate.getDate() - 3);
        this.nextDate.setDate(this.nextDate.getDate() + 3);
        this.selectedCol = 0;
    }

    PrevDate() {
        this.prevDate.setDate(this.prevDate.getDate() - 7);
        this.nextDate.setDate(this.nextDate.getDate() - 7);
        this.selectedCol = 0;
    }

    NextDate() {
        this.prevDate.setDate(this.prevDate.getDate() + 7);
        this.nextDate.setDate(this.nextDate.getDate() + 7);
        this.selectedCol = 0;
    }

    displayDate() {
        if (this.prevDate == null || this.nextDate == null) return "";
        let from = this.datePipe.transform(this.prevDate, "dd MMMM");
        let to = this.datePipe.transform(this.nextDate, "dd MMMM");
        return from + " - " + to;
    }

    getColDisplayDay(number: number) {
        let date = this.getDateByNumber(number);
        if (date == null) return "";
    if (date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) return "Сьогодні";
        return this.datePipe.transform(date, "EE, dd");
    }

    getDateByNumber(number: number): Date {
        if (this.prevDate == null) return null;
        let dateToReturn = new Date(this.prevDate);
        dateToReturn.setDate(this.prevDate.getDate() + (number - 1));
        return dateToReturn;
    }

    selectedCol: number = 0;
    selectCol(number: number) {
        /* this.service.getPrescriptions(_that._selectedStationary).subscribe((data: Prescription[]) => {
       _that.prescriptions = data;
       _that.sortPrescriptions();
       _that.collapse = false;
     });*/

        this.selectedCol = number;

        let date = this.getDateByNumber(number);
        // try catch
        this.openDialogCalendarViewer(null, date).subscribe(result => {
            if (result.rezult) {
                //update current prescription
            }
            else {
                //refresh old not edited values
            }

        });
    }

    getSelectedColColor(number: number) {
        //if (this.selectedCol == number) return "#d9edf7"; else return "white";
        let date = this.getDateByNumber(number);
        if (date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) { return "#d9edf7"; }
        return "white";

    }
    getSelectedColShadow(number: number) {
        let date = this.getDateByNumber(number);
        if (date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) {
            return "inset 0 2px 0 #3361CF, inset 0 -1px 0 #dee2e6";
        }
        return "inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6";
    }

    getSelectedColTextColor(number: number) {
        let date = this.getDateByNumber(number);

        if (this.selectedCol == number) { return "#3361CF"; }
        else if (date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0))
            return "#47505A";
        return "#748599";
    }

    getSelectedColTextSize(number: number) {
        if (this.selectedCol == number) { return "500"; }
        return "400";
    }

    getBookingsByDate(number: number, p: Prescription) {
        let colDate = this.getDateByNumber(number);
        colDate.setHours(0, 0, 0, 0);

        if (p.bookings == null || p.bookings.length == 0) return null;

        let bookingsByDate: Booking[] = [];

        for (let b of p.bookings) {
            let bdate = new Date(b.bookingDate);
            bdate.setHours(0, 0, 0, 0);
            if (bdate.valueOf() == colDate.valueOf()) bookingsByDate.push(b);
        }

        return bookingsByDate;
    }

    getBookingCount(number: number, p: Prescription) {

        let bookingsByDate: Booking[] = this.getBookingsByDate(number, p);
        if (bookingsByDate == null || bookingsByDate.length == 0) return "";
        else if (bookingsByDate.length > 0) {
            if (bookingsByDate.every(r => r.bookingStatusRef == "CNC")) {
                return "";// return text in geticon
            }
            else {
                let cmpb: number = bookingsByDate.filter(r => r.bookingStatusRef == "CMP").length;
                return cmpb + "/" + bookingsByDate.length;
            }
        }
    }

    getPrescBookingIcon(number: number, p: Prescription) {
        let bookingsByDate: Booking[] = this.getBookingsByDate(number, p);
        if (bookingsByDate == null || bookingsByDate.length == 0) return "";
        else if (bookingsByDate.length > 0) {
            if (bookingsByDate.every(r => r.bookingStatusRef == "CMP"))//every CMP - green check
                return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-check" style="color:#3B7A00;width:16px;cursor:default"></i>');
            else if (bookingsByDate.every(r => r.bookingStatusRef == "PLN"))//every PLN - blue clock
                return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-clock-o" style="color:#1874C4;width:16px;cursor:default"></i>');
            else if (bookingsByDate.every(r => r.bookingStatusRef == "CNC"))// every CNC - red word Скасовано
                return this.sanitizer.bypassSecurityTrustHtml('<p style="color:#C60000;font-size:12px;width:50px;cursor:default;margin-bottom:0px">Скасовано</p>');

            else if (bookingsByDate.some(r => r.bookingStatusRef == "PLN"))//any PLN - blue clock
                return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-clock-o" style="color:#1874C4;width:16px;cursor:default"></i>');

            else if (bookingsByDate.every(r => r.bookingStatusRef == "CMP" || r.bookingStatusRef == "CNC"))//every CMP or CNC - green check
                return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-check" style="color:#3B7A00;width:16px;cursor:default"></i>');

        }
    }


    bookingInDate(number: number, b: Booking) {
        let date = this.getDateByNumber(number);
        date.setHours(0, 0, 0, 0);

        let bDate = new Date(b.bookingDate);
        bDate.setHours(0, 0, 0, 0);

        if (bDate.valueOf() == date.valueOf()) return true;
        else return false;
    }
    popDisabled(number: number, b: Booking) {
        return !this.bookingInDate(number, b) || this._canEditLLP == false;
    }


    getBookingCountBool(number: number, p: Prescription) {
        let bookingsByDate: Booking[] = this.getBookingsByDate(number, p);

        if (bookingsByDate == null || bookingsByDate.length == 0) return true;
        else if (bookingsByDate.length > 0) return false;
    }

    getCursor(number: number, p: Prescription) {
        let bookingsByDate: Booking[] = this.getBookingsByDate(number, p);

        if (bookingsByDate == null || bookingsByDate.length == 0) return "unset";
        else if (bookingsByDate.length > 0) return "pointer";
    }

    getBookingCursor(number: number, b: Booking) {
        let date = this.getDateByNumber(number);
        date.setHours(0, 0, 0, 0);

        let bDate = new Date(b.bookingDate);
        bDate.setHours(0, 0, 0, 0);

        if (bDate.valueOf() == date.valueOf()) return "pointer";
        else return "unset";
    }

    getBookingPathName(b: Booking) {
        return b.serviceParent + " / " + b.bookingName;
    }

    getHeaderMinWidth() {
        if (this.collapseDesc) return "300px";
        else return "485px";
    }

    getHeaderWidth() {
        if (this.collapseDesc) return "300px";
        else return "auto";
    }

    getmedNameWidth() {
        if (this.collapseDesc) return "calc(100% - 50px)";
        else return "calc(40% - 20px)";
    }

    canClickBookingDocument(b: Booking) {
        if (b.bookingDocumentRef != null) {
            return { 'color': '#3361CF', 'cursor': 'pointer' };
        } else return { 'color': '#47505A', 'cursor': 'default' };
    }

    getRowHeight() {
        if (this.isTablet == true) return "30px";
        else return "48px";
    }

    getRefreshButtonText() {
    if (this.isTablet) return ""; else return "Сьогодні";
    }

    getLineHeightForRowData() {
        if (this.isTablet) return 1.2; else return 1.5;
    }

    bookingDocumentView(b: Booking) {
        if (b.bookingDocumentRef != null) {
            this.documentService.getDocumentRow(b.bookingDocumentRef).subscribe((data: Document) => {
                this.documentView(data);
            });
        }
    }

    documentView(doc: Document) {
        let d: DocumentViewerDialogData = new DocumentViewerDialogData();
        d.content = doc.documentHTML;

        this.openDocumentDialog(d);
    }

    openDocumentDialog(d: DocumentViewerDialogData): Observable<DocumentViewerDialogData> {

        const dialogRef = this.dialog.open(DocumentViewerDialog, {

            data: d,

            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }

  openDiagramDialog(d: DynamicData): Observable<DynamicData> {

    const dialogRef = this.dialog.open(DiagramDynamicDialog, {

      data: d,

      hasBackdrop: true,
      height: '80%',
      disableClose: false,
      width: '80%',
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

  showDiagram(b: Booking) {
    let d: DynamicData = new DynamicData();
    //b.bookingID = 6399; //test booking
    d.booking = b;
    this.openDiagramDialog(d);
  }


    clickedBookings: Booking[] = [];
    setClickedCellBookings(number: number, p: Prescription) {
        this.clickedBookings = this.getBookingsByDate(number, p);
    }

    clickedBooking: Booking = null;
    setClickedCellBooking(b: Booking) {
        this.clickedBooking = b;
    }

    showAddMed() {
        return this.prescriptions.length == 0 && this._canEditLLP;
    }

    showAddBooking() {
        return this.bookings.length == 0 && this._canEditLLP;
    }

    stopALL() {
        let body: Prescription = new Prescription();
        body.courseID = this._selectedCourse;
        body.patientRef = this._selectedPatient.patient.patientID;
        body.assignmentServiceResidenceRef = this._selectedStationary;

        let data: ConfirmDialogData = new ConfirmDialogData();
        data.message = "Скасувати всі лікарські призначення для пацієнта " + this._selectedPatient.patient.patientName + "?";
        data.YesNoButton = true;
        this.openConfirmDialog(data).subscribe(result => {
            if (result.rezult) {

                this.service.stopAllPrescriptions(body).subscribe((data: Prescription) => {
                    var _that = this;

                    // refresh data
                    this.service.getPrescriptions(_that._selectedStationary).subscribe((data: Prescription[]) => {
                        _that.prescriptions = data;
                        _that.sortPrescriptions();
                        _that.collapse = false;
                    });
                });
            }
        });

    }

    prescriptionClick(prescription: Prescription) {
        var _that = this;
        // refresh data need to desctop client
        /* this.service.getPrescriptions(_that._selectedStationary).subscribe((data: Prescription[]) => {
           _that.prescriptions = data;
           _that.sortPrescriptions();
           _that.collapse = false;
         });*/


        // try catch
        this.openDialogPrescriptionEdit(prescription).subscribe(result => {
            if (result.rezult) {

                if (result.stop) {

                    this.service.stopPrescription(result.currentPrescription).subscribe((data: Prescription) => {


                        // refresh data
                        this.service.getPrescriptions(_that._selectedStationary).subscribe((data: Prescription[]) => {
                            _that.prescriptions = data;
                            _that.sortPrescriptions();
                            _that.collapse = false;
                        });
                    });

                }
                else {
                    this.service.updatePrescription(result.currentPrescription).subscribe((data: Prescription) => {
                        var _that = this;

                        // refresh data
                        this.service.getPrescriptions(_that._selectedStationary).subscribe((data: Prescription[]) => {
                            _that.prescriptions = data;
                            _that.sortPrescriptions();
                            _that.collapse = false;
                        });
                    });
                }
            }
            else {
                //refresh old not edited values
            }

        });
    }

    prescriptionAddClick() {
        // try catch
        this.openDialogPrescriptionAdd().subscribe(result => {
            if (result.rezult) {
                //call db save
                this.newPrescriptionSave(result.newPrescription);
            }
            else { }
        });
    }

    newPrescriptionSave(newPrescription: Prescription) {
        this.service.createPrescription(newPrescription).subscribe((data: Prescription) => {
            var _that = this;

            // refresh data
            this.service.getPrescriptions(_that._selectedStationary).subscribe((data: Prescription[]) => {
                _that.prescriptions = data;
                _that.sortPrescriptions();
                _that.collapse = false;
            });
        });
    }

    addBooking() {
        this.openDialogAddService().subscribe(result => {
            if (result.rezult) {
                var _that = this;
                result.serviceToSave.courseRef = this._selectedCourse;
                result.serviceToSave.patientRef = this._selectedPatient.patient.patientID;
                result.serviceToSave.residenceRef = this._selectedStationary;

                this.service.createService(result.serviceToSave).subscribe((service: Service) => {

                    this.service.getOtherBookings(this._selectedStationary).subscribe((data: Booking[]) => {
                        _that.bookings = data;
                        _that.sortBookings();
                        _that.collapseB = false;
                    });

                });
            }
            else {
            }
        });
    }

    addPrescByTemplate() {
        //1 open select template Dialog
        this.openDialogTemplateSelect().subscribe(result => {
            if (result.rezult) {
                if (result.templatePrescription != null) {
                    this.openDialogPrescriptionAdd(result.templatePrescription).subscribe(result => {
                        if (result.rezult) {
                            //call db save
                            this.newPrescriptionSave(result.newPrescription);
                        }
                        else { }
                    });
                }

            }
            else {
                //refresh old not edited values
            }
        });
    }

    addPrescriptionCopy(p: Prescription) {
        this.openDialogPrescriptionAdd(p, false).subscribe(result => {
            if (result.rezult) {
                //call db save
                this.newPrescriptionSave(result.newPrescription);
            }
            else { }
        });
    }

    openConfirmDialog(d: ConfirmDialogData): Observable<ConfirmDialogData> {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: d,
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }

    openDialogTemplateSelect(): Observable<PrescriptionAddData> {
        let d: PrescriptionAddData = new PrescriptionAddData();
        d.newPrescription = null;
        d.templatePrescription = null;

        const dialogRef = this.dialog.open(PrescriptionSelectDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    openDialogPrescriptionAdd(template: Prescription = null, copyName = true): Observable<PrescriptionAddData> {
        let d: PrescriptionAddData = new PrescriptionAddData();
        d.newPrescription = new Prescription();
        d.newPrescription.prescriptionTotalQuantity = 1;
        d.newPrescription.courseID = this._selectedCourse;
        d.newPrescription.patientRef = this._selectedPatient.patient.patientID;
        d.newPrescription.assignmentServiceResidenceRef = this._selectedStationary;
        d.templatePrescription = template;
        d.copyName = copyName;

        const dialogRef = this.dialog.open(PrescriptionAddDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    openDialogPrescriptionEdit(prescription: Prescription): Observable<PrescriptionEditData> {
        let d: PrescriptionEditData = new PrescriptionEditData();
        d.currentPrescription = prescription;

        d.prescriptions = this.prescriptions;

        d.currentPrescription.courseID = this._selectedCourse;
        d.currentPrescription.patientRef = this._selectedPatient.patient.patientID;
        d.currentPrescription.assignmentServiceResidenceRef = this._selectedStationary;

        d.canEdit = this._canEditLLP;

        const dialogRef = this.dialog.open(PrescriptionEditDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    openDialogCalendarViewer(prescription: Prescription, date: Date): Observable<PrescriptionEditData> {
        let d: PrescriptionEditData = new PrescriptionEditData();
        d.canEdit = this._canEditLLP;
        d.currentPrescription = prescription;
        d.prescriptions = this.prescriptions;
        d.selectedDate = date;

        const dialogRef = this.dialog.open(CalendarViewerDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }


    openDialogAddService(): Observable<ServiceAddData> {
        let d: ServiceAddData = new ServiceAddData();
        d.patient = this._selectedPatient.patient;
        d.residence = this._selectedResidence;
        d.residence.courseDefaultAccountRef = this._selectedCourseRow.courseDefaultAccountRef;

        const dialogRef = this.dialog.open(AddServiceDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }



}
