import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { DataService } from 'app/calendar/calendar.service';
import { User } from 'app/models/User';
import { OwlDateTimeFormats, DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS, OwlDateTimeComponent } from 'ng-pick-datetime';
import { Moment } from 'moment';
import { JournalData } from 'app/models/JournalData';

@Component({
  selector: 'UserJournalsDialog',
  templateUrl: './UserJournalsDialog.html',
  styleUrls: ['./UserJournalsDialog.css'],
})

export class UserJournalsDialog {

  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  journal1: JournalData[] = null;
  journal2: JournalData[] = null;
  journal3: JournalData[] = null;
  journal4: JournalData[] = null;
  currentJournalName: string = "BookDischargeDefault";
  loading: boolean = false;
  journal1Loaded: boolean = false;
  journal2Loaded: boolean = false;
  journal3Loaded: boolean = false;
  journal4Loaded: boolean = false;

  nameSearchtext1: string = "";
  numberSearchtext1: string = "";
  numberEHSearchtext1: string = "";
  diagnozSearchtext1: string = "";

  nameSearchtext2: string = "";
  numberSearchtext2: string = "";
  numberEHSearchtext2: string = "";
  diagnozSearchtext2: string = "";

  nameSearchtext3: string = "";
  numberSearchtext3: string = "";
  numberEHSearchtext3: string = "";
  diagnozSearchtext3: string = "";

  nameSearchtext4: string = "";
  numberSearchtext4: string = "";
  numberEHSearchtext4: string = "";
  diagnozSearchtext4: string = "";

  constructor(private service: DataService, public dialogRef: MatDialogRef<UserJournalsDialog>, public dialog: MatDialog, private datePipe: DatePipe) {
    var _that = this;
    this.dateFrom.setDate(this.dateFrom.getDate() - 7);
    //this.loadData(this.currentJournalName);
  }

  //need load only active journal here
  loadData(name: string) {

    let _that = this;
    let dFrom: string = this.datePipe.transform(this.dateFrom, "yyyyMMdd");
    let dTo: string = this.datePipe.transform(this.dateTo, "yyyyMMdd");

    if (name == "BookDischargeDefault" && !this.journal1Loaded) {
      this.journal1 = null;
      this.loading = true;
      this.service.getJournalData(dFrom, dTo, "BookDischargeDefault").subscribe((data: JournalData[]) => { _that.journal1 = data; _that.loading = false; _that.journal1Loaded = true; });
    }
    else if (name == "BookRejectDefault" && !this.journal2Loaded) {
      this.journal2 = null;
      this.loading = true;
      this.service.getJournalData(dFrom, dTo, "BookRejectDefault").subscribe((data: JournalData[]) => {
        _that.journal2 = data; _that.loading = false; _that.journal2Loaded = true;
      });
    }
    else if (name == "BookDeceaseDefault" && !this.journal3Loaded) {
      this.journal3 = null;
      this.loading = true;
      this.service.getJournalData(dFrom, dTo, "BookDeceaseDefault").subscribe((data: JournalData[]) => { _that.journal3 = data; _that.loading = false; _that.journal3Loaded = true; });
    }
    else if (name == "BookDepartmentMoveTo" && !this.journal4Loaded) {
      this.journal4 = null;
      this.loading = true;
      this.service.getJournalData(dFrom, dTo, "BookDepartmentMoveTo").subscribe((data: JournalData[]) => { _that.journal4 = data; _that.loading = false; _that.journal4Loaded = true; });
    }
  }

  dateFromChanged() {
    this.journal1Loaded = this.journal2Loaded = this.journal3Loaded = this.journal4Loaded = false;
    //this.loadData(this.currentJournalName);
  }

  dateToChanged() {
    this.journal1Loaded = this.journal2Loaded = this.journal3Loaded = this.journal4Loaded = false;
    //this.loadData(this.currentJournalName);
  }

  onYesClick(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateFormat(date: Date) {
    if (date) {
      return this.datePipe.transform(date, "dd.MM.yyyy")
    }
    return "";
  }

  journalChanged(name: string) {
    this.currentJournalName = name;
    //this.loadData(name);
  }

  table1Filter(row: JournalData, numberSearchtext: string, numberEHSearchtext: string, nameSearchtext: string, diagnozSearchtext: string) {
    let exist: boolean = true;

    const searchValueNumber = numberSearchtext.toUpperCase();
    const searchValueEHNumber = numberEHSearchtext.toUpperCase();
    const searchValuePatientName = nameSearchtext.toUpperCase();
    const searchValueDiagnoz = diagnozSearchtext.toUpperCase();

    if (numberSearchtext != "" && row != null) {
      if (row.courseSequence2NumberString == null || !(row.courseSequence2NumberString.toUpperCase().indexOf(searchValueNumber) >= 0)) exist = false;
    }

    if (numberEHSearchtext != "" && row != null) {
      if (row.courseEhealthNumber == null || !(row.courseEhealthNumber.toUpperCase().indexOf(searchValueEHNumber) >= 0)) exist = false;
    }

    if (nameSearchtext != "" && row != null) {
      if (row.patientName == null || !(row.patientName.toUpperCase().indexOf(searchValuePatientName) >= 0)) exist = false;
    }

    if (diagnozSearchtext != "" && row != null) {
      if (row.startDiagnosis == null || !(row.startDiagnosis.toUpperCase().indexOf(searchValueDiagnoz) >= 0)) exist = false;
    }

    return exist;
  }

}

