import { Component, Inject } from '@angular/core';
import { Dictionary } from '../../../models/Dictionary';
import { ResidenceCategory } from '../../../models/ResidenceCategory';
import { ResidenceCreateService } from '../ResidenceCreateService';
import { DialogData } from '../../../calendar/Day/day.component';
import { Observable, Subscription } from 'rxjs';
import { BedData } from '../../../models/BedData';
import { Document } from '../../../models/Document';
import { DiagnosisSelectDialog } from '../../DiagnosisSelect/DiagnosisSelectDialog';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosData, DocumentEditData } from '../ResidenceCreateDialog';
import { Router } from '@angular/router';
import { Sequence } from '../../../models/Sequence';
import { Document066Dialog } from 'app/modalWindows/Documents/066/Document066Dialog';
import { Config } from 'app/Config';
import { DataService } from 'app/calendar/calendar.service';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'residence-decease',
  templateUrl: './residence.decease.html',
  styleUrls: ['./residence.decease.css'],
})
export class ResidenceDeceaseComponent {



  doctors: Dictionary[];
  diagnosis: Dictionary[];
  users: Dictionary[];
  tempDate: Date;
  tempUser: string;


  constructor(private service: ResidenceCreateService, private dataService: DataService, private router: Router, public dialog: MatDialog, private _config: Config, private lservice: LoginService)
  {
    var _that = this;

    this.service.residenceData.childRoute = "decease";

    this.service.getDiagnozis().subscribe((data: Dictionary[]) => { _that.diagnosis = data; });
    this.service.getDoctors().subscribe((data: Dictionary[]) => { _that.doctors = data; });
    this.service.getDictionary("Користувачі").subscribe((data: Dictionary[]) => { _that.users = data; });

    this.tempDate = this.service.endDate;
    this.service.endDate = new Date();

    this.tempUser = this.service.userRef;
    this.service.userRef = lservice.UserToken.userLogin;
  }

  navigateToMain() {
    this.service.endDate = this.tempDate;
    this.service.userRef = this.tempUser;

    this.router.navigate(['.', { outlets: { 'residence': 'main' } }]);
  }

  ngOnInit() {

  }

  //for select diagnosis
  onDiagnosClick(data: BedData) {
    this.openDialog().subscribe(result => {

      if (result != null && result.rezult)//if success in modal dialog
      {
        if (result.newDiagnos.dictionaryCode != "") {
          this.service.CurrentDiagnos.dictionaryID = result.newDiagnos.dictionaryID;
          this.service.CurrentDiagnos.dictionaryCode = result.newDiagnos.dictionaryCode;
          this.service.CurrentDiagnos.dictionaryName = result.newDiagnos.dictionaryName;
        }
      }
      else {
        // something if false (pressed no)
      }
    });
  }

  createDocs() {
    //this.service.getdocuments(this.data.patient.patientID, -1).subscribe((data: Document[]) => { _that.documents = data; });
    let data: DocumentEditData = new DocumentEditData();
    data.document = null;
    data.patient = this.service.residenceData.patient;
    data.residence = this.service.residenceData.residence;
    this.open066Document(data);
    let guid: string = this._config.get("066TemplateStaticGuid");

    this.service.getdocuments(data.patient.patientID, data.residence.residenceCourseRef).subscribe((docs: Document[]) => {

      if (docs.find(r => r.documentTemplateStaticGUIDString == guid)) { }
      else {
        this.open066Document(data).subscribe(result => {
          if (result.rezult == true) {
            result.document.json = result.jsonToSave;
            result.document.courseRef = data.residence.residenceCourseRef;
            result.document.patientRef = data.patient.patientID;
            result.document.documentTemplateStaticGUIDString = guid;
            result.document.documentTemplateStaticGUID = guid;

            this.dataService.updateDocumentJson(result.document).subscribe((rez => { }));
          }
        });
      }
    });
  }

  open066Document(d: DocumentEditData): Observable<DocumentEditData> {

    const dialogRef = this.dialog.open(Document066Dialog, {
      data: d,
      width: '1380px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panelLeft'
    });
    return dialogRef.afterClosed();
  }

  //for select diagnosis
  openDialog(): Observable<DiagnosData> {

    let d: DiagnosData = new DiagnosData();
    d.diagnosis = this.diagnosis;
    d.oldDiagnos = this.service.CurrentDiagnos;
    d.newDiagnos = new Dictionary("");
    const dialogRef = this.dialog.open(DiagnosisSelectDialog, {
      width: '600px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

}

