import { OnInit, Component, ChangeDetectorRef, NgZone, PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from '../models/Patient';
import { Sequence } from '../models/Sequence';
import { Venue } from '../models/Venue';
import $ from 'jquery';
import { Subject, Subscription, Observable } from 'rxjs';
import { RoomData } from '../models/RoomData';
import { BedData } from '../models/BedData';
import { DropBedData } from '../models/DropBedData';
import { ResidenceCategory } from '../models/ResidenceCategory';
import { ResidenceCreateDialog, DocumentEditData, DocumentViewerDialogData, EHFrameData } from '../modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepartmentData } from '../models/DepartmentData';
import { SequenceSelectDialog } from '../modalWindows/SequenceSelect/SequenceSelectDialog';
import { PatientSearchDialog } from '../modalWindows/PatientSearch/PatientSearchDialog';
import { Residence } from '../models/Residence';
import { ResidenceCreateService } from '../modalWindows/ResidenceCreate/ResidenceCreateService';
import { Document003Dialog } from 'app/modalWindows/Documents/003/Document003Dialog';
import moment from 'moment';
import { Document066Dialog } from 'app/modalWindows/Documents/066/Document066Dialog';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import dragula from 'dragula';
import { responsiveService } from "../responsiveService";
import { DoctorService } from './doctor.service';
import { DataService } from 'app/calendar/calendar.service';
import { Dictionary } from '../models/Dictionary';
import { Token } from '../models/Token';
import { Course } from 'app/models/Course';
import { Document } from 'app/models/Document';
import { DatePipe } from '@angular/common';
import { DialogData } from 'app/calendar/Day/day.component';
import { Stationary } from 'app/models/Stationary';
import { DocumentViewerDialog } from 'app/modalWindows/DocumentViewer/DocumentViewerDialog';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { DocumentEditorService } from 'app/DocumentEditor/DocumentEditorService';
import { EHFrameDialog } from 'app/modalWindows/EHFrame/EHFrameDialog';
import { Config } from 'app/Config';
import { DocumentTemplateSelectDialog, DocumentTemplateData } from 'app/modalWindows/DocumentTemplateSelect/DocumentTemplateSelectDialog';

@Pipe({ name: 'searchPatient' })

export class searchPatientPipe implements PipeTransform {
  public transform(values: BedData[], filter: string): BedData[] {
    if (!values || !values.length) return [];
    if (!filter) return values;

    filter = filter.toUpperCase();

    if (filter) {
      return values.filter(v => v.patient.patientName.toUpperCase().indexOf(filter) >= 0);
    }
  }
}

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css'],
  providers: [DoctorService]
})
export class DoctorComponent implements OnInit {

  myOptionsColor = {
    'theme': 'light'
  }

  query: string = "";

  isEhealth: boolean = false;

  isTablet: boolean;
  viewSelectedRoleText: string = "Лікуючий лікар";
  selectedRole: number = 1;

  public selectedPatient: BedData = null;
  public courses: Course[] = [];

  public selectedCourse: number = null;
  public selectedCourseRow: Course = null;

  public canEditLLP: boolean = false;

  public stationaryties: Stationary[] = [];
  public selectedStationary: number = null;//residenceID
  public selectedResidence: Residence = null;//residenceID

  public venueSequences: Sequence[];
  public selectedVenueSeqDep: Sequence;
  public venuePatients: BedData[] = [];

  public patients: BedData[] = [];

  public residenceCategories: ResidenceCategory[];
  users: Dictionary[];

  documents: Document[] = [];

  seqByCompanySub: Subscription;
  patinetByCompanySub: Subscription;

  useDocumentEditor: boolean = false;

  guid3: string;
  guid66: string;

  constructor(private service: DoctorService,
    private responsiveService: responsiveService,
    private calendarService: DataService,
    private residenceService: ResidenceCreateService,
    private documentEditorService: DocumentEditorService,
    private datePipe: DatePipe,
    private _config: Config,
    public dialog: MatDialog, private _ngZone: NgZone, private router: Router, private lservice: LoginService) {
    this.useDocumentEditor = this._config.get("DocumentEditorGuid") != "";

    this.isEhealth = lservice.UserToken && lservice.UserToken.userEhealthIsAuthorized;

    this.seqByCompanySub = this.lservice.updateSeqByCompanyBind().subscribe(id => this.updateSequences());
    this.patinetByCompanySub = this.lservice.updatePatientByCompanyBind().subscribe(companyGuid => this.loadPatientsForDoctor());


    this.calendarService.getStorage("WebResidenceDoctorRole").subscribe(r => {
      if (r == 1 || r == 2 || r == 3) {
        if (this.AllowRole1() && r == 1)
          this.onRoleSelect(1, false);
        else if (this.AllowRole2() && r == 2)
          this.onRoleSelect(2, false);
        else if (r == 3)
          this.onRoleSelect(3, false);
      }
      else {
        if (this.AllowRole1())
          this.onRoleSelect(1, true);
        else if (this.AllowRole2())
          this.onRoleSelect(2, true);
        else
          this.onRoleSelect(3, false);
      }//default load if no storage

      this.calendarService.getStorage("WebResidenceDoctorRoleSelectedVenueSeqDep").subscribe(c => {
        if (r == 2 && c != null && this.allowUseJournals()) {
          if (this.venueSequences && this.venueSequences.find(r => r.sequenceID == c))
            this.sequenceVenueChanged(c);
        }
      });
    });

    this.guid3 = this._config.get("003TemplateStaticGuid");
    this.guid66 = this._config.get("066TemplateStaticGuid");

  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    var _that = this;

    this.checkParallelWork();

    this.residenceService.getDictionary("Користувачі").subscribe((data: Dictionary[]) => { _that.users = data; });

    if (this.allowUseJournals())
      this.service.getSequences("RCH").subscribe((data: Sequence[]) => {
        _that.venueSequences = data;
      });

    this.calendarService.getResidenceCategories().subscribe((data: ResidenceCategory[]) => { _that.residenceCategories = data; });
  }

  onResize() {
    this.responsiveService.getTabletStatus().subscribe(isTablet => {
      this.isTablet = isTablet;
    });
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
    this.seqByCompanySub.unsubscribe();
    this.patinetByCompanySub.unsubscribe();
  }

  checkParallelWork() {
    this.service.CheckParalelWork().subscribe((data: Token) => {
      if (data.parallelWork) {
        this.router.navigate(['']);
        this.dialog.closeAll();
        this.lservice.clearToken();
        alert("parallel work detected");
      }
    });
  }

  updateSequences() {
    var _that = this;

    if (this.selectedRole == 2) this.selectedPatient = null;
    this.venuePatients = [];
    this.selectedVenueSeqDep = null;

    if (this.allowUseJournals())
      this.service.getSequences("RCH").subscribe((data: Sequence[]) => {
        _that.venueSequences = data;
      });
  }

  allowUseJournals() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Books_acBooks == 'True')
      return true;
    else return false;
  }

  AllowViewDocument() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_EMR_acCoursesDocuments == 'True')
      return true;
    else return false;
  }

  AllowRole1() {//Лікуючий лікар
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace_acViewDepartmentStationaryPatients == 'True')
      return true;
    else return false;
  }

  AllowRole2() {//Черговий лікар
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace_acViewUserStationaryPatients == 'True')
      return true;
    else return false;
  }

  AllowAny() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      (permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace_acViewUserStationaryPatients == 'True') ||
      (permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace_acViewDepartmentStationaryPatients == 'True')
    )
      return true;
    else return false;
  }

  onRoleSelect(id: number, setStorage: boolean = true) {
    this.selectedPatient = null;
    if (id == 1) {
      this.viewSelectedRoleText = "Лікуючий лікар";
      this.selectedRole = 1;
      if (setStorage) this.calendarService.setStorage("WebResidenceDoctorRole", 1).subscribe(r => { });
      this.loadPatientsForDoctor();
    }
    if (id == 2) {
      this.selectedRole = 2;
      this.viewSelectedRoleText = "Черговий лікар";
      if (setStorage) this.calendarService.setStorage("WebResidenceDoctorRole", 2).subscribe(r => { });

      this.calendarService.getStorage("WebResidenceDoctorRoleSelectedVenueSeqDep").subscribe(c => {
        if (c != null) {
          if (this.venueSequences && this.venueSequences.find(r => r.sequenceID == c))
            this.sequenceVenueChanged(c);
        }
      });
      //select sequence in future from storage, patient will select automatically
      //if (this.venuePatients && this.venuePatients.length > 0)
      //   this.selectPatient(this.venuePatients[0]);
    }
    if (id == 3) {
      this.selectedRole = 3;
      this.viewSelectedRoleText = "Лікар приймального";
      if (setStorage) this.calendarService.setStorage("WebResidenceDoctorRole", 3).subscribe(r => { });
    }
  }

  getHeaderHeight() {
    if (this.selectedRole == 1) return "165px";
    else if (this.selectedRole == 2) return "200px";
    else return "200px";
  }

  getContentHeight() {
    if (this.selectedRole == 1) return " calc(100% - 175px)";
    else if (this.selectedRole == 2) return " calc(100% - 215px)";
    else return " calc(100% - 215px)";
  }

  loadPatientsForDoctor() {
    var _that = this;

    this.service.getPatientsForDoctor1()
      .subscribe((data: BedData[]) => {
        _that.patients = data;

        if (_that.patients && _that.patients.length > 0)
          _that.selectPatient(_that.patients[0]);
      });
  }

  getPatientsCount() {
    if (this.selectedRole == 2) {
      if (this.selectedVenueSeqDep == null) return "";
      if (this.venuePatients) return this.venuePatients.length;
    }

    else if (this.selectedRole == 1) {
      if (this.patients) return this.patients.length;
    }

    return "";
  }

  getVenueSeqHeader() {
    if (this.selectedVenueSeqDep == null) return "Оберіть відділення";
    return this.selectedVenueSeqDep.sequenceName;
  }

  sequenceVenueChanged(selectedValue: number, update: boolean = true) {
    var _that = this;
    this.selectedPatient = null;

    this.calendarService.setStorage("WebResidenceDoctorRoleSelectedVenueSeqDep", selectedValue).subscribe(r => { });

    if (this.venueSequences != null)
      this.selectedVenueSeqDep = this.venueSequences.find(r => r.sequenceID == selectedValue);

    this.service.getPatientsForDoctor2(selectedValue.toString())
      .subscribe((data: BedData[]) => {
        _that.venuePatients = data;
        if (_that.venuePatients && _that.venuePatients.length > 0)
          _that.selectPatient(_that.venuePatients[0]);
      });
  }

  selectPatient(d: BedData) {
    this.selectedPatient = d;
    var _that = this;
    this.checkParallelWork();

    this.service.getcources(d.patient.patientID).subscribe((data: Course[]) => {
      _that.courses = data.filter(r => r.courseKindRef == "HSP");
      _that.selectedCourse = _that.courses.find(c => c.courseID == d.residence.residenceCourseRef).courseID;
      _that.selectedCourseRow = _that.courses.find(c => c.courseID == d.residence.residenceCourseRef);
      _that.selectedCourseChange();
      console.log();
    });

  }


  editDocumentIsDisabled() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Document_acEdit == 'True')
      return false;
    else return true;
  }

  selectedCourseChange() {
    var _that = this;

    //this.canEditLLP = this.selectedCourse == this.selectedPatient.residence.residenceCourseRef;

    this.service.getPatientStationaries(this.selectedCourse).subscribe((data: Stationary[]) => {
      _that.stationaryties = data;

      let current: Stationary = _that.stationaryties.find(c => c.residence.residenceID == _that.selectedPatient.residence.residenceID);

      if (current != null && current.residence != null) {
        _that.selectedStationary = current.residence.residenceID;
        _that.selectedResidence = current.residence;
        _that.selectedStationaryChange();
      }
      else if (_that.stationaryties.length > 0) {
        _that.selectedStationary = _that.stationaryties[0].residence.residenceID;
        _that.selectedResidence = _that.stationaryties[0].residence;
        _that.selectedStationaryChange();
      }
    });

    this.getDocuments();
  }

  //1 = a-z
  //2 = date
  sortMode: string = "2";

  getDocuments() {
    var _that = this;
    this.service.getdocuments(this.selectedPatient.patient.patientID, this.selectedCourse).subscribe((data: Document[]) => {
      _that.documents = data;
      _that.sortDocuments();
    });
  }

  sortDocuments() {
    if (this.sortMode == "2")
      this.documents = this.documents.sort((a, b) => (a.documentCreationDate < b.documentCreationDate ? -1 : 1));
    else if (this.sortMode == "1") this.documents.sort((a, b) => a.documentTemplateName.localeCompare(b.documentTemplateName));
  }

  documentView(doc: Document) {
    let data: DocumentEditData = new DocumentEditData();
    data.document = doc;
    data.patient = this.selectedPatient.patient;
    data.residence = this.selectedResidence;

    if (doc.documentTemplateStaticGUIDString == this.guid3 && this.editDocumentIsDisabled() == false) {
      this.open003Document(data).subscribe(result => {
        if (result.rezult == true) {
          this.documentUpdate(result);
        }
      });
    }
    else if (doc.documentTemplateStaticGUIDString == this.guid66 && this.editDocumentIsDisabled() == false) {
      this.open066Document(data).subscribe(result => {
        if (result.rezult == true) {
          this.documentUpdate(result);
        }
      });
    }
    else {
      let d: DocumentViewerDialogData = new DocumentViewerDialogData();
      this.service.getdocuments(this.selectedPatient.patient.patientID, this.selectedCourse).subscribe(async (data: Document[]) => {
        doc = data.find(r => r.documentID == doc.documentID);

        this.documents = data;
        this.sortDocuments();
        d.content = doc.documentHTML;
        this.openDocumentDialog(d);
      });
    }
  }

  open003Document(d: DocumentEditData): Observable<DocumentEditData> {

    const dialogRef = this.dialog.open(Document003Dialog, {
      data: d,
      width: '1380px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panelLeft'
    });
    return dialogRef.afterClosed();
  }


  open066Document(d: DocumentEditData): Observable<DocumentEditData> {
    const dialogRef = this.dialog.open(Document066Dialog, {
      data: d,
      width: '1380px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panelLeft'
    });
    return dialogRef.afterClosed();
  }

  openDocumentDialog(d: DocumentViewerDialogData): Observable<DocumentViewerDialogData> {

    const dialogRef = this.dialog.open(DocumentViewerDialog, {

      data: d,

      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

  selectedStationaryChange() {
    this.canEditLLP = (this.selectedCourse == this.selectedPatient.residence.residenceCourseRef) &&
      (this.selectedStationary == this.selectedPatient.residence.residenceID);
  }

  getStationaryInfo(stationary: Stationary) {
    let room: string = "";
    let bed: string = "";
    let seq: string = "";
    if (stationary.venue.room != null) room = stationary.venue.room;
    if (stationary.venue.venueName != null) bed = stationary.venue.venueName;
    if (stationary.venue.sequenceName != null) seq = stationary.venue.sequenceName;

    return seq + ', ' + room + ', ' + bed;
  }

  getDiagnoz(r: Residence) {
    if (r.residenceDiagnosis1 == null && r.residenceDiagnosis1Name == null) return "Діагноз не встановлений";
    let code = ""; if (r.residenceDiagnosis1 != null) code = r.residenceDiagnosis1;
    let name = ""; if (r.residenceDiagnosis1Name != null) name = r.residenceDiagnosis1Name;
    return code + " | " + name;
  }
  getDiadnozColor(r: Residence) {
    if (r.residenceDiagnosis1 == null && r.residenceDiagnosis1Name == null) return "#818795";
    else return "#47505A"
  }

  getVenueInfo(v: Venue) {
    if (v == null) return "немає даних про поселення";

    if (v.room == null) return v.venueName;

    var floor = v.roomPositionZ == null ? "" : "Поверх " + v.roomPositionZ + " | ";

    if (this.selectedRole == 1)
      return floor + v.room + " | " + v.venueName + " | " + v.sequenceName;
    else if (this.selectedRole == 2)
      return floor + v.room + " | " + v.venueName;
  }

  getCourseInfo(c: Course) {
    if (c == null) return "";

    let start = "";
    let end = "";
    let status = "";
    let diagnoz = "";
    if (c.courseStartDate != null)
      start = this.datePipe.transform(c.courseStartDate, "dd.MM.yyyy");
    if (c.courseEndDate != null)
      end = this.datePipe.transform(c.courseEndDate, "dd.MM.yyyy");
    let dateRange = start + "; ";
    if (c.courseEndDate) dateRange = end + " - " + start + "; ";

    if (c.courseStatusRef == "OPN") status = "Відкритий; ";
    else if (c.courseStatusRef == "CLS") status = "Закритий; ";
    else if (c.courseStatusRef == "HZD") status = "Госпіталізований; ";

    if (c.courseDiagnosis1 != null)
      diagnoz = c.courseDiagnosis1;

    return dateRange + status + diagnoz;
  }

  getCategoryColor(bed: BedData, displayEmpty: boolean = false) {
    let emptycolor: string = "white";
    if (displayEmpty) emptycolor = "#818795";

    if (bed.residence == null) return emptycolor;


    if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
      return emptycolor;
    }

    let cat: ResidenceCategory;

    if (this.residenceCategories != null)
      cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

    if (cat != null) {
      return cat.color;
    }
  }

  getCategoryColorText(bed: BedData, displayEmpty: boolean = false) {
    let emptyText: string = "";
    if (displayEmpty) emptyText = "не встановлено";

    if (bed.residence == null) return emptyText;


    if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
      return emptyText
    }

    let cat: ResidenceCategory;

    if (this.residenceCategories != null)
      cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

    if (cat != null) {
      return cat.residenceCategoryName;
    }
  }

  getIdentOrNoImage(pat: EHPatient) {
    if (pat.isPrePerson) {
      return "url(../../assets/images/NoIdentified.png)";
    }
    else {
      return "url(../../assets/images/Identified.png)";
    }
  }

  getPhoto() {
    if (this.selectedPatient) {
      if (this.selectedPatient.patient.patientSexRef == "MAL") return "url(../../assets/images/male.svg)";
      else if (this.selectedPatient.patient.patientSexRef == "FEM") return "url(../../assets/images/female.svg)";
      else
        return "url(../../assets/images/unknown.svg)";
    }
  }

  getPatientHeaderText() {
    if (this.selectedPatient == null) return "";
    if (this.selectedPatient.patient.patientAge == null) return this.selectedPatient.patient.patientName;
    return this.selectedPatient.patient.patientName + ", " + this.selectedPatient.patient.patientAge + "р.";
  }

  getPatientId() {
    if (this.selectedPatient == null) return null;
    return this.selectedPatient.patient.patientID;
  }

  getUser() {
    if (!this.users || this.selectedPatient.residence.userRef == "") return "";
    let user = this.users.find(r => r.dictionaryCode == this.selectedPatient.residence.userRef);
    if (user != null) return user.dictionaryName;
    return "";
  }

  bedEdit(data: BedData) {

    this.openDialogFromEdit(data, "").subscribe(result => {
      if (result != null && result.rezult)//if success in modal dialog
      {
        this.calendarService.updatePatient(result.patient).subscribe((rez => {
          result.patient = rez;
          data.patient = rez;
        }));
      }
      else {/* something if false (pressed no) */ }
    });
  }

  openDialogFromEdit(data: BedData, name: string): Observable<DialogData> {
    let d: DialogData = new DialogData();
    d.room = new Venue(-1, "Відділення");
    d.bed = new Venue(-2, name);
    d.patient = data.patient;
    d.residence = data.residence;
    d.disableResidence = true;

    const dialogRef = this.dialog.open(ResidenceCreateDialog, {
      data: d,
      width: '387px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

  collapsed: boolean = false;
  collapseDep() {
    this.collapsed = true;
  }

  expandDep() {
    this.collapsed = false;
  }

  getMainWidth() {
    if (this.collapsed) {
      return "calc(100% - 76px)";
    } else {
      return "calc(100% - 336px)";
    }
  }

  getHEaderPatientWidth() {
    if (this.isTablet && this.collapsed == false) {
      return "calc(100% - 1px)";
    } else {
      return "calc(100% - 160px)";
    }
  }

  headerCollapsed: boolean = false;
  collapsHeader() { this.headerCollapsed = true; }
  expandHeader() { this.headerCollapsed = false; }

  getMainContentHeight() {
    if (this.headerCollapsed) return " calc(100% - 88px)"; else return " calc(100% - 253px)";
  }

  getMainContentHMinWidth() {
    if (this.isTablet) return "650px"; else return "735px";
  }


  dateFormat(date: Date) {
    if (date) {
      return this.datePipe.transform(date, "dd.MM.yyyy HH:mm")
    }
    return "";
  }

  async documentEditOnWeb(d: Document) {

    let rez: string = await this.documentEditorService.generateDocEditURL(
      d.documentTemplateRef,
      this.selectedPatient.patient.patientID,
      this.selectedCourse,
      d.documentID);

    this.openFrame(rez);
  }

  async documentCreateOnWeb() {
    let data: DocumentTemplateData = new DocumentTemplateData();
    this.openDocumentTemplateSelectDialog(data).subscribe(async result => {
      if (result.rezult && result.documentTemplateRow != null) {

        let rez: string = await this.documentEditorService.generateDocCreateURL(
          result.documentTemplateRow.documentTemplateID,
          this.selectedPatient.patient.patientID,
          this.selectedCourse);

        this.openFrame(rez);
      }
    });
  }

  documentUpdate(result: DocumentEditData) {
    var _that = this;

    this.residenceService.updateDocument(result.document).subscribe((rez => {
      console.log(rez);
      this.getDocuments();
    }));
  }
  openFrame(url: string) {
    let frData: EHFrameData = new EHFrameData();
    frData.isDocument = true;
    frData.url = url;

    this.openFrameDialog(frData).subscribe(result => {
      this.getDocuments();
    });
  }

  openFrameDialog(d: EHFrameData): Observable<EHFrameData> {
    const dialogRef = this.dialog.open(EHFrameDialog, {
      data: d,
      height: '95%',
      width: '95%',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

  openDocumentTemplateSelectDialog(d: DocumentTemplateData): Observable<DocumentTemplateData> {
    const dialogRef = this.dialog.open(DocumentTemplateSelectDialog, {
      data: d,
      width: '387px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });
    return dialogRef.afterClosed();
  }
}


