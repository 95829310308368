import { Venue } from "./Venue";
import { RoomData } from "./RoomData";

export class DepartmentData {

  constructor(r: RoomData[])
  {
    this.rooms = r; 
  }

  public rooms: RoomData[] = [];
}
