import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dictionary } from '../../models/Dictionary';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import { AuthGuard } from '../../auth/auth.guard';
import { Sequence } from '../../models/Sequence';
import { Residence } from '../../models/Residence';
import { Document } from '../../models/Document';
import { DialogData } from '../../calendar/Day/day.component';
import { Account } from '../../models/Account';
import { Course } from '../../models/Course';
import { Patient } from '../../models/Patient';
import { LoginService } from '../Login/LoginService';

@Injectable()
export class ResidenceCreateService
{
  getHeaders() {
    let token: string = this.lservice.UserToken.userToken;
    let userlogin: string = this.lservice.UserToken.userLogin;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('charset', 'UTF-8')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer ' + token)
      .set('userlogin', userlogin);
    return headers;
  }

  //
  public residenceData: DialogData;
  startDate: Date = new Date();
  endDate: Date = new Date();
  CurrentDiagnos: Dictionary = new Dictionary("");
  residenceStatusRef: string;
  residenceCategory: number;
  courseArrivalType: string;
  residenceDesc: string;
  accountRef: number;
  userRef: string;
  bookRejectReason: string;
  stationaryDepartureType: string;
  serviceDispenseRequestId: string;
  //

  constructor(private http: HttpClient, private authGuard: AuthGuard, private lservice: LoginService) { }

  getDictionary(name:string): Observable<Dictionary[]> {
    let route: string;
    route = "/api/dictionary/GetByName/" + name;

    return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Dictionary[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getDiagnozis(): Observable<Dictionary[]> {
    let route: string;
    route = "/api/dictionary/GetDiagnozis";

    return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Dictionary[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getArrivalTypes(): Observable<Dictionary[]> {
    let route: string;
    route = "/api/dictionary/GetArrivalTypes";

    return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Dictionary[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getDoctors(): Observable<Dictionary[]> {
    let route: string;
    route = "/api/dictionary/GetDoctors";

    return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Dictionary[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getPatientAccounts(patientID: number): Observable<Account[]> {
    let route: string;
    route = "/api/account/GetPatientAccounts/" + patientID;

    return this.http.get<Account[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Account[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getResidenceCategories(): Observable<ResidenceCategory[]> {
    let route: string;
    route = "/api/residence/GetResidenceCategories";

    return this.http.get<ResidenceCategory[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: ResidenceCategory[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getSequences(type: string): Observable<Sequence[]> {
    let route: string;

    let CompanyGUID: string = null;
    if (this.lservice.SelectedCompany != null)
      CompanyGUID = this.lservice.SelectedCompany.CompanyGUID;

    route = "/api/sequence/GetSecuences/" + type + '/' + CompanyGUID;

    return this.http.get<Sequence[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Sequence[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getdocuments(patientRef: number, courseRef: number): Observable<Document[]> {
    let route: string;
    route = "/api/Document/GetDocuments/" + patientRef + '/' + courseRef;

    return this.http.get<Document[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Document[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getcources(patientID: number): Observable<Course[]> {
    let route: string;
    route = "/api/Course/GetPatientCourses/" + patientID;

    return this.http.get<Course[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Course[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  //POST need subscription
  //
  //
  createresidence(body: Residence): Observable<Residence> {
    let route: string;
    route = "/api/residence/CreateResidence";
    return this.http.post<Residence>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Residence) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  updateDocument(body: Document): Observable<Document> {
    let route: string;
    route = "/api/Document/SetDocumentJson";

    return this.http.post<Document>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Document) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }


  //////////////////
  //subscribtions

  //service to calendarComponent:

  private sequenceSbj = new Subject();
  // call onse for calendar component subscribtion
  sequenceUpdated() { return this.sequenceSbj.asObservable(); }
  // call from patientToSeq
  sequenceUpd() { this.sequenceSbj.next(); }

  //

  private mainSbj = new Subject();

  mainUpdated() { return this.mainSbj.asObservable(); }

  mainUpd() { this.mainSbj.next(); }
}
