import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import $ from 'jquery';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Config } from '../../Config';
import { EhealthService } from '../EhealthService';
import { Obj } from '@popperjs/core';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { PatientToSequenceData, PatientToQueueData, PatientSyncData } from 'app/modalWindows/PatientSearch/PatientSearchDialog';
import { MatDialog } from '@angular/material/dialog';
import { PatientToSequenceDialog } from 'app/modalWindows/PatientToSequence/PatientToSequenceDialog';
import { addToQueueDialog } from 'app/modalWindows/EHealth/addToQueue/addToQueueDialog';
import { syncPatientDialog } from 'app/modalWindows/EHealth/syncPatient/syncPatientDialog';
import { ResidenceCreateService } from 'app/modalWindows/ResidenceCreate/ResidenceCreateService';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'app-searchByNumber',
  templateUrl: './searchByNumber.component.html',
  styleUrls: ['./searchByNumber.component.css'],
  providers: [EhealthService]
})
export class SearchByNumberComponent {
  @Output() voted = new EventEmitter<boolean>();

  text: string;
  guid: string;
  url: string;
  EHDispences: serviceDispense[];

  constructor(private router: Router, _config: Config, private service: EhealthService, public dialog: MatDialog, private rservice: ResidenceCreateService, private lservice: LoginService)
  {
    this.guid = _config.get("EhealthInstalationGuid");
    this.url = _config.get("EHPortalApiURL");
  }

  ngOnInit() {
  }

  addToJournalIsDisabled() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Workflow_Patient_acRegisterForStationaryTreatment == 'True')
      return false;
    else return true;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }

    /*const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;*/
  }

  async search()
  {
    this.EHDispences = [];

    await this.service.searchEhealthByNumber(this.text, this.guid, this.url, this.dialog).then(data => {
      this.EHDispences = data.serviceDispense;
      if (data.serviceDispense) {
        this.EHDispences.forEach(r => {
          this.service.getPatientsByDispenseNumber(r.serviceDispenseRequestId, r.patientRef).subscribe((p: Patient) =>
          {
            r.DEPatient = p;
          })
        });
      }
     });
  }


  patientClick(patient: Patient, dispence: serviceDispense) {
    // try catch
    this.openDialogPatientToSequence(patient, dispence.number).subscribe(result => {
      if (result.rezult) {
        this.voted.emit(true);
      }
      else {
      }

    });
  }

  patientToQueueClick(patient: Patient, dispence: serviceDispense) {
    this.openDialogPatientToQueue(patient, dispence).subscribe(result => {
      if (result.rezult) {
        this.voted.emit(true);
      }
    });
  }

  patientSync(patient: Patient, dispence: serviceDispense) {
    this.openDialogSyncPatient(patient, dispence).subscribe(result => {
      if (result.rezult) {
        this.voted.emit(true);
      }
    });
  }

  openDialogPatientToSequence(patient: Patient, serviceDispenseRequestId: string): Observable<PatientToSequenceData> {
    let d: PatientToSequenceData = new PatientToSequenceData();
    d.patient = patient;
    d.dispenceNumber = serviceDispenseRequestId;

    const dialogRef = this.dialog.open(PatientToSequenceDialog, {
      width: '430px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }

  openDialogPatientToQueue(patient: Patient, dispence: serviceDispense): Observable<PatientToQueueData> {
    let d: PatientToQueueData = new PatientToQueueData();
    d.patient = patient;
    d.dispence = dispence;

    const dialogRef = this.dialog.open(addToQueueDialog, {
      width: '400px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }

  openDialogSyncPatient(patient: Patient, dispence: serviceDispense): Observable<PatientSyncData> {
    let d: PatientSyncData = new PatientSyncData();
    d.patient = patient;
    d.dispence = dispence;

    const dialogRef = this.dialog.open(syncPatientDialog, {
      width: '400px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }
}
