export class Token {

  constructor()
  {
  }

  public userIsAuthorized: boolean = false;

  public message: string = "";

  public userLogin: string = "";

  public userIsNT: boolean;

  public userPassword: string = "";

  public userPasswordEncryptJS: string = "";

  public userToken: string = null;

  public documentEditorToken: string = null;

  public documentEditorIsAuthorized: boolean = false;

  public documentEditorDate: string = null;

  public userEhealthIsAuthorized: boolean = false;

  public userEhealthLogin: string = "";

  public userEhealthPassword: string = null;

  public userEhealthToken: string = "";

  public userEhealthTokenDate: string = null;

  public permissions: string = null;

  public parallelWork: boolean = false;

  public portalLogin: string = null;

  public portalPswd: string = null;
}
