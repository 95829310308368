import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DatePipe } from '@angular/common';
import moment, { Moment } from 'moment';
import { BedData } from 'app/models/BedData';
import { Prescription } from 'app/models/Prescription';
import { DoctorService } from 'app/doctor/doctor.service';
import { Booking } from 'app/models/Booking';
import { DomSanitizer } from '@angular/platform-browser';
import { PrescriptionEditData } from '../llp.component';
import { OwlDateTimeComponent } from 'ng-pick-datetime';
import { PopoverDirective } from 'ngx-smart-popover';

@Component({
  selector: 'app-CalendarViewer',
  templateUrl: './CalendarViewer.component.html',
  styleUrls: ['./CalendarViewer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarViewerComponent {

  @Input() prescriptionData: PrescriptionEditData;

  @ViewChild('popoverDirective') popoverDirective: PopoverDirective;

  public selectedMoment = new Date();

  public min = null;
  public max = null;

  public bookingsInDay = [];

  popoverHideSubscribtion: Subscription;

  constructor(private router: Router, private datePipe: DatePipe,
    private service: DoctorService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {

    if (this.prescriptionData.selectedDate != null)
      this.selectedMoment = this.prescriptionData.selectedDate;

    this.selectedMoment.setHours(0, 0, 0, 0);

    for(let p of this.prescriptionData.prescriptions)
    {
      if (p.bookings != null && p.bookings.length > 0) {

        let mi = new Date(Math.min.apply(null, p.bookings.map(function (e)
        { return new Date(e.bookingDate);  })));

        if (this.min == null || mi < this.min) this.min = mi;

        let ma = new Date(Math.max.apply(null, p.bookings.map(function (e)
        { return new Date(e.bookingDate); })));

        if (this.max == null || ma > this.max) this.max = ma;
      }
    }

    this.min.setHours(0, 0, 0, 0);
    this.max.setHours(0, 0, 0, 0);

    if (!(this.selectedMoment >= this.min && this.selectedMoment <= this.max)) {
      this.selectedMoment = this.min;
    }
    this.setBookingsInDate(this.selectedMoment);



    this.popoverHideSubscribtion = this.service.popHided().subscribe(r => this.hidePopover());
  }

  ngAfterViewChecked() {
    this.setStyles();
  }

  popover: any;
  showPop(pop) {
    this.popover = pop;
  }

  hidePopover() {
    this.popover.hide();
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then();
  }

  setStyles() {
    this.delay(1000).then(any => {

    if (this.prescriptionData.currentPrescription != null && this.prescriptionData.currentPrescription.bookings != null)
      for (let b of this.prescriptionData.currentPrescription.bookings) {
        let date: string = this.datePipe.transform(b.bookingDate, "d MMMM yyyy")
        var element = document.querySelector('[aria-label="' + date  +" р." + '"]');
          if (element != null) {
          element.setAttribute("style", "color:#3361CF; height:48px");
        }
        }
    });
  }


  dateChanged() {
    console.log(this.selectedMoment);
    this.setBookingsInDate(this.selectedMoment);
  }

  setBookingsInDate(date: Date) {
    this.selectedMoment.setHours(0, 0, 0, 0);
    let bookingsByDate: Booking[] = [];

    for (let p of this.prescriptionData.prescriptions) {
      if (p.bookings != null && p.bookings.length > 0) {
        for (let b of p.bookings) {
          let bdate = new Date(b.bookingDate);
          bdate.setHours(0, 0, 0, 0);
          if (bdate.valueOf() == this.selectedMoment.valueOf()) bookingsByDate.push(b);
        }
      }
    }
    bookingsByDate = bookingsByDate.sort((a, b) => (a.bookingDate < b.bookingDate ? -1 : 1));

    this.bookingsInDay = bookingsByDate;
  }

  getBookingIcon(b: Booking) {
    if (b.bookingStatusRef == "CMP")
      return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-check" style="color:#3B7A00;width:16px;cursor:default"></i>');
    else if (b.bookingStatusRef == "PLN") {
      var now = new Date();
      var current = new Date(b.bookingDate);
      if (current < now)
        return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-clock-o" style="color:#C60000;width:16px;cursor:default"></i>');
      else
        return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-clock-o" style="color:#1874C4;width:16px;cursor:default"></i>');
    }
    else if (b.bookingStatusRef == "CNC")
      return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-times" style="color:#EB5757;width:16px;cursor:default"></i>');
  }

  getBookingTime(b: Booking) {
    let time = this.datePipe.transform(b.bookingDate, "HH:mm");
    return time;
  }

  getBookingExecutor(b: Booking) {
    if (b.username == null || b.username == "") return "Не встановлено";
    else return b.username;
  }

  getBookingDose(b: Booking) {
    if (b.prescriptionDose == null) return "";
    else return b.prescriptionDose.toFixed(3);
  }

  getBookingBackColor(b: Booking) {
    if (this.prescriptionData.currentPrescription == null || this.prescriptionData.currentPrescription.bookings == null)
      return "white";

    if (this.prescriptionData.currentPrescription.bookings.find(r => r.assignmentServiceID == b.assignmentServiceID))
      return "#EFF4FC";

    return "white";
  }

  selectedBookingPop = null;
  setClickedBooking(b: Booking) {
    this.selectedBookingPop = b;
  }

}
