import { Component, Inject, ViewChild } from '@angular/core';
import { BedData } from '../../models/BedData';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../calendar/Day/day.component';
import { ResidenceCreateService } from '..//ResidenceCreate/ResidenceCreateService';
import { Dictionary } from '../../models/Dictionary';
import { Document } from '../../models/Document';
import { Observable } from 'rxjs';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { DocumentViewerDialog } from '../DocumentViewer/DocumentViewerDialog';
import { Router } from '@angular/router';
import { Course } from '../../models/Course';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientComponent } from '../../patient/patient.component';
import { Patient } from '../../models/Patient';
import { PatientEditData } from '../PatientSearch/PatientSearchDialog';
import { DocumentEditData, DocumentViewerDialogData } from '../ResidenceCreate/ResidenceCreateDialog';
import { Document003Dialog } from '../Documents/003/Document003Dialog';
import { EHealthViewComponent } from 'app/Ehealth/eHealthView/eHealthView.component';
import { LoginService } from '../Login/LoginService';


@Component({
  selector: 'app-modalWindows-PatientEdit',
  templateUrl: './PatientEditDialog.html',
  styleUrls: ['./PatientEditDialog.css'],
  providers: [DatePipe],
})

export class PatientEditDialog {

  myOptionsColor = {
    'theme': 'light'
  }

  @ViewChild(PatientComponent)
  private patientComponent: PatientComponent;

  @ViewChild(EHealthViewComponent)
  private eHealthViewComponent: EHealthViewComponent;

  data: PatientEditData;

  documents: Document[];
  courses: Course[];
  selectedCourse: number;
  isEhealth: boolean = false;

  constructor(private service: ResidenceCreateService, private lservice: LoginService, public dialogRef: MatDialogRef<PatientEditDialog>, @Inject(MAT_DIALOG_DATA)
  public d: PatientEditData, private datePipe: DatePipe, public dialog: MatDialog, ) {


    this.isEhealth = lservice.UserToken && lservice.UserToken.userEhealthIsAuthorized;

    this.data = d;

    var _that = this;

    this.service.getcources(this.data.patient.patientID).subscribe((data: Course[]) => {
      _that.courses = data;
    });

    this.service.getdocuments(this.data.patient.patientID, -1).subscribe((data: Document[]) => { _that.documents = data; });
  }

  patientEditIsDisabled() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_EMR_acEditPatientData == 'True') {}
    else return { 'pointer-events': 'none', opacity: 0.5, background: 'white' };
  }

  AllowViewDocument() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_EMR_acCoursesDocuments == 'True')
      return true;
    else return false;
  }


  onYesClick(): void
  {
    //bind patient by viewchild
    this.patientComponent.bindData();
    this.data.rezult = true;
    this.dialogRef.close(this.data);
   
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

  onVoted(agreed: boolean) {
    if (agreed) {
      this.data.rezult = true;
      this.dialogRef.close(this.data);
    }
  }

  getCourseHeader() {
    if (this.selectedCourse == null) return "Всі";
    let course: Course = this.courses.find(r => r.courseID == this.selectedCourse);
    return this.getCourseInfo(course) + " (" + this.getCourseType(course) + ")";

  }

  getCourseInfo(c: Course) {
    let rez: string =   "Курс ";
    return rez + (c.courseStartDate == null ? "" : this.datePipe.transform(c.courseStartDate, "dd.MM.yyyy"))
      +
      (c.courseEndDate == null ? "" : " - " + this.datePipe.transform(c.courseEndDate, "dd.MM.yyyy"));
  }

  getColor() {
    if (this.selectedCourse == null) return "#1874C4";
    else return "#000000";
  }

  getselectedCourseColor(c: Course) {
    if (this.selectedCourse == null) return "#000000";
    else if (this.selectedCourse == c.courseID) {
      return "#1874C4";
    } else { return "#000000"; }
  }

  getCourseType(c: Course) {
    if (c.courseKindRef == "AMB") return "швидка допомога";
    if (c.courseKindRef == "DNR") return "донор";
    if (c.courseKindRef == "GEN") return "загальний";
    if (c.courseKindRef == "HSP") return "стаціонарний";
    if (c.courseKindRef == "PLC") return "поліклінічний";
    else return c.courseKindRef;
  }

  styleCourseStatus(c: Course) {
    if (c.courseStatusRef == "OPN") return { color: '#008926', border: '0.5px solid #008926' };
    else if (c.courseStatusRef == "CLS") return { color: '#1874C4', border: '0.5px solid #1874C4' };
    else if (c.courseStatusRef == "DED") return { color: 'gray', border: '0.5px solid gray' };
    else if (c.courseStatusRef == "CNC") return { color: 'salmon', border: '0.5px solid salmon' };
    else if (c.courseStatusRef == "HZD") return { color: 'orange', border: '0.5px solid orange' };
  }

  getCourseStatus(c: Course) {
    if (c.courseStatusRef == "OPN") return "Відкритий";
    else if (c.courseStatusRef == "CLS") return "Закритий";
    else if (c.courseStatusRef == "DED") return "Помер";
    else if (c.courseStatusRef == "CNC") return "Відмінено";
    else if (c.courseStatusRef == "HZD") return "Госпіталізований";
  }

  getDate(d: Document) {
    if (d.documentCreationDate != null)
        return this.datePipe.transform(d.documentCreationDate, "dd.MM.yyyy HH:mm");
    else return "";
  }


  showDoc(d: Document) {
    if (this.selectedCourse == null) return "block";
    return d.courseRef == this.selectedCourse ? "block" : "none";
  }

  documentView(doc: Document) {
    let d: DocumentViewerDialogData = new DocumentViewerDialogData();
    d.content = doc.documentHTML;

    this.openDocumentDialog(d);
  }

  openDocumentDialog(d: DocumentViewerDialogData): Observable<DocumentViewerDialogData> {

    const dialogRef = this.dialog.open(DocumentViewerDialog, {

      data: d,

      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

}

