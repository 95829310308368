export class Booking {

    public assignmentServiceID: number;

    public bookingID: number;

    public bookingName: string;

    public bookingDate: Date;

    public bookingStatusRef: string;

    public bookingStatusName: string;

    public bookingDesc: string;

    public bookingDocumentRef: number;

    public serviceParent: string;

    public serviceParentRef: number;

    public username: string;

    public userName: string;

    public patientID: number;

    public patientName: string;

    public prescriptionDose: number;

    public prescriptionMedicationName: string;

    public serviceIsLaboratory: boolean;

    public venueID: number;
    public venueID1: number;
    public venueName: string;
    public venueName1: string;
    public venuePositionZ: number;
    public venueRoomName: string;

    public bookKind: number;


}
