import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import $ from 'jquery';
import { DiagnosisSelectDialog } from '../DiagnosisSelect/DiagnosisSelectDialog';
import { ConfirmDialogData } from '../../calendar/Day/day.component';
import { DocumentViewerDialogData, LoginFailedDialogData } from '../ResidenceCreate/ResidenceCreateDialog';
import { DOCUMENT } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core'


@Component({
  selector: 'app-modalWindows-LoginFailed',
  templateUrl: './LoginFailedDialog.html',
  styleUrls: ['./LoginFailedDialog.css']
})
export class LoginFailedDialog {

  data: LoginFailedDialogData;

  constructor(public dialogRef: MatDialogRef<LoginFailedDialog>, @Inject(MAT_DIALOG_DATA) data: LoginFailedDialogData)
  {
    this.data = data;
  }

  ngOnInit() {
  }

  onYesClick(): void {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }
}

