import { Component, Input, ViewChild } from '@angular/core';
import $ from 'jquery';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { BedData } from 'app/models/BedData';
import { Prescription } from 'app/models/Prescription';
import { DoctorService } from 'app/doctor/doctor.service';
import { Booking } from 'app/models/Booking';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-PrescriptionPop',
  templateUrl: './PrescriptionPop.component.html',
  styleUrls: ['./PrescriptionPop.component.css']
})
export class PrescriptionPopComponent {

  private _bookings: Booking[] = [];

  @Input() set selectedBookings(value: Booking[])
  {
    this._bookings = value;
  }

  constructor(private router: Router, private datePipe: DatePipe,
    private service: DoctorService, private sanitizer: DomSanitizer)
  {
  }

  ngOnInit() {
  }

  getBookingIcon(b: Booking) {
      if (b.bookingStatusRef == "CMP")
        return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-check" style="color:#3B7A00;width:16px;cursor:default"></i>');  
      else if (b.bookingStatusRef == "PLN")
        return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-clock-o" style="color:#1874C4;width:16px;cursor:default"></i>');
      else if (b.bookingStatusRef == "CNC")
        return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-times" style="color:#EB5757;width:16px;cursor:default"></i>');   
  }

  getBookingTime(b: Booking) {
    let time = this.datePipe.transform(b.bookingDate, "HH:mm");
    return time;
  }

  getBookingExecutor(b: Booking) {
    if (b.username == null || b.username == "") return "Не встановлено";
    else return b.username;
  }

  getBookingDose(b: Booking) {
    if (b.prescriptionDose == null ) return "";
    else return b.prescriptionDose.toFixed(3);
  }

}
