import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Token } from '../../models/Token';
import { PatientSearchDialogData } from '../../calendar/calendar.component';
import { Patient } from '../../models/Patient';
import { DataService } from '../../calendar/calendar.service';
import { PatientToSequenceData } from '../PatientSearch/PatientSearchDialog';
import { Residence } from '../../models/Residence';
import { Dictionary } from '../../models/Dictionary';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import { ResidenceCreateService } from '../ResidenceCreate/ResidenceCreateService';
import { DiagnosData } from '../ResidenceCreate/ResidenceCreateDialog';
import { BedData } from '../../models/BedData';
import { DiagnosisSelectDialog } from '../DiagnosisSelect/DiagnosisSelectDialog';
import { Sequence } from '../../models/Sequence';
import { Account } from '../../models/Account';
import moment from 'moment';


@Component({
    selector: 'app-modalWindows-PatientToSequence',
    templateUrl: './PatientToSequenceDialog.html',
    styleUrls: ['./PatientToSequenceDialog.css']
})

export class PatientToSequenceDialog {

    public sequences: Sequence[];
    public selectedSeqDep: Sequence;

    CurrentDiagnos: Dictionary = new Dictionary("");

    d: PatientToSequenceData;
    residence: Residence;

    arrivalTypes: Dictionary[];
    residenceCategories: ResidenceCategory[];
    accounts: Account[];
    users: Dictionary[];

    diagnosis: Dictionary[];

    startsettings = {
        bigBanner: true,
        timePicker: true,
        format: 'dd-MM-yyyy HH-mm',
        defaultOpen: false
    }
    endsettings = {
        bigBanner: true,
        timePicker: true,
        format: "",
        defaultOpen: false
    }

    constructor(private service: ResidenceCreateService,
    public dialog: MatDialog, public dialogRef: MatDialogRef<PatientToSequenceDialog>, @Inject(MAT_DIALOG_DATA) public data: PatientToSequenceData)
  {
        this.d = data;
        this.residence = new Residence(0);
        this.residence.residenceStartDate = new Date();
        //this.residence.residenceEndDate = new Date();
        //this.residence.residenceEndDate.setDate(this.residence.residenceEndDate.getDate() + 14);

        this.residence.patientRef = data.patient.patientID;

        var _that = this;

        this.service.userRef = null;
        this.service.getArrivalTypes().subscribe((dic: Dictionary[]) => { _that.arrivalTypes = dic; });
        this.service.getDictionary("Користувачі").subscribe((data: Dictionary[]) => { _that.users = data; });
        this.service.getDiagnozis().subscribe((dic: Dictionary[]) => { _that.diagnosis = dic; });
        this.service.getResidenceCategories().subscribe((dic: ResidenceCategory[]) => { _that.residenceCategories = dic; });
        this.service.getSequences("RCE").subscribe((data: Sequence[]) => { _that.sequences = data; });
        this.service.getPatientAccounts(data.patient.patientID)
            .subscribe((data: Account[]) => { _that.accounts = data; })
    }

    onDateSelect(event) {
        this.endsettings = {
            bigBanner: true,
            timePicker: true,
            format: 'dd-MM-yyyy H-m',
            defaultOpen: false
        }
    }

    sequenceChanged(selectedValue: number) {
        this.selectedSeqDep = this.sequences.find(r => r.sequenceID == selectedValue);
    }

    addToJournal() {
        //1 setValues
        if (this.residence.residenceCategory != null)
            this.residence.residenceCategoryRow = this.residenceCategories.find(r => r.residenceCategoryID == this.residence.residenceCategory);

        this.residence.residenceDiagnosis1 = this.CurrentDiagnos.dictionaryCode;
        this.residence.residenceDiagnosis1Name = this.CurrentDiagnos.dictionaryName;

        if (this.selectedSeqDep != null) {
            this.residence.residenceSequence1Ref = this.selectedSeqDep.sequenceID;
        }
        else {
            this.residence.residenceSequence1Ref = this.sequences[0].sequenceID;
        }

        if (this.residence.residenceSequence1Ref == null) {
            this.dialogRef.close(this.d);//show message
        }

        this.residence.residenceStartDate = new Date(this.residence.residenceStartDate);

        var start = moment((this.residence.residenceStartDate), 'YYYY-MM-DD HH:mm').local().toDate();
        var userTimezoneOffset = start.getTimezoneOffset() * 60000;
        this.residence.residenceStartDate = new Date(start.getTime() - userTimezoneOffset);



        if (this.residence.residenceEndDate != null) {
            var end = moment(this.residence.residenceEndDate, 'YYYY-MM-DD HH:mm').local().toDate();
            var userTimezoneOffset = end.getTimezoneOffset() * 60000;
            this.residence.residenceEndDate = new Date(end.getTime() - userTimezoneOffset);
        }

        this.residence.bitProcess = "new";

        this.residence.dispenceNumber = this.d.dispenceNumber;
        this.residence.userRef = this.service.userRef;


        //2 dbUpdate
        this.service.createresidence(this.residence).subscribe((rez => {
            if (rez != null) {

                //3 update list in calendar
                this.d.rezult = true;
                this.d.residence = rez;
                this.dialogRef.close(this.d);
                this.service.sequenceUpd();
            }
            else {
                //show error message from service
            }
        }));

    }


    onNoClick(): void {
        this.dialogRef.close(this.d);
    }

    //for select diagnosis
    onDiagnosClick() {
        this.openDialog().subscribe(result => {

            if (result != null && result.rezult)//if success in modal dialog
            {
                if (result.newDiagnos.dictionaryCode != "") {
                    this.CurrentDiagnos.dictionaryID = result.newDiagnos.dictionaryID;
                    this.CurrentDiagnos.dictionaryCode = result.newDiagnos.dictionaryCode;
                    this.CurrentDiagnos.dictionaryName = result.newDiagnos.dictionaryName;
                }
            }
            else {
                // something if false (pressed no)
            }
        });
    }


    //for select diagnosis
    openDialog(): Observable<DiagnosData> {

        let d: DiagnosData = new DiagnosData();
        d.diagnosis = this.diagnosis;
        d.oldDiagnos = new Dictionary("");
        d.newDiagnos = new Dictionary("");
        const dialogRef = this.dialog.open(DiagnosisSelectDialog, {
            width: '600px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.afterClosed();
    }

    eventHandler(event) {
        event.returnValue = false;
        event.preventDefault();
    }

    //styles
    getPatientInfo() {
        if (this.d.patient == null) return "";

        return this.d.patient.patientName;
    }

    getPatientAge() 
    {
        if (this.d.patient == null) return "";

        let patientAge: string = "";
        if (this.d.patient.patientAge != null) { patientAge = this.d.patient.patientAge + " р. |"; }

        return patientAge;
    }

    getPatientID() {
        if (this.d.patient == null) return 0;

        return this.d.patient.patientID;
    }
}




