import { Component, Inject } from '@angular/core';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(private service: LoginService, private router: Router)
  {
  }

}

