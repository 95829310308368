import { Component, Inject, ViewChild } from '@angular/core';
import { BedData } from '../../models/BedData';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../calendar/Day/day.component';
import { ResidenceCreateService } from '..//ResidenceCreate/ResidenceCreateService';
import { Dictionary } from '../../models/Dictionary';
import { Document } from '../../models/Document';
import { Observable } from 'rxjs';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { DocumentViewerDialog } from '../DocumentViewer/DocumentViewerDialog';
import { Router } from '@angular/router';
import { ResidenceMainComponent } from './ResidenceMain/residence.main';
import { Course } from '../../models/Course';
import { Config } from '../../Config';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientComponent } from '../../patient/patient.component';
import { Patient } from '../../models/Patient';
import { Document003Dialog } from '../Documents/003/Document003Dialog';
import { Document066Dialog } from '../Documents/066/Document066Dialog';
import { Residence } from 'app/models/Residence';
import { LoginService } from '../Login/LoginService';

export class DiagnosData {
    rezult: boolean;
    oldDiagnos: Dictionary;
    newDiagnos: Dictionary;
    diagnosis: Dictionary[];
}

export class DictionaryData {
    rezult: boolean;
    dictionaryName: string;
    dictionaryRow: Dictionary;
    searchByID: boolean = false;
}

export class DocumentViewerDialogData {
    rezult: boolean;
    content: string[];
}

export class LoginFailedDialogData {
    rezult: boolean;
    message: string;
}

export class EHFrameData {
    rezult: boolean;
    url: string;
    isDocument: boolean = false;
}

export class DocumentEditData {
    rezult: boolean;
    patient: Patient;
    residence: Residence;
    document: Document;
    staticTemplate: string;
    jsonToSave: string;
}

@Component({
    selector: 'app-modalWindows-ResidenceCreate',
    templateUrl: './ResidenceCreateDialog.html',
    styleUrls: ['./ResidenceCreateDialog.css'],
    providers: [DatePipe],
})

export class ResidenceCreateDialog {

    myOptionsColor = {
        'theme': 'light'
    }

    @ViewChild(PatientComponent)
    private patientComponent: PatientComponent;

    languages = ["a", "b", "c"];
    selectedLang = this.languages[0];

    documents: Document[];
    courses: Course[];
    selectedCourse: number;

    guid3: string;
    guid66: string;

    isEhealth: boolean = false;
    public loading: boolean = false;

    constructor(private service: ResidenceCreateService, private lservice: LoginService, public dialogRef: MatDialogRef<ResidenceCreateDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private _config: Config) {
        this.data.rezult = true;
        this.loading = false;
        this.isEhealth = lservice.UserToken && lservice.UserToken.userEhealthIsAuthorized;

        this.guid3 = this._config.get("003TemplateStaticGuid");
        this.guid66 = this._config.get("066TemplateStaticGuid");

        this.selectedCourse = data.residence.residenceCourseRef;
        //pass data
        service.residenceData = data;

        if (this.service.residenceData.residence != null) {
            this.service.CurrentDiagnos.dictionaryID = data.residence.residenceDiagnosis1;
            this.service.CurrentDiagnos.dictionaryCode = data.residence.residenceDiagnosis1;
            this.service.CurrentDiagnos.dictionaryName = data.residence.residenceDiagnosis1Name;
        }

        this.service.courseArrivalType = data.residence.courseArrivalType;
        this.service.residenceCategory = data.residence.residenceCategory;
        this.service.residenceStatusRef = data.residence.residenceStatusRef;
        this.service.residenceDesc = data.residence.residenceDesc;
        this.service.accountRef = data.residence.courseDefaultAccountRef;
        this.service.userRef = data.residence.userRef;
        this.service.stationaryDepartureType = data.residence.stationaryDepartureType;

        this.service.startDate = data.residence.residenceStartDate;
        this.service.endDate = data.residence.residenceEndDate;

        if (data.openChangeSeq) {
            this.router.navigate(['.', { outlets: { 'residence': 'changeDep' } }]);// Called first time before the ngOnInit()
        }
        else {
            this.router.navigate(['.', { outlets: { 'residence': 'main' } }]);// Called first time before the ngOnInit()
        }

        var _that = this;

        this.service.getcources(this.data.patient.patientID).subscribe((data: Course[]) => {
            _that.courses = data.sort((a, b) => a.courseID == _that.data.residence.residenceCourseRef ? -1 : 1);
        });

        this.service.getdocuments(this.data.patient.patientID, -1).subscribe((data: Document[]) => { _that.documents = data; });
    }

    getDate(d: Document) {
        if (d.documentCreationDate != null)
            return this.datePipe.transform(d.documentCreationDate, "dd.MM.yyyy HH:mm");
        else return "";
    }


    onYesClick(): void {
        //bind residence by event becouse its routed component
        this.service.mainUpd();

        //bind patient by viewchild
        this.patientComponent.bindData();

        //
        //

        if (this.service.residenceData.rezult == true) {
            this.dialogRef.close(this.service.residenceData);
        }

    }

    onNoClick(): void {
        this.data.rezult = false;
        this.dialogRef.close(this.data);
    }

    onVoted(agreed: boolean) {
        if (agreed) {
            this.data.rezult = true;
            this.dialogRef.close(this.data);
        }
    }

    documentView(doc: Document) {
        if (this.service.residenceStatusRef == 'CMP' || this.service.residenceStatusRef == 'DED') {
            let d: DocumentViewerDialogData = new DocumentViewerDialogData();
            d.content = doc.documentHTML;
            this.openDocumentDialog(d);
        }
        else {
            let data: DocumentEditData = new DocumentEditData();
            data.document = doc;
            data.patient = this.data.patient;
            data.residence = this.data.residence;

            if (doc.documentTemplateStaticGUIDString == this.guid3 && this.editDocumentIsDisabled() == false) {
                this.open003Document(data).subscribe(result => {
                    if (result.rezult == true) {
                        this.documentUpdate(result);
                    }
                });
            }
            else if (doc.documentTemplateStaticGUIDString == this.guid66 && this.editDocumentIsDisabled() == false) {
                this.open066Document(data).subscribe(result => {
                    if (result.rezult == true) {
                        this.documentUpdate(result);
                    }
                });
            } else {
                let d: DocumentViewerDialogData = new DocumentViewerDialogData();
                d.content = doc.documentHTML;
                this.openDocumentDialog(d);
            }
        }
    }

    documentUpdate(result: DocumentEditData) {
        var _that = this;

        this.loading = true;
        this.service.updateDocument(result.document).subscribe((rez => {
            console.log(rez);
            this.loading = false;
            _that.loading = true;
            this.service.getdocuments(this.data.patient.patientID, -1).subscribe((data: Document[]) => {
                _that.documents = data;
                _that.loading = false;
            });
        }));
    }

    openDocumentDialog(d: DocumentViewerDialogData): Observable<DocumentViewerDialogData> {

        const dialogRef = this.dialog.open(DocumentViewerDialog, {

            data: d,
            //height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }

    open003Document(d: DocumentEditData): Observable<DocumentEditData> {

        const dialogRef = this.dialog.open(Document003Dialog, {
            data: d,
            width: '1380px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    open066Document(d: DocumentEditData): Observable<DocumentEditData> {

        const dialogRef = this.dialog.open(Document066Dialog, {
            data: d,
            width: '1380px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    //styles
    getPatientInfo() {
        if (this.data.patient == null) return "";

        return this.data.patient.patientName;
    }

    getPatientAge() {
        if (this.data.patient == null) return "";

        let patientAge: string = "";
        if (this.data.patient.patientAge != null) { patientAge = this.data.patient.patientAge + " р. |"; }

        return patientAge;
    }

    getPatientID() {
        if (this.data.patient == null) return 0;

        return this.data.patient.patientID;
    }

    getCourseHeader() {
        if (this.selectedCourse == null) return "Всі";
        if (this.courses != null) {
            let course: Course = this.courses.find(r => r.courseID == this.selectedCourse);
            return this.getCourseInfo(course) + " (" + this.getCourseType(course) + ")";
        }

    }

    getCourseInfo(c: Course) {
        let rez: string = c.courseID == this.data.residence.residenceCourseRef ? "Поточний " : "";
        return rez + (c.courseStartDate == null ? "" : this.datePipe.transform(c.courseStartDate, "dd.MM.yyyy"))
            + (c.courseEndDate == null ? "" : " - " + this.datePipe.transform(c.courseEndDate, "dd.MM.yyyy"));
    }

    getColor() {
        if (this.selectedCourse == null) return "#1874C4";
        else return "#000000";
    }

    getselectedCourseColor(c: Course) {
        if (this.selectedCourse == null) return "#000000";
        else if (this.selectedCourse == c.courseID) {
            return "#1874C4";
        } else { return "#000000"; }
    }

    getCourseType(c: Course) {
        if (c.courseKindRef == "AMB") return "швидка допомога";
        if (c.courseKindRef == "DNR") return "донор";
        if (c.courseKindRef == "GEN") return "загальний";
        if (c.courseKindRef == "HSP") return "стаціонарний";
        if (c.courseKindRef == "PLC") return "поліклінічний";
        else return c.courseKindRef;
    }

    styleCourseStatus(c: Course) {
        if (c.courseStatusRef == "OPN") return { color: '#008926', border: '0.5px solid #008926' };
        else if (c.courseStatusRef == "CLS") return { color: '#1874C4', border: '0.5px solid #1874C4' };
        else if (c.courseStatusRef == "DED") return { color: 'gray', border: '0.5px solid gray' };
        else if (c.courseStatusRef == "CNC") return { color: 'salmon', border: '0.5px solid salmon' };
        else if (c.courseStatusRef == "HZD") return { color: 'orange', border: '0.5px solid orange' };
    }

    getCourseStatus(c: Course) {
        if (c.courseStatusRef == "OPN") return "Відкритий";
        else if (c.courseStatusRef == "CLS") return "Закритий";
        else if (c.courseStatusRef == "DED") return "Помер";
        else if (c.courseStatusRef == "CNC") return "Відмінено";
        else if (c.courseStatusRef == "HZD") return "Госпіталізований";
    }

    showDoc(d: Document) {
        if (this.selectedCourse == null) return "block";
        return d.courseRef == this.selectedCourse ? "block" : "none";
    }

    editDocumentIsDisabled() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Document_acEdit == 'True')
            return false;
        else return true;
    }

    AllowViewDocument() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_EMR_acCoursesDocuments == 'True')
            return true;
        else return false;
    }

    canEditDoc(d: Document) {
        if ((d.documentTemplateStaticGUIDString == this.guid3 || d.documentTemplateStaticGUIDString == this.guid66) &&
            (this.service.residenceStatusRef != 'CMP' && this.service.residenceStatusRef != 'DED')) {
            return true;
        }
        return false;
    }

    checkDisabled() {
        if (this.data.residence.residenceStatusRef == "PLN" || this.data.residence.residenceStatusRef == "PRG") return false;
        else return true;
    }

    patientEditIsDisabled() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_EMR_acEditPatientData == 'True') {
            return this.styleDisabled();
        }
        else return { 'pointer-events': 'none', opacity: 0.5, background: 'white' };
    }

    styleDisabled() {
        if (!(this.data.residence.residenceStatusRef == "PLN" || this.data.residence.residenceStatusRef == "PRG")) {
            return { 'pointer-events': 'none', opacity: 0.5, background: 'white' }
        }

    }

    resDisabled() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_EMR_acEditPatientData == 'True') {

            if (this.data.disableResidence) {
                return { 'pointer-events': 'none', opacity: 0.5, background: 'white' }
            }
            return this.styleDisabled();
        }
        else return { 'pointer-events': 'none', opacity: 0.5, background: 'white' };

    }
}

