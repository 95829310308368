import { OnInit, Component, OnChanges, NgZone, ViewEncapsulation } from '@angular/core';
import { OnDestroy } from "@angular/core";
import { ISubscription } from "rxjs/Subscription";
import { DataService, DrakeOBJ } from '..//calendar.service';
import { Patient } from '../../models/Patient';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { Venue } from '../../models/Venue';
import { finalize } from 'rxjs/operators';
import { DepartmentData } from '../../models/DepartmentData';
import { RoomData } from '../../models/RoomData';
import { BedData } from '../../models/BedData';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResidenceCreateDialog, DocumentEditData } from '../../modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { ConfirmDialog } from '../../modalWindows/Confirm/ConfirmDialog';
import { Residence } from '../../models/Residence';
import { Dictionary } from '../../models/Dictionary';
import { Token } from '../../models/Token';
import { DropBedData } from '../../models/DropBedData';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/toPromise';
import $ from 'jquery';
import { Router } from '@angular/router';
import { ConfirmPlannedDialog } from '../../modalWindows/ConfirmPlanned/ConfirmPlannedDialog';
import { Sequence } from '../../models/Sequence';
import { Document003Dialog } from 'app/modalWindows/Documents/003/Document003Dialog';
import moment from 'moment';
import { Document066Dialog } from 'app/modalWindows/Documents/066/Document066Dialog';
import { UserJournalsDialog } from 'app/modalWindows/UserJournals/UserJournalsDialog';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import { responsiveService } from "../../responsiveService";
import { Config } from 'app/Config';
import { Document } from '../../models/Document';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { DayViewFilterDialog } from 'app/modalWindows/DayViewFilter/DayViewFilterDialog';
import { DoctorService } from 'app/doctor/doctor.service';

export class DialogData {
    rezult: boolean;
    patient: Patient;
    bed: Venue;
    room: Venue;
    residence: Residence;
    newSequence: number;
    openChangeSeq: boolean;
    childRoute: string;
    dropProcess: boolean;
    disableResidence: boolean = false;
}

export class ConfirmDialogData {
    rezult: boolean;
    message: string;
    OkButton: boolean;
    YesNoButton: boolean;
}

export class ConfirmPlannedDialogData {
    rezult: boolean;
    bedData: BedData;
}

export class FilterDialogData {
    rezult: boolean;

    public displayRows;

    public bedType: Dictionary;
    //0-all 1-fem 2-mal 3-fem&mal 4-notdefined
    public roomGender;

    public roomBedState;

    public bedInRoomsCount;

    public floor;

    public floors: number[] = [];

    public patientId: number;

    public patientName: string;

    public departmentData: DepartmentData;
}

@Component({
    selector: 'app-calendar-day',
    templateUrl: './day.component.html',
    styleUrls: ['./day.component.css'],
    providers: [DatePipe],
})

export class DayComponent implements OnInit, OnDestroy {

    // 1 - one row 2 - two rows
    public displayRows: string = "1";

    public bedType: Dictionary = null;
    //0-all 1-fem 2-mal 3-fem&mal 4-notdefined
    public roomGender: string = "0";
    //0 all 1-rooms without patients 2- rooms with patients 3-beds without patients 4- beds with patients
    public roomBedState: string = "0";
    //0 - all 1-one bed rooms 2-two beds rooms
    public bedInRoomsCount: string = "0";

    public floor: string = "0";

    public filteredPatientId: number = null;
    public filteredPatientName: string = null;

    public isTablet: boolean;

    myOptions = {
        'theme': 'light',
        'width': '300',
        'max-width': '400'
    }

    myOptionsColor = {
        'theme': 'light'
    }

    myOptionsFilter = {
        'placement': 'top',
        'theme': 'light',
        'displayTouchscreen': 'false',
        'hide-delay': '0',
        'display': (this.bedType != null || this.roomGender != "0") ? 'true' : 'false'
    }

    public isOpened: boolean = false;
    public date: Date;
    public sequenceID: number;
    public vaitingListID: number;
    //public departmentData: DepartmentData = new DepartmentData([]); move to service to calculate stat
    public loading: boolean;

    public bedTypes: Dictionary[];
    public residenceCategories: ResidenceCategory[];

    vaitinglistSubscription: Subscription;
    sequenceSubscription: Subscription;
    drakeSub: Subscription;
    bedToDebSubscribtion: Subscription;
    timelineUpdateSubscribtion: Subscription;

    public dayText: string = " День ";
    public lineText: string = " Графік ";


    public isDateOpened = true;

    settings = {
        bigBanner: true,
        timePicker: false,
        format: 'dd-MM-yyyy',
        defaultOpen: false
    }

    isEhealth: boolean = false;

    constructor(private calendarService: DataService, private lservice: LoginService, private responsiveService: responsiveService,
        private doctorService: DoctorService,
        public dialog: MatDialog, private datePipe: DatePipe, private router: Router,
        private _config: Config) {
        this.date = new Date();

        this.isEhealth = lservice.UserToken && lservice.UserToken.userEhealthIsAuthorized;


        var _that = this;
        this.calendarService.getBedTypes().subscribe((data: Dictionary[]) => { _that.bedTypes = data; });
        this.calendarService.getResidenceCategories().subscribe((data: ResidenceCategory[]) => { _that.residenceCategories = data; });

        if (this.calendarService.selectedSeqDepartment != null)
            this.sequenceID = this.calendarService.selectedSeqDepartment.sequenceID;
        else { this.sequenceID = null; }
    }

    ngOnInit() {
        this.sequenceSubscription = this.calendarService.sequenceChanged().subscribe(id => this.sequenceChanging(id));
        this.vaitinglistSubscription = this.calendarService.vaitinglistChanged().subscribe(id => this.vaitinglistChanging(id));
        this.bedToDebSubscribtion = this.calendarService.bedToDepChanged().subscribe(bed => this.removebed(bed));
        this.timelineUpdateSubscribtion = this.calendarService.updatedVenues().subscribe(rez => this.intitVenues(this.sequenceID));
        this.drakeSub = this.calendarService.componentMethodFromDrakeCalled$.subscribe(b => this.dropFromDrake(b));
        this.fillSequences();
        this.fillVenueSequences();

        if (this.sequenceID != null)
            this.intitVenues(this.sequenceID);

        this.onResizeC();
        this.responsiveService.checkWidth();
        this.checkParallelWork();
    }

    checkParallelWork() {
        this.doctorService.CheckParalelWork().subscribe((data: Token) => {
            if (data.parallelWork) {
                this.router.navigate(['']);
                this.dialog.closeAll();
                this.lservice.clearToken();
                alert("parallel work detected");
            }
        });
    }

    onResizeC() {
        this.responsiveService.getTabletStatus().subscribe(isTablet => {
            this.isTablet = isTablet;
        });
    }

    ngAfterContentInit() {

    }

    ngAfterViewChecked() {
        this.onResize(null);
        this.calendarService.addDrakeContainer("bedContainer", true);
    }

    ngOnDestroy() {
        this.sequenceSubscription.unsubscribe();
        this.drakeSub.unsubscribe();
    }


    //need only for one logic, remove it in service from calendar and day
    public sequences: Sequence[];
    public fillSequences() {
        var _that = this;
        this.calendarService.getSequences("RCE").subscribe((data: Sequence[]) => { _that.sequences = data; });
    }
    public venueSequences: Sequence[];
    public fillVenueSequences() {
        var _that = this;
        this.calendarService.getSequences("RCH").subscribe((data: Sequence[]) => { _that.venueSequences = data; });
    }


    removebed(bedFromDrop: DropBedData) {
        this.moveBed(this.calendarService.departmentData, bedFromDrop);
    }

    sequenceChanging(SequenceID: number) {

        this.floor = "0";

        if (SequenceID == null) {
            this.sequenceID = null;
            this.calendarService.departmentData = new DepartmentData([]);;
        }
        else {
            this.sequenceID = SequenceID;
            console.log("SequenceID : " + SequenceID);
            this.intitVenues(SequenceID);
        }
    }

    vaitinglistChanging(SequenceID: number) {
        this.vaitingListID = SequenceID;
    }


    intitVenues(sequence: number) {
        var _that = this;
        _that.loading = true;

        this.calendarService.getDepartmentDataBySequence(sequence.toString(), this.datePipe.transform(this.date, "yyyyMMdd HH:mm"), "0", false)
            .subscribe((data: DepartmentData) => {
                _that.calendarService.departmentData = data;
                _that.loading = false;
                _that.calendarService.initVenueStat();
                _that.onResize(null);
            });
    }



    public bedTypeChanged(bedType: Dictionary) {
        this.bedType = bedType;
        this.intitVenues(this.sequenceID);
    }


    public dateChanged() {
        this.intitVenues(this.sequenceID);
    }

    changeSeqProc(data: DialogData) {

        if (data.newSequence != null) {

            let id: number;
            let s1 = this.sequences;
            let s2 = this.venueSequences;

            let change: boolean = false;

            if (s1.find(r => r.sequenceID == data.newSequence)) {
                id = 1;
                change = data.residence.residenceSequence1Ref != data.newSequence;
            }
            if (s2.find(r => r.sequenceID == data.newSequence)) {
                id = 2;
                change = data.residence.residenceSequence1Ref != data.newSequence;
            }

            //if (change) {

            var start = moment(new Date(), 'YYYY-MM-DD HH:mm').local().toDate();
            var userTimezoneOffset = start.getTimezoneOffset() * 60000;
            data.residence.residenceStartDate = new Date(start.getTime() - userTimezoneOffset);

            data.residence.bitProcess = "dtod";
            data.residence.residenceSequence1Ref = data.newSequence;
            data.residence.venueRef = null;
            data.residence.previousResidenceRef = data.residence.residenceID;
            data.residence.residenceStatusRef = id == 1 ? "PRG" : "PLN";
            //}
        }
    }

    editProc(data: DialogData) {
        data.residence.bitProcess = "dtob";
        data.residence.residenceStatusRef = "PRG";

        if (data.residence.residenceStartDate != null) {
            let today = new Date(); today.setHours(0, 0, 0, 0);
            let start: Date = new Date(data.residence.residenceStartDate); start.setHours(0, 0, 0, 0);
            if (start > today) {
                data.residence.residenceStatusRef = "PLN";
            }
        }
    }

    otherProc(data: DialogData) {
        switch (data.childRoute) {
            case "reject":
                data.residence.bitProcess = "rej";
                break;
            case "decease":
                data.residence.bitProcess = "ded";
                break;
            case "discharge":
                data.residence.bitProcess = "cls";
                break;
        }
    }

    dropProc(data: DialogData, index: number, currentBed: BedData) {

        data.residence.bitProcess = (index == 3 || index == 1) ? "dtob" : "dtod";
        data.residence.residenceStatusRef = "PRG";
        data.residence.venueRef = currentBed.bed.venueID;
        data.residence.residenceSequence1Ref = this.sequenceID;

        if (data.residence.residenceStartDate != null) {
            let today = new Date(); today.setHours(0, 0, 0, 0);
            let start: Date = new Date(data.residence.residenceStartDate); start.setHours(0, 0, 0, 0);
            if (start > today) {
                data.residence.residenceStatusRef = "PLN";
            }
        }

        if (index == 2) {
            data.residence.previousResidenceRef = data.residence.residenceID;
        }


    }

    saveData(result: DialogData, data: BedData, submit003: boolean) {

        this.calendarService.updatePatient(result.patient).subscribe((rez => {
            result.patient = rez;
        }));

        let arrType: string = result.residence.courseArrivalType;// затичка сторка не вертає додаткових полі для резіденса

        this.calendarService.updateresidence(result.residence).subscribe((rez => {
            result.residence = rez;

            result.residence.courseArrivalType = arrType;

            this.mapBed(data, result);

            this.calendarService.initVenueStat();

            if (submit003) {
                this.submit003Form(result.patient, result.residence);
            }

        }));
    }

    bedEdit(data: BedData, room: Venue, ddata: DepartmentData) {

        this.checkParallelWork();
        this.openDialogFromEdit(data, room).subscribe(result => {
            if (result != null && result.rezult)//if success in modal dialog
            {
                let d: DropBedData = new DropBedData(); d.bed = data;


                if (result.childRoute == "reject" || result.childRoute == "decease" || result.childRoute == "discharge") {
                    this.otherProc(result);
                    this.moveBed(ddata, d);
                    this.intitVenues(this.sequenceID);// to avoid this set correct data to PLN bed()wrong styles now...
                    if (result.childRoute == "decease" || result.childRoute == "discharge") { this.submit066Form(result.patient, result.residence); }
                }
                else if (result.childRoute == "changeDep" || (result.childRoute == "main" && result.newSequence != null)) {
                    this.changeSeqProc(result);
                    this.intitVenues(this.sequenceID);
                }
                else if (result.childRoute == "main") {
                    this.editProc(result);
                }

                this.saveData(result, data, false);
                this.intitVenues(this.sequenceID);
            }
            else {/* something if false (pressed no) */ }
        });

    }

    mapBed(data: BedData, result: DialogData) {


        if (this.checkResidenseDate(result.residence) &&
            (result.newSequence == null || result.newSequence == this.sequenceID)) {
            data.patient = result.patient;
            data.residence = result.residence;
            data.allowDrop = result.residence.residenceStatusRef == "PLN";
        }
        else {
            data.patient = null;
            data.residence = null;
            data.allowDrop = true;
        }

        if (result.newSequence != null &&
            (result.newSequence == this.sequenceID || result.newSequence == this.vaitingListID)) {
            this.calendarService.sequencesChange();
        }
    }


    checkResidenseDate(r: Residence): boolean {
        let today = new Date(); today.setHours(0, 0, 0, 0);

        let start: Date = new Date(r.residenceStartDate); start.setHours(0, 0, 0, 0);

        let end: Date;
        if (r.residenceEndDate != null) {
            end = new Date(r.residenceEndDate);
            end.setHours(0, 0, 0, 0);
        }

        let checkStart: boolean = r.residenceStatusRef == "PRG";

        return ((checkStart ? start <= today : true) && (end == null || end >= today))
    }

    moveBed(ddata: DepartmentData, bedFromDrop: DropBedData) {
        for (let dd of ddata.rooms) {
            let v = dd.beds.find(r => r.bed.venueID == bedFromDrop.bed.bed.venueID);
            if (v != null) { //clear moved bed
                v.patient = null;
                v.residence = null;
                v.allowDrop = true;
                return;
            }
        }
    }


    dragBed(ev, bed: BedData) {
        let data: DropBedData = new DropBedData();
        data.bed = bed;
        data.index = 3;
        ev.dataTransfer.setData("text", JSON.stringify(data));
        //localStorage.setItem("draggedEl", JSON.stringify(data));
    }

    allowDrop(ev, bed: BedData) {
        if (bed.allowDrop == true) {
            //ev.preventDefault();
        }
    }


    dropFromDrake(obj: DrakeOBJ) {

        let bedFromDrop: DropBedData = null;

        if (obj.el.classList.contains("bedContent") && obj.target.id == "bedContainer") {
            bedFromDrop = new DropBedData();
            for (let room of this.calendarService.departmentData.rooms) {
                bedFromDrop.bed = room.beds.find(r => r.bed.venueID == Number(obj.el.id));
                if (bedFromDrop.bed != null) {
                    bedFromDrop.index = 3;
                    break;
                }
            }
        }
        else if (obj.el.dataset.drakeFrom == "patientsList" && obj.target.id == "bedContainer") {
            let resID = obj.el.title;
            bedFromDrop = new DropBedData();
            bedFromDrop.bed = this.calendarService.patients.find(r => r.residence.residenceID == Number(resID));
            bedFromDrop.index = 2;
        }
        else if (obj.el.dataset.drakeFrom == "sequencePatientList" && obj.target.id == "bedContainer") {
            let resID = obj.el.title;
            bedFromDrop = new DropBedData();
            bedFromDrop.bed = this.calendarService.venuePatients.find(r => r.residence.residenceID == Number(resID));
            bedFromDrop.index = 1;
        }

        if (bedFromDrop != null && bedFromDrop.bed && bedFromDrop.index) {

            let room: Venue = null;
            let venueRoom: RoomData = null;
            let currentBed: BedData = null;

            let currentVenueID: number = Number(obj.target.lang);

            for (let r of this.calendarService.departmentData.rooms) {
                currentBed = r.beds.find(c => c.bed.venueID == currentVenueID);
                if (currentBed != null) {
                    room = r.room;
                    venueRoom = r;
                    this.drop(bedFromDrop, currentBed, room, venueRoom);
                    break;
                }
            }

        }
    }

    allowDropToBed() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Workflow_Patient_acHospitalize == 'True')
            return true;
        else return false;
    }

    drop(bedFromDrop: DropBedData, currentBed: BedData, room: Venue, venuerRoom: RoomData) {
        this.checkParallelWork();


        let submit003: boolean = false;
        if (this.allowDropToBed()) {
            if (currentBed.allowDrop) //if bed is empty
            {
                //check if pln show info

                this.checkHasPlannedResidence(currentBed)
                    .then(rezult => {
                        if (rezult) {


                            this.checkGenderConfirmation(bedFromDrop.bed.patient, venuerRoom)
                                .then(rez => {
                                    if (rez) {

                                        //hardcode // select this in dropdown in future
                                        bedFromDrop.bed.residence.residenceStatusRef = "PRG";

                                        let newSeq: number = null;
                                        if (bedFromDrop.index == 1 || bedFromDrop.index == 2) {
                                            newSeq = this.sequenceID;
                                            if (bedFromDrop.index != 1)
                                                bedFromDrop.bed.residence.residenceStartDate = new Date();
                                        }


                                        let updatelists: boolean = false;

                                        this.openDialogFromDrop(bedFromDrop.bed, currentBed, room, newSeq).subscribe(result => {

                                            if (result != null && result.rezult)//if success in modal dialog
                                            {

                                                if (result.childRoute == "reject" || result.childRoute == "decease" || result.childRoute == "discharge") {
                                                    this.otherProc(result);

                                                    if (result.childRoute == "decease" || result.childRoute == "discharge") { this.submit066Form(result.patient, result.residence); }
                                                }
                                                else if (result.newSequence != null && (newSeq == null ? true : newSeq != result.newSequence)) {
                                                    this.changeSeqProc(result);
                                                    this.intitVenues(this.sequenceID);
                                                }
                                                else if (result.childRoute == "main" || result.childRoute == "changeDep") {
                                                    this.dropProc(result, bedFromDrop.index, currentBed);
                                                    if (bedFromDrop.index == 3) {

                                                    }

                                                    if (bedFromDrop.index == 2) {
                                                        //
                                                        submit003 = true;
                                                    }

                                                    this.intitVenues(this.sequenceID);
                                                    if (bedFromDrop.index == 2 || bedFromDrop.index == 1) { this.calendarService.sequencesChange(); }
                                                }

                                                this.saveData(result, currentBed, submit003);
                                            }
                                            else {
                                                if (bedFromDrop.index == 2 || bedFromDrop.index == 1) { this.calendarService.sequencesChange(); }
                                                this.intitVenues(this.sequenceID);  /* something if false (pressed no)*/
                                            }
                                        });
                                    }
                                    else {
                                        this.intitVenues(this.sequenceID);
                                        if (bedFromDrop.index == 2 || bedFromDrop.index == 1) { this.calendarService.sequencesChange(); }
                                        return;
                                    }
                                })

                        } else {
                            if (bedFromDrop.index == 2 || bedFromDrop.index == 1) { this.calendarService.sequencesChange(); }
                            this.intitVenues(this.sequenceID);
                        }
                    });
            } else {
                if (bedFromDrop.index == 2 || bedFromDrop.index == 1) { this.calendarService.sequencesChange(); }
                this.intitVenues(this.sequenceID);
            }
        } else {
            alert("no permissions to move patient on bed");
            this.intitVenues(this.sequenceID);
            if (bedFromDrop.index == 2 || bedFromDrop.index == 1) { this.calendarService.sequencesChange(); }
            return;
        }
    }

    submit003Form(p: Patient, r: Residence) {
        let data: DocumentEditData = new DocumentEditData();
        data.document = null;
        data.patient = p;
        data.residence = r;

        let guid: string = this._config.get("003TemplateStaticGuid");

        this.calendarService.getdocuments(p.patientID, r.residenceCourseRef).subscribe((docs: Document[]) => {

            if (docs.find(r => r.documentTemplateStaticGUIDString == guid)) { }
            else {
                this.open003Document(data).subscribe(result => {
                    if (result.rezult == true) {
                        result.document.json = result.jsonToSave;
                        result.document.courseRef = r.residenceCourseRef;
                        result.document.patientRef = p.patientID;
                        result.document.documentTemplateStaticGUIDString = guid;
                        result.document.documentTemplateStaticGUID = guid;

                        this.calendarService.updateDocumentJson(result.document).subscribe((rez => { }));
                    }
                });
            }
        });
    }

    submit066Form(p: Patient, r: Residence) {
        let data: DocumentEditData = new DocumentEditData();
        data.document = null;
        data.patient = p;
        data.residence = r;
        let guid: string = this._config.get("066TemplateStaticGuid");

        this.calendarService.getdocuments(p.patientID, r.residenceCourseRef).subscribe((docs: Document[]) => {

            if (docs.find(r => r.documentTemplateStaticGUIDString == guid)) { }
            else {
                this.open066Document(data).subscribe(result => {
                    if (result.rezult == true) {
                        result.document.json = result.jsonToSave;
                        result.document.courseRef = r.residenceCourseRef;
                        result.document.patientRef = p.patientID;
                        result.document.documentTemplateStaticGUIDString = guid;
                        result.document.documentTemplateStaticGUID = guid;

                        this.calendarService.updateDocumentJson(result.document).subscribe((rez => { }));
                    }
                });
            }
        });
    }

    open066Document(d: DocumentEditData): Observable<DocumentEditData> {

        const dialogRef = this.dialog.open(Document066Dialog, {
            data: d,
            width: '1380px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    open003Document(d: DocumentEditData): Observable<DocumentEditData> {

        const dialogRef = this.dialog.open(Document003Dialog, {
            data: d,
            width: '1380px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    async checkHasPlannedResidence(bed: BedData) {
        if (bed.residence == null || bed.residence.residenceStatusRef != "PLN") {
            return true;
        }

        let d: ConfirmPlannedDialogData = new ConfirmPlannedDialogData();
        d.bedData = bed;

        let response: ConfirmPlannedDialogData = await this.openConfirmPlannedDialog(d).toPromise();

        return response.rezult;
    }

    async checkGenderConfirmation(patient: Patient, venuerRoom: RoomData): Promise<boolean> {
        let rez: boolean = true;
        if (patient.patientSexRef == null || patient.patientSexRef == "") return rez;

        let patients: Patient[] = venuerRoom.beds.map(r => r.patient).filter(p => p != null);

        if (patients.length == 0) return rez;

        if (patients.find(r => r.patientSexRef != patient.patientSexRef)) {
            let data: ConfirmDialogData = new ConfirmDialogData();
            data.message = "Виявлені пацієнти з ішою статтю, продовжити?";
            data.YesNoButton = true;

            let response: ConfirmDialogData = await this.openConfirmDialog(data).toPromise();

            return response.rezult;
        }
        else {
            return rez;
        }
    }

    ///// modal dialogs

    openDialogFromDrop(bedFromDrop: BedData, currentBed: BedData, room: Venue, newSequence: number): Observable<DialogData> {

        let d: DialogData = new DialogData();
        d.room = room;
        d.bed = currentBed.bed;
        d.dropProcess = true;

        d.patient = bedFromDrop.patient;
        d.residence = bedFromDrop.residence;

        d.newSequence = newSequence;

        const dialogRef = this.dialog.open(ResidenceCreateDialog, {
            width: '387px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed(); //afterClosed();
    }
    /////

    openDialogFromEdit(data: BedData, room: Venue): Observable<DialogData> {
        let d: DialogData = new DialogData();
        d.bed = data.bed;
        d.room = room;
        d.patient = data.patient;
        d.residence = data.residence;

        const dialogRef = this.dialog.open(ResidenceCreateDialog, {
            data: d,
            width: '387px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.afterClosed();
    }

    openConfirmDialog(d: ConfirmDialogData): Observable<ConfirmDialogData> {

        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: d,
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }

    openConfirmPlannedDialog(d: ConfirmPlannedDialogData): Observable<ConfirmPlannedDialogData> {
        const dialogRef = this.dialog.open(ConfirmPlannedDialog, {
            data: d,
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }

    openJournals() {
        const dialogRef = this.dialog.open(UserJournalsDialog, {

            height: '100%',
            disableClose: false,
            width: '100%',
            hasBackdrop: true,
            panelClass: 'my-panelFull'
        });
        return dialogRef.afterClosed();
    }

    // styles
    //[ngStyle]="{'color': bedData.patient != null ? 'DodgerBlue' : 'gray'}"
    /*getColorPatientGender(bed: BedData) {
      if (bed.patient == null) return "gray";
  
      switch (bed.patient.patientSexRef) {
        case "FEM": return "hotpink";
        case "MAL": return "DodgerBlue";
        default: return "gray";
      }
    }*/
    // [ngStyle]="{'background-color':
    // bedData.residence != null ? bedData.residence.ResidenceStatusRef == 'PLN' ? '#fffeed' : '#EDF5FF' : 'white'


    getClockTitle(d: BedData) {
        let date: string = this.datePipe.transform(d.residence.residenceStartDate, "dd/MM/yyyy");
        let end: string = "";
        if (d.residence.residenceEndDate != null) {
            end = " - " + this.datePipe.transform(d.residence.residenceEndDate, "dd/MM/yyyy");
        }
        return date + end;
    }


    getBGroundColorBed(bed: BedData) {
        if (bed.allowDrop) return "#F5F6F7";
        else return "white";
    }

    allowShowBell(bed: BedData) {
        if (bed != null && bed.allowDrop && bed.residence != null && (bed.residence.residenceStatusRef == 'DED'
            || bed.residence.residenceStatusRef == 'CMP'))
            return true;
        else
            return false;
    }

    allowShowBedInfo(bed: BedData) {
        if (!bed.allowDrop)
            return true
        else if (bed.residence != null && bed.residence.residenceEndDate != null && (bed.residence.residenceStatusRef == 'DED'
            || bed.residence.residenceStatusRef == 'CMP')) {
            if (this.datePipe.transform(bed.residence.residenceEndDate, "yyyyMMdd") > this.datePipe.transform(this.date, "yyyyMMdd"))
                return true;
            else if (this.datePipe.transform(bed.residence.residenceEndDate, "yyyyMMdd") == this.datePipe.transform(this.date, "yyyyMMdd") &&
                this.datePipe.transform(bed.residence.residenceEndDate, "HHmm") > this.datePipe.transform(this.date, "HHmm"))
                return true;
            else
                return false;
        }
        else
            false;
    }

    getGenderColor(bed: BedData) {
        if (bed.patient == null) return "#F5F6F7";

        if (bed.patient.patientSexRef == "FEM") return "#D94C6B";
        if (bed.patient.patientSexRef == "MAL") return "#1874C4";

        return "#F5F6F7";
    }

    getTypeColor(bed: BedData) {
        if (bed.residence == null) return "white";

        switch (bed.residence.residenceStatusRef) {
            case "PLN": return "#ffd84d";
            case "PRG": return "#4dac67";
            case "CMP": return "#a1c5ff";
            default: return "white";
        }
    }

    getCategoryColor(bed: BedData) {
        if (bed.residence == null) return "white";


        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return this.getBGroundColorBed(bed);
        }

        let cat: ResidenceCategory;

        if (this.residenceCategories != null)
            cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.color;
        }
    }

    getCategoryColorText(bed: BedData) {
        if (bed.residence == null) return "";


        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return "";
        }

        let cat: ResidenceCategory;

        if (this.residenceCategories != null)
            cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.residenceCategoryName;
        }
    }

    getRoomStatusColor(data: RoomData) {
        if (data.beds.length == 0) return "#A2A2A2";

        let mal: number = data.beds.filter(r => r.patient != null && r.patient.patientSexRef == "MAL").length;
        let fem: number = data.beds.filter(r => r.patient != null && r.patient.patientSexRef == "FEM").length;

        if (mal == 0 && fem > 0) return "#D94C6B";
        if (fem == 0 && mal > 0) return "#1874C4";
        if (fem > 0 && mal > 0) return "#D58000";

        return "#A2A2A2";
    }

    getRoomStatusType(data: RoomData) {
        if (data.beds.length == 0) return "не визначений";

        let mal: number = data.beds.filter(r => r.patient != null && r.patient.patientSexRef == "MAL").length;
        let fem: number = data.beds.filter(r => r.patient != null && r.patient.patientSexRef == "FEM").length;

        if (mal == 0 && fem > 0) return "жіноча";
        if (fem == 0 && mal > 0) return "чоловіча";
        if (fem > 0 && mal > 0) return "змішана";

        return "не визначений";
    }

    roominRowsDisplay1() {
        if (this.displayRows == "2") {
            return { 'padding-bottom': '1px', 'height': 'calc(50% - 10px)' };
        } else return { 'padding-bottom': '0px', 'height': 'calc(100% - 5px)' };
    }

    //0-all 1-fem 2-mal 3-fem&mal 4-notdefined
    FilterRooms(data: RoomData): string {
        //1 gender filter
        let state: string = "inline-block";

        if (this.roomGender != null && this.roomGender != "0") {
            let mal: number = data.beds.filter(r => r.patient != null && r.patient.patientSexRef == "MAL").length;
            let fem: number = data.beds.filter(r => r.patient != null && r.patient.patientSexRef == "FEM").length;

            switch (this.roomGender) {
                case "1": if (fem == 0 || mal != 0) { state = "none"; } break;
                case "2": if (mal == 0 || fem != 0) { state = "none"; } break;
                case "3": if (fem == 0 || mal == 0) { state = "none"; } break;
                case "4": if (mal > 0 || fem > 0) { state = "none"; } break;
            }
        }

        if (this.roomBedState != null && this.roomBedState != "0") {
            switch (this.roomBedState) {
                case "1": if (data.beds.find(r => r.allowDrop != true)) { state = "none"; } break;
                case "2": if (data.beds.find(r => r.allowDrop != false)) { state = "none"; } break;
                case "3": if (!data.beds.find(r => r.allowDrop == true)) { state = "none"; } break; //do not show emty rooms on ded filtering
                case "4": if (!data.beds.find(r => r.allowDrop == false)) { state = "none"; } break; //do not show emty rooms on ded filtering
            }
        }

        if (this.bedInRoomsCount != null && this.bedInRoomsCount != "0") {
            switch (this.bedInRoomsCount) {
                case "1": if (data.beds.length != 1) { state = "none"; } break;
                case "2": if (data.beds.length != 2) { state = "none"; } break;
            }
        }

        if (this.floor != null && this.floor != "0") {
            if (data.room.venuePositionZ != Number(this.floor)) { state = "none"; }
        }

        if (this.filteredPatientId != null && !data.beds.find(r => r.patient != null && r.patient.patientID == this.filteredPatientId))
            state = "none";

        return state;
    }

    FilterBeds(bed: BedData): string {
        let state: string = "inline-block";


    if (this.bedType != null) 
    {
            if (this.bedType.dictionaryID != bed.bed.venueKindRef) state = "none";
        }

        if (this.roomBedState != null && this.roomBedState != "0") {
            switch (this.roomBedState) {
                case "3": if (bed.allowDrop != true) { state = "none"; } break;
                case "4": if (bed.allowDrop != false) { state = "none"; } break;
            }
        }

        if (this.filteredPatientId != null && (bed.patient == null || bed.patient.patientID != this.filteredPatientId))
            state = "none";

        return state;
    }

    MarginBedBottomIsTablet() {
        if (this.isTablet) return "4px"; else return "8px"
    }



    openFilter() {
        let data: FilterDialogData = new FilterDialogData();
        data.bedType = this.bedType;
        data.roomGender = this.roomGender;
        data.floor = this.floor;
        data.floors = this.calendarService.departmentData.rooms.map(item => item.room.venuePositionZ)
            .filter((value, index, self) => self.indexOf(value) === index && value != null).sort();
        data.displayRows = this.displayRows;
        data.roomBedState = this.roomBedState;
        data.bedInRoomsCount = this.bedInRoomsCount;
        data.departmentData = this.calendarService.departmentData;
        data.patientId = this.filteredPatientId;
        data.patientName = this.filteredPatientName;

        this.openFilterDialog(data).subscribe(result => {
      if (result.rezult == true)
      {
                this.roomGender = result.roomGender;
                this.bedType = result.bedType;
                this.displayRows = result.displayRows;
                this.roomBedState = result.roomBedState;
                this.bedInRoomsCount = result.bedInRoomsCount;
                this.filteredPatientId = result.patientId;
                this.filteredPatientName = result.patientName;
                this.floor = result.floor;
            }
        });
    }

    openFilterDialog(d: FilterDialogData): Observable<FilterDialogData> {

        const dialogRef = this.dialog.open(DayViewFilterDialog, {
            data: d,
            width: '387px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    getRoomsFilterText() {
        let text: string = "";

        if (this.floor != "0") {
            text = this.floor + " поверх; ";
        }


        if (this.roomBedState != "0") {
            switch (this.roomBedState) {
                case "1": text += "Вільні; "; break;
                case "2": text += "Зайняті; "; break;
            }
        }

        if (this.roomGender != "0") {
            switch (this.roomGender) {
                case "1": text += "Жіночі; "; break;
                case "2": text += "Чоловічі; "; break;
                case "3": text += "Змішані; "; break;
                case "4": text += "Не визначені; "; break;
            }
        }

        if (this.bedInRoomsCount != "0") {
            switch (this.bedInRoomsCount) {
                case "1": text = "Одномісні; "; break;
                case "2": text = "Двомісні; "; break;
            }

        }


        return text;
    }

    getBedsFilterText() {
        let text: string = " ";

        if (this.roomBedState != "0") {
            switch (this.roomBedState) {
                case "3": text = "Вільні; "; break;
                case "4": text = "Зайняті; "; break;
            }
        }

        if (this.bedType != null) {
            text += this.bedType.dictionaryName + "; ";
        }
        return text;
    }

    getBedsCount(data: RoomData) {
        return data.beds.filter(r => r.allowDrop).length + "/" + data.beds.length;
    }


    //ToDo: navigate here not from html
    //to reload data in day after constructor is call
    //this.router.navigate(["/search", "1", ""]).then(() => {
    // do whatever you need after navigation succeeds
    //});
    toggle(index: number): void {
        if (index == 1) {
            $('a[data-title="' + "Y" + '"]').removeClass('active').addClass('notActive');
            $('a[data-title="' + "N" + '"]').removeClass('notActive').addClass('active');
            this.router.navigate(['/calendar', { outlets: { 'view': 'timeline' } }]);
            this.checkParallelWork();
        }
        else {
            $('a[data-title="' + "Y" + '"]').removeClass('notActive').addClass('active');
            $('a[data-title="' + "N" + '"]').removeClass('active').addClass('notActive');
            this.router.navigate(['/calendar', { outlets: { 'view': 'day' } }]);
            this.checkParallelWork();
        }
    }



    onResize(event) {
        /*
        var el = document.getElementById('filterPanel'); if (el == null) return;
    
        var width = el.offsetWidth;
    
        let element1: HTMLElement = document.getElementById('FilterContainer');
        let element2: HTMLElement = document.getElementById('datepickerContainer');
        let element3: HTMLElement = document.getElementById('clock');
    
        if (width != null && element1!= null && element2 != null && element3 != null) {
    
          if(width)
          //clock
          if (width < 400) {
            element2.setAttribute("style", "width:25px");
            element3.setAttribute("style", "width:0px");
          }
          else {
            element2.setAttribute("style", "width:160px");
            element3.setAttribute("style", "width:140px");
          }
    
          if (width < 480) {
            this.dayText = "";
            this.lineText = "";
          } else {
        this.dayText = " День ";
        this.lineText = " Графік ";
          }
    
        }
        */
    }

    getSelectedColor(data: string) {
        if (data == null || data == "0") return "#A0C0EA";
        else return "#1874C4";
    }

    getIdentOrNoImage(pat: EHPatient) {
        if (pat.isPrePerson) {
            return "url(../../assets/images/NoIdentified.png)";
        }
        else {
            return "url(../../assets/images/Identified.png)";
        }
    }
    getContainerWidth(allowDrop) {
        if (allowDrop)
            return "calc(100% - 4px)";
        else {
            return "calc(100%)";
        }
    }

    getHeaderMinWidth() {
        if (this.isTablet) return "490px";
        return "490px";
    }

    getRoomWidth() {
        if (this.isTablet) return "170px";
        return "213px";
    }

    getDaysInCourse(bedData: BedData) {
        if (bedData == null || bedData.residence == null || bedData.residence.courseStartDate == null) return "";

        let date1: Date = new Date();
        let date2: Date = new Date(bedData.residence.courseStartDate);

        let timeInMilisec: number = date1.getTime() - date2.getTime();
        let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));

        return daysBetweenDates;
    }

    getDaysInDepartment(bedData: BedData) {
        if (bedData == null || bedData.residence == null || bedData.residence.residenceStartDate == null) return "";

        let date1: Date = new Date();
        let date2: Date = new Date(bedData.residence.residenceStartDate);

        let timeInMilisec: number = date1.getTime() - date2.getTime();
        let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));

        return daysBetweenDates;
    }


    //////////////

    //
}
