import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientComponent } from '../../../patient/patient.component';
import { DocumentEditData, DictionaryData } from '../../ResidenceCreate/ResidenceCreateDialog';
import { JsonDocumentData } from 'app/models/JsonDocumentData';
import { DocumentService } from '../DocumentService';
import { Config } from '../../../Config';
import { DictionarySelectDialog } from 'app/modalWindows/DictionarySelect/DictionarySelectDialog';
import { Dictionary } from '../../../models/Dictionary';
import { Document } from 'app/models/Document';
import { Venue } from 'app/models/Venue';

@Component({
  selector: 'Document066Dialog',
  templateUrl: './Document066Dialog.html',
  styleUrls: ['./Document066Dialog.css']
})

export class Document066Dialog {

  data: DocumentEditData;

  datetest: Date = null;

  json: JsonDocumentData;

  //dictionaries
  countries: Dictionary[] = [];
  arrivalTypes: Dictionary[] = [];
  INFdepartments: Dictionary[] = [];
  usersShort: Dictionary[] = [];

  //custom arrays from json
  genders: any = [];
  documents: any = [];
  gospTypes: any = [];
  gospTypesTimes: any = [];
  whereExist: any = [];
  povtor_hosp: any = [];
  povtor_dniv: any = [];
  lRezult: any = [];
  travma: any = [];
  rezist: any = [];
  diagnozTypes: any = [];
  PatAnVariants: any = [];
  pilgCat: any = [];
  operationTypes: any = [];

  constructor(public service: DocumentService, public dialogRef: MatDialogRef<Document066Dialog>, @Inject(MAT_DIALOG_DATA) public d: DocumentEditData, private _config: Config, public dialog: MatDialog, private datePipe: DatePipe) {
    this.data = d;

  }

  ngOnInit() {
    var _that = this;
    let guid: string = this._config.get("066TemplateStaticGuid");
    var _that = this;
    this.service.getDocumentJson(this.data.patient.patientID, guid, this.data.document, this.data.residence.residenceCourseRef).subscribe((data: JsonDocumentData) => {
      _that.json = data;

      this.service.getDictionary("Place of Birth").subscribe((data: Dictionary[]) => { _that.countries = data; });
      this.service.getDictionary("StationaryArrivalType").subscribe((data: Dictionary[]) => { _that.arrivalTypes = data; });
      this.service.getDictionary("Відділення інфраструктури").subscribe((data: Dictionary[]) => { _that.INFdepartments = data; });
      this.service.getDictionary("Користувачі (короткі імена)").subscribe((data: Dictionary[]) => { _that.usersShort = data; });

      //init custom arrays from json
      _that.genders = Object.entries(data.Root.Structure['Node1A39A36D-08D7-45AD-95CD-352AE0E97771Node'].ns);
      _that.documents = Object.entries(data.Root.Structure['Node37BE3278-644F-4F3F-87BB-72A118C0CD60Node'].ns);
      _that.gospTypes = Object.entries(data.Root.Structure['Node4D0CD259-2C92-4D8C-878F-140EB986E3D2Node'].ns);
      _that.gospTypesTimes = Object.entries(data.Root.Structure['NodeD917CF56-5D74-4CFA-B1F6-9CB9EB9B787ANode'].ns);
      _that.whereExist = Object.entries(data.Root.Structure['Node7EA52F76-1A61-4481-8C10-9F1E6CD466CBNode'].ns);
      _that.povtor_hosp = Object.entries(data.Root.Structure['Node24579C4E-1932-4974-9634-9A09EA37B164Node'].ns);
      _that.povtor_dniv = Object.entries(data.Root.Structure['Node4200DEB1-74A3-4BB3-AAA8-EF699E221DC9Node'].ns);
      _that.lRezult = Object.entries(data.Root.Structure['NodeD4305601-FA0A-4413-8F0E-282FB4BF7B53Node'].ns);
      _that.travma = Object.entries(data.Root.Structure['NodeAB648AD0-16FD-442A-835C-F9CAB67AD881Node'].ns);
      _that.diagnozTypes = Object.entries(data.Root.Structure['Node8C517F98-8712-4926-8A35-A6BB57C27866Node'].ns
      ['NodeCFBD90FA-108D-46BC-B386-E6F43BDFE770Node'].ns);
      _that.rezist = Object.entries(data.Root.Structure["Node61630293-515A-4B1B-94AA-62B2618BD844Node"].ns);
      _that.PatAnVariants = Object.entries(data.Root.Structure["Node697EDE68-F89C-4F95-9194-7EE1B6116055Node"].ns["Node9E4D1177-19E6-4D25-9EA8-94FC2F7B16D6Node"].ns);
      _that.pilgCat = Object.entries(data.Root.Structure["Node5BED643F-4D21-4DAA-8003-B28FAD86B5A7Node"].ns);
      _that.operationTypes = Object.entries(data.Root.Structure['Node7C0A17C4-9556-4602-8261-0B329DF72CEBNode']
        .ns['Node70B682A4-742E-4EAC-A946-13A3F7E39E58Node'].ns);


      this.service.parseDate(this.json, this.datePipe, 'Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node', true);
      this.service.parseDate(this.json, this.datePipe, 'NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode', true);
      this.service.parseDate(this.json, this.datePipe, 'NodeB974268F-0D06-4138-8E5D-D42313991CBBNode', true);
      this.service.parseTableDate(this.json, this.datePipe, 'NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node', 'Node7C0A17C4-9556-4602-8261-0B329DF72CEBNode', true);

      this.service.parseDate(this.json, this.datePipe, 'Node54632AEC-30B6-46A1-8B71-D5FB2797DD0ENode', true);
      this.service.parseDate(this.json, this.datePipe, 'Node85D87459-38A9-4408-A06B-F687C90ADDF5Node', true);
      this.service.parseDate(this.json, this.datePipe, 'Node8F7F2632-7D96-4D38-B9CC-7D5E53D1AF95Node', true);
      this.service.parseDate(this.json, this.datePipe, 'Node863AE8B0-E211-4CE4-8190-30FB5508674FNode', true);
      this.service.parseDate(this.json, this.datePipe, 'NodeE650B8EA-89DC-4B01-BB37-3D35CCA2158ANode', true);

      _that.autocompleteFields(_that.json);
    });
  }

  AddAdditionalDiagnozRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['NodeD6297384-8270-4241-966C-820A30F8EF51Node'], structure);
    Object.assign(this.json.Root.DocumentValues['Node5B27DC48-2BF7-4500-93F4-6F2F98F50D02Node'], structure1);
    Object.assign(this.json.Root.DocumentValues['NodeCFBD90FA-108D-46BC-B386-E6F43BDFE770Node'], structure2);
  }

  RemoveAdditionalDiagnozRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['NodeD6297384-8270-4241-966C-820A30F8EF51Node'][path];
      delete this.json.Root.DocumentValues['Node5B27DC48-2BF7-4500-93F4-6F2F98F50D02Node'][path];
      delete this.json.Root.DocumentValues['NodeCFBD90FA-108D-46BC-B386-E6F43BDFE770Node'][path];
    }
  }

  AddPatAnatomRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['Node742395DE-A569-40E9-AF1A-533ABEBCEBD9Node'], structure);
    Object.assign(this.json.Root.DocumentValues['Node12C342E7-6324-4A38-81C1-5A8D97A1BBC3Node'], structure1);
    Object.assign(this.json.Root.DocumentValues['Node2E6DC699-3557-4E6B-B4E2-F350CEE83CD7Node'], structure2);
  }

  RemovePatAnatomRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['Node742395DE-A569-40E9-AF1A-533ABEBCEBD9Node'][path];
      delete this.json.Root.DocumentValues['Node12C342E7-6324-4A38-81C1-5A8D97A1BBC3Node'][path];
      delete this.json.Root.DocumentValues['Node2E6DC699-3557-4E6B-B4E2-F350CEE83CD7Node'][path];
    }
  }

  AddDeadWhyRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };
    var structure3 = {}; structure3[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['Node5D403B99-9897-4F3B-A6E3-C679E32860D5Node'], structure);
    Object.assign(this.json.Root.DocumentValues['Node9E4D1177-19E6-4D25-9EA8-94FC2F7B16D6Node'], structure1);
    Object.assign(this.json.Root.DocumentValues['Node064FE3CC-C22B-4CCD-8B45-97790618B3C6Node'], structure2);
    Object.assign(this.json.Root.DocumentValues['Node168048F6-A7C9-43AF-B832-6DB24758EEB7Node'], structure3);
  }

  RemoveDeadWhyRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['Node5D403B99-9897-4F3B-A6E3-C679E32860D5Node'][path];
      delete this.json.Root.DocumentValues['Node9E4D1177-19E6-4D25-9EA8-94FC2F7B16D6Node'][path];
      delete this.json.Root.DocumentValues['Node064FE3CC-C22B-4CCD-8B45-97790618B3C6Node'][path];
      delete this.json.Root.DocumentValues['Node168048F6-A7C9-43AF-B832-6DB24758EEB7Node'][path];
    }
  }

  AddOperationRow(node: string) {
    if (!this.json || !this.json.Root) return;

    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index + 1).toString();
    let path: string = 'row_' + index + 'row';

    var structure = {}; structure[path] = { Value: null };
    var structure1 = {}; structure1[path] = { Value: null };
    var structure2 = {}; structure2[path] = { Value: null };
    var structure3 = {}; structure3[path] = { Value: null };
    var structure4 = {}; structure4[path] = { Value: null };
    var structure5 = {}; structure5[path] = { Value: null };
    var structure6 = {}; structure6[path] = { Value: null };

    Object.assign(this.json.Root.DocumentValues['NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node'], structure);
    Object.assign(this.json.Root.DocumentValues['NodeA0C0AD05-6956-4609-B618-87ACD5A2276FNode'], structure1);
    Object.assign(this.json.Root.DocumentValues['Node70B682A4-742E-4EAC-A946-13A3F7E39E58Node'], structure2);
    Object.assign(this.json.Root.DocumentValues['Node1FDA9861-F485-4C27-B742-A71ECA77BB14Node'], structure3);
    Object.assign(this.json.Root.DocumentValues['Node8B4E96BB-0129-48C8-B580-169D591E2220Node'], structure4);
    Object.assign(this.json.Root.DocumentValues['Node51A6C792-5076-4730-B4E6-C02F6BED4294Node'], structure5);
    Object.assign(this.json.Root.DocumentValues['Node7F080367-5478-46E8-B560-7EC073912B29Node'], structure6);
  }


  RemoveOperationRow(node: string) {
    let i: string = this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);
    if (index > 0) {
      let path: string = 'row_' + index + 'row';
      this.json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"] = (index - 1).toString();

      delete this.json.Root.DocumentValues['NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node'][path];
      delete this.json.Root.DocumentValues['NodeA0C0AD05-6956-4609-B618-87ACD5A2276FNode'][path];
      delete this.json.Root.DocumentValues['Node70B682A4-742E-4EAC-A946-13A3F7E39E58Node'][path];
      delete this.json.Root.DocumentValues['Node1FDA9861-F485-4C27-B742-A71ECA77BB14Node'][path];

      delete this.json.Root.DocumentValues['Node8B4E96BB-0129-48C8-B580-169D591E2220Node'][path];
      delete this.json.Root.DocumentValues['Node51A6C792-5076-4730-B4E6-C02F6BED4294Node'][path];
      delete this.json.Root.DocumentValues['Node7F080367-5478-46E8-B560-7EC073912B29Node'][path];
    }
  }

  onYesClick(): void
  {
    this.service.parseDate(this.json, this.datePipe, 'Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node');
    this.service.parseDate(this.json, this.datePipe, 'NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode');
    this.service.parseDate(this.json, this.datePipe, 'NodeB974268F-0D06-4138-8E5D-D42313991CBBNode');
    this.service.parseTableDate(this.json, this.datePipe, 'NodeB22B2506-7E0D-412C-9EA7-A1B6C895B0C4Node', 'Node7C0A17C4-9556-4602-8261-0B329DF72CEBNode');
    this.service.parseDate(this.json, this.datePipe, 'Node54632AEC-30B6-46A1-8B71-D5FB2797DD0ENode');
    this.service.parseDate(this.json, this.datePipe, 'Node85D87459-38A9-4408-A06B-F687C90ADDF5Node');
    this.service.parseDate(this.json, this.datePipe, 'Node8F7F2632-7D96-4D38-B9CC-7D5E53D1AF95Node');
    this.service.parseDate(this.json, this.datePipe, 'Node863AE8B0-E211-4CE4-8190-30FB5508674FNode');
    this.service.parseDate(this.json, this.datePipe, 'NodeE650B8EA-89DC-4B01-BB37-3D35CCA2158ANode');
    //this.data.rezult = true;
    //this.dialogRef.close(this.data);
    this.data.rezult = true;

    if (this.data.document == null) this.data.document = new Document(null);

    this.data.jsonToSave = JSON.stringify(this.json);
    this.data.document.json = JSON.stringify(this.json);

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    //this.data.rezult = false;
    console.log(this.datetest);
    this.dialogRef.close();
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  calcCount() {
    if (this.json.Root.DocumentValues['Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node'].Value
      && this.json.Root.DocumentValues['NodeB974268F-0D06-4138-8E5D-D42313991CBBNode'].Value) {

      var date1 = new Date(this.json.Root.DocumentValues['Node3878FB8A-5950-478C-8DC4-95A3DAF76A58Node'].Value);
      var date2 = new Date(this.json.Root.DocumentValues['NodeB974268F-0D06-4138-8E5D-D42313991CBBNode'].Value);

      var diff = date2.getTime() - date1.getTime();
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      this.json.Root.DocumentValues['Node230967B3-7BEF-49AB-A815-151DE5256514Node'].Value = diffDays;

      return diffDays;
    }
    else {
      return "";
    }
  }

  autocompleteFields(json: JsonDocumentData)
  {
    var structure = { root: { item: { dictInfo: { ID: " ", Code: " ", Name: " ", Path: " " } } } };
    if (this.json.Root.DocumentValues['NodeBE45C101-B455-4B96-A430-C0BDC7AF7956Node'].Value == null)
    {
      this.json.Root.DocumentValues['NodeBE45C101-B455-4B96-A430-C0BDC7AF7956Node'].Value = structure;
      if (this.data.residence != null && this.data.patient != null) {
        if (this.data.residence.venueRef != null) {
          this.service.getDepartment(this.data.residence).subscribe((data: Venue) =>
          {
            this.json.Root.DocumentValues['NodeBE45C101-B455-4B96-A430-C0BDC7AF7956Node'].Value.root.item.dictInfo.ID = data.venueID;
            this.json.Root.DocumentValues['NodeBE45C101-B455-4B96-A430-C0BDC7AF7956Node'].Value.root.item.dictInfo.Name = data.venueName;
          });
        }
      }
    }
    if (this.data.residence != null && this.data.patient != null) {
      if (this.json.Root.DocumentValues['Node544982C4-7DB3-4CE4-9B19-DFF630423440Node'].Value == null)
        this.json.Root.DocumentValues['Node544982C4-7DB3-4CE4-9B19-DFF630423440Node'].Value = this.data.patient.patientName;

      if (this.json.Root.DocumentValues['NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode'].Value == null)
        this.json.Root.DocumentValues['NodeC553ADC4-ADFF-4AFF-B47A-563C54C5B1BENode'].Value = this.data.patient.patientBirthDate;

      if (this.json.Root.DocumentValues['Node151BA937-3CC3-4243-B058-537A420C4EC7Node'].Value == null)
        this.json.Root.DocumentValues['Node151BA937-3CC3-4243-B058-537A420C4EC7Node'].Value = this.data.patient.patientAge;

      if (this.json.Root.DocumentValues['Node3AAD5003-1D01-4421-B1C9-973F7C18EED9Node'].Value == null)
        this.json.Root.DocumentValues['Node3AAD5003-1D01-4421-B1C9-973F7C18EED9Node'].Value = this.data.patient.patientAddress;
    }
  }

  getDateColor(date: Date) {
    if (date != null) {
      return "#28a745";
    } else {
      return "#B2B2B2"
    }
  }

  onDictionatyClick(node: string, dicNeme: string, index: number = null) {
    this.openDialog(dicNeme).subscribe(result => {

      if (result != null && result.rezult && result.dictionaryRow)//if success in modal dialog
      {
        this.service.parseDictionary(node, result.dictionaryRow, index, this.json);
        // bind data to needed object
      }
      else {
        // something if false (pressed no)
      }
    });
  }

  openDialog(dicName: string): Observable<DictionaryData> {

    let d: DictionaryData = new DictionaryData();
    d.dictionaryName = dicName;

    const dialogRef = this.dialog.open(DictionarySelectDialog, {
      width: '600px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

  eventHandler(event) {
    event.returnValue = false;
    event.preventDefault();
  }

}

