import { Component, Inject } from '@angular/core';
import { Dictionary } from '../../../models/Dictionary';
import { ResidenceCategory } from '../../../models/ResidenceCategory';
import { ResidenceCreateService } from '../ResidenceCreateService';
import { DialogData } from '../../../calendar/Day/day.component';
import { Observable, Subscription } from 'rxjs';
import { BedData } from '../../../models/BedData';
import { Account } from '../../../models/Account';
import { DiagnosisSelectDialog } from '../../DiagnosisSelect/DiagnosisSelectDialog';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosData } from '../ResidenceCreateDialog';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'residence-main',
  templateUrl: './residence.main.html',
  styleUrls: ['./residence.main.css']
})
export class ResidenceMainComponent {

  doctors: Dictionary[];
  arrivalTypes: Dictionary[];
  diagnosis: Dictionary[];
  users: Dictionary[];
  residenceCategories: ResidenceCategory[];
  documents: Document[];
  accounts: Account[];

  subscribtion: Subscription;
  constructor(private service: ResidenceCreateService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private lservice: LoginService) {

    var _that = this;

    this.service.residenceData.childRoute = "main";

    if (this.service.residenceData.dropProcess == undefined) {
      this.service.residenceData.dropProcess = false;
    }
    

    this.service.getArrivalTypes().subscribe((data: Dictionary[]) => { _that.arrivalTypes = data; });
    this.service.getDiagnozis().subscribe((data: Dictionary[]) => { _that.diagnosis = data; });
    this.service.getDoctors().subscribe((data: Dictionary[]) => { _that.doctors = data; });
    this.service.getResidenceCategories().subscribe((data: ResidenceCategory[]) => { _that.residenceCategories = data; });
    this.service.getPatientAccounts(this.service.residenceData.patient.patientID)
      .subscribe((data: Account[]) => { _that.accounts = data; });
    this.service.getDictionary("Користувачі").subscribe((data: Dictionary[]) =>
    { _that.users = data; });

    this.subscribtion = this.service.mainUpdated().subscribe(r => this.bindData());
  }


  public ngOnInit() {
  }


  ngOnDestroy() {

  }

  rejectIsDisabled() {//відмова
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Workflow_Patient_acRejectByEntry == 'True')
      return this.service.residenceData.dropProcess;
    else return true;
  }
  moveIsDisabled() {//переведення
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Workflow_Patient_acMoveToAnotherDepartment == 'True')
      return this.service.residenceData.dropProcess;
    else return true;
  }
  deceaseIsDisabled() {//смерть
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Workflow_Patient_acPatientDeceased == 'True')
      return this.service.residenceData.dropProcess;
    else return true;
  }
  dischargeIsDisabled() {//виписка
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Workflow_Patient_acDischarge == 'True')
      return this.service.residenceData.dropProcess;
    else return true;
  }


  bindData() {
    //validation
    //if (this.service.courseArrivalType === null) {
    //alert("empty CourseArrivalType");
    //this.service.residenceData.rezult = false;
    //}
    // else {
    this.service.residenceData.rezult = true;
    this.service.residenceData.residence.userRef = this.service.userRef;
    this.service.residenceData.residence.courseDefaultAccountRef = this.service.accountRef;
    this.service.residenceData.residence.courseArrivalType = this.service.courseArrivalType;
    this.service.residenceData.residence.residenceCategory = this.service.residenceCategory;
    if (this.service.residenceCategory != null)
      this.service.residenceData.residence.residenceCategoryRow = this.residenceCategories.find(r => r.residenceCategoryID == this.service.residenceCategory);
    this.service.residenceData.residence.residenceDiagnosis1 = this.service.CurrentDiagnos.dictionaryCode;
    this.service.residenceData.residence.residenceDiagnosis1Name = this.service.CurrentDiagnos.dictionaryName;
    this.service.residenceData.residence.residenceStatusRef = this.service.residenceStatusRef;
    this.service.residenceData.residence.residenceDesc = this.service.residenceDesc;
    if (this.service.bookRejectReason)
      this.service.residenceData.residence.bookRejectReason = this.service.bookRejectReason;

    this.service.residenceData.residence.stationaryDepartureType = this.service.stationaryDepartureType;

    var start = moment(this.service.startDate, 'YYYY-MM-DD HH:mm').local().toDate();
    var userTimezoneOffset = start.getTimezoneOffset() * 60000;
    this.service.residenceData.residence.residenceStartDate = new Date(start.getTime() - userTimezoneOffset);

 

    if (this.service.endDate != null) {
      var end =  moment(this.service.endDate, 'YYYY-MM-DD HH:mm').local().toDate();
      var userTimezoneOffset = end.getTimezoneOffset() * 60000;
      this.service.residenceData.residence.residenceEndDate = new Date(end.getTime() - userTimezoneOffset);
    }
    //}
  }

  changeDep() {
    this.router.navigate(['.', { outlets: { 'residence': 'changeDep' } }]);
  }

  decease() {
    this.router.navigate(['.', { outlets: { 'residence': 'decease' } }]);
  }

  discharge() {
    this.router.navigate(['.', { outlets: { 'residence': 'discharge' } }]);
  }

  reject() {
    this.router.navigate(['.', { outlets: { 'residence': 'reject' } }]);
  }



  optionLimit(text: string) {
    return text.substring(0, 15);
  }

  startDateChanged() {
    if (this.service.endDate != null) {
      if (this.service.endDate <= this.service.startDate) {
        this.service.endDate = null;
      }
    }
  }

  getRestriction() {
    let date: Date = new Date(this.service.startDate);
    date.setMinutes(date.getMinutes() + 1);
    return date;
  }

  //for select diagnosis
  onDiagnosClick(data: BedData) {
    this.openDialog().subscribe(result => {

      if (result != null && result.rezult)//if success in modal dialog
      {
        if (result.newDiagnos.dictionaryCode != "") {
          this.service.CurrentDiagnos.dictionaryID = result.newDiagnos.dictionaryID;
          this.service.CurrentDiagnos.dictionaryCode = result.newDiagnos.dictionaryCode;
          this.service.CurrentDiagnos.dictionaryName = result.newDiagnos.dictionaryName;
        }
      }
      else {
        // something if false (pressed no)
      }
    });
  }


  //for select diagnosis
  openDialog(): Observable<DiagnosData> {

    let d: DiagnosData = new DiagnosData();
    d.diagnosis = this.diagnosis;
    d.oldDiagnos = this.service.CurrentDiagnos;
    d.newDiagnos = new Dictionary("");
    const dialogRef = this.dialog.open(DiagnosisSelectDialog, {
      width: '600px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

  eventHandler(event) {
    event.returnValue = false;
    event.preventDefault();
  }


}

