
export class User {

  constructor(userLogin: string) {
    this.userLogin = userLogin;
  }

  public userLogin: string;
  public userPassword: string;
  public userDepartmentRef: number;
  public userName: string;
  public userSpecialityRef: string;
  public userSpecialityName: string;
  public userTitle: string;
  public departmentName: string;
  public departmentCompanyName: string;
  public userDesc: string;
}
