import { Component } from '@angular/core';
import { LoginService } from './modalWindows/Login/LoginService';
import { Token } from './models/Token';
import { Config } from '../../src/app/Config';
import { responsiveService } from './responsiveService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private responsiveService: responsiveService,private service: LoginService,  private _config: Config)
  {
    let response: Promise<Token> = this.service.authorize().toPromise();
    _config.load();
  }

  ngOnInit() {
    this.responsiveService.getTabletStatus().subscribe(isMobile => {
      if (isMobile) {
        console.log('Tablet device detected')
      }
      else {
        console.log('Desktop detected')
      }
    });
    this.onResize();
  }

  onResize() {
    this.responsiveService.checkWidth();
  }

}
