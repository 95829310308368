import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { DataService } from 'app/calendar/calendar.service';
import { User } from 'app/models/User';
import { LoginService } from '../Login/LoginService';
import { object } from 'prop-types';
import { UserObjectHistory } from 'app/models/UserObjectHistory';


@Component({
  selector: 'UserProfileDialog',
  templateUrl: './UserProfileDialog.html',
  styleUrls: ['./UserProfileDialog.css']
})

export class UserProfileDialog {

  user: User;
  date: Date = new Date();

  history: UserObjectHistory[] = [];

  permissions: any = null;
  firstload: boolean = true;

  constructor(private service: DataService, private lservice: LoginService, public dialogRef: MatDialogRef<UserProfileDialog>, public dialog: MatDialog, private datePipe: DatePipe)
  {
    var _that = this;

    this.permissions = lservice.getUserPermissions();

    this.service.getUser(this.lservice.UserToken.userLogin).subscribe((data: User) =>
    {
     _that.user = data;
    });
  }

  Load() {
    var _that = this;

    this.firstload = false;
    let dFrom: string = this.datePipe.transform(this.date, "yyyyMMdd");

    let endDate = new Date(this.date);
    endDate.setDate(endDate.getDate()+1);

    let dTo: string = this.datePipe.transform(endDate, "yyyyMMdd");

    this.service.getUserObjectHistory(dFrom, dTo).subscribe((data: UserObjectHistory[]) => {
      _that.history = data;
    });
  }

  onYesClick(): void
  {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  getPhoto() {
      return "url(../../assets/images/unknown.svg)";
  }

  collapsed: boolean = false;
  collapseUser() {
    this.collapsed = true;
  }

  expandUser() {
    this.collapsed = false;
  }

  getMainWidth() {
    if (this.collapsed) {
      return "calc(100% - 40px)";
    } else {
      return "calc(100% - 330px)";
    }
  }

  iconShow(key, bool) {
    if (bool) {
      return this.permissions[key] == "False";
    } else {
      return this.permissions[key] == "True";
    }
  }


  permissionIsActive(key) {
    return this.permissions[key] == "True";
  }

  dateFormat(date: Date) {
    if (date) {
      return this.datePipe.transform(date, "dd.MM.yyyy HH:mm")
    }
    return "";
  }
  
}

