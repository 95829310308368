import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import $ from 'jquery';
import { SequenceDialogData } from '../../calendar/calendar.component';

@Component({
  selector: 'app-modalWindows-SequenceSelect',
  templateUrl: './SequenceSelectDialog.html',
  styleUrls: ['./SequenceSelectDialog.css'],
})
export class SequenceSelectDialog {

  data: SequenceDialogData;
  selectedSequence: number;

  constructor(public dialogRef: MatDialogRef<SequenceSelectDialog>, @Inject(MAT_DIALOG_DATA) public d: SequenceDialogData)
  {
    this.selectedSequence = d.currentSequence.sequenceID;
    this.data = d;
  }

  onYesClick(): void {
    this.data.rezult = true;
    this.data.selectedSequence = this.selectedSequence;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }
}

