import { Component, Input, ViewChild } from '@angular/core';
import $ from 'jquery';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { BedData } from 'app/models/BedData';
import { Prescription } from 'app/models/Prescription';
import { DoctorService } from 'app/doctor/doctor.service';
import { Booking } from 'app/models/Booking';
import { DomSanitizer } from '@angular/platform-browser';
import { PopoverDirective } from 'ngx-smart-popover';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'app-BookingStatusPop',
  templateUrl: './BookingStatusPop.component.html',
  styleUrls: ['./BookingStatusPop.component.css']
})




export class BookingStatusPopComponent {

  private booking: Booking;

  private status: string;
  private desc: string;
  private pop: PopoverDirective;
  private _isBooking: boolean = false;

  @Input() set selectedBooking(value: Booking)
  {
    if (value != null) {
      this.booking = value;
      this.status = value.bookingStatusRef;
      this.desc = value.bookingDesc;
    }
  }

  @Input('is-Booking') set isBooking(value: boolean)
  {
    this._isBooking = value;
  }

  constructor(private router: Router, private datePipe: DatePipe,
    private service: DoctorService, private sanitizer: DomSanitizer, private lservice: LoginService)
  {
  }

  ngOnInit() {
  }

  AllowComplete() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.SecurityActions_Booking_Execution_Complete == 'True')
      return true;
    else return false;
  }

  AllowCancel() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.SecurityActions_Booking_Execution_Cancel == 'True')
      return true;
    else return false;
  }

  onYesClick()
  {
    this.service.popHide();

    let body: Booking = new Booking();
     body.bookingStatusRef = this.status;
    body.bookingDesc = this.desc;
    body.bookingID = this.booking.bookingID;

    this.service.updateBooking(body).subscribe((rez => {

      //save to db and after that
      this.booking.bookingStatusRef = rez.bookingStatusRef;
      this.booking.bookingDesc = rez.bookingDesc;
      this.booking.username = rez.username;
    }));

    this.service.popHide();
  }

}
