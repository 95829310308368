import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';

import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData, ServiceAddData } from 'app/llp/llp.component';
import { ServiceData } from 'app/models/ServiceData';
import { DoctorService } from 'app/doctor/doctor.service';
import { SelectServiceDialog } from '../SelectService/SelectServiceDialog';
import { Service } from 'app/models/Service';
import { TariffPlan } from 'app/models/TariffPlan';
import { Account } from 'app/models/Account';
import { DataService } from 'app/calendar/calendar.service';
import { ConfirmDialogData } from 'app/calendar/Day/day.component';
import { ConfirmDialog } from 'app/modalWindows/Confirm/ConfirmDialog';


@Component({
    selector: 'app-modalWindows-AddService',
    templateUrl: './AddServiceDialog.html',
    styleUrls: ['./AddServiceDialog.css'],
    providers: [DatePipe],
})

export class AddServiceDialog {

    myOptionsColor = {
        'theme': 'light'
    }

    data: ServiceAddData;
    bookingStartDate: Date = new Date();
    count: number = 1;
    desc: string;

    selectedAccount: Account = null;
    selectedTariffPlan: TariffPlan = null;

    patientAccounts: Account[] = [];
    patientTariffPlans: TariffPlan[] = [];

    selectedService: ServiceData = new ServiceData();

    constructor(public dialogRef: MatDialogRef<AddServiceDialog>, @Inject(MAT_DIALOG_DATA)
    public d: ServiceAddData, private datePipe: DatePipe, public dialog: MatDialog,
        private service: DoctorService, private dataService: DataService) {
        this.data = d;

        var _that = this;

        this.dataService.getPatientAccounts(d.patient.patientID)
            .subscribe((data: Account[]) => {
                _that.patientAccounts = data;
                let accountRef: number = d.residence.courseDefaultAccountRef;
                if (accountRef == null) { accountRef = d.patient.patientDefaultAccountRef; }

                if (accountRef != null && data.find(r => r.accountID == accountRef)) {
                    _that.selectedAccount = data.find(r => r.accountID == accountRef);

                    this.dataService.getTariffPlans(_that.selectedAccount.accountTypeRef)
                        .subscribe((tdata: TariffPlan[]) => {
                            _that.patientTariffPlans = tdata;
                            if (_that.selectedAccount.tariffPlanRef != null && tdata.find(r => r.tariffPlanID == _that.selectedAccount.tariffPlanRef)) {
                                _that.selectedTariffPlan = tdata.find(r => r.tariffPlanID == _that.selectedAccount.tariffPlanRef);
                            }
                            else if (tdata.find(r => r.accountTypeTariffPlanIsDefault)) {
                                _that.selectedTariffPlan = tdata.find(r => r.accountTypeTariffPlanIsDefault);
                            }
                        });
                }
            })

    }

    selectedAccountChange() {
        this.selectedTariffPlan = null;

        var _that = this;

        this.dataService.getTariffPlans(_that.selectedAccount.accountTypeRef)
            .subscribe((tdata: TariffPlan[]) => {
                _that.patientTariffPlans = tdata;
                if (_that.selectedAccount.tariffPlanRef != null && tdata.find(r => r.tariffPlanID == _that.selectedAccount.tariffPlanRef)) {
                    _that.selectedTariffPlan = tdata.find(r => r.tariffPlanID == _that.selectedAccount.tariffPlanRef);
                }
                else if (tdata.find(r => r.accountTypeTariffPlanIsDefault)) {
                    _that.selectedTariffPlan = tdata.find(r => r.accountTypeTariffPlanIsDefault);
                }
            });
    }

    selectedTPlanChange() {

    }

    ngOnInit() {

    }


    onActivate(event: any) {

    }

    isFolder(node: any) {
    }

    eventHandler(event) {
        event.returnValue = false;
        event.preventDefault();
    }

    onSelectClick(event) {
        let searchText: string = "";
        if (event != undefined)
            searchText = event.key;
        this.openDialogSelectService(searchText).subscribe(result => {
            if (result.rezult) {
                this.selectedService = result.selectedService;
            }
            else {

            }
        });
    }

    onYesClick(): void {
        if (this.selectedService.servicePriceRate == null && this.selectedService.servicePriceIsRequired) {
            let data: ConfirmDialogData = new ConfirmDialogData();
            data.message = "Неможливо призначити послугу без ціни.";
            data.OkButton = true;
            this.openConfirmDialog(data);
        }
        else {
            this.data.rezult = true;
            this.data.selectedService = this.selectedService;

            let serviceToSave: Service = new Service();
            serviceToSave.serviceID = Number(this.selectedService.id);
            serviceToSave.assignmentServiceQuantity = this.count;
            serviceToSave.assignmentServiceStartDate = this.bookingStartDate;
            serviceToSave.desc = this.desc;
            serviceToSave.tariffPlanRef = this.selectedTariffPlan.tariffPlanID;
            serviceToSave.assignmentServiceAccountRef = this.selectedAccount.accountID;

            this.data.serviceToSave = serviceToSave;

            this.dialogRef.close(this.data);
        }
    }

    onNoClick(): void {
        this.data.rezult = false;
        this.dialogRef.close(this.data);
    }

    keyDownFunction(event) {
        if (event.key === '.' || event.key === ',') {
            event.preventDefault();
        }
    }

    openDialogSelectService(searchText: string): Observable<ServiceAddData> {
        let d: ServiceAddData = new ServiceAddData();
        d.selectedAccountTypeRef = this.selectedAccount.accountTypeRef;
        d.selectedTPRef = this.selectedTariffPlan.tariffPlanID;
        d.serviceSearchText = searchText;

        const dialogRef = this.dialog.open(SelectServiceDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    openConfirmDialog(d: ConfirmDialogData): Observable<ConfirmDialogData> {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: d,
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }
}

