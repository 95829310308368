export class Dictionary
{

  constructor(id: string)
  {
    this.dictionaryID = id;
  }

  public dictionaryID: string;

  public dictionaryCode: string;

  public dictionaryName: string;

  public dictionaryPath: string;

  public dictionaryParentRef: string;
}
