import { Patient } from "./Patient";

export class Venue {

  constructor(id: number, n: string)
  {
    this.venueID = id;
    this.venueName = n;
    this.patient = null;
  }

  public venueID : number;
  public venueName: string;
  public venuePositionZ: number;
  public patient?: Patient;
  public venueKindRef: string;
  public room: string;
  public roomPositionZ: number;
  public sequenceName: string;
}
