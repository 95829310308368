import { Component, Input, ViewChild } from '@angular/core';
import $ from 'jquery';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Config } from '../../app/Config';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent {

  @Input() patient: Patient;

  patientIsVIP: boolean;
  patientName: string;
  patientBirthDate: Date;
  patientSexRef: string;
  patientAddress: string;
  patientPhone: string;
  patientEmail: string;
  patientNote: string;
  patientTaxIdentifier: string;
  patientPassport: string;



  useGoogleApi: boolean = false;
  constructor(private router: Router, _config: Config, private datePipe: DatePipe)
  {

    this.useGoogleApi = (_config.get("UseGooglePlace") == "1");
  }

  ngOnInit() {
    this.patientIsVIP = this.patient.patientIsVIP;
    this.patientName = this.patient.patientName;
    this.patientBirthDate = this.patient.patientBirthDate;
    this.patientSexRef = this.patient.patientSexRef;
    this.patientAddress = this.patient.patientAddress;
    this.patientPhone = this.patient.patientPhone;
    this.patientEmail = this.patient.patientEmail;
    this.patientNote = this.patient.patientNote;
    this.patientTaxIdentifier = this.patient.patientTaxIdentifier;
    this.patientPassport = this.patient.patientPassport;
  }

  bindData() {
    this.patient.patientIsVIP = this.patientIsVIP;
    this.patient.patientName = this.patientName;

    if (this.patientBirthDate != null) {
     
      this.patient.patientBirthDate = new Date(this.patientBirthDate);
      this.patient.patientBirthDate.setHours(12, 0, 0, 0);

    } else
    {
      this.patient.patientBirthDate = null;
    }

    this.patient.patientSexRef = this.patientSexRef;
    this.patient.patientAddress = this.patientAddress;
    this.patient.patientPhone = this.patientPhone;
    this.patient.patientEmail = this.patientEmail;
    this.patient.patientNote = this.patientNote;
    this.patient.patientTaxIdentifier = this.patientTaxIdentifier;
    this.patient.patientPassport = this.patientPassport;
  }

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(event)
  {
    console.log(event);
    // Do some stuff
  }

  getPhoto() {
    if (this.patient) {
      if (this.patient.patientSexRef == "MAL") return "url(../../assets/images/male.svg)";
      else if (this.patient.patientSexRef == "FEM") return "url(../../assets/images/female.svg)";
        else
      return "url(../../assets/images/unknown.svg)";
    }
  }


}
