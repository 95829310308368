import { NgModule, Injectable } from '@angular/core';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class responsiveService
{
  private isTablet = new Subject();
  public screenWidth: string;

  constructor() {
    this.checkWidth();
  }

  onTabletChange(status: boolean) {
    this.isTablet.next(status);
  }

  getTabletStatus(): Observable<any> {
    return this.isTablet.asObservable();
  }

  public checkWidth() {
    var width = window.innerWidth;
    if (width <= 768) {
      this.screenWidth = 'ipadMini';//ipad mini
      this.onTabletChange(true);
    } else if (width > 768 && width <= 1024) {
      this.screenWidth = 'ipad Pro';//ipad Pro
      this.onTabletChange(true);
    } else {
      this.screenWidth = 'lg';
      this.onTabletChange(false);
    }
  }

}
