import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';

import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData, ServiceAddData } from 'app/llp/llp.component';
import { ServiceData } from 'app/models/ServiceData';
import { DoctorService } from 'app/doctor/doctor.service';
import { Service } from 'app/models/Service';

import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions, TreeNode, TreeComponent } from '@circlon/angular-tree-component';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'highlight'
})

export class HighlightSearch implements PipeTransform {

    transform(value: any, args: any): any {
        if (!args) { return value; }
        var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        return value.replace(re, '<span style="color:red" class="highlight">$&</span>');
    }
}

const actionMapping: IActionMapping = {
    mouse: {
    click: (tree, node, $event) =>
    {
            if ((node.data.serviceIsBooking && !node.data.serviceIsDisabled)
        && (node.children.length == 0 || node.data.serviceIsSelectedWithChildren ) )
      {
                node.setIsActive(true);
            }
        }
    }
}

@Component({
    selector: 'app-modalWindows-SelectService',
    templateUrl: './SelectServiceDialog.html',
    styleUrls: ['./SelectServiceDialog.css'],
    providers: [DatePipe],
})



export class SelectServiceDialog {

    //@ViewChild('tree') tree: TreeComponent;
    @ViewChild(TreeComponent)
    private tree: TreeComponent;

    myOptionsColor = {
        'theme': 'light'
    }

    data: ServiceAddData;
    services: ServiceData[] = [];

    selectedService: ServiceData = null;

    items = [];
    options: ITreeOptions = { actionMapping };

    constructor(public dialogRef: MatDialogRef<SelectServiceDialog>, @Inject(MAT_DIALOG_DATA)
    public d: ServiceAddData, private datePipe: DatePipe, public dialog: MatDialog, private service: DoctorService) {
        this.data = d;
    }

    ngOnInit() {
        var _that = this;

        this.service.getServiceData(this.data.selectedAccountTypeRef, this.data.selectedTPRef).subscribe((data: ServiceData[]) => {
            _that.services = data;
            _that.items = _that.services;//JSON.stringify(_that.services)
        });
    }


    onActivate(event: any) {
        console.log(event.node.id);

        let root: ServiceData = new ServiceData();
        root.id = "0";
        root.children = this.services;
        let service: any = this.searchTree(root, event.node.id);

        //if (/*this.isFolder(service)*/) {
        //  this.selectedService = null;
        //} else {
        this.selectedService = service;
        // }
    }

  searchTree(element, id)
  {
        if (element.id == id) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], id);
            }
            return result;
        }
        return null;
    }

    isFolder(node: any) {
        return node.children.length > 0;
    }

  getNodeColor(node: any)
  {
        if (!node.data.serviceIsDisabled) return "#000000"; else return "gray";
    }

    getFolderColor(node: any) {
        if ((!node.data.serviceIsDisabled && node.data.serviceIsBooking) &&
            (node.children.length == 0 || node.data.serviceIsSelectedWithChildren)) return "#1874C4"; else return "#A0C0EA";
    }


    getServiceColor(node: any) {
        if ((!node.data.serviceIsDisabled && node.data.serviceIsBooking) &&
            (node.children.length == 0 || node.data.serviceIsSelectedWithChildren)) return "#A0C0EA"; else return "gray";
    }

    onNoClick(): void {
        this.data.rezult = false;
        this.dialogRef.close(this.data);
    }

    onSelectClick() {
        this.data.rezult = true;
        this.data.selectedService = this.selectedService;
        this.dialogRef.close(this.data);
    }

    public doubleClick(node: any) {
        if (this.selectedService != null && node.id != null && this.selectedService.id == node.id) {
            this.data.rezult = true;
            this.data.selectedService = this.selectedService;
            this.dialogRef.close(this.data);
        }
    }

    filterNodes(value, tree) {


        let foundResults: TreeNode[] = [];
        tree.treeModel.filterNodes((node: TreeNode) => {
            const nodeName = node.data.serviceCodeName.toLowerCase();
            const searchValue = value.toLowerCase();
            const nodeFound = nodeName.includes(searchValue);
            if (nodeFound && node.hasChildren) {
                console.log(node.children)
                for (let child of node.children) {
                    if (child.hasChildren) {
                        foundResults.push(child);
                    }
                }
                foundResults.push(node);

            }
            return nodeFound;
        });
        foundResults.forEach((item) => {
            item.children.forEach((child) => {
                child.show();
                child.ensureVisible();
            });
        });

        if (value == "") {
            tree.treeModel.collapseAll();
            this.selectedService = null;
        }
    }

}

