import { OnInit, Component, ChangeDetectorRef, NgZone, PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { Subject, Subscription, Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { responsiveService } from "../../responsiveService";
import { DatePipe } from '@angular/common';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { Config } from 'app/Config';
import { Sequence } from 'app/models/Sequence';
import { DoctorService } from 'app/doctor/doctor.service';
import { DataService } from 'app/calendar/calendar.service';
import { Booking } from 'app/models/Booking';
import { NurseService } from '../nurse.service';
import { BookingDetailsData } from '../nursePatients/nursePatients.component';
import { BookingDetailsDialog } from 'app/modalWindows/Nurse/BookingDetails/BookingDetailsDialog';
import { Patient } from 'app/models/Patient';


@Component({
    selector: 'app-nurseAll',
    templateUrl: './nurseAll.component.html',
    styleUrls: ['./nurseAll.component.css']
})
export class NurseAllComponent implements OnInit {

    isTablet: boolean;

    public selectedSequence: Sequence = null;
    public venueSequences: Sequence[] = [];

    public selectedBooking: Booking = null;
    public bookings: Booking[] = [];
    public seqByCompanySub: Subscription;

    //booking type Filters:
    public selectedBookingTypeFilter: number = 0;
    // 0 - Всі послуги

    // 3 - Медикаменти
    // 1 - Забір матеріалів
    // 4 - Стаціонарні послуги
    // 2 - Прийом в кабінеті

    // 5 - Дієта
    // ...

    public selectedStatusFilter = "PLN";

    public selectedBookingNameFilter = "";

    public selectedRoomFilter = "";

    public selectedBedFilter = "";

    public selectedKabinetFilter = "";

    public selectedFloorFilter: number = -1;

    nameSearchText: string = "";
    bookingSearchText: string = "";

    constructor(
        private responsiveService: responsiveService,
        private datePipe: DatePipe,
        private _config: Config,
        private nService: NurseService,
        private dService: DoctorService,
        private cService: DataService,
        public dialog: MatDialog, private _ngZone: NgZone, private router: Router, private lservice: LoginService) {
        this.seqByCompanySub = this.lservice.updateSeqByCompanyBind().subscribe(id => this.loadSequences());
    }

    ngOnInit() {
        this.onResize();
        this.responsiveService.checkWidth();

        var _that = this;

        this.loadSequences();
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
    }

    onResize() {
        this.responsiveService.getTabletStatus().subscribe(isTablet => {
            this.isTablet = isTablet;
        });
    }

    async loadSequences() {
        var _that = this;

        this.venueSequences = await this.dService.getSequences("RCH").toPromise();

        if (this.venueSequences.length > 0) {

            let savedSeqID = await this.cService.getStorage("WebResidenceNurseSelectedVenueSeqDepAllPatients").toPromise();

            if (savedSeqID && this.venueSequences.find(r => r.sequenceID == savedSeqID)) {
                this.sequenceVenueChanged(this.venueSequences.find(r => r.sequenceID == savedSeqID));//select from storage
            } else {
                this.sequenceVenueChanged(this.venueSequences[0]);//select first
            }
        }
    }

    sequenceVenueChanged(seq: Sequence) {
        this.selectedSequence = seq;
        this.cService.setStorage("WebResidenceNurseSelectedVenueSeqDepAllPatients", seq.sequenceID).subscribe(r => { });

        this.loadBookings(seq);
    }

    loadBookings(seq: Sequence) {
        this.selectedBooking = null;
        this.selectedBookingNameFilter = "";
        this.selectedFloorFilter = -1;

        var _that = this;
        let date = new Date();
        let dString: string = this.datePipe.transform(date, "yyyyMMdd");
        this.nService.getBookingsBySequence(seq.sequenceID, dString).subscribe((data: Booking[]) => {
            _that.bookings = data;
        });
    }

    clearBookingFilters() {
        this.selectedBookingTypeFilter = 0;
    }

    selectedBookingTypeFilterClick(filterNumber: number) {
        this.selectedBookingTypeFilter = filterNumber;
    }

    editBooking(b: Booking) {
        this.selectedBooking = b;

        this.openDialogBookingDetails(b).subscribe(result => {
            if (result.rezult) {
                b.bookingStatusRef = result.newStatus;
                b.bookingDesc = result.comment;

                this.dService.updateBooking(b).subscribe((rez => {

                    //save to db and after that
                    b.bookingStatusRef = rez.bookingStatusRef;
                    b.bookingStatusName = rez.bookingStatusName;
                    b.bookingDesc = rez.bookingDesc;
                    b.userName = b.username = rez.username;
                }));
            }
        });
    }

    openDialogBookingDetails(b: Booking): Observable<BookingDetailsData> {
        let p: Patient = new Patient(0);//load from db in futer; patientID conmtain sp, need add field to Booking model
        p.patientName = b.patientName;

        let d: BookingDetailsData = new BookingDetailsData();
        d.booking = b;
        d.patient = p;

        const dialogRef = this.dialog.open(BookingDetailsDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    //if change it change second method 
    filterBookingRow(booking: Booking) {
        if (this.selectedBookingTypeFilter != 0 && this.selectedBookingTypeFilter != booking.bookKind) return false;
        else if (this.selectedBookingNameFilter != "" && this.selectedBookingNameFilter != booking.bookingName) return false;
        else if (this.selectedStatusFilter != "" && (this.selectedStatusFilter == 'EXP' && !this.checkBookingIsExpired(booking)
            || (this.selectedStatusFilter != 'EXP' && this.selectedStatusFilter != booking.bookingStatusRef))) return false;
        else if (this.selectedBedFilter != "" && this.selectedBedFilter != booking.venueName1) return false;
        else if (this.selectedRoomFilter != "" && this.selectedRoomFilter != booking.venueRoomName) return false;
        else if (this.selectedKabinetFilter != "" && this.selectedKabinetFilter != booking.venueName) return false;
        else if ((this.selectedFloorFilter != null && this.selectedFloorFilter != -1 && this.selectedFloorFilter != booking.venuePositionZ)
              || (this.selectedFloorFilter == null && booking.venuePositionZ != null)) return false;
        else return true;
    }
    getFilteredBookings(bookingTypeFilter: number) {
        return this.bookings.filter(r =>
            (bookingTypeFilter == 0 ? true : bookingTypeFilter == r.bookKind) &&
            (this.selectedBookingNameFilter == "" ? true : this.selectedBookingNameFilter == r.bookingName) &&
            (this.selectedStatusFilter == "" ? true : (this.selectedStatusFilter == 'EXP' && this.checkBookingIsExpired(r)) ? true : this.selectedStatusFilter == r.bookingStatusRef) &&
            (this.selectedBedFilter == "" ? true : this.selectedBedFilter == r.venueName1) &&
            (this.selectedRoomFilter == "" ? true : this.selectedRoomFilter == r.venueName1) &&
            (this.selectedKabinetFilter == "" ? true : this.selectedKabinetFilter == r.venueName) &&
            (this.selectedFloorFilter == -1 ? true : this.selectedFloorFilter == null ? r.venuePositionZ == null : this.selectedFloorFilter == r.venuePositionZ));
    }

    getHour(booking: Booking) {//get not all bookings but only filtered in future
        let bookings: Booking[] = this.getFilteredBookings(this.selectedBookingTypeFilter);
        let date = new Date(booking.bookingDate);
        date.setMinutes(0);

        if (bookings.length == 1) return this.datePipe.transform(date, "HH:mm");
        else if (bookings.length > 0) {
            let index: number = bookings.findIndex(i => i.bookingID === booking.bookingID);

            if (index == 0) { return this.datePipe.transform(date, "HH:mm"); }
            else if (index > 0) {

                if (new Date(booking.bookingDate).getHours() > new Date(bookings[index - 1].bookingDate).getHours()) {
                    return this.datePipe.transform(date, "HH:mm");
                }
                else {
                    return "";
                }
            }
        }
        else {
            return "";
        }
    }

    getStatusText(status: string) {
        switch (status) {
            case "CMP":
                return "Виконані";
            case "CNC":
                return "Скасовані";
            case "PLN":
                return "Заплановані";
            case "EXP":
                return "Протерміновані";
            default:
                return "";
        }
    }

    getColorForSelectedBookName() {
        if (this.selectedBookingNameFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    getColorForSelectedBookStatus() {
        if (this.selectedStatusFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    getColorForSelectedBed() {
        if (this.selectedBedFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    getColorForSelectedRoom() {
        if (this.selectedRoomFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    getColorForSelectedKabinet() {
        if (this.selectedKabinetFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    formatTime(date: Date) {
        if (date) {
            return this.datePipe.transform(date, "HH:mm")
        }
        return "";
    }

    getBookingsByTypeCount(id: number) {
        if (this.bookings.length > 0) {
            if (id == 0)
                return this.getFilteredBookings(id).length;
            else
                return this.getFilteredBookings(id).filter(r => r.bookKind == id).length;
        }
        else return "";
    }

    //
    getSelectedFloorHeader() {
        if (this.selectedFloorFilter == -1) return 'Поверх';
        else if (this.selectedFloorFilter == null) return 'Поверх не вказано';
        else return this.selectedFloorFilter + ' поверх';        
    }

    getBookFilterTypeColor(filterNumber: number, color: string) {
        if (this.selectedBookingTypeFilter != filterNumber) return 'white';
        else return color;
    }

    getBookFilterTypeColorText(filterNumber: number) {
        if (this.selectedBookingTypeFilter != filterNumber) return '#0D1014';
        else return 'white';
    }

    getColorForTime(booking: Booking) {
        if (booking.bookingStatusRef == 'CMP') return "#3FA013";
        else if (this.checkBookingIsExpired(booking)) return "#C60000";
        else return "#212529";
    }

    checkBookingIsExpired(booking: Booking) {
        var now = new Date();
        var bookingDate = new Date(booking.bookingDate);
        if (booking.bookingStatusRef == 'PLN' && now.getTime() > bookingDate.getTime()) return true;
        else return false;
    }

    getColorForBookingName(booking: Booking) {
        if (booking.bookingStatusRef == 'CMP') return "#3FA013";
        else return "#212529";
    }

    tableFilter(row: Booking) {
        let exist: boolean = true;

        const searchValuePatientName = this.nameSearchText.toUpperCase();
        const searchValueBookingName = this.bookingSearchText.toUpperCase();

        if (this.nameSearchText != "" && row != null) {
            if (row.patientName == null || !(row.patientName.toUpperCase().indexOf(searchValuePatientName) >= 0)) exist = false;
        }

        if (this.bookingSearchText != "" && row != null) {
            if (row.bookingName == null || !(row.bookingName.toUpperCase().indexOf(searchValueBookingName) >= 0)) exist = false;
        }

        return exist;
    }
}


