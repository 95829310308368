import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import $ from 'jquery';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Config } from '../../Config';
import { EhealthService } from '../EhealthService';
import { Obj } from '@popperjs/core';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { PatientToSequenceData, PatientToQueueData, PatientSyncData, PatientEditData } from 'app/modalWindows/PatientSearch/PatientSearchDialog';
import { MatDialog } from '@angular/material/dialog';
import { PatientToSequenceDialog } from 'app/modalWindows/PatientToSequence/PatientToSequenceDialog';
import { addToQueueDialog } from 'app/modalWindows/EHealth/addToQueue/addToQueueDialog';
import { syncPatientDialog } from 'app/modalWindows/EHealth/syncPatient/syncPatientDialog';
import { DatePipe } from '@angular/common';
import { patient } from 'app/models/Ehealth/patient';
import { PatientToSequenceCreateDialog } from 'app/modalWindows/PatientToSequenceCreate/PatientToSequenceCreateDialog';
import { DataService } from 'app/calendar/calendar.service';

@Component({
  selector: 'app-searchByPredicate',
  templateUrl: './searchByPredicate.component.html',
  styleUrls: ['./searchByPredicate.component.css']
})
export class SearchByPredicateComponent {

  @Output() voted = new EventEmitter<boolean>();


  guid: string;
  url: string;

  patientBirthDate: Date;
  patientIPN: string;

  patients: patient[];


  constructor(private router: Router, _config: Config, private service: EhealthService, private dataService: DataService, public dialog: MatDialog,
    private datePipe: DatePipe)
  {
    this.url = _config.get("EHPortalApiURL");
    this.guid = _config.get("EhealthInstalationGuid");

    //router.events.subscribe((val) => {

    //});
  }

  ngOnInit() {
    
  }

  /*ngAfterContentChecked() {
    this.patientName = this.dataService.searchPatientText;
  }*/

  async search()
  {
    let birth: string = this.datePipe.transform(this.patientBirthDate, "yyyy-MM-dd");

    await this.service.searchEhealthByPredicate(this.dataService.searchPatientText, birth, this.patientIPN, this.guid, this.url, this.dialog).then((rez: any) =>
    {
      if (rez.errorMessage) {
        this.patients = null;
      }
      else if (rez.patient) {
        this.patients = rez.patient;
      }
    });
  }

  getPName(p : patient) {
    return p.patientLastName + " " + p.patientFirstName + " " + p.patientSecondName;
  }

  getPAdress(p: patient) {
    return p.patientCountry + " " + p.patientSettlement;
  }

  sync(p: patient) {
    this.openDialogSyncPatient(null, p).subscribe(result => {
      if (result.rezult) {
        this.voted.emit(true);
      }
    });
  }

  newPatient() {
    let p: Patient = new Patient(0);
    p.patientName = this.dataService.searchPatientText;
    p.patientBirthDate = this.patientBirthDate;
    this.openDialogPatientCreate(p).subscribe(result => {
      if (result.rezult) {
        this.dataService.addPatient(result.patient).subscribe((rez => {
         
          this.openDialogPatientToSequence(rez).subscribe(result => {
          if (result.rezult) {
            this.voted.emit(true);
          }
          else {
          }
          });
        }));
      }});
  }

  openDialogPatientToSequence(patient: Patient): Observable<PatientToSequenceData> {
    let d: PatientToSequenceData = new PatientToSequenceData();
    d.patient = patient;

    const dialogRef = this.dialog.open(PatientToSequenceDialog, {
      width: '430px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }


  openDialogPatientCreate(patient: Patient): Observable<PatientEditData> {
    let d: PatientEditData = new PatientEditData();
    d.patient = patient;

    const dialogRef = this.dialog.open(PatientToSequenceCreateDialog, {
      width: '400px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }

  openDialogSyncPatient(patient: Patient, p: patient): Observable<PatientSyncData> {

    let d: PatientSyncData = new PatientSyncData();
    d.patient = patient;
    d.patientSearced = p;
    d.searchByPredicate = true;

    const dialogRef = this.dialog.open(syncPatientDialog, {
      width: '400px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });
    return dialogRef.beforeClosed();
  }

}
