import { OnInit, Component, ChangeDetectorRef, NgZone, PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from '../models/Patient';
import { Sequence } from '../models/Sequence';
import { Venue } from '../models/Venue';
import $ from 'jquery';
import { Subject, Subscription, Observable } from 'rxjs';
import { RoomData } from '../models/RoomData';
import { BedData } from '../models/BedData';
import { DropBedData } from '../models/DropBedData';
import { ResidenceCategory } from '../models/ResidenceCategory';
import { ResidenceCreateDialog, DocumentEditData, DocumentViewerDialogData, EHFrameData } from '../modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepartmentData } from '../models/DepartmentData';
import { SequenceSelectDialog } from '../modalWindows/SequenceSelect/SequenceSelectDialog';
import { PatientSearchDialog } from '../modalWindows/PatientSearch/PatientSearchDialog';
import { Residence } from '../models/Residence';
import { ResidenceCreateService } from '../modalWindows/ResidenceCreate/ResidenceCreateService';
import { Document003Dialog } from 'app/modalWindows/Documents/003/Document003Dialog';
import moment from 'moment';
import { Document066Dialog } from 'app/modalWindows/Documents/066/Document066Dialog';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import dragula from 'dragula';
import { responsiveService } from "../responsiveService";
import { DataService } from 'app/calendar/calendar.service';
import { Dictionary } from '../models/Dictionary';
import { Token } from '../models/Token';
import { Course } from 'app/models/Course';
import { Document } from 'app/models/Document';
import { DatePipe } from '@angular/common';
import { DialogData } from 'app/calendar/Day/day.component';
import { Stationary } from 'app/models/Stationary';
import { DocumentViewerDialog } from 'app/modalWindows/DocumentViewer/DocumentViewerDialog';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { DocumentEditorService } from 'app/DocumentEditor/DocumentEditorService';
import { EHFrameDialog } from 'app/modalWindows/EHFrame/EHFrameDialog';
import { Config } from 'app/Config';
import { DocumentTemplateSelectDialog, DocumentTemplateData } from 'app/modalWindows/DocumentTemplateSelect/DocumentTemplateSelectDialog';
import { DoctorService } from 'app/doctor/doctor.service';


@Component({
  selector: 'app-nurse',
  templateUrl: './nurse.component.html',
  styleUrls: ['./nurse.component.css']
})
export class NurseComponent implements OnInit {

  isTablet: boolean;

  constructor(
    private service: DoctorService,
    private responsiveService: responsiveService,
    private calendarService: DataService,
    private residenceService: ResidenceCreateService,
    private documentEditorService: DocumentEditorService,
    private datePipe: DatePipe,
    private _config: Config,
    public dialog: MatDialog, private _ngZone: NgZone, private router: Router, private lservice: LoginService)
  {

  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    var _that = this;

    this.checkParallelWork();
  }

  onResize() {
    this.responsiveService.getTabletStatus().subscribe(isTablet => {
      this.isTablet = isTablet;
    });
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
  }

  checkParallelWork() {
    this.service.CheckParalelWork().subscribe((data: Token) => {
      if (data.parallelWork) {
        this.router.navigate(['']);
        this.dialog.closeAll();
        this.lservice.clearToken();
        alert("parallel work detected");
      }
    });
  }

  AllowNurseAll() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace_acViewSchedule == 'True')
      return true;
    else return false;
  }

  AllowNursePatients() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null &&
      permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace_acViewDepartmentStationaryPatients == 'True')
      return true;
    else return false;
  }

  AllowAny() {
    let permissions = this.lservice.getUserPermissions();
    if ( permissions != null && 
        (permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace_acViewDepartmentStationaryPatients == 'True' ) ||
        (permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace_acViewSchedule == 'True')
      )
      return true;
    else return false;
  }

}


