import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Token } from '../../../models/Token';
import { PatientSearchDialogData } from '../../../calendar/calendar.component';
import { Patient } from '../../../models/Patient';
import { DataService } from '../../../calendar/calendar.service';
import { PatientToSequenceData, PatientToQueueData, PatientSyncData, PatientEditData } from '../../PatientSearch/PatientSearchDialog';
import { Residence } from '../../../models/Residence';
import { Dictionary } from '../../../models/Dictionary';
import { ResidenceCategory } from '../../../models/ResidenceCategory';
import { ResidenceCreateService } from '../../ResidenceCreate/ResidenceCreateService';
import { DiagnosData } from '../../ResidenceCreate/ResidenceCreateDialog';
import { BedData } from '../../../models/BedData';
import { DiagnosisSelectDialog } from '../../DiagnosisSelect/DiagnosisSelectDialog';
import { Sequence } from '../../../models/Sequence';
import { Account } from '../../../models/Account';
import moment from 'moment';
import { EhealthService } from 'app/Ehealth/EhealthService';
import { employee } from 'app/models/Ehealth/employee';
import { Config } from '../../../Config';
import { mnp } from 'app/models/Ehealth/mnp';
import { medicalProgram } from 'app/models/Ehealth/medicalProgram';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { DatePipe } from '@angular/common';
import { PatientToSequenceDialog } from 'app/modalWindows/PatientToSequence/PatientToSequenceDialog';
import { PatientToSequenceCreateDialog } from 'app/modalWindows/PatientToSequenceCreate/PatientToSequenceCreateDialog';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import { EHServiceDispense } from 'app/models/Ehealth/EHServiceDispense';
import { LoginService } from 'app/modalWindows/Login/LoginService';


@Component({
  selector: 'app-modalWindows-syncPatient',
  templateUrl: './syncPatientDialog.html',
   styleUrls: ['./syncPatientDialog.css']
})

export class syncPatientDialog {

  d: PatientSyncData;
  guid: string;
  patients: Patient[];
  url: string;


  newDispance: serviceDispense;

  constructor(private EHservice: EhealthService, private service: DataService, _config: Config, private datePipe: DatePipe,
    public dialog: MatDialog, public dialogRef: MatDialogRef<syncPatientDialog>, @Inject(MAT_DIALOG_DATA) public data: PatientSyncData, private lservice: LoginService)
  {
    this.d = data;
    this.guid = _config.get("EhealthInstalationGuid");
    this.url = _config.get("EHPortalApiURL");


    this.loadData();

  }

  addToJournalIsDisabled() {
    let permissions = this.lservice.getUserPermissions();
    if (permissions != null && permissions.SecurityActions_Workflow_Patient_acRegisterForStationaryTreatment == 'True')
      return false;
    else return true;
  }

  loadData() {
    var _that = this;
    let patient: Patient = new Patient(0);

    if (!this.data.searchByPredicate) {
      patient.ehPatientGuid = this.d.dispence.patientRef;
      patient.patientName = this.d.searchdispence.patientDesc;
    }
    else
    {
      patient.ehPatientGuid = this.d.patientSearced.patientGuid;
      patient.patientName = this.d.patientSearced.patientLastName + " " +
        this.d.patientSearced.patientFirstName + " " +
        this.d.patientSearced.patientSecondName;
    }

    //get birthdate by EH call

      this.service.getPatientByEhealth(patient).subscribe((p: Patient[]) => {
        _that.patients = p;
        if (this.d.searchByPredicate)
          _that.patients = _that.patients.filter(r => r.ehPatientGuid == null ? true : r.ehPatientGuid == this.d.patientSearced.patientGuid);
        else
          _that.patients = _that.patients.filter(r => r.ehPatientGuid == null ? true : r.ehPatientGuid == this.d.dispence.patientRef);
    });
  }

  ngOnInit() {
  }


  onNoClick(): void {
    this.d.rezult = false;
    this.dialogRef.close(this.d);
  }

  getDate(p: Patient) {
    if (p.patientBirthDate != null)
      return this.datePipe.transform(p.patientBirthDate, "dd.MM.yyyy");
    else return "";
  }

  patientClick(patient: Patient) {
    // try catch
    this.openDialogPatientToSequence(patient).subscribe(result => {
      if (result.rezult) {
        this.d.rezult = true;
        this.dialogRef.close(this.d);
      }
      else {
      }

    });
  }

  openDialogPatientToSequence(patient: Patient): Observable<PatientToSequenceData> {
    let d: PatientToSequenceData = new PatientToSequenceData();
    d.patient = patient;

    const dialogRef = this.dialog.open(PatientToSequenceDialog, {
      width: '430px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }

  // inside openDialog if true
  // true only if full process finished to close all windows only
   //this.d.rezult = true;

  syncProcess(createPatient: boolean, pat: Patient = null) {
    var _that = this;

    if (createPatient) {

      let p: Patient = new Patient(0);
      if (!this.d.searchByPredicate) {
        p.patientName = this.d.searchdispence.patientDesc;
      }
      else {
        p.patientName = this.d.patientSearced.patientLastName + " " +
          this.d.patientSearced.patientFirstName + " " +
          this.d.patientSearced.patientSecondName;
      }

      this.openDialogPatientCreate(p).subscribe(result => {
        if (result.rezult) {
          this.service.addPatient(result.patient).subscribe((rez => {
            p = rez;

            let ehPat: EHPatient = new EHPatient();

            if (!this.d.searchByPredicate) {
              ehPat.ehPatientGuid = this.d.dispence.patientRef;
            } else {
              ehPat.ehPatientGuid = this.d.patientSearced.patientGuid;
            }

            ehPat.patientName = p.patientName;
            ehPat.patientRef = p.patientID;

            this.EHservice.createEHPatient(ehPat).subscribe((ep: EHPatient) => {
              ehPat = ep;

              let ehServDisp: EHServiceDispense = new EHServiceDispense();
              ehServDisp.ehPatientRef = ehPat.ehPatientID;
              ehServDisp.ehPatientGuid = ehPat.ehPatientGuid;

              if (!this.d.searchByPredicate) {
                ehServDisp.ehServiceDispenseProcessID = this.d.dispence.serviceDispenseProcessId;
                ehServDisp.ehServiceRequestGuid = this.d.dispence.serviceDispenseRequestId;
                ehServDisp.ehServiceDispenseNumber = this.d.searchdispence.number;
                ehServDisp.ehServiceDispenseGuid = this.d.dispence.immutable_id;

                this.EHservice.createEHServiceDisense(ehServDisp).subscribe((esd: EHServiceDispense) => {
                  ehServDisp = esd;
                  this.loadData();
                });
              }
              else {
                this.loadData();
              }

            });
            //sp
            //sp
            //refresh
          }));
        }
      });

    }
    else {

      let ehPat: EHPatient = new EHPatient();

      if (!this.d.searchByPredicate) {
        ehPat.ehPatientGuid = this.d.dispence.patientRef;
      } else {
        ehPat.ehPatientGuid = this.d.patientSearced.patientGuid;
      }


      ehPat.patientRef = pat.patientID;
      ehPat.patientName = pat.patientName;

      this.EHservice.createEHPatient(ehPat).subscribe((ep: EHPatient) => {
        ehPat = ep;

        let ehServDisp: EHServiceDispense = new EHServiceDispense();
        ehServDisp.ehPatientRef = ehPat.ehPatientID;


        if (!this.d.searchByPredicate) {
          ehServDisp.ehServiceDispenseProcessID = this.d.dispence.serviceDispenseProcessId;
          ehServDisp.ehServiceRequestGuid = this.d.dispence.serviceDispenseRequestId;
          ehServDisp.ehServiceDispenseNumber = this.d.searchdispence.number;
          ehServDisp.ehServiceDispenseGuid = this.d.dispence.immutable_id;

          this.EHservice.createEHServiceDisense(ehServDisp).subscribe((esd: EHServiceDispense) => {
            ehServDisp = esd;
            this.loadData();

          });
        }
        else {
          this.loadData();
        }

      });
    }


  }

  checkEx() {
    return !this.patients || this.patients == null || this.patients.length == 0;
  }

  canCreateEMK() {
    if (this.d.searchByPredicate && this.patients && (this.patients.find(r=>r.ehPatientGuid != null && r.ehPatientGuid == this.d.patientSearced.patientGuid) != null))
    {
      return true;
    }
    else if (!this.d.searchByPredicate && this.patients && (this.patients.find(r => r.ehPatientGuid != null && r.ehPatientGuid == this.d.dispence.patientRef) != null)) {
      return true;
    }
    return false;
  }

  openDialogPatientCreate(patient: Patient): Observable<PatientEditData> {
    let d: PatientEditData = new PatientEditData();
    d.patient = patient;

    const dialogRef = this.dialog.open(PatientToSequenceCreateDialog, {
      width: '400px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.beforeClosed();
  }
}





