export class Document {

  constructor(id: number) {
    this.documentID = id;
  }

  public documentID: number;

  public courseRef: number;

  public documentTemplateRef: number;

  public documentTemplateName: string;

  public documentTemplateStaticGUIDString: string;

  public documentTemplateStaticGUID: string;

  public documentHTML: string[];

  public documentHtmlArchived: string;

  public documentCreationDate: Date;

  public documentApproveStatusRef: string;

  public documentUserRef: string;

  public documentUserName: string;

  public documentTemplateCanEditOnMobile: boolean;

  public residenceRef: number;

  public json: string;

  //custom data
  public patientRef: number;

}
