export class Service {

    public serviceID: number;
    public serviceNameLanguage1: string;
    public serviceParentRef: number;
    public assignmentServiceQuantity: number;
    public assignmentServiceStartDate: Date;
    public servicePriceRate: number;
    public courseRef: number;
    public patientRef: number;
    public desc: string;
    public residenceRef: number;
    public assignmentServiceAccountRef: number;
    public tariffPlanRef: number;
    public assignmentRef: number;
}
