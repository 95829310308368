import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosData, DictionaryData } from '../ResidenceCreate/ResidenceCreateDialog';
import { Dictionary } from '../../models/Dictionary';
import $ from 'jquery';

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ResidenceCreateService } from '../ResidenceCreate/ResidenceCreateService';


@Pipe({ name: 'search' })



export class searchPipe implements PipeTransform
{
  transform(values: any[], filter: string): any {
    if (!values || !values.length) return [];
    if (!filter) return values;

    filter = filter.toUpperCase();

    if (filter && Array.isArray(values)) {
      const keys = Object.keys(values[0]);
      return values.filter(v => v && keys.some(k => v[k].toUpperCase().indexOf(filter) >= 0));
    }
  }
}

@Component({
  selector: 'app-modalWindows-DictionarySelect',
  templateUrl: './DictionarySelectDialog.html',
  styleUrls: ['./DictionarySelectDialog.css'],
  providers: [DecimalPipe]
})
export class DictionarySelectDialog {

  p: number = 1;
  dictionary: Dictionary[];

  constructor(private service: ResidenceCreateService,public dialogRef: MatDialogRef<DictionarySelectDialog>, @Inject(MAT_DIALOG_DATA) public data: DictionaryData)
  {
    this.data.rezult = true;
    var _that = this;
    this.service.getDictionary(this.data.dictionaryName).subscribe((data: Dictionary[]) => { _that.dictionary = data; });
  }


  public selectDictionaryRow(event: any, item: any)
  {
    this.data.dictionaryRow = item;
  }

  getIDOrCode(row: Dictionary) {
    if (this.data.searchByID) return row.dictionaryID;
    else return row.dictionaryCode;
  }

  public doubleClick(item: any) {
    this.data.dictionaryRow = item;
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  //to yes click write same event and call result = true; this.dialogRef.close(this.data);
  //if vLIDATION DONT CALL close(this.data);
  onNoClick(): void { this.data.rezult = false; this.dialogRef.close(this.data); }

}

