import { Booking } from './Booking';

export class Prescription {

  public prescriptionID: number;

  public assignmentServiceDesc: string;

  public assignmentServiceID: number;

  public prescriptionMedicationName: string;
  public prescriptionMedicationDesc: string;

  public drugFrequencyKindName: string;

  public drugRoutingKindName: string;

  public grescriptionNumber: string;

  public assignmentServiceQuantity: number;

  public prescriptionDose: number;

  public prescriptionTotalQuantity: number;

  public prescriptionMedicinePackageRef: number;

  public prescriptionStartDate: Date;

  public drugFrequencyKindRef: number;

  public drugRoutingKindRef: number;

  public drugDurationKindRef: number;

  public bookings: Booking[];

  public prescriptionIsPRN: boolean;

  public prescriptionIsRecommendation: boolean;

  public prescriptionDoseDesc: string;

  public prescriptionNumber: string;

  public assignmentRef: number;

  public serviceRef: number;

  public prescriptionEndDate: Date;


  /////////////

  public courseID: number;
  public patientRef: number;
  public assignmentServiceResidenceRef: number;

}
