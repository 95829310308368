import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';

import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData } from 'app/llp/llp.component';


@Component({
  selector: 'app-modalWindows-CalendarViewerDialog',
  templateUrl: './CalendarViewerDialog.html',
  styleUrls: ['./CalendarViewerDialog.css'],
  providers: [DatePipe],
})

export class CalendarViewerDialog {

  myOptionsColor = {
    'theme': 'light'
  }

  data: PrescriptionEditData;

  constructor(public dialogRef: MatDialogRef<CalendarViewerDialog>, @Inject(MAT_DIALOG_DATA)
  public d: PrescriptionEditData, private datePipe: DatePipe, public dialog: MatDialog, ) {
    this.data = d;
  }


  onYesClick(): void
  {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
   
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

}

