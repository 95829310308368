import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dictionary } from '../../app/models/Dictionary';
import { AuthGuard } from 'app/auth/auth.guard';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { Config } from 'app/Config';
import CryptoJS from 'crypto-js';


@Injectable()
export class DocumentEditorService {


  constructor(private http: HttpClient, private authGuard: AuthGuard, private _config: Config, private lservice: LoginService) {

  }

  getDocumentEditorAuthHeadersLogin() {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/x-www-form-urlencoded')
    return headers;
  }

  authorizeDocumentEditor() {
    let route: string = this._config.get("DocumentEditorURL") + "/api/token";
    let guid: string = this._config.get("DocumentEditorGuid");

    let password: string = this.decrypt(this.lservice.UserToken.userPasswordEncryptJS, this._config.get("encryptSecretKey"));

    let body: string;
    body = "grant_type=password&username=" + this.lservice.UserToken.userLogin + "@@@" + guid + "&password=" + password;

    return this.http.post<object>(route, body, { headers: this.getDocumentEditorAuthHeadersLogin() })
      .pipe(map(async (response: object) => {
        this.lservice.UserToken.documentEditorToken = response['access_token'];
        this.lservice.UserToken.documentEditorDate = new Date().getDay().toString();
        return response;
      }),
        catchError(async err => {
          this.lservice.UserToken.documentEditorToken = null;
          this.lservice.UserToken.documentEditorDate = null;
          alert(err.error.error);
          return Observable.throw('Failed to authorize in document editor');
        }));
  }


  async getToken() {

    let day = this.lservice.UserToken.documentEditorDate;

    if ((!day || day != new Date().getDay().toString()) || !this.lservice.UserToken.documentEditorToken) {

      await this.authorizeDocumentEditor().toPromise().then(async (data: object) => {
          //   return data['access_token'];
        this.lservice.UserToken.documentEditorToken = data['access_token'];
        this.lservice.UserToken.documentEditorDate = new Date().getDay().toString();

        this.lservice.setToken(this.lservice.UserToken);

        return data['access_token'];
        });
    }

    return this.lservice.UserToken.documentEditorToken;
  }

  async generateDocCreateURL(documentTemplateRef: number, patientRef: number, courseRef: number) {
    let token: string = await this.getToken();

    let route: string = this._config.get("DocumentEditorURL") + "/web/PatientPresentation2020/#/?action=spzIBWebDocumentEdit";
    route += ("&id=" + documentTemplateRef + "&idName=DocumentTemplateRef");
    route += ("&id1=" + patientRef + "&id1Name=PatientRef");
    route += ("&id2=" + courseRef + "&id2Name=CourseRef");
    route += ("&username=" + this.lservice.UserToken.userLogin);
    route += ("&token=" + token);

    return route;
  }

  async generateDocEditURL(documentTemplateRef: number, patientRef: number, courseRef: number, documentId: number) {
    let token: string = await this.getToken();

    let route: string = this._config.get("DocumentEditorURL") + "/web/PatientPresentation2020/#/?action=spzIBWebDocumentEdit";
    route += ("&id=" + documentTemplateRef + "&idName=DocumentTemplateRef");
    route += ("&id1=" + patientRef + "&id1Name=PatientRef");
    route += ("&id2=" + courseRef + "&id2Name=CourseRef");
    route += ("&id3=" + documentId + "&id3Name=DocumentRef");
    route += ("&username=" + this.lservice.UserToken.userLogin);
    route += ("&token=" + token);

    return route;
  }

  decrypt(transitmessage, pass)
  {
    var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
    var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
    var encrypted = transitmessage.substring(64);

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: 256 / 32,
      iterations: 100
    });

    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    })
    return decrypted.toString(CryptoJS.enc.Utf8);
  } 

  

}

  //////////////////
  //subscribtions


