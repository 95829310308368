import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Token } from '../../models/Token';
import { PatientSearchDialogData } from '../../calendar/calendar.component';
import { Patient } from '../../models/Patient';
import { DataService } from '../../calendar/calendar.service';
import { Residence } from '../../models/Residence';
import { PatientToSequenceDialog } from '../PatientToSequence/PatientToSequenceDialog';
import { PatientEditDialog } from '../PatientEdit/PatientEditDialog';
import { PatientToSequenceCreateDialog } from '../PatientToSequenceCreate/PatientToSequenceCreateDialog';
import * as bootstrap from 'bootstrap';
import { DatePipe } from '@angular/common';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { patient } from 'app/models/Ehealth/patient';
import { LoginService } from '../Login/LoginService';


export class PatientToSequenceData {
    rezult: boolean;
    residence: Residence;
    patient: Patient;
    dispenceNumber: string;
    userref: string;
}

export class PatientEditData {
    rezult: boolean;
    patient: Patient;
}

export class PatientToQueueData {
    rezult: boolean = false;
    patient: Patient;
    dispence: serviceDispense;
    searchdispence: serviceDispense;
}

export class PatientSyncData {
    rezult: boolean = false;
    patient: Patient;
    dispence: serviceDispense;
    searchdispence: serviceDispense;
    patientSearced: patient;
    searchByPredicate: boolean = false;
}


@Component({
    selector: 'app-modalWindows-PatientSearch',
    templateUrl: './PatientSearchDialog.html',
    styleUrls: ['./PatientSearchDialog.css']
})

export class PatientSearchDialog {

    d: PatientSearchDialogData;

    text: string;

    patients: Patient[];

    public loading: boolean;

    isEhealth: boolean = false;


  constructor(private service: DataService, private lservice: LoginService, public dialog: MatDialog, public dialogRef: MatDialogRef<PatientSearchDialog>, @Inject(MAT_DIALOG_DATA) public data: PatientSearchDialogData, private datePipe: DatePipe)
  {
        this.d = data;
        this.isEhealth = lservice.UserToken && lservice.UserToken.userEhealthIsAuthorized;
        this.service.searchPatientText = "";
    }

    textChanged() {
        this.service.searchPatientText = this.text;
    }

    searchIsDisabled() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Patient_acPatientSearch == 'True')
            return false;
        else return true;
    }

    newPatientIsDisabled() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Patient_acNew_acNewPatientFromSearch == 'True')
            return false;
        else return true;
    }

    addToJournalIsDisabled() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Workflow_Patient_acRegisterForStationaryTreatment == 'True')
            return false;
        else return true;
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.search();
        }
    }

  search()
  {
        if (this.searchIsDisabled()) return;

        this.patients = null;
        console.log(this.text.length);

        if (this.text.length < 3) {

        }
        else {
            var _that = this;
            _that.loading = true;
            this.service.getPatientsByFilter(this.text)
                .subscribe((data: Patient[]) => {
                    _that.patients = data;
                    _that.loading = false;
                });
        }
    }

  showWarn()
  {
        return this.text != null && this.text.length < 3;
    }

    onYesClick() {
        this.d.rezult = true;
        this.dialogRef.close(this.d);
    }

    onNoClick(): void {
        this.dialogRef.close(this.d);
    }

    patientClick(patient: Patient) {
        // try catch
        this.openDialogPatientToSequence(patient).subscribe(result => {
            if (result.rezult) {
                this.dialogRef.close(this.d);
            }
            else {
            }

        });
    }

    patientEditClick(patient: Patient) {
        this.openDialogPatientEdit(patient).subscribe(result => {
            if (result.rezult) {

                this.service.updatePatient(result.patient).subscribe((rez => {
                    result.patient = rez;
                }));
            }
        });
    }

    createPatientClick() {
        if (this.newPatientIsDisabled()) return;

        let p: Patient = new Patient(0);
        p.patientName = this.text;


        this.openDialogPatientCreate(p).subscribe(result => {
            if (result.rezult) {

                this.service.addPatient(result.patient).subscribe((rez => {

                    if (!this.patients) {
                        this.patients = new Array<Patient>();
                    }

                    this.patients.splice(0, 0, rez);

                }));
            }
        });
    }

    openDialogPatientToSequence(patient: Patient): Observable<PatientToSequenceData> {
        let d: PatientToSequenceData = new PatientToSequenceData();
        d.patient = patient;

        const dialogRef = this.dialog.open(PatientToSequenceDialog, {
            width: '387px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    openDialogPatientEdit(patient: Patient): Observable<PatientEditData> {
        let d: PatientEditData = new PatientEditData();
        d.patient = patient;

        const dialogRef = this.dialog.open(PatientEditDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    openDialogPatientCreate(patient: Patient): Observable<PatientEditData> {
        let d: PatientEditData = new PatientEditData();
        d.patient = patient;

        const dialogRef = this.dialog.open(PatientToSequenceCreateDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    showEhealthSearch() {
        if (this.isEhealth && this.patients != null && this.patients && this.patients.length == 0) {
            return true;
        }
        return false;
    }

    openTabE() {
        $('#myTab a[href="#searchByFields"]').tab('show');
    }

    getDate(p: Patient) {
        if (p.patientBirthDate != null)
            return this.datePipe.transform(p.patientBirthDate, "dd.MM.yyyy");
        else return "";
    }

    getDeceaseDate(p: Patient) {
        if (p.patientDeceaseDate != null)
            return this.datePipe.transform(p.patientDeceaseDate, "dd.MM.yyyy HH:mm");
        else return "";
    }

    getbackgroundColor(p: Patient) {
        if (p.patientDeceaseDate != null) return "#F1F2F2";
        else return "#FFFFFF";
    }

    onVoted(agreed: boolean) {
        if (agreed) {
            this.dialogRef.close(this.d);
        }
    }

}




