import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosisSelectDialog } from '../DiagnosisSelect/DiagnosisSelectDialog';
import { ConfirmDialogData, ConfirmPlannedDialogData } from '../../calendar/Day/day.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-modalWindows-Confirm',
  templateUrl: './ConfirmPlannedDialog.html',
  styleUrls: ['./ConfirmPlannedDialog.css'],
  providers: [DatePipe],
})
export class ConfirmPlannedDialog {

  data: ConfirmPlannedDialogData;

  constructor(public dialogRef: MatDialogRef<ConfirmPlannedDialog>, private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data: ConfirmPlannedDialogData)
  {
    this.data = data;
  }

  getDate()
  {
    let startDate = this.datePipe.transform(this.data.bedData.residence.residenceStartDate, "yyyy/MM/dd");
    let endDate: string = "";

    if (this.data.bedData.residence.residenceEndDate != null) {
      endDate = this.datePipe.transform(this.data.bedData.residence.residenceEndDate, "yyyy/MM/dd");
    }

    return startDate + " - " + endDate;   
  }

  onYesClick(): void {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }
}

