import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosisSelectDialog } from '../DiagnosisSelect/DiagnosisSelectDialog';
import { ConfirmDialogData } from '../../calendar/Day/day.component';

@Component({
  selector: 'app-modalWindows-Confirm',
  templateUrl: './ConfirmDialog.html'
})
export class ConfirmDialog {

  data: ConfirmDialogData;

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) data: ConfirmDialogData)
  {
    this.data = data;
  }

  onYesClick(): void {
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }
}

