import { Component } from '@angular/core';
import $ from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '../modalWindows/Login/LoginDialog';
import { Token } from '../models/Token';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../modalWindows/Login/LoginService';
import { UserProfileDialog } from 'app/modalWindows/UserProfile/UserProfileDialog';
import { responsiveService } from 'app/responsiveService';


@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

    public isTablet: boolean;

    loginSub: Subscription;

    viewText: string = "Оберіть";
    constructor(public dialog: MatDialog, private router: Router, private service: LoginService, private responsiveService: responsiveService) {

        this.loginSub = this.service.loginBind().subscribe(id => this.loginProcess());
        //if (window.location.href.includes("/calendar")
    }

    ngOnInit() {
        this.selectAvailable();
        this.onResize();
        this.responsiveService.checkWidth();
    }

    onResize() {
        this.responsiveService.getTabletStatus().subscribe(isTablet => {
            this.isTablet = isTablet;
        });
    }

    async loginProcess() {

        this.service.clearToken();

        let response: Token = await this.openLoginDialog().toPromise();

        if (response && response.userIsAuthorized) 
        {
            await this.service.setToken(response).then((r: any) => 
            {
                //init 1 workplace
                //in ffuture remember from storage
                this.selectAvailable();
            });


        }
        else 
        {
            this.service.clearToken();
            this.router.navigate(['']);
        }
    }

    logout() 
    {
        //1  redirect to calendar
        this.router.navigate(['']);
        this.dialog.closeAll();
        this.service.clearToken();
    }

    openLoginDialog(): Observable<Token> {

        let d: Token = new Token();

        const dialogRef = this.dialog.open(LoginDialog, {
            data: d,
            width: '500px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    openUserProfile() {

        const dialogRef = this.dialog.open(UserProfileDialog, {

            height: '100%',
            disableClose: false,
            width: '100%',
            hasBackdrop: true,
            panelClass: 'my-panelFull'
        });
        return dialogRef.afterClosed();
    }

    isEhealth() {
        return this.service.UserToken.userEhealthIsAuthorized;
    }

    onSelect(id: number) {
        if (id == 1) {
            this.viewText = "Стаціонар";

            if (this.router.url.includes("timeline")) {
                this.router.navigate(['/calendar', { outlets: { 'view': 'timeline' } }]);
            }
            else {
                this.router.navigate(['/calendar', { outlets: { 'view': 'day' } }]);
            }
        }
        if (id == 2) {
            this.viewText = "Лікар стаціонару";
            this.router.navigate(['/doctor']);
        }
        if (id == 3) {
            this.viewText = "Медсестра";
            this.router.navigate(['/nurse']);
        }
    }

    selectAvailable() {
        let permissions = this.service.getUserPermissions();

        if (permissions != null && permissions.DoctorEleksWin32Foundation_Workplaces_HospitalWorkplace == 'True') {
            this.onSelect(1);
        }
        else if (permissions != null && permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace == 'True') {
            this.onSelect(2);
        }
        else if (permissions != null && permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace == 'True') {
            this.onSelect(3);
        }
        else if (permissions != null) {
            alert("no access to any workplaces, ask system administrator to add permissions");
        }
    }

    showWorkplaceSelect() {
        let permissions = this.service.getUserPermissions();

        //
        if (permissions != null && (permissions.DoctorEleksWin32Foundation_Workplaces_HospitalWorkplace == 'True' ||
            permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace == 'True' ||
            permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace == 'True')) {
            return true;
        } else
            return false;
    }

    workplaceExist(n: number) {
        let permissions = this.service.getUserPermissions();
        if (permissions == null) return false;
        else {
            if (n == 1) return permissions.DoctorEleksWin32Foundation_Workplaces_HospitalWorkplace == 'True';
            else if (n == 2) return permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace == 'True';
            else if (n == 3) return permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace == 'True';
        }
        return false;
    }

    onCompanySelect(company) {
        this.service.SelectedCompany = company;
        console.log(company.CompanyID);
        this.service.setStorage(this.service.SelectedCompanyStorageGuidString, company.CompanyID).subscribe(r => { });
        this.service.updateSeqByCompany();
        this.service.updatePatientByCompany();
    }

}
