import { EHPatient } from './Ehealth/EHPatient';

export class Patient {

  constructor(id: number)
  {
    this.patientID = id;
  }


  public patientID: number;
  public patientName: string;
  public patientSexRef: string;
  public patientIsVIP: boolean;
  public patientAge: number;
  public patientAddress: string;
  public patientPhone: string;
  public patientEmail: string;
  public patientBirthDate: Date;
  public patientDeceaseDate: Date;
  public sequenceRef: number;
  public patientDefaultAccountRef: number;
  public sequenceName: string;
  public patientNote: string;
  public patientPassport: string;
  public patientTaxIdentifier: string;
  public ehPatientRef: number;
  public ehPatientGuid: string;
  public ehPatientRow: EHPatient;

}
