import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Token } from '../../models/Token';
import { LoginService } from '..//Login/LoginService';
import CryptoJS from 'crypto-js';
import { Config } from '../../Config';
import { bool } from 'prop-types';
import { LoginFailedDialogData } from '../ResidenceCreate/ResidenceCreateDialog';
import { LoginFailedDialog } from '../LoginFailed/LoginFailedDialog';


@Component({
  selector: 'app-modalWindows-Login',
  templateUrl: './LoginDialog.html',
  styleUrls: ['./LoginDialog.css']
})

export class LoginDialog {

  userPassword: string = "";
  userToken: Token = new Token;

  ehealth: boolean = false;
  encryptSecretKey: string = null;
  windowsAuthentication: boolean = false;
  windowsUserName: string = "";

  guid: string;
  url: string;

  constructor(
    private _config: Config,
    private service: LoginService,
    public dialogRef: MatDialogRef<LoginDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Token)
  {
    this.guid = _config.get("EhealthInstalationGuid");
    this.url = _config.get("EHPortalApiURL");
    this.encryptSecretKey = _config.get("encryptSecretKey");
  }

  async ngOnInit() {
  }

  async onYesClick() {
    var password = this.encryptData(this.userPassword);
    this.userToken.userPassword = password;
    this.userToken.portalLogin = this._config.get("PortalLogin");
    this.userToken.portalPswd = this._config.get("PortalPswd");

    let response: Token = await this.service.getToken(this.userToken).toPromise();

    if (response != null && response.userIsAuthorized) {

      this.userToken.message = response.message;
      this.userToken.userIsAuthorized = response.userIsAuthorized;
      this.userToken.userLogin = response.userLogin;
      this.userToken.userPassword = response.userPassword;
      this.userToken.userToken = response.userToken;
      response.userPasswordEncryptJS = this.userToken.userPasswordEncryptJS;

      // login to ehealth
      if (this.ehealth) {

        this.service.authorizeEhealth(
          encodeURIComponent(this.userToken.userEhealthLogin),
          encodeURIComponent(this.userToken.userEhealthPassword),
          this.guid, this.url).subscribe((data: object) => {
            if (data == null ? false : true) {
              response.userEhealthIsAuthorized = true;
              response.userEhealthTokenDate = new Date().getDay().toString();
              response.userEhealthToken = data['access_token'];
              response.userEhealthLogin = this.userToken.userEhealthLogin;
              response.userEhealthPassword = this.userToken.userEhealthPassword;
              this.dialogRef.close(response);
            }
            else
            {

            }

            console.log(data);
          });
        
      }
      else {
        this.dialogRef.close(response);
      }
    }
    else if (response != null) {
      //"500 Access denied"
      var data = new LoginFailedDialogData();
      data.message = response.message;
      this.openLoginFailedDialog(data);

      this.userPassword = "";
    }
    else
    {
      var data = new LoginFailedDialogData();
      data.message = "Something went wrong, please try again";
      this.openLoginFailedDialog(data);

      this.userPassword = "";
    }
  }

  openLoginFailedDialog(d: LoginFailedDialogData): Observable<LoginFailedDialogData> {

    const dialogRef = this.dialog.open(LoginFailedDialog, {

      data: d,

      hasBackdrop: true,
      disableClose: false,
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

  onNoClick(): void {
    this.userToken.userIsAuthorized = false;
    this.userToken.userEhealthIsAuthorized = false;
    this.dialogRef.close(this.userToken);
  }

  loginDataDisabled() {
    if (this.userToken.userLogin == null || this.userToken.userLogin.length == 0) return true;
    else if (this.ehealth && (this.userToken.userEhealthLogin == null || this.userToken.userEhealthLogin.length == 0)) return true;
    else return false;
  }

  async windowsAuthenticationChanged() {
    if (this.windowsAuthentication) {

      this.userPassword = "";

      let request: Token = await this.service.getWindowsUserName().toPromise();

      this.windowsUserName = request.userLogin;
      this.userToken.userLogin = request.userLogin;
      this.userToken.userIsNT = true;
    }
    else {
      this.userPassword = "";
      this.userToken.userLogin = "";
      this.userToken.userIsNT = false;
    }
  }


  //https://medium.com/@sstarx/encryption-and-decryption-in-angular-asp-net-core-application-1f55bfa3d8bd
  encryptData(msg) {
    if (msg == null || msg == "") return "";
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(this.encryptSecretKey, salt, {
      keySize: keySize / 32,
      iterations: 100
    });
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    this.userToken.userPasswordEncryptJS = salt.toString() + iv.toString() + encrypted.toString(); 

    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
    return result;
  }

}





