import { ResidenceCategory } from "./ResidenceCategory";

export class Residence {

  constructor(id: number)
  {
    this.residenceID = id;
  }

  public residenceID: number;

  public residenceStatusRef: string;

  public residenceStartDate: Date;

  public residenceEndDate: Date;

  public residenceDiagnosis1: string;

  public residenceCategory: number;

  public residenceCategoryRow: ResidenceCategory;

  public userRef: string;

  public courseArrivalType: string;

  public residenceDiagnosis1Name: string;

  public residenceSequence1Ref: number;

  public residenceDesc: string;

  public residenceCourseRef: number;

  public courseDefaultAccountRef: number;

  public courseStartDate: Date;

  public patientRef: number;

  public bitProcess: string;

  public previousResidenceRef: number;

  public venueRef: number;

  public bookRejectReason: string;

  public stationaryDepartureType: string;

  public dispenceNumber: string;
}
