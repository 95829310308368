import { Component, Inject, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';

import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData, PrescriptionAddData } from 'app/llp/llp.component';
import { DoctorService } from 'app/doctor/doctor.service';
import { Dictionary } from 'app/models/Dictionary';
import moment from 'moment';

@Pipe({ name: 'searchTemplate' })

export class searchTemplatePipe implements PipeTransform {
  transform(values: Prescription[], filter: string): Prescription[] {
    if (!values || !values.length) return [];
    if (!filter) return values;

    filter = filter.toUpperCase();

    if (filter) {
      return values.filter(v => v.prescriptionMedicationName.toUpperCase().indexOf(filter) >= 0);
    }
  }
}


@Component({
  selector: 'app-modalWindows-PrescriptionSelect',
  templateUrl: './PrescriptionSelectDialog.html',
  styleUrls: ['./PrescriptionSelectDialog.css'],
  providers: [DatePipe],
})

export class PrescriptionSelectDialog {

  myOptionsColor = {
    'theme': 'light'
  }

  data: PrescriptionAddData;
  templates: Prescription[];

  selectedPrescription: Prescription = null;

  constructor(public dialogRef: MatDialogRef<PrescriptionSelectDialog>, @Inject(MAT_DIALOG_DATA)
  public d: PrescriptionAddData, private datePipe: DatePipe, public dialog: MatDialog,
              public service: DoctorService)
  {
    this.data = d;   
  }

  ngOnInit() {
  

    this.service.getPrescriptionTemplates().subscribe(c => {
      this.templates = c;
    });
    
  }

  selectPrescription(p: Prescription) {
    this.selectedPrescription = p;
  }

  onYesClick(): void {

    this.data.rezult = true;
    this.data.templatePrescription = this.selectedPrescription;
    this.dialogRef.close(this.data);
  
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

}

