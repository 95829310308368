import { Component, Inject } from '@angular/core';
import { Dictionary } from '../../../models/Dictionary';
import { ResidenceCategory } from '../../../models/ResidenceCategory';
import { ResidenceCreateService } from '../ResidenceCreateService';
import { DialogData } from '../../../calendar/Day/day.component';
import { Observable, Subscription } from 'rxjs';
import { BedData } from '../../../models/BedData';
import { DiagnosisSelectDialog } from '../../DiagnosisSelect/DiagnosisSelectDialog';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosData } from '../ResidenceCreateDialog';
import { Router } from '@angular/router';
import { Sequence } from '../../../models/Sequence';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'residence-reject',
  templateUrl: './residence.reject.html',
  styleUrls: ['./residence.reject.css'],
})
export class ResidenceRejectComponent {

  doctors: Dictionary[];
  diagnosis: Dictionary[];
  bookRejectReasons: Dictionary[];
  users: Dictionary[];
  tempDate: Date;
  tempUser: string;


  constructor(private service: ResidenceCreateService, private router: Router, public dialog: MatDialog, private lservice: LoginService)
  {
    var _that = this;

    this.service.residenceData.childRoute = "reject";

    this.service.getDiagnozis().subscribe((data: Dictionary[]) => { _that.diagnosis = data; });
    this.service.getDoctors().subscribe((data: Dictionary[]) => { _that.doctors = data; });
    this.service.getDictionary("BookRejectReason").subscribe((data: Dictionary[]) => { _that.bookRejectReasons = data; });
    this.service.getDictionary("Користувачі").subscribe((data: Dictionary[]) => { _that.users = data; });

    this.tempDate = this.service.endDate;
    this.service.endDate = new Date();

    this.tempUser = this.service.userRef;
    this.service.userRef = lservice.UserToken.userLogin;
  }

  navigateToMain() {
    this.service.endDate = this.tempDate;
    this.service.userRef = this.tempUser;

    this.router.navigate(['.', { outlets: { 'residence': 'main' } }]);
  }

  ngOnInit() {

  }

  //for select diagnosis
  onDiagnosClick(data: BedData) {
    this.openDialog().subscribe(result => {

      if (result != null && result.rezult)//if success in modal dialog
      {
        if (result.newDiagnos.dictionaryCode != "") {
          this.service.CurrentDiagnos.dictionaryID = result.newDiagnos.dictionaryID;
          this.service.CurrentDiagnos.dictionaryCode = result.newDiagnos.dictionaryCode;
          this.service.CurrentDiagnos.dictionaryName = result.newDiagnos.dictionaryName;
        }
      }
      else {
        // something if false (pressed no)
      }
    });
  }


  //for select diagnosis
  openDialog(): Observable<DiagnosData> {

    let d: DiagnosData = new DiagnosData();
    d.diagnosis = this.diagnosis;
    d.oldDiagnos = this.service.CurrentDiagnos;
    d.newDiagnos = new Dictionary("");
    const dialogRef = this.dialog.open(DiagnosisSelectDialog, {
      width: '600px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

}

