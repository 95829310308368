import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';

import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData } from 'app/llp/llp.component';
import { DoctorService } from 'app/doctor/doctor.service';
import { Dictionary } from 'app/models/Dictionary';
import moment from 'moment';
import { ConfirmDialogData } from 'app/calendar/Day/day.component';
import { ConfirmDialog } from 'app/modalWindows/Confirm/ConfirmDialog';
import { LoginService } from 'app/modalWindows/Login/LoginService';


@Component({
    selector: 'app-modalWindows-PrescriptionEdit',
    templateUrl: './PrescriptionEditDialog.html',
    styleUrls: ['./PrescriptionEditDialog.css'],
    providers: [DatePipe],
})

export class PrescriptionEditDialog {

    myOptionsColor = {
        'theme': 'light'
    }

    disabled: boolean = false;

    data: PrescriptionEditData;

    medicaments: Dictionary[];
  frequencies: Dictionary[];//частота
  routings: Dictionary[];//спосіб введення
  durations: Dictionary[];//тривалість

    medRef: string;
    freqRef: string;
    routRef: string;
    durationRef: string;

    prescCount: number;
    prescDose: number;
    prescStartDate: Date;
    isPRN: boolean;
    isRecommendation: boolean;
    prescriptionDoseDesc: string;

    constructor(public dialogRef: MatDialogRef<PrescriptionEditDialog>, @Inject(MAT_DIALOG_DATA)
    public d: PrescriptionEditData, private datePipe: DatePipe, public dialog: MatDialog,
        public service: DoctorService, private lservice: LoginService) {
        this.data = d;

        if (d.currentPrescription.bookings.find(r => r.bookingStatusRef == "CMP")) { this.disabled = true; }

        if (this.AllowEdit() == false || !this.data.canEdit) { this.disabled = true; }

        if (d.currentPrescription.prescriptionMedicinePackageRef != null)
            this.medRef = d.currentPrescription.prescriptionMedicinePackageRef.toString();
        if (d.currentPrescription.drugFrequencyKindRef != null)
            this.freqRef = d.currentPrescription.drugFrequencyKindRef.toString();
        if (d.currentPrescription.drugRoutingKindRef != null)
            this.routRef = d.currentPrescription.drugRoutingKindRef.toString();
        if (d.currentPrescription.drugDurationKindRef != null)
            this.durationRef = d.currentPrescription.drugDurationKindRef.toString();

        this.prescCount = d.currentPrescription.prescriptionTotalQuantity;
        this.prescDose = d.currentPrescription.prescriptionDose;


        this.prescStartDate = d.currentPrescription.prescriptionStartDate;

        if (d.currentPrescription.prescriptionIsPRN != null)
            this.isPRN = d.currentPrescription.prescriptionIsPRN;

        if (d.currentPrescription.prescriptionIsRecommendation != null)
            this.isRecommendation = d.currentPrescription.prescriptionIsRecommendation;

        this.prescriptionDoseDesc = d.currentPrescription.prescriptionDoseDesc;

    }

    AllowEdit() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null &&
            permissions.SecurityActions_Schedule_acEditSchedule == 'True')
            return true;
        else return false;
    }

    AllowCancel() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null &&
            permissions.SecurityActions_Booking_Execution_Cancel == 'True')
            return true;
        else return false;
    }

    disabledCNC() {
        return this.data.canEdit == false || this.AllowCancel() == false;
    }


    getDateColor(date: Date) {
        if (date != null) {
            return "#28a745";
        } else {
            return "#dc3545"
        }
    }

    ngOnInit() {
        let dictionaryMed = "MedicinePackage";//Medication
        this.service.getStorage("UseMedicinePackageForPrescription").subscribe(c => {
            if (c != null && c == 1) {
                dictionaryMed = "MedicinePackage";
            }
            this.initMedDictionary(dictionaryMed);
        });

        this.service.getDictionary("DrugFrequencyKind").subscribe(c => {
            this.frequencies = c;
        });

        this.service.getDictionary("DrugRoutingKind").subscribe(c => {
            this.routings = c;
        });

        this.service.getDictionary("DrugDurationKind").subscribe(c => {
            this.durations = c;
        });
    }

    initMedDictionary(name: string) {
        this.service.getDictionary(name).subscribe(c => {
            this.medicaments = c;
        });
    }

    keyDownFunction(event) {
        if (event.key === '.' || event.key === ',') {
            event.preventDefault();
        }
    }

    validate() {
        if (this.medRef == null) return false;
        else if (this.freqRef == null) return false;
        else if (this.routRef == null) return false;
        else if (this.prescCount == null) return false;
        else if (this.prescDose == null) return false;
        else if (this.prescStartDate == null) return false;
        else return true;
    }

    bindData() {
        if (this.durationRef != null && this.durationRef != "")
            this.data.currentPrescription.drugDurationKindRef = Number(this.durationRef);

        if (this.medRef != null && this.medRef != "")
            this.data.currentPrescription.prescriptionMedicinePackageRef = Number(this.medRef);

        if (this.freqRef != null && this.freqRef != "")
            this.data.currentPrescription.drugFrequencyKindRef = Number(this.freqRef);

        if (this.routRef != null && this.routRef != "")
            this.data.currentPrescription.drugRoutingKindRef = Number(this.routRef);

        this.data.currentPrescription.prescriptionTotalQuantity = this.prescCount;

        this.data.currentPrescription.prescriptionDose = this.prescDose;

        this.data.currentPrescription.prescriptionStartDate = this.prescStartDate;

        this.data.currentPrescription.prescriptionIsPRN = this.isPRN;
        this.data.currentPrescription.prescriptionIsRecommendation = this.isRecommendation;

        this.data.currentPrescription.prescriptionDoseDesc = this.prescriptionDoseDesc;

    }

    stop() {
        let data: ConfirmDialogData = new ConfirmDialogData();
    data.message = "Скасувати виконання призначення?";
        data.YesNoButton = true;
        this.openConfirmDialog(data).subscribe(result => {
            if (result.rezult) {

                this.data.rezult = true;
                this.data.stop = true;
                this.dialogRef.close(this.data);

            }
        });

    }

    onYesClick(): void {
        if (this.validate()) {
            var start = moment(this.prescStartDate, 'YYYY-MM-DD HH:mm').local().toDate();
            var userTimezoneOffset = start.getTimezoneOffset() * 60000;
            this.prescStartDate = new Date(start.getTime() - userTimezoneOffset);

            this.bindData();
            this.data.rezult = true;
            this.dialogRef.close(this.data);
        }
        else {
      alert("заповніть обовязкові поля");
        }
    }

    onNoClick(): void {
        this.data.rezult = false;
        this.dialogRef.close(this.data);
    }

    openConfirmDialog(d: ConfirmDialogData): Observable<ConfirmDialogData> {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: d,
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });
        return dialogRef.afterClosed();
    }


}

