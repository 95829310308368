import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dictionary } from '../../app/models/Dictionary';

import { AuthGuard } from '../../app/auth/auth.guard';
import { PatientSearchBody } from 'app/models/Ehealth/patientSearchBody';
import { Params } from 'app/models/Ehealth/Params';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { Patient } from 'app/models/Patient';
import { employee } from 'app/models/Ehealth/employee';
import { mnp } from 'app/models/Ehealth/mnp';
import { medicalProgram } from 'app/models/Ehealth/medicalProgram';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import { EHServiceDispense } from 'app/models/Ehealth/EHServiceDispense';
import { EHPerson } from 'app/models/Ehealth/EHPerson';
import { patient } from 'app/models/Ehealth/patient';
import { Config } from '../Config';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { EHFrameDialog } from 'app/modalWindows/EHFrame/EHFrameDialog';
import { MatDialog } from '@angular/material/dialog';
import { EHFrameData } from 'app/modalWindows/ResidenceCreate/ResidenceCreateDialog';

@Injectable()
export class EhealthService {

  async getEhealthAuthHeaders() {
    let headers = new HttpHeaders();

    let day = this.lservice.UserToken.userEhealthTokenDate;
    if (!day || day != new Date().getDay().toString()) {

      await this.authorizeEhealth(
        encodeURIComponent(this.lservice.UserToken.userEhealthLogin),
        encodeURIComponent(this.lservice.UserToken.userEhealthPassword),
        this.guid, this.url).toPromise().then(async (data: object) => {
          //   return data['access_token'];

          headers = headers
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + data['access_token'])
        });
    }
    else {
      headers = headers
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.lservice.UserToken.userEhealthToken)
    }


    return headers;
  }

  authorizeEhealth(login: string, password: string, guid: string, url: string) {
    let route: string;
    route = url + "token";
    let body: string;
    body = "grant_type=password&username=" + login + "@@@" + guid + "&password=" + password;

    return this.http.post<object>(route, body, { headers: this.getEhealthAuthHeadersLogin() })
      .pipe(map(async (response: object) => {
        this.lservice.UserToken.userEhealthToken = response['access_token'];
        this.lservice.UserToken.userEhealthTokenDate = new Date().getDay().toString();
        return response;
      }),
        catchError(async err => {
          this.lservice.UserToken.userEhealthToken = null;
          this.lservice.UserToken.userEhealthTokenDate = null;
          this.lservice.UserToken.userEhealthLogin = null;
          this.lservice.UserToken.userPassword = null;
          alert(err.error.error);
          return Observable.throw('Expired Token');
        }));
  }

  getEhealthAuthHeadersLogin() {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/x-www-form-urlencoded')
    return headers;
  }

  getHeaders() {
    let token: string = this.lservice.UserToken.userToken;
    let userlogin: string = this.lservice.UserToken.userLogin;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('charset', 'UTF-8')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer ' + token)
      .set('userlogin', userlogin);
    return headers;
  }


  guid: string;
  url: string;
  constructor(private http: HttpClient, private authGuard: AuthGuard, _config: Config,private lservice: LoginService ) {
    this.guid = _config.get("EhealthInstalationGuid");
    this.url = _config.get("EHPortalApiURL");
  }

  getEHToken() {
  }

  async refreshCentralComponentToken(url: string, guid: string, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any =
    {
      "name": "spdeCompanyDetails",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": 1,
          "name": "RequestAccessTokenRefreshUrl"
        }
      ],
      "installationId": guid
    }


    let day = this.lservice.UserToken.userEhealthTokenDate;
    if (!day || day != new Date().getDay().toString()) {

      let headers: HttpHeaders = await this.getEhealthAuthHeaders();

      await this.http.post<any>(route, body, { headers: headers })
        .pipe(map((response: any) => {
          rezult = response;
          if (response && response.requestAccessTokenRefreshUrl && response.requestAccessTokenRefreshUrl[0].url)
          {
            let frData: EHFrameData = new EHFrameData();
            frData.url = response.requestAccessTokenRefreshUrl[0].url;

            this.openFrameDialog(frData, dialog).subscribe(result => {

              console.log();
            });
          }
        }),
          catchError(err => {
            rezult = this.authGuard.handleError(err);
            return this.authGuard.handleError(err);
          })).toPromise();
    }
    return rezult;
  }

  openFrameDialog(d: EHFrameData, dialog: MatDialog): Observable<EHFrameData> {
    const dialogRef = dialog.open(EHFrameDialog, {
      data: d,
      height: '95%',
      width: '95%',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

  async searchEhealthByNumber(search: string, guid: string, url: string, dialog:MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any = {
      "name": "spdePatientSearch",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": search,
          "name": "ServiceRequestNumber"
        }
      ],
      "installationId": guid
    }

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);

    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response;
      }),
        catchError(err => {
          rezult = this.authGuard.handleError(err);
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  async searchEhealthByPredicate(pName: string, birthdate: string, ipn: string, guid: string, url: string, dialog:MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    const chars = pName.trim().split(' ');
    let pFirstn: string;
    let pSecondn: string;
    let pLastn: string;
    if (chars[0]) pLastn = chars[0];
    if (chars[1]) pFirstn = chars[1];
    if (chars[2]) pSecondn = chars[2];

    let body: any = {
      "name": "spdePatientSearch",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": pLastn,
          "name": "PersonLastName"
        },
        {
          "value": pFirstn,
          "name": "PersonFirstName"
        },
        {
          "value": pSecondn,
          "name": "PersonSecondName"
        },

        {
          "value": birthdate,
          "name": "PersonBirthDate"
        },
        {
          "value": ipn,
          "name": "PersonTaxID"
        },
        {
          "value": null,
          "name": "ServiceRequestNumber"
        },
        {
          "value": null,
          "name": "DeclarationNumber"
        }
      ],
      "installationId": guid
    }

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid,dialog);


    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response;
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();

    return rezult;
  }

  async  getEmployees(guid: string, url: string, dialog:MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any = {
      "name": "spdeCompanyDetails",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": "1",
          "name": "Employee"
        }
      ],
      "installationId": guid
    };

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);


    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response.employee;
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  async  getMnps(guid: string, url: string, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any = {
      "name": "spdeCompanyDetails",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": "1",
          "name": "Division"
        }
      ],
      "installationId": guid
    };

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);


    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response.division;
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  async  getPrograms(guid: string, url: string, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any = {
      "name": "spdeCompanyDetails",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": "1",
          "name": "MedicalProgram"
        }
      ],
      "installationId": guid
    };

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);


    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response.medicalProgram;
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  async  saveDispence(guid: string, dispence: serviceDispense, url: string, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any = {
      "name": "spdeServiceDispenseSaveExternal",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": dispence.serviceDispenseRequestId,
          "name": "serviceRequestId"
        },
        {
          "value": dispence.number,
          "name": "serviceRequestNumber"
        }
      ],
      "installationId": guid
    };

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);



    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response.serviceDispense[0];
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  async  personDetails(guid: string, person: EHPerson, url: string, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let body: any = {
      "name": "spdePersonDetails",
      "Params": [
        {
          "name": "Login",
          "value": this.lservice.UserToken.userEhealthLogin
        },
        {
          "value": person.ehPersonImmutableGuid,
          "name": "immutable_id"
        },
        {
          "value": "1",
          "name": "Person"
        }
      ],
      "installationId": guid
    }

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);

    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response.person[0];
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  async  savePerson(guid: string, person: EHPerson, patient: EHPatient, url: string, birth: string, DEPatient: Patient, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let gender: string = null;
    let rezult: any;

    if (DEPatient.patientSexRef != null) {
      if (DEPatient.patientSexRef == "FEM")
        gender = "FEMALE"
      else if (DEPatient.patientSexRef == "MAL")
        gender = "MALE"
    }

    let isPre: number = 0;
    if (patient.isPrePerson == true) isPre = 1;

    let body: any = {
      "name": "spdePersonSave",
      "Params": [
        {
          "value": this.lservice.UserToken.userEhealthLogin,
          "name": "Login"
        },
        {
          "value": person.ehPersonImmutableGuid,
          "name": "id"
        },
        {
          "value": person.ehPersonImmutableGuid,
          "name": "immutable_id"
        },
        {
          "value": patient.ehPatientFirstName,
          "name": "person_first_name"
        },
        {
          "value": patient.ehPatientLastName,
          "name": "person_last_name"
        },
        {
          "value": patient.ehPatientSecondName,
          "name": "person_second_name"
        },
        {
          "value": birth,
          "name": "person_birth_date"
        },
        {
          "value": gender,
          "name": "person_gender"
        },
        {
          "value": DEPatient.patientEmail,
          "name": "person_email"
        },
        {
          "value": null,
          "name": "legal_entity_id"
        },
        {
          "value": DEPatient.patientPhone,
          "name": "phoneNumber"
        },
        {
          "value": patient.ehPatientTaxId,
          "name": "person_tax_id"
        },
        {
          "value": isPre,
          "name": "is_preperson"
        }
      ],
      "installationId": guid
    }


    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, guid, dialog);


    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response;
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();

    return rezult;
  }

  async processDispance(body: any, url: string, dialog: MatDialog) {
    let route: string;
    route = url + "run";
    let rezult: any;

    let headers: HttpHeaders = await this.getEhealthAuthHeaders();
    let check: any = await this.refreshCentralComponentToken(url, this.guid, dialog);


    await this.http.post<any>(route, body, { headers: headers })
      .pipe(map((response: any) => {
        rezult = response;
      }),
        catchError(err => {
          return this.authGuard.handleError(err);
        })).toPromise();
    return rezult;
  }

  getPatientsByDispenseNumber(number: string, patientRef: string): Observable<Patient> {
    return this.GetIdentity<Patient>("/api/patient/GetPatientsByDispenseNumber/" + number + '/' + patientRef);
  }

  createEHPatient(body: EHPatient): Observable<EHPatient> {
    let route: string;
    route = "/api/values/CreateEHPatient";

    return this.http.post<EHPatient>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: EHPatient) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  updateEHPatient(body: EHPatient): Observable<EHPatient> {
    let route: string;
    route = "/api/values/UpdateEHPatient";
    return this.http.post<EHPatient>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: EHPatient) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  createEHPerson(body: EHPatient): Observable<EHPerson> {
    let route: string;
    route = "/api/values/CreateEHPerson";

    return this.http.post<EHPerson>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: EHPerson) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  createEHServiceDisense(body: EHServiceDispense): Observable<EHServiceDispense> {
    let route: string;
    route = "/api/values/CreateEHServiceDispense";

    return this.http.post<EHServiceDispense>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: EHServiceDispense) => {
        return response
      }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  getEHPerson(body: EHPatient): Observable<EHPerson> {
    let route: string;
    route = "/api/values/GetEHPerson";

    return this.http.post<EHPerson>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: EHPerson) => {
        return response
      }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  getEHServiceDispence(EHPatientID: number): Observable<EHServiceDispense> {
    return this.GetIdentity<EHServiceDispense>("/api/values/GetEHServiceDispence/" + EHPatientID);
  }

  GetIdentity<T>(route: string): Observable<T> {
    return this.http.get<T>(route, { headers: this.getHeaders() }).pipe(
      map((response: T) => {
        return response
      }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }
}


  //////////////////
  //subscribtions


