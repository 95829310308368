import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../modalWindows/Login/LoginService';
import { Token } from '../models/Token';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoginDialog } from '../modalWindows/Login/LoginDialog';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate
{

  constructor(public dialog: MatDialog, private service: LoginService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.service.UserToken || !this.service.UserToken.userIsAuthorized)
    {
      this.service.login(); 
      return false;
    }
    else
    {
      let permissions = this.service.getUserPermissions();

      if (route.data.componentid == "calendar"
        && permissions
        && permissions.DoctorEleksWin32Foundation_Workplaces_HospitalWorkplace == 'False') {
        this.router.navigate(['']);
        alert("you have no permissions to calendar view");
      }

      if (route.data.componentid == "doctor"
        && permissions
        && permissions.DoctorEleksWin32Foundation_Workplaces_PhysicianWorkplace == 'False') {
        this.router.navigate(['']);
        alert("you have no permissions to doctor view");
      }
      if (route.data.componentid == "nurse"
        && permissions
        && permissions.DoctorEleksWin32Foundation_Workplaces_NurseWorkplace == 'False') {
        this.router.navigate(['']);
        alert("you have no permissions to nurse view");
      }
      return true;
    }
  }


   handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}` +
        `err: ${error.message}`
      );
    }

     if (error.status == 401 || error.status == 600) {
       this.service.clearToken();
       this.router.navigate(['']);
       this.dialog.closeAll();
       this.service.login();
       console.clear();
       return;
     }
    // return an observable with a user-facing error message
     return throwError(error.message || error);
  };

}
