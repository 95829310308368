import { OnInit, Component, ChangeDetectorRef, NgZone, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, DrakeOBJ } from './calendar.service';
import { Patient } from '../models/Patient';
import { Sequence } from '../models/Sequence';
import { Venue } from '../models/Venue';
import $ from 'jquery';
import { Subject, Subscription, Observable } from 'rxjs';
import { RoomData } from '../models/RoomData';
import { BedData } from '../models/BedData';
import { DropBedData } from '../models/DropBedData';
import { ResidenceCategory } from '../models/ResidenceCategory';
import { DialogData } from './Day/day.component';
import { ResidenceCreateDialog, DocumentEditData } from '../modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepartmentData } from '../models/DepartmentData';
import { SequenceSelectDialog } from '../modalWindows/SequenceSelect/SequenceSelectDialog';
import { PatientSearchDialog } from '../modalWindows/PatientSearch/PatientSearchDialog';
import { Residence } from '../models/Residence';
import { ResidenceCreateService } from '../modalWindows/ResidenceCreate/ResidenceCreateService';
import { Document003Dialog } from 'app/modalWindows/Documents/003/Document003Dialog';
import moment from 'moment';
import { Document066Dialog } from 'app/modalWindows/Documents/066/Document066Dialog';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import { Document } from 'app/models/Document';
import { Token } from 'app/models/Token';
import dragula from 'dragula';
import { responsiveService } from "../responsiveService";
import { Config } from 'app/Config';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { DoctorService } from 'app/doctor/doctor.service';


export class SequenceDialogData {
    rezult: boolean;
    public sequences: Sequence[];
    public currentSequence: Sequence;
    public selectedSequence: number;
}
export class PatientSearchDialogData {
    rezult: boolean;
    public sequence: Sequence;
}

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css'],
    providers: [DataService]
})
export class CalendarComponent implements OnInit {

    myOptionsColor = {
        'theme': 'light'
    }

    query2: string = "";
    query3: string = "";

    isEhealth: boolean = false;

    public isTablet: boolean;
    public date: Date;
    //vaiting list
    public sequences: Sequence[];
    public selectedSeqDep: Sequence = null;

    public selectedPatient: Patient;
    public isSequenceSelected: boolean;
    public sequenceIsEmpty: boolean;

    //department data
    public venueSequences: Sequence[];
    //public venuePatients: BedData[]; located in service to calculate statistic
    public selectedVenueSeqDep: Sequence = null;
    public isSequenceVenueSelected: boolean;

    public residenceCategories: ResidenceCategory[];

    public patientColapsed: boolean;

    collapseOne: boolean = true;
    collapseTwo: boolean = false;

    settings = {
        bigBanner: true,
        timePicker: false,
        format: 'dd-MM-yyyy',
        defaultOpen: false
    }

    seqByCompanySub: Subscription;

    constructor(private dataService: DataService, private lservice: LoginService, private doctorService: DoctorService,
        private rezService: ResidenceCreateService,
        private responsiveService: responsiveService,
        public dialog: MatDialog, private _ngZone: NgZone, private router: Router,
        private _config: Config) {
        this.date = new Date();

        this.isEhealth = lservice.UserToken && lservice.UserToken.userEhealthIsAuthorized;

        this.seqByCompanySub = this.lservice.updateSeqByCompanyBind().subscribe(id => this.updateSequences());

        if (this.router.url.includes("timeline")) {
            this.router.navigate(['/calendar', { outlets: { 'view': 'timeline' } }]);// Called first time before the ngOnInit()
        }
        else {
            this.router.navigate(['/calendar', { outlets: { 'view': 'day' } }]);// Called first time before the ngOnInit()
        }
        this.patientColapsed = this.isSequenceSelected = this.isSequenceVenueSelected = false;
        this.sequenceIsEmpty = true;
        var _that = this;
        this.dataService.getResidenceCategories().subscribe((data: ResidenceCategory[]) => { _that.residenceCategories = data; });
    }

    updateSequences() {

        this.checkParallelWork();

        this.venueSequences = null;
        this.venueSequences = null;

        this.patientColapsed = this.isSequenceSelected = this.isSequenceVenueSelected = false;
        this.sequenceIsEmpty = true;

        this.dataService.selectedSeqDepartment = this.selectedVenueSeqDep = null;
        this.selectedSeqDep = null;

        this.fillSequences();
        this.fillVenueSequences();

        var _that = this;
        if (this.allowUseJournals())
            this.dataService.getSequences("RCH").subscribe((data: Sequence[]) => {
                _that.venueSequences = data;

                this.dataService.getStorage("WebResidenceVenueSequence").subscribe(r => {
                    if (r) {
                        if (this.venueSequences.find(c => c.sequenceID == r)) {
                            this.sequenceVenueChanged(r, false);
                            this.dataService.updateTimeline();
                            this.dataService.sequenceChange(r);
                        }
                        else if (this.venueSequences.length > 0) {
                            this.sequenceVenueChanged(this.venueSequences[0].sequenceID, false);
                            this.dataService.updateTimeline();
                            this.dataService.sequenceChange(this.venueSequences[0].sequenceID);
                        }
                        else {
                            this.dataService.sequenceChange(null);
                            this.dataService.updateTimeline();
                        }
                    }
                });

                this.dataService.vaitinglistChange(null);
            });
    }

    venuePatientisRezidenced: Subscription;
    sequenceSubscription: Subscription;
    sequencechangeSubscribtion: Subscription;
    updateSequencesFromTimeline: Subscription;
    reloadCalendar: Subscription;
    drakeSub: Subscription;
    getBed: Subscription;

    ngOnInit() {

        this.checkParallelWork();

        this.fillSequences();
        this.fillVenueSequences();

        this.venuePatientisRezidenced = this.dataService.venuePatientChanged().subscribe(b => this.removeVenuePatient(b));

        this.drakeSub = this.dataService.componentMethodFromDrakeCalled$.subscribe(b => this.dropFromDrake(b));

        this.sequencechangeSubscribtion = this.dataService.sequencesChanged().subscribe(r => this.updateSequencesLists());

        this.updateSequencesFromTimeline = this.dataService.updatedSequences().subscribe(r => this.updateSequencesLists());

        this.sequenceSubscription = this.rezService.sequenceUpdated().subscribe(r => this.sequenceUpdate());

        this.getBed = this.dataService.getBedSubscribe().subscribe(r => this.getBedData(r));


        this.onResize();
        this.responsiveService.checkWidth();
    }

    checkParallelWork() {
        this.doctorService.CheckParalelWork().subscribe((data: Token) => {
            if (data.parallelWork) {
                this.router.navigate(['']);
                this.dialog.closeAll();
                this.lservice.clearToken();
                alert("parallel work detected");
            }
        });
    }

    onResize() {
        this.responsiveService.getTabletStatus().subscribe(isTablet => {
            this.isTablet = isTablet;
        });
    }


    ngAfterViewChecked() {
        this.dataService.addDrakeContainer("patientsList");
        this.dataService.addDrakeContainer("sequencePatientList");
    }

    loadStorageSequence() {
        this.dataService.getStorage("WebResidenceVenueSequence").subscribe(r => {
            if (r) {
                if (this.venueSequences.find(c => c.sequenceID == r)) {
                    this.sequenceVenueChanged(r, false);
                } else if (this.venueSequences.length > 0) {
                    this.sequenceVenueChanged(this.venueSequences[0].sequenceID, false);
                }
            }
        });
    }

    subs = new Subscription();

    ngOnDestroy() {
        this.venuePatientisRezidenced.unsubscribe();
        this.drakeSub.unsubscribe();
        this.seqByCompanySub.unsubscribe();

    }


    getBedData(residenceId: number) {

        if (this.dataService.venuePatients != null)
            this.dataService.bed = this.dataService.venuePatients.find(r => r.residence.residenceID == residenceId);
        if (this.dataService.patients != null && this.dataService.bed == null)
            this.dataService.bed = this.dataService.patients.find(r => r.residence.residenceID == residenceId);
    }

    removeVenuePatient(b: DropBedData) {
        let data: BedData;

        if (b.index == 1) {
            data = this.dataService.venuePatients.find(r => r.patient.patientID == b.bed.patient.patientID);
            if (data != null) {
                const index = this.dataService.venuePatients.indexOf(data);
                if (index === -1) return;
                this.dataService.venuePatients.splice(index, 1);
            }
        }
        else if (b.index == 2) {
            data = this.dataService.patients.find(r => r.patient.patientID == b.bed.patient.patientID);
            if (data != null) {
                const index = this.dataService.patients.indexOf(data);
                if (index === -1) return;
                this.dataService.patients.splice(index, 1);
            }
        }
    }

    getResidenceID(data: BedData) {
        if (data && data.residence)
            return data.residence.residenceID;
    }

    updateSequencesLists() {
        //reload lists
        this.sequenceUpdate();
        this.departmentUpdate();
    }

    sequenceUpdate() {
        if (this.sequences != null && this.selectedSeqDep != null) {
            this.sequenceChanged(this.selectedSeqDep.sequenceID);
        }
    }

    departmentUpdate() {
        if (this.venueSequences != null && this.selectedVenueSeqDep != null) {
            this.sequenceVenueChanged(this.selectedVenueSeqDep.sequenceID);
        }
    }

    allowUseJournals() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Books_acBooks == 'True')
            return true;
        else return false;
    }

    public fillSequences() {
        var _that = this;
        if (this.allowUseJournals())
            this.dataService.getSequences("RCE").subscribe((data: Sequence[]) => { _that.sequences = data; });
    }

    public fillVenueSequences() {
        var _that = this;
        if (this.allowUseJournals())
            this.dataService.getSequences("RCH").subscribe((data: Sequence[]) => {
                _that.venueSequences = data;
                this.loadStorageSequence();
            });
    }

    onSelectPatient(p: Patient): void {
        this.selectedPatient = p;
    }

    patientsLoading: boolean = false;
    //vaiting list of patients
    sequenceChanged(selectedValue: number) {

        this.isSequenceSelected = true;
        this.selectedPatient = null;
        this.selectedSeqDep = this.sequences.find(r => r.sequenceID == selectedValue);
        this.dataService.patients = null;
        this.patientsLoading = true;
        var _that = this;

        this.dataService.getPatientsBySequence(selectedValue)
            .subscribe((data: BedData[]) => {
                _that.dataService.patients = data;
                _that.patientsLoading = false;
                _that.sequenceIsEmpty = _that.dataService.patients.length == 0;
                _that.dataService.updateTimeline();
            });

        this.dataService.vaitinglistChange(selectedValue);
    }

    venuePpatientsLoading: boolean = false;
    //patients in sequence, department loading
    sequenceVenueChanged(selectedValue: number, update: boolean = true) {

        this.dataService.criticalPatientsInDepCount = 0;
        var _that = this;
        this.dataService.venuePatients = null;
        this.venuePpatientsLoading = true;

        this.isSequenceVenueSelected = true;
        this.selectedVenueSeqDep = this.venueSequences.find(r => r.sequenceID == selectedValue);

        this.dataService.selectedSeqDepartment = this.selectedVenueSeqDep;

        this.dataService.getVenuePatientsBySequence(selectedValue.toString())
            .subscribe((data: BedData[]) => {

                _that.dataService.venuePatients = data;
                _that.venuePpatientsLoading = false;
                //_that.dataService.updateTimeline();
            });

        //init venues(rooms and Beds) in dayComponent
        this.dataService.sequenceChange(selectedValue);

        if (update && this.selectedVenueSeqDep) {
            this.dataService.setStorage("WebResidenceVenueSequence", this.selectedVenueSeqDep.sequenceID).subscribe(r => { });
        }
    }

    getVenueSeqHeader() {
        if (this.selectedVenueSeqDep == null) return "Оберіть відділення";
        return this.selectedVenueSeqDep.sequenceName;
    }

    getSeqHeader() {
        if (this.selectedSeqDep == null) return "Оберіть відділення";
        return this.selectedSeqDep.sequenceName;
    }

    getColor() {
        if (this.selectedVenueSeqDep == null) return "#1874C4";
        else return "#000000";
    }

    changeSequence(bed: BedData) {
        this.openDialogSequenceChange().subscribe(result => {

            if (result != null && result.rezult)//if success in modal dialog
            {
                // dbUpdate here or in modal
                if (true && result.selectedSequence != null && result.selectedSequence != result.currentSequence.sequenceID) {

                    const index = this.dataService.venuePatients.indexOf(bed);
                    if (index === -1) return;
                    this.dataService.venuePatients.splice(index, 1);


                    //to reinit list of patient
                    if (this.selectedSeqDep != null)
                        this.sequenceChanged(this.selectedSeqDep.sequenceID);

                    this.dataService.initVenueStat();
                }
            }
            else {  /* something if false (pressed no)*/ }
        });
    }

    /////styles

    getGenderColor(bed: BedData) {
        if (bed.patient == null) return "#F5F6F7";

        if (bed.patient.patientSexRef == "FEM") return "#D94C6B";
        if (bed.patient.patientSexRef == "MAL") return "#1874C4";

        return "#F5F6F7";
    }

    getCategoryColor(bed: BedData) {
        if (bed.residence == null) return "white";


        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return this.getBGroundColorBed(bed);
        }

        let cat: ResidenceCategory;

        if (this.residenceCategories != null)
            cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.color;
        }
    }

    getCategoryColorText(bed: BedData) {
        if (bed.residence == null) return "";


        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return "";
        }

        let cat: ResidenceCategory;

        if (this.residenceCategories != null)
            cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.residenceCategoryName;
        }
    }

    getBGColorKindForPat(bed: BedData) {
        if (bed.residence == null) return "white";

        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return "white";
        }

        let cat: ResidenceCategory = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.color;
        }
    }

    getBGroundColorBed(bed: BedData) {
        if (bed.allowDrop) return "#F5F6F7";
        else return "white";
    }

    getTypeColor(bed: BedData) {
        if (bed.residence == null) return "white";

        switch (bed.residence.residenceStatusRef) {
            case "PLN": return "#ffd84d";
            case "PRG": return "#4dac67";
            case "CMP": return "#a1c5ff";
            default: return "white";
        }
    }

    getTypeName(bed: BedData) {
        if (bed.residence == null) return "";

        switch (bed.residence.residenceStatusRef) {
            case "PLN": return "очікує";
            case "PRG": return "поселено";
            case "CMP": return "виписується";
            default: return "";
        }
    }

    ////////////////

    dragPatient(ev, bed: BedData) {
        let data: DropBedData = new DropBedData();
        data.bed = bed;
        data.index = 2;
        ev.dataTransfer.setData("text", JSON.stringify(data));
        //localStorage.setItem("draggedEl", JSON.stringify(data));
    }

    dragVenuePatient(ev, bed: BedData) {
        //this.dataService.dragStart();
        let data: DropBedData = new DropBedData();
        data.bed = bed;
        data.index = 1;
        ev.dataTransfer.setData("text", JSON.stringify(data));
        //localStorage.setItem("draggedEl", JSON.stringify(data));
    }

    dragend(ev) {
        //  this.dataService.dragEnd();
    }

    toJson(bed: BedData) {
        return JSON.stringify(bed);
    }


    //drop from vaiting list to department 
    allowDrop(ev) {
        //ev.preventDefault();
    }

    allowDropToDepartment() {
        let permissions = this.lservice.getUserPermissions();
        if (permissions != null && permissions.SecurityActions_Workflow_Patient_acToBeHospitalized == 'True')
            return true;
        else return false;
    }

    dropFromDrake(obj: DrakeOBJ) {
        if (obj.el.dataset.drakeFrom == "sequencePatientList" && obj.target.id == "sequencePatientList") {
            this.departmentUpdate();
        }
        //from waitinglist to department list
        else if (obj.el.dataset.drakeFrom == "patientsList" && obj.target.id == "sequencePatientList") {
            let resID = obj.el.title;

            if (resID) {
                if (this.allowDropToDepartment()) {
                    let data: DropBedData = new DropBedData();
                    data.index = 2;//from waitinglist to department list
                    data.bed = this.dataService.patients.find(r => r.residence.residenceID == Number(resID));
                    this.drop(data);
                } else {
                    alert("no permissions for move patient to department");
                    this.updateSequencesLists();
                }
            }
        }
        else if (obj.el.classList.contains("bedContent") && obj.target.id == "sequencePatientList") {
            let data: DropBedData = new DropBedData();


            for (let room of this.dataService.departmentData.rooms) {

                data.bed = room.beds.find(r => r.bed.venueID == Number(obj.el.id));

                if (data.bed != null) break;
            }
            if (data != null) {
                data.index = 3;
                this.drop(data);
            }

        }
    }


    drop(bedFromDrop: DropBedData) {

        this.checkParallelWork();

        let submit003: boolean = false;
        //from waitinglist to department list
        if (bedFromDrop.index == 2) {

            this.openDialogFromDrop(bedFromDrop.bed).subscribe(result => {

                if (result != null && result.rezult)//if success in modal dialog
                {
                    if (result.childRoute == "reject" || result.childRoute == "decease" || result.childRoute == "discharge") {
                        this.otherProc(result);
                        if (result.childRoute == "decease" || result.childRoute == "discharge") { this.submit066Form(result.patient, result.residence); }
                    }
                    else if (result.childRoute == "changeDep" || (result.childRoute == "main" && result.newSequence != null)) {
                        this.changeSeqProc(result);
                        //this.submit003Form(result.patient, result.residence);
                        submit003 = true;
                    }
                    else if (result.childRoute == "main") {
                        this.dropProc(result);
                    }

                    this.saveData(result, bedFromDrop.bed, true, submit003);

                }
                else { this.updateSequencesLists(); /* something if false (pressed no)*/ }
            });

        }
        else if (bedFromDrop.index == 3) //bed to dep no dialogs need
        {
            bedFromDrop.bed.residence.bitProcess = "dtob";
            bedFromDrop.bed.residence.residenceStatusRef = "PLN";
            bedFromDrop.bed.residence.venueRef = null;

            let ddata: DialogData = new DialogData();
            ddata.patient = bedFromDrop.bed.patient;
            ddata.residence = bedFromDrop.bed.residence;

            this.saveData(ddata, bedFromDrop.bed, true, false);
        }
        //logic from department to vaiting list
        else if (bedFromDrop.index == 1) { }
    }

    submit003Form(p: Patient, r: Residence) {
        let data: DocumentEditData = new DocumentEditData();
        data.document = null;
        data.patient = p;
        data.residence = r;

        let guid: string = this._config.get("003TemplateStaticGuid");

        this.rezService.getdocuments(p.patientID, r.residenceCourseRef).subscribe((docs: Document[]) => {

            if (docs.find(r => r.documentTemplateStaticGUIDString == guid)) { }
            else {
                this.open003Document(data).subscribe(result => {
                    if (result.rezult == true) {
                        result.document.json = result.jsonToSave;
                        result.document.courseRef = r.residenceCourseRef;
                        result.document.patientRef = p.patientID;
                        result.document.documentTemplateStaticGUIDString = guid;
                        result.document.documentTemplateStaticGUID = guid;

                        this.dataService.updateDocumentJson(result.document).subscribe((rez => { }));
                    }
                });
            }
        });
    }

    submit066Form(p: Patient, r: Residence) {
        let data: DocumentEditData = new DocumentEditData();
        data.document = null;
        data.patient = p;
        data.residence = r;

        let guid: string = this._config.get("066TemplateStaticGuid");

        this.rezService.getdocuments(p.patientID, r.residenceCourseRef).subscribe((docs: Document[]) => {

            if (docs.find(r => r.documentTemplateStaticGUIDString == guid)) { }
            else {
                this.open066Document(data).subscribe(result => {
                    if (result.rezult == true) {
                        result.document.json = result.jsonToSave;
                        result.document.courseRef = r.residenceCourseRef;
                        result.document.patientRef = p.patientID;
                        result.document.documentTemplateStaticGUIDString = guid;
                        result.document.documentTemplateStaticGUID = guid;

                        this.dataService.updateDocumentJson(result.document).subscribe((rez => { }));
                    }
                });
            }
        });
    }

    open066Document(d: DocumentEditData): Observable<DocumentEditData> {

        const dialogRef = this.dialog.open(Document066Dialog, {
            data: d,
            width: '1380px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    open003Document(d: DocumentEditData): Observable<DocumentEditData> {

        const dialogRef = this.dialog.open(Document003Dialog, {
            data: d,
            width: '1380px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panelLeft'
        });
        return dialogRef.afterClosed();
    }

    // id: 1 - vaitingList; 2 - venueDep
    bedEdit(data: BedData, name: string, id: number) {
        this.checkParallelWork();

        let submit003: boolean = false;

        this.openDialogFromEdit(data, name).subscribe(result => {
            if (result != null && result.rezult)//if success in modal dialog
            {
                if (result.childRoute == "reject" || result.childRoute == "decease" || result.childRoute == "discharge") {
                    this.otherProc(result);
                    if (result.childRoute == "decease" || result.childRoute == "discharge") { this.submit066Form(result.patient, result.residence); }
                }
                else if (result.childRoute == "changeDep" || (result.childRoute == "main" && result.newSequence != null)) {
                    this.changeSeqProc(result);
                    if (id == 1 && !this.sequences.find(r => r.sequenceID == result.newSequence))
                        //this.submit003Form(result.patient, result.residence);
                        submit003 = true;
                }
                else if (result.childRoute == "main") {
                    this.editProc(result);
                }

                this.saveData(result, data, true, submit003);
            }
            else {/* something if false (pressed no) */ }
        });
    }

    patientSearch() {
        this.openDialogPatientSearch().subscribe(result => {
            if (result.rezult) {
                this.sequenceUpdate();
            }
        });
    }

    // id: 1 - vaitingList; 2 - venueDep
    changeSeqProc(data: DialogData) {

        if (data.newSequence != null) {

            let id: number;
            let s1 = this.sequences;
            let s2 = this.venueSequences;

            let change: boolean = false;

            if (s1.find(r => r.sequenceID == data.newSequence)) {
                id = 1;
                change = data.residence.residenceSequence1Ref != data.newSequence;
            }
            if (s2.find(r => r.sequenceID == data.newSequence)) {
                id = 2;
                change = data.residence.residenceSequence1Ref != data.newSequence;
            }

            if (change) {

                var start = moment(new Date(), 'YYYY-MM-DD HH:mm').local().toDate();
                var userTimezoneOffset = start.getTimezoneOffset() * 60000;
                data.residence.residenceStartDate = new Date(start.getTime() - userTimezoneOffset);

                data.residence.bitProcess = "dtod";
                data.residence.residenceSequence1Ref = data.newSequence;
                data.residence.venueRef = null;
                data.residence.previousResidenceRef = data.residence.residenceID;
                data.residence.residenceStatusRef = id == 1 ? "PRG" : "PLN";
            }
        }
    }

    editProc(data: DialogData) {
        data.residence.bitProcess = "dtob";
        data.residence.venueRef = null;
    }

    dropProc(data: DialogData) {
        data.residence.bitProcess = "dtod";
        data.residence.venueRef = null;
        data.residence.residenceStatusRef = "PLN";
        data.residence.previousResidenceRef = data.residence.residenceID;
    }

    otherProc(data: DialogData) {
        switch (data.childRoute) {
            case "reject":
                data.residence.bitProcess = "rej";
                break;
            case "decease":
                data.residence.bitProcess = "ded";
                break;
            case "discharge":
                data.residence.bitProcess = "cls";
                break;
        }
    }

    saveData(result: DialogData, data: BedData, needReloadLists: boolean, submit003: boolean) {

        this.dataService.updatePatient(result.patient).subscribe((rez => {
            result.patient = rez;
        }));

        this.dataService.updateresidence(result.residence).subscribe((rez => {
            result.residence = rez;

            if (needReloadLists == true) {
                this.updateSequencesLists();
            }
            else {
                this.mapBed(data, result);
            }

            if (submit003) {
                this.submit003Form(result.patient, result.residence);
            }

            this.dataService.initVenueStat();

        }));
    }

    mapSelectedSequence(result: DialogData, id: number, nocheck: boolean = false) {

        result.residence.residenceSequence1Ref = result.patient.sequenceRef;

        if (result.newSequence != null) {
            result.residence.residenceSequence1Ref = result.newSequence;
            if (id == 1) {
                if (nocheck || result.newSequence != this.selectedSeqDep.sequenceID) {
                    result.residence.bitProcess = "dtod";
                    result.residence.previousResidenceRef = result.residence.residenceID;
                    result.residence.residenceStatusRef = "PRG";
                    result.residence.venueRef = null;
                }
            }
            else if (id == 2) {
                if (result.newSequence != null) {
                    result.residence.venueRef = null;
                    result.residence.bitProcess = "dtod";
                    result.residence.previousResidenceRef = result.residence.residenceID;
                    result.residence.residenceStatusRef = "PLN";
                }
            }
        }
    }

    mapBed(data: BedData, result: DialogData) {
        data.patient = result.patient;
        data.residence = result.residence;
        data.allowDrop = result.residence.residenceStatusRef == "PLN";
    }


    openDialogFromEdit(data: BedData, name: string): Observable<DialogData> {
        let d: DialogData = new DialogData();
        d.room = new Venue(-1, "Відділення");
        d.bed = new Venue(-2, name);
        d.patient = data.patient;
        d.residence = data.residence;

        const dialogRef = this.dialog.open(ResidenceCreateDialog, {
            data: d,
            width: '387px',
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.afterClosed();
    }

    openDialogFromDrop(bedFromDrop: BedData): Observable<DialogData> {

        let d: DialogData = new DialogData();
        d.room = new Venue(-1, "Відділення");
        d.bed = new Venue(-2, this.selectedVenueSeqDep.sequenceName);
        d.patient = bedFromDrop.patient;
        d.residence = bedFromDrop.residence;
        d.openChangeSeq = true;
        d.newSequence = this.selectedVenueSeqDep.sequenceID;
        d.dropProcess = true;

        const dialogRef = this.dialog.open(ResidenceCreateDialog, {
            width: '387px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed(); //afterClosed();
    }

    openDialogSequenceChange(): Observable<SequenceDialogData> {
        let d: SequenceDialogData = new SequenceDialogData();
        d.currentSequence = this.selectedVenueSeqDep;
        d.selectedSequence = this.selectedVenueSeqDep.sequenceID;
        d.sequences = this.sequences.concat(this.venueSequences);

        const dialogRef = this.dialog.open(SequenceSelectDialog, {
            data: d,
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'my-panel'
        });

        return dialogRef.beforeClosed();
    }

    openDialogPatientSearch(): Observable<PatientSearchDialogData> {
        let d: PatientSearchDialogData = new PatientSearchDialogData();

        const dialogRef = this.dialog.open(PatientSearchDialog, {
            width: '387px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    seqCollapsed: boolean = false;
    public collapseSeq() {
        if (this.seqCollapsed) {
            $('div[data-title="sequenceData"]').removeClass('sequenceDataHidden').addClass('sequenceData');
            $('div[data-title="sequenceDataCollapse"]').removeClass('sequenceDataCollapse').addClass('sequenceDataCollapseHidden');
        }
        else {
            $('div[data-title="sequenceData"]').removeClass('sequenceData').addClass('sequenceDataHidden');
            $('div[data-title="sequenceDataCollapse"]').removeClass('sequenceDataCollapseHidden').addClass('sequenceDataCollapse');
        }

        this.seqCollapsed = !this.seqCollapsed;
        this.dataService.updateTimeline();
    }

    depCollapsed: boolean = false;
    public collapseDep() {
        if (this.depCollapsed) {
            $('div[data-title="departmentData"]').removeClass('departmentDataHidden').addClass('departmentData');
            $('div[data-title="departmentDataCollapse"]').removeClass('departmentDataCollapse').addClass('departmentDataCollapseHidden');
        }
        else {
            $('div[data-title="departmentData"]').removeClass('departmentData').addClass('departmentDataHidden');
            $('div[data-title="departmentDataCollapse"]').removeClass('departmentDataCollapseHidden').addClass('departmentDataCollapse');
        }

        this.depCollapsed = !this.depCollapsed;
        this.dataService.updateTimeline();
    }


    checkResidenseDate(r: Residence): boolean {
        let today = new Date(); today.setHours(0, 0, 0, 0);

        let start: Date = new Date(r.residenceStartDate); start.setHours(0, 0, 0, 0);

        let end: Date;
        if (r.residenceEndDate != null) {
            end = new Date(r.residenceEndDate);
            end.setHours(0, 0, 0, 0);
        }

        return (start <= today && (end == null || end >= today))
    }


    getIdentOrNoImage(pat: EHPatient) {
        if (pat.isPrePerson) {
            return "url(../../assets/images/NoIdentified.png)";
        }
        else {
            return "url(../../assets/images/Identified.png)";
        }
    }
    getLeftMenuWidth() {
        if (this.isTablet) return "230px";
        return "300px";
    }

    patientsExist() {
        return this.dataService.patients != null && this.dataService.patients.length > 0
    }

    getPatientsListHeight() {
        if (this.dataService.patients != null && this.dataService.patients.length > 0) return "calc(100% - 153px)";
        return "calc(100% - 113px)";
    }

    venuePatientsExist() {
        return this.dataService.venuePatients != null && this.dataService.venuePatients.length > 0
    }

    getVenuePatientsListHeight() {
        if (this.dataService.venuePatients != null && this.dataService.venuePatients.length > 0) return "calc(100% - 88px)";
        return "calc(100% - 48px)";
    }
}


