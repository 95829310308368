import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dictionary } from '../../models/Dictionary';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import { AuthGuard } from '../../auth/auth.guard';
import { Sequence } from '../../models/Sequence';
import { Residence } from '../../models/Residence';
import { Document } from '../../models/Document';
import { DialogData } from '../../calendar/Day/day.component';
import { Account } from '../../models/Account';
import { Course } from '../../models/Course';
import { Patient } from '../../models/Patient';
import { DocumentEditData } from '../ResidenceCreate/ResidenceCreateDialog';
import { JsonDocumentData } from 'app/models/JsonDocumentData';
import { DatePipe } from '@angular/common';
import { Venue } from 'app/models/Venue';
import { LoginService } from '../Login/LoginService';
import { DocumentTemplate } from 'app/models/DocumentTemplate';

@Injectable()
export class DocumentService {
  getHeaders() {
    let token: string = this.lservice.UserToken.userToken;
    let userlogin: string = this.lservice.UserToken.userLogin;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('charset', 'UTF-8')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer ' + token)
      .set('userlogin', userlogin);
    return headers;
  }


  constructor(private http: HttpClient, private authGuard: AuthGuard, private lservice: LoginService) { }

  getDictionary(name: string): Observable<Dictionary[]> {
    let route: string;
    route = "/api/dictionary/GetByName/" + name;

    return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Dictionary[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getDiagnozis(): Observable<Dictionary[]> {
    let route: string;
    route = "/api/dictionary/GetDiagnozis";

    return this.http.get<Dictionary[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Dictionary[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getDocumentJson(patientRef: number, staticGuid: string, doc: Document, courseId: number = -1): Observable<any> {
    let route: string;
    let docId: number = -1;
    if (doc && doc != null) {
      docId = doc.documentID;
    }


    route = "/api/Document/GetDocumentJson/" + patientRef + "/" + staticGuid + "/" + docId + "/" + courseId;

    return this.http.get<string>(route, { headers: this.getHeaders() }).pipe(
      map((response: string) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getDocumentTemplates(filterByWeb: boolean): Observable<DocumentTemplate[]> {
    let route: string;
    route = "/api/values/GetDocumentTemplates/" + filterByWeb;

    return this.http.get<DocumentTemplate[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: DocumentTemplate[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getDocumentRow(documentRef: number): Observable<Document> {
    let route: string;
    route = "/api/Document/GetDocumentRow/" + documentRef;

    return this.http.get<Document>(route, { headers: this.getHeaders() }).pipe(
      map((response: Document) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }


  //POST need subscription
  //
  //
  updateDocument(body: Document): Observable<Document> {
    let route: string;
    route = "/api/Document/SetDocumentJson";

    return this.http.post<Document>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Document) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  getDepartment(body: Residence): Observable<Venue>{
    let route: string;
    route = "/api/Venue/GetDepartment";

    return this.http.post<Venue>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Venue) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }


  //////////////////
  //subscribtions

  getDictionaryDisplayText(node: string, index: number = null, json: JsonDocumentData) {
    let path: string = 'row_' + index + 'row';

    if (!json || !json.Root || !json.Root.DocumentValues || !json.Root.DocumentValues[node])
      return "";

    let object: any = json.Root.DocumentValues[node].Value;
    if (index != null && json.Root.DocumentValues[node][path]) object = json.Root.DocumentValues[node][path].Value;

    if (object == null || !object || object.root == null) return "";

    let code: string = "";
    let name: string = "";

    if (object.root.item.dictInfo.Code)
      code = object.root.item.dictInfo.Code;
    if (object.root.item.dictInfo.Name)
      name = object.root.item.dictInfo.Name;

    return code + " " + name;
  }


  parseDictionary(node: string, selectedRow: Dictionary, index: number = null, json: JsonDocumentData) {

    let path: string = 'row_' + index + 'row';

    let object: any = json.Root.DocumentValues[node].Value;
    if (index != null && json.Root.DocumentValues[node][path]) object = json.Root.DocumentValues[node][path].Value;

    if (!selectedRow) {
      object = null;

      if (index != null && json.Root.DocumentValues[node][path])
        json.Root.DocumentValues[node][path].Value = object;
      else
        json.Root.DocumentValues[node].Value = object;

      return;
    }

    //add structure if no value selected previously
    if (!object) {
      var structure = { root: { item: { dictInfo: { ID: " ", Code: " ", Name: " ", Path: " " } } } };
      object = structure;
    }

    // parse data
    if (object.root.item.dictInfo.ID != null)
      object.root.item.dictInfo.ID = selectedRow.dictionaryID;

    if (object.root.item.dictInfo.Code != null)
      object.root.item.dictInfo.Code = selectedRow.dictionaryCode;

    if (object.root.item.dictInfo.Name != null)
      object.root.item.dictInfo.Name = selectedRow.dictionaryName;

    if (object.root.item.dictInfo.Path != null)
      object.root.item.dictInfo.Path = selectedRow.dictionaryPath;

    if (index != null && json.Root.DocumentValues[node][path])
      json.Root.DocumentValues[node][path].Value = object;
    else
      json.Root.DocumentValues[node].Value = object;
  }

  getInputModel(node: string, i: string, json: JsonDocumentData) {
    let path: string = 'row_' + i + 'row';
    return json.Root.DocumentValues[node][path].Value;
  }

  setInputModel(value, node: string, i: string, json: JsonDocumentData) {
    let path: string = 'row_' + i + 'row';
    json.Root.DocumentValues[node][path].Value = value;
  }

  setSelectModel(value, node: string, i: string, json: JsonDocumentData) {
    let path: string = 'row_' + i + 'row';
    json.Root.DocumentValues[node][path].Value = value;
  }

  SelectModelIsSelected(value, node: string, i: string, json: JsonDocumentData) {
    let path: string = 'row_' + i + 'row';

    if (value != "" && value != null) {
      return json.Root.DocumentValues[node][path].Value == value;
    } else return false;
  }

  parseDate(json: JsonDocumentData, datePipe: DatePipe, node: string, toClient: boolean = false) {
    if (json.Root.DocumentValues[node].Value != null) {
      if (toClient) {
        json.Root.DocumentValues[node].Value = new Date(json.Root.DocumentValues[node].Value);
      } else {
        json.Root.DocumentValues[node].Value = datePipe.transform(json.Root.DocumentValues[node].Value,
          "yyyy-MM-ddTHH:mm:ss.SSS");
      }
    }
  }

  parseTableDate(json: JsonDocumentData, datePipe: DatePipe, node: string, repetitionsNode: string, toClient: boolean = false) {

    let i: string = json.Root.DocumentValues[repetitionsNode].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];
    let index: number = Number(i);

    for (let i = 0; i < index; i++) {
      let path: string = 'row_' + i + 'row';

      if (json.Root.DocumentValues[node][path].Value != null) {

        if (toClient) {
          json.Root.DocumentValues[node][path].Value = new Date(json.Root.DocumentValues[node][path].Value);
        }
        else {
          json.Root.DocumentValues[node][path].Value = datePipe.transform(json.Root.DocumentValues[node][path].Value, "yyyy-MM-ddTHH:mm:ss.SSS");
        }
      }
    }
  }

  dictionaryChange(json: JsonDocumentData, value, node: string, dictionary: Dictionary[], code: boolean = false, index: number = null) {

    let dictionaryRow = null;
    if (code) dictionaryRow = dictionary.find(r => r.dictionaryCode == value);
    else dictionaryRow = dictionary.find(r => r.dictionaryID == value);

    this.parseDictionary(node, dictionaryRow, index, json);
  }

  initDicSelectedValue(json: JsonDocumentData, row: Dictionary, node: string, index: number = null) {

    let path: string = 'row_' + index + 'row';
    let object: any = json.Root.DocumentValues[node].Value;
    if (index != null && json.Root.DocumentValues[node][path]) object =json.Root.DocumentValues[node][path].Value;

    if (object != null) {
      if ((object.root.item.dictInfo.ID == row.dictionaryID && row.dictionaryID != null) ||
        (object.root.item.dictInfo.Code == row.dictionaryCode && row.dictionaryCode != null)) {
        return true;
      }
      return false;
    }
    return false;
  }

  counter(json: JsonDocumentData, node: string) {

    if (!json || !json.Root || !json.Root.DocumentValues[node].Value) return Array.from(Array(1).keys());

    let i: string = json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];

    let index: number = Number(i);
    if (index == 0) { index = 1; } else { }
    return Array.from(Array(index).keys());
  }

  canRemove(json: JsonDocumentData, node: string) {
    if (!json || !json.Root || !json.Root.DocumentValues[node].Value) return false;

    let i: string = json.Root.DocumentValues[node].Value.root.RepeatNumbers.RepeatNumber["@repetitions"];

    let index: number = Number(i);

    if (index > 1) return true; else return false;
  }

}









