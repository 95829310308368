import { Component, Inject, ViewChild } from '@angular/core';
import { BedData } from '../../models/BedData';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../calendar/Day/day.component';
import { ResidenceCreateService } from '..//ResidenceCreate/ResidenceCreateService';
import { Dictionary } from '../../models/Dictionary';
import { Document } from '../../models/Document';
import { Observable } from 'rxjs';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { DocumentViewerDialog } from '../DocumentViewer/DocumentViewerDialog';
import { Router } from '@angular/router';
import { Course } from '../../models/Course';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientComponent } from '../../patient/patient.component';
import { Patient } from '../../models/Patient';
import { PatientEditData } from '../PatientSearch/PatientSearchDialog';
import { Sequence } from '../../models/Sequence';



@Component({
  selector: 'app-modalWindows-PatientEdit',
  templateUrl: './PatientToSequenceCreateDialog.html',
  styleUrls: ['./PatientToSequenceCreateDialog.css']
})

export class PatientToSequenceCreateDialog {

  @ViewChild(PatientComponent)
  private patientComponent: PatientComponent;

  data: PatientEditData;
  public sequences: Sequence[];
  public selectedSeqDep: Sequence;

  constructor(private service: ResidenceCreateService, public dialogRef: MatDialogRef<PatientToSequenceCreateDialog>, @Inject(MAT_DIALOG_DATA)
  public d: PatientEditData) {
    var _that = this;

    this.data = d;
    this.service.getSequences("RCE").subscribe((data: Sequence[]) => { _that.sequences = data; });
  }

  sequenceChanged(selectedValue: number) {
    this.selectedSeqDep = this.sequences.find(r => r.sequenceID == selectedValue);
  }


  onYesClick(): void
  {
    //bind patient by viewchild
    this.patientComponent.bindData();

    //validation
    if (!this.patientComponent.patientName) {
      alert("Не заповнено поле П.І.Б.");
      return;
    }

    this.data.rezult = true;
    this.dialogRef.close(this.data);
   
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

}

