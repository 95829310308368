import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';

import { Router } from '@angular/router';
import { Prescription } from '../../../models/Prescription';
import { CommonModule, DatePipe } from '@angular/common';
import { PrescriptionEditData, PrescriptionAddData } from 'app/llp/llp.component';
import { DoctorService } from 'app/doctor/doctor.service';
import { Dictionary } from 'app/models/Dictionary';
import moment from 'moment';
import { DictionaryData } from 'app/modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { DictionarySelectDialog } from 'app/modalWindows/DictionarySelect/DictionarySelectDialog';


@Component({
  selector: 'app-modalWindows-PrescriptionAdd',
  templateUrl: './PrescriptionAddDialog.html',
  styleUrls: ['./PrescriptionAddDialog.css'],
  providers: [DatePipe],
})

export class PrescriptionAddDialog {

  myOptionsColor = {
    'theme': 'light'
  }

  data: PrescriptionAddData;

  medicaments: Dictionary[] = [];
  frequencies: Dictionary[];//частота
  routings: Dictionary[];//спосіб введення
  durations: Dictionary[];//тривалість

  medRef: string;
  freqRef: string;
  routRef: string;
  durationRef: string;

  constructor(public dialogRef: MatDialogRef<PrescriptionAddDialog>, @Inject(MAT_DIALOG_DATA)
  public d: PrescriptionAddData, private datePipe: DatePipe, public dialog: MatDialog,
              public service: DoctorService)
  {
    this.data = d;

  }

  ngOnInit() {
    this.data.newPrescription.prescriptionStartDate = moment(new Date(), 'YYYY-MM-DD HH:mm').local().toDate();
    this.data.newPrescription.prescriptionDose = 1.000;

    let dictionaryMed = "MedicinePackage";//Medication
    this.service.getStorage("UseMedicinePackageForPrescription").subscribe(c => {
      if (c != null && c == 1) {
        dictionaryMed = "MedicinePackage";
      }
      this.initMedDictionary(dictionaryMed);
    });

    this.service.getDictionary("DrugFrequencyKind").subscribe(c => {
      this.frequencies = c;
    });

    this.service.getDictionary("DrugRoutingKind").subscribe(c => {
      this.routings = c;
    });

    this.service.getDictionary("DrugDurationKind").subscribe(c => {
      this.durations = c;
    });

    if (this.data.templatePrescription != null) {
      this.bindTemplateData();
    }


    //string and int issues(dictionaryID is string)
    if (this.data.newPrescription.prescriptionMedicinePackageRef != null)
      this.medRef = this.data.newPrescription.prescriptionMedicinePackageRef.toString();
    if (this.data.newPrescription.drugFrequencyKindRef != null)
      this.freqRef = this.data.newPrescription.drugFrequencyKindRef.toString();
    if (this.data.newPrescription.drugRoutingKindRef != null)
      this.routRef = this.data.newPrescription.drugRoutingKindRef.toString();
    if (this.data.newPrescription.drugDurationKindRef != null)
      this.durationRef = this.data.newPrescription.drugDurationKindRef.toString();
  }

  bindTemplateData()
  {
    this.data.newPrescription.prescriptionNumber = this.data.templatePrescription.prescriptionNumber;

    if (this.data.copyName)
    this.data.newPrescription.prescriptionMedicinePackageRef = this.data.templatePrescription.prescriptionMedicinePackageRef;

    this.data.newPrescription.drugFrequencyKindRef = this.data.templatePrescription.drugFrequencyKindRef;
    this.data.newPrescription.drugRoutingKindRef = this.data.templatePrescription.drugRoutingKindRef;
    this.data.newPrescription.drugDurationKindRef = this.data.templatePrescription.drugDurationKindRef;
    if (this.data.templatePrescription.prescriptionTotalQuantity != null)
    this.data.newPrescription.prescriptionTotalQuantity = this.data.templatePrescription.prescriptionTotalQuantity;
    this.data.newPrescription.prescriptionDose = this.data.templatePrescription.prescriptionDose;
    this.data.newPrescription.prescriptionIsPRN = this.data.templatePrescription.prescriptionIsPRN;
    this.data.newPrescription.prescriptionIsRecommendation = this.data.templatePrescription.prescriptionIsRecommendation;
  }

  initMedDictionary(name: string) {
    this.service.getDictionary(name).subscribe(c => {
      this.medicaments = c;
    });
  }

  keyDownFunction(event) {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  }


  eventHandler(event) {
    event.returnValue = false;
    event.preventDefault();
  }


  getDateColor(date: Date) {
    if (date != null) {
      return "#28a745";
    } else {
      return "#dc3545"
    }
  }

  validate() {
    if (this.data.newPrescription.prescriptionMedicinePackageRef == null) return false;
    else if (this.data.newPrescription.drugFrequencyKindRef == null) return false;
    else if (this.data.newPrescription.drugRoutingKindRef == null) return false;
    else if (this.data.newPrescription.prescriptionTotalQuantity == null) return false;
    else if (this.data.newPrescription.prescriptionDose == null) return false;
    else if (this.data.newPrescription.prescriptionStartDate == null) return false;
    else return true;
  }

  onYesClick(): void
  {

        //string and int issues(dictionaryID is string)
    if (this.durationRef != null && this.durationRef != "")
      this.data.newPrescription.drugDurationKindRef = Number(this.durationRef);
    if (this.medRef != null && this.medRef != "")
      this.data.newPrescription.prescriptionMedicinePackageRef = Number(this.medRef);
    if (this.freqRef != null && this.freqRef != "")
      this.data.newPrescription.drugFrequencyKindRef = Number(this.freqRef);
    if (this.routRef != null && this.routRef != "")
      this.data.newPrescription.drugRoutingKindRef = Number(this.routRef);

    //validation required Fields
    if (this.validate()) {

      var start = moment(this.data.newPrescription.prescriptionStartDate, 'YYYY-MM-DD HH:mm').local().toDate();
      var userTimezoneOffset = start.getTimezoneOffset() * 60000;
      this.data.newPrescription.prescriptionStartDate = new Date(start.getTime() - userTimezoneOffset);


      this.data.rezult = true;
      this.dialogRef.close(this.data);
    } else {
      alert("заповніть обовязкові поля");
    }
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

  onDictionaryClick() {
    this.openDialog("MedicinePackage").subscribe(result => {

      if (result != null && result.rezult && result.dictionaryRow)//if success in modal dialog
      {
        this.medRef = result.dictionaryRow.dictionaryID;
      }
      else {
        
      }
    });
  }

  getDisplayText() {
    if (this.medRef == null || this.medRef == "" || !this.medicaments) return "";
    else {
      let m: Dictionary = this.medicaments.find(r => r.dictionaryID == this.medRef);
      return (m!= null && m) ? m.dictionaryName : "";
    }
  }

  openDialog(dicName: string): Observable<DictionaryData> {

    let d: DictionaryData = new DictionaryData();
    d.dictionaryName = dicName;
    d.searchByID = true;

    const dialogRef = this.dialog.open(DictionarySelectDialog, {
      width: '500px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

}

