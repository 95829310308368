import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Token } from '../../../models/Token';
import { PatientSearchDialogData } from '../../../calendar/calendar.component';
import { Patient } from '../../../models/Patient';
import { DataService } from '../../../calendar/calendar.service';
import { PatientToSequenceData, PatientToQueueData, PatientSyncData } from '../../PatientSearch/PatientSearchDialog';
import { Residence } from '../../../models/Residence';
import { Dictionary } from '../../../models/Dictionary';
import { ResidenceCategory } from '../../../models/ResidenceCategory';
import { ResidenceCreateService } from '../../ResidenceCreate/ResidenceCreateService';
import { DiagnosData } from '../../ResidenceCreate/ResidenceCreateDialog';
import { BedData } from '../../../models/BedData';
import { DiagnosisSelectDialog } from '../../DiagnosisSelect/DiagnosisSelectDialog';
import { Sequence } from '../../../models/Sequence';
import { Account } from '../../../models/Account';
import moment from 'moment';
import { EhealthService } from 'app/Ehealth/EhealthService';
import { employee } from 'app/models/Ehealth/employee';
import { Config } from '../../../Config';
import { mnp } from 'app/models/Ehealth/mnp';
import { medicalProgram } from 'app/models/Ehealth/medicalProgram';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { DatePipe } from '@angular/common';
import { syncPatientDialog } from '../syncPatient/syncPatientDialog';
import { LoginService } from 'app/modalWindows/Login/LoginService';


@Component({
  selector: 'app-modalWindows-addToQueue',
  templateUrl: './addToQueueDialog.html',
  styleUrls: ['./addToQueueDialog.css']
})

export class addToQueueDialog {

  d: PatientToQueueData;
  guid: string;

  employees: employee[] = [];
  mnps: mnp[] = [];
  programs: medicalProgram[] = [];

  workerID: string = "";
  mnpID: string = "";
  dateStart: Date = new Date();
  programID: string = "";
  coment: string = "";
  url: string;

  newDispance: serviceDispense;

  disabled() {
    return this.workerID == "" || this.mnpID == "" || this.programID == "";
  }

  constructor(private service: EhealthService, private lservice: LoginService, _config: Config, private datePipe: DatePipe,
    public dialog: MatDialog, public dialogRef: MatDialogRef<addToQueueDialog>, @Inject(MAT_DIALOG_DATA) public data: PatientToQueueData)
  {
    this.d = data;
    this.guid = _config.get("EhealthInstalationGuid");
    this.url = _config.get("EHPortalApiURL");
    this.loadData();
  }

  async loadData() {
    var _that = this;
     await this.service.getEmployees(this.guid, this.url, this.dialog).then((e: any) =>
    {
      _that.employees = e;
    });
    await this.service.getMnps(this.guid, this.url, this.dialog).then((e: any) => {
      _that.mnps = e;
    });
    await this.service.getPrograms(this.guid, this.url, this.dialog).then((e: any) => {
      _that.programs = e;
    });
  }

  ngOnInit() {
    this.loadDataAsync();
  }

  async loadDataAsync() {
    var _that = this;
    await this.service.saveDispence(this.guid, this.d.dispence, this.url, this.dialog).then((e: any) => {
      _that.newDispance = e;
    });
  }

  onNoClick(): void {
    this.d.rezult = false;
    this.dialogRef.close(this.d);
  }

  async toQueue() {
    let body: any;

    if (this.newDispance.status == "in_queue") {

      body = {
        "name": "spdeServiceDispenseRelease",
        "Params": [
          {
            "name": "Login",
            "value": this.lservice.UserToken.userEhealthLogin
          },
          {
            "value": this.newDispance.serviceDispenseRequestId,
            "name": "serviceRequestId"
          },
          {
            "value": this.newDispance.serviceDispenseProcessId,
            "name": "serviceRequestProcessID"
          }
        ],
        "installationId": this.guid
      }

      await this.service.processDispance(body, this.url, this.dialog).then((e: any) => {
        console.log(e);
      });
    }
    else {

      body = {
        "name": "spdeServiceDispenseSetInUse",
        "Params": [
          {
            "name": "Login",
            "value": this.lservice.UserToken.userEhealthLogin
          },
          {
            "value": this.newDispance.serviceDispenseRequestId,
            "name": "serviceRequestId"
          },
          {
            "value": this.newDispance.serviceDispenseProcessId,
            "name": "serviceRequestProcessID"
          },
          {
            "value": this.workerID,
            "name": "employeeGuid"
          },
          {
            "value": this.mnpID,
            "name": "divisionGuid"
          },
          {
            "value": this.programID,
            "name": "medicalProgramId"
          },
          {
            "value": this.datePipe.transform(this.dateStart, "yyyy-MM-dd"),
            "name": "visitDate"
          }
        ],
        "installationId": this.guid
      }

      await this.service.processDispance(body, this.url, this.dialog).then((e: any) =>
      {
        if (e.result[0] && e.result[0].result && e.result[0].result == "1")
        {
          this.openDialogSyncPatient(this.d.dispence.DEPatient, this.d.dispence).subscribe(result => {
            if (result.rezult) {
              this.d.rezult = true;
              this.dialogRef.close(this.d);
            }
          });
        }
        else if (e.result[0] && e.result[0].message)
        alert(e.result[0].message);
      });
    }
  }

  openDialogSyncPatient(patient: Patient, dispence: serviceDispense): Observable<PatientSyncData> {
    let d: PatientSyncData = new PatientSyncData();
    d.patient = patient;
    d.dispence = this.newDispance;
    d.searchdispence = dispence;

    const dialogRef = this.dialog.open(syncPatientDialog, {
      width: '400px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });
    return dialogRef.beforeClosed();
  }



  // inside openDialog if true
  // true only if full process finished to close all windows only
   //this.d.rezult = true;
}





