import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, FilterDialogData } from '../../calendar/Day/day.component';
import { Dictionary } from '../../models/Dictionary';
import { Observable } from 'rxjs';
import { ResidenceCategory } from '../../models/ResidenceCategory';
import 'rxjs/add/operator/catch';
import { error } from 'protractor';
import { CommonModule, DatePipe } from '@angular/common';
import { LoginService } from '../Login/LoginService';
import { DataService } from 'app/calendar/calendar.service';
import { RoomData } from '../../models/RoomData';


@Component({
  selector: 'app-modalWindows-DayViewFilter',
  templateUrl: './DayViewFilterDialog.html',
  styleUrls: ['./DayViewFilterDialog.css'],
  providers: [DatePipe]
})

export class DayViewFilterDialog {

  public bedTypes: Dictionary[];

  data: FilterDialogData;

  bedTypeCode: string = "";

  dropdownListSource: any = [];

  dropdownList: any[] = [];

  dropdownListSelected: any = null;

  dropdownSettings: any = {
    singleSelection: true,
    text: "пошук госпіталізованих пацієнтів",
    enableSearchFilter: true,
    enableCheckAll: true,
    enableFilterSelectAll: true,
    searchPlaceholderText: "Введіть прізвище пацієнта",
    //labelKey: "test text 1",
    noDataLabel: "не знайдено результатів",
    classes: "myclass custom-class",
    showCheckbox: true,
    searchBy: ["itemName"]
  };

  constructor(public calendarService: DataService, private lservice: LoginService, public dialogRef: MatDialogRef<DayViewFilterDialog>,
    @Inject(MAT_DIALOG_DATA) public d: FilterDialogData, private datePipe: DatePipe, public dialog: MatDialog ) {
    this.data = d;
    var _that = this;

    this.calendarService.getBedTypes().subscribe((data: Dictionary[]) => {
      _that.bedTypes = data;
      if(d.bedType != null)
        _that.bedTypeCode = data.find(r => r.dictionaryCode == d.bedType.dictionaryCode).dictionaryCode;
    });
    

    this.initPatients();
  }

  onSearch(evt: any) {

    let filter = evt.target.value;

    if (!this.dropdownListSource || !this.dropdownListSource.length) this.dropdownList = [];

    if (!filter || filter == null || filter == "")
    {
      for (let item of this.dropdownListSource)
      {
        if (!this.dropdownList.find(r => r['id'] == item['id']))
        this.dropdownList.push(item);
      }
      return this.dropdownList;
    }
    else {
      filter = filter.toUpperCase();
      this.dropdownList = this.dropdownListSource.filter(v => v && v['itemName'] != null && v['itemName'].toUpperCase().indexOf(filter) >= 0);
      return this.dropdownList;
    }
  }

  onRefreshFiltering(item: any) {
    this.dropdownList = this.dropdownListSource;
  }

  clear() {
    this.dropdownListSelected = [];
  }

  onItemSelect(item: any) {
    //this.dropdownListSelected = item;
  }
  OnItemDeSelect(item: any) {
    //this.dropdownListSelected = null;
  }

  initPatients() {
    if (!this.data.departmentData || !this.data.departmentData.rooms) return;

    this.data.departmentData.rooms.forEach(r => {
      r.beds.forEach(b => {
        if (b != null && b.patient != null) {
          this.dropdownList.push({ "id": b.patient.patientID, "itemName": b.patient.patientName });
          this.dropdownListSource.push({ "id": b.patient.patientID, "itemName": b.patient.patientName });

          if (this.data.patientId != null && this.data.patientId == b.patient.patientID)
          {
            this.dropdownListSelected = [];
            this.dropdownListSelected.push( { "id": b.patient.patientID, "itemName": b.patient.patientName } );
          }
        }
      });
    });


  }

  bedTypeChanged() {
    if (this.bedTypeCode == "") this.data.bedType = null;
    else {
      this.data.bedType = this.bedTypes.find(r => r.dictionaryCode == this.bedTypeCode);
    }
  }

  onYesClick(): void
  {
    this.data.patientId = (this.dropdownListSelected == null || this.dropdownListSelected.length == 0) ? null : this.dropdownListSelected[0].id;
    this.data.patientName = (this.dropdownListSelected == null || this.dropdownListSelected.length == 0) ? null : this.dropdownListSelected[0].itemName;

    this.data.rezult = true;
    this.dialogRef.close(this.data); 
  }

  onCancelClick(): void {
    this.data.rezult = true;
    this.data.bedType = null;
    this.data.roomGender = "0";
    this.data.floor = "0";
    this.data.displayRows = "1";
    this.data.roomBedState = "0";
    this.data.bedInRoomsCount = "0";
    this.data.patientId = null;
    this.data.patientName = "";
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.rezult = false;
    this.dialogRef.close(this.data);
  }

}

