import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import $ from 'jquery';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Patient } from '../../models/Patient';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Config } from '../../Config';
import { EhealthService } from '../EhealthService';
import { Obj } from '@popperjs/core';
import { serviceDispense } from 'app/models/Ehealth/serviceDispense';
import { PatientToSequenceData, PatientToQueueData, PatientSyncData, PatientEditData } from 'app/modalWindows/PatientSearch/PatientSearchDialog';
import { MatDialog } from '@angular/material/dialog';
import { PatientToSequenceDialog } from 'app/modalWindows/PatientToSequence/PatientToSequenceDialog';
import { addToQueueDialog } from 'app/modalWindows/EHealth/addToQueue/addToQueueDialog';
import { syncPatientDialog } from 'app/modalWindows/EHealth/syncPatient/syncPatientDialog';
import { DatePipe } from '@angular/common';
import { patient } from 'app/models/Ehealth/patient';
import { PatientToSequenceCreateDialog } from 'app/modalWindows/PatientToSequenceCreate/PatientToSequenceCreateDialog';
import { DataService } from 'app/calendar/calendar.service';
import { EHServiceDispense } from 'app/models/Ehealth/EHServiceDispense';
import { EHFrameData } from 'app/modalWindows/ResidenceCreate/ResidenceCreateDialog';
import { EHFrameDialog } from 'app/modalWindows/EHFrame/EHFrameDialog';
import { EHPatient } from 'app/models/Ehealth/EHPatient';
import { EHPerson } from 'app/models/Ehealth/EHPerson';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'app-eHealthView',
  templateUrl: './eHealthView.component.html',
  styleUrls: ['./eHealthView.component.css']
})
export class EHealthViewComponent {

  @Input() patient: Patient;

  patients: patient[];

  @Output() voted = new EventEmitter<boolean>();
  guid: string;
  startURL: string;
  url: string;

  dispence: EHServiceDispense;

  patientName: string;
  patientBirthDate: Date;
  patientIPN: string


  constructor(private router: Router, _config: Config, private service: EhealthService, private lservice:LoginService, private dataService: DataService, public dialog: MatDialog,
    private datePipe: DatePipe) {
    this.guid = _config.get("EhealthInstalationGuid");
    this.startURL = _config.get("EHPortalURL");
    this.url = _config.get("EHPortalApiURL");

  }

  ngOnInit() {
    this.loadData();
    this.checkIdentifiedOrNoInQeue();
  }

  loadData() {
    var _that = this;
    if (this.patient != null && this.patient.ehPatientRow != null && this.patient.ehPatientRow.ehPatientGuid != null)
      this.service.getEHServiceDispence(this.patient.patientID).subscribe((data: EHServiceDispense) => {
        _that.dispence = data;
      });

    if (this.patient != null) {
      this.patientName = this.patient.patientName;
      this.patientBirthDate = this.patient.patientBirthDate;
      this.patientIPN = this.patient.patientTaxIdentifier;
    }
  }


  getDispenceNumber() {
    if (!this.dispence || this.dispence == null) return "";
    return "Номер направлення: " + this.dispence.ehServiceDispenseNumber;
  }

  gotoEHEalthDispense() {
    let EHUserlogin: string = encodeURIComponent(this.lservice.UserToken.userEhealthLogin);
    let EHtoken: string = this.lservice.UserToken.userEhealthToken;

    let frData: EHFrameData = new EHFrameData();
    frData.url = this.startURL + "#/?action=spzIBWebServiceRequestProcessCreateOrEdit"
      + "&id=" + this.dispence.ehServiceDispenseProcessID
      + "&idName=ServiceRequestProcessId"
      + "&id1=" + this.dispence.ehServiceRequestGuid
      + "&id1Name=ServiceRequestId"
      + "&token=" + EHtoken
      + "&username=" + EHUserlogin;

    this.openFrameDialog(frData).subscribe(result => {
      if (result.rezult) {

      }
      else {
      }

    });
  }

  gotoEHEalth() {

    let EHUserlogin: string = encodeURIComponent(this.lservice.UserToken.userEhealthLogin);
    let EHtoken: string = this.lservice.UserToken.userEhealthToken;

    let frData: EHFrameData = new EHFrameData();
    frData.url = this.startURL + "#/?action=spzIBWebPatientGeneralList"
      + "&id=" + this.patient.ehPatientRow.ehPatientGuid
      + "&idName=PatientRef"
      + "&token=" + EHtoken
      + "&username=" + EHUserlogin;


    this.openFrameDialog(frData).subscribe(result => {
      if (result.rezult) {

      }
      else {
      }

    });
  }

  async search() {
    let birth: string = this.datePipe.transform(this.patientBirthDate, "yyyy-MM-dd");

    await this.service.searchEhealthByPredicate(this.patientName, birth, this.patientIPN, this.guid, this.url, this.dialog).then((rez: any) => {
      if (rez.errorMessage) {
        this.patients = null;
      }
      else if (rez.patient) {
        this.patients = rez.patient;
        for (var pat of this.patients) {

          let patientTemp: Patient = new Patient(0);
          patientTemp.ehPatientGuid = pat.patientGuid;
          patientTemp.patientName = pat.patientLastName + " " + pat.patientFirstName + " " + pat.patientSecondName;

          this.dataService.getPatientByEhealth(patientTemp).subscribe((p: Patient[]) => {
            pat.Patients = p.filter(r => r.ehPatientGuid == pat.patientGuid);
          });
        }

      }
    });
  }


  merge(p: patient) {
    let ehPat: EHPatient = new EHPatient();

    ehPat.ehPatientGuid = p.patientGuid;
    ehPat.patientRef = this.patient.patientID;
    ehPat.patientName = this.patient.patientName;
    ehPat.ehPatientBirthDate = this.patient.patientBirthDate;
    ehPat.ehPatientTaxId = this.patient.patientTaxIdentifier;

    this.service.createEHPatient(ehPat).subscribe((ep: EHPatient) => {
      ehPat = ep;

      let ehServDisp: EHServiceDispense = new EHServiceDispense();
      ehServDisp.ehPatientRef = ehPat.ehPatientID;

      this.search();

    });
  }

  createIdentfiedOrNo(identified: boolean) {
    //1 create EH from DB
    let ehPat: EHPatient;
    if (this.patient.ehPatientRow != null && this.patient.ehPatientGuid == null)
    {
      ehPat = this.patient.ehPatientRow;
      ehPat.isPrePerson = !identified;
      this.createOrGetEHPerson(ehPat);
    }
    else
    {
      ehPat = new EHPatient();
      ehPat.ehPatientGuid = null;
      ehPat.patientRef = this.patient.patientID;
      ehPat.patientName = this.patient.patientName;
      ehPat.ehPatientTaxId = this.patient.patientTaxIdentifier;
      ehPat.ehPatientBirthDate = this.patient.patientBirthDate;
      ehPat.isPrePerson = !identified;

      this.service.createEHPatient(ehPat).subscribe((ep: EHPatient) => {
        //ep.isPrePerson = !identified;
        ehPat = ep;
        this.createOrGetEHPerson(ep);
      });
    }


  }

  createOrGetEHPerson(ep: EHPatient) {

    //// find existed person !!!!!!!!!!!!!!!!!!!!!!!! dontNow if needed

    this.service.createEHPerson(ep).subscribe((eper: EHPerson) => {
      this.ehPerson = eper;
      this.saveAndOpenURL(ep, eper);
    });
  }

  async saveAndOpenURL(ep: EHPatient, eper: EHPerson) {
    //url
    let birth: string = this.datePipe.transform(this.patient.patientBirthDate, "yyyy-MM-dd");

    await this.service.savePerson(this.guid, eper, ep, this.url, birth, this.patient, this.dialog).then((rez: any) => {
      console.log(rez);

      let frData: EHFrameData = new EHFrameData();
      let EHUserlogin: string = encodeURIComponent(this.lservice.UserToken.userEhealthLogin);
      let EHtoken: string = this.lservice.UserToken.userEhealthToken;

      //!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      let url1: string = "#/?action=spdeWebPersonCreateOrEdit";
      if (ep.isPrePerson == true) {
        url1 = "#/?action=spdeWebPrePersonCreateOrEdit";
      }

      frData.url = this.startURL + url1
        + "&id=" + eper.ehPersonImmutableGuid
        + "&idName=PersonImmutableID"
        + "&token=" + EHtoken
        + "&username=" + EHUserlogin;
      this.openFrameDialog(frData).subscribe(result => {

          this.getPersonFromEH(eper, ep);
        
    
      });

    });
  }

  editPersonURL() {
    if (this.personFromEH) {
      let frData: EHFrameData = new EHFrameData();
      let EHUserlogin: string = encodeURIComponent(this.lservice.UserToken.userEhealthLogin);
      let EHtoken: string = this.lservice.UserToken.userEhealthToken;

      let url1: string = "#/?action=spdeWebPersonCreateOrEdit";
      if (this.personFromEH.isPrePerson == true) {
        url1 = "#/?action=spdeWebPrePersonCreateOrEdit";
      }

      frData.url = this.startURL + url1
        + "&id=" + this.personFromEH.immutable_id
        + "&idName=PersonImmutableID"
        + "&token=" + EHtoken
        + "&username=" + EHUserlogin;
      this.openFrameDialog(frData).subscribe(result => {

        this.getPersonFromEH(this.ehPerson, this.patient.ehPatientRow);


      });
    }
  }

  personFromEH: patient;

  getPersonStatusTip() {
    if (!this.personFromEH.isPrePerson)
    {
      if (!this.personFromEH.status || this.personFromEH.status == null || this.personFromEH.status == "") return "Невідомий";
      else if (this.personFromEH.status.toLowerCase() == "signed") return "Підтверджений";
      else if (this.personFromEH.status.toLowerCase() == "approved") return "Підтверджений";
      else if (this.personFromEH.status.toLowerCase() == "expired") return "Прострочено";
      else if (this.personFromEH.status.toLowerCase() == "new") return "Нова заявка";
      else if (this.personFromEH.status.toLowerCase() == "dismissed") return "Звільнений";
      else if (this.personFromEH.status.toLowerCase() == "rejected") return "Відхилений";
      else return "Невідомий";
    }
    else
    {
      if (!this.personFromEH.status || this.personFromEH.status == null || this.personFromEH.status == "") return "Без статусу";
      else if (this.personFromEH.status.toLowerCase() == "active") return "Активний";
      else if (this.personFromEH.status.toLowerCase() == "inactive") return "Неактивний";
      else return "Без статусу";
    }
  }

  getPersonOrderText() {
    if (this.personFromEH.isPrePerson) return "Заявка на неідентифікованого пацієнта";
    else return "Заявка на ідентифікованого пацієнта";
  }

  getPersonOrderStatusIcon() {
    if (!this.personFromEH.status || this.personFromEH.status == null || this.personFromEH.status == "")
      return "url(../../assets/images/NoStatus.png)";
    else if (this.personFromEH.status.toLowerCase() == "signed" || this.personFromEH.status == "active")
      return "url(../../assets/images/SignedStatus.png)";
    else if (this.personFromEH.status.toLowerCase() == "approved" || this.personFromEH.status == "new")
      return "url(../../assets/images/NEWStatus.png)";
    else if (this.personFromEH.status.toLowerCase() == "expired" || this.personFromEH.status == "dismissed" ||
      this.personFromEH.status.toLowerCase() == "rejected" || this.personFromEH.status == "inactive")
      return "url(../../assets/images/RejectStatus.png)";
  }

  checkIdentifiedOrNoInQeue() {
    if (this.patient.ehPatientRow != null && this.patient.ehPatientRow.ehPatientGuid == null) {
      //1 get person if exist


      this.service.getEHPerson(this.patient.ehPatientRow).subscribe((ep: EHPerson) => {
        if (ep) {
          this.ehPerson = ep;
          this.getPersonFromEH(ep, this.patient.ehPatientRow);
        }
      });
    }
  }

  ehPerson: EHPerson;

  //call this method on load if find preperson byEHPatientRef with null guid
  async getPersonFromEH(eper: EHPerson, epat: EHPatient) {
    // Postman get person

    await this.service.personDetails(this.guid, eper, this.url, this.dialog).then((ep: any) => {
      this.personFromEH = ep;

      if (ep && eper.isPrePerson == 1) {
        this.personFromEH.isPrePerson = true;
      } else if (ep) {
        this.personFromEH.isPrePerson = false;
      }

      if (ep && ep.status && ep.status != null) {
        if (ep.status == "SIGNED") {
            
          epat.ehPatientGuid = ep.patientGuid;
          this.service.updateEHPatient(epat).subscribe((ehp: EHPatient) => {
            this.patient.ehPatientRow = ehp;
            this.loadData();
          });
        } else {
          //show info about status automatically by personFromEH
        }
      }

    });
  }
  //onload if no EHPatient(without Guid) Get EhPErson from DB, then from portal, shows status if signed update 


  getPName(p: patient) {
    return p.patientLastName + " " + p.patientFirstName + " " + p.patientSecondName;
  }

  getPAdress(p: patient) {
    return p.patientCountry + " " + p.patientSettlement;
  }

  openFrameDialog(d: EHFrameData): Observable<EHFrameData> {

    const dialogRef = this.dialog.open(EHFrameDialog, {

      data: d,
      height: '95%',
      width: '95%',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'my-panel'
    });
    return dialogRef.afterClosed();
  }

}
