import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { Patient } from '../models/Patient';
import { Sequence } from '../models/Sequence';
import { Venue } from '../models/Venue';
import { DepartmentData } from '../models/DepartmentData';
import { RoomData } from '../models/RoomData';
import { DatePipe } from '@angular/common';
import { BedData } from '../models/BedData';
import { Account } from '../models/Account';
import { Dictionary } from '../models/Dictionary';
import { DropBedData } from '../models/DropBedData';
import { Residence } from '../models/Residence';
import { ResidenceCategory } from '../models/ResidenceCategory';
import { AuthGuard } from '../auth/auth.guard';
import { User } from 'app/models/User';
import { UserObjectHistory } from 'app/models/UserObjectHistory';
import dragula from 'dragula';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { JournalData } from 'app/models/JournalData';
import { Document } from '../models/Document';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { TariffPlan } from 'app/models/TariffPlan';

export class DrakeOBJ {
  el: HTMLElement;
  target: HTMLElement;
}

@Injectable()
export class DataService {
  constructor(private http: HttpClient, private authGuard: AuthGuard,private lservice:LoginService) { }


  public selectedSeqDepartment: Sequence;

  public patients: BedData[];
  public venuePatients: BedData[];
  public departmentData: DepartmentData = new DepartmentData([]);

  public roomsCount: number = 0;
  public bedsCount: number = 0;
  public freeBedsCount: number = 0;
  public criticalPatientsCount: number = 0;
  public criticalPatientsInDepCount: number = 0;

  drake: dragula.Drake;
  timelineThirdParty: ThirdPartyDraggable[] = [];

  searchPatientText: string = "";

  //.patientBox must be single everywhere; else need to change logic
  addDrakeContainer(containerName: string, byClass:boolean = false, itemSelector: string = ".patientBox"/*.patientBox*/) {
    if (byClass) { // logic from day: ThirdPartyDraggable no need
      let containers = <HTMLElement[]><any>document.getElementsByClassName(containerName);
      if (containers && containers.length > 0) {
        for (var i = 0; i < containers.length; i++) {
          {
            if (!this.drake) {
              this.initDragula(containers[i]);
              //drake.cancel(revert)
            }
            else
            {
              if (this.drake.containers.find(r => r == containers[i])) {
                this.drake.containers = this.drake.containers.filter(f => f != containers[i]);
                //console.log("filter " + containers[i].id);
              }
              this.drake.containers.push(containers[i]);
              //console.log("push " + containers[i].id);
            }
          }
        }
      }
    }
    else if (!byClass) { // logic for timeline: ThirdPartyDraggable
      let container = document.getElementById(containerName);
      if (container != null /*&& container.children && container.children.length > 0*/) {
        /*if (this.drake) {
          this.drake.destroy();
        } else {}
        */
        if (!this.drake) {
          this.initDragula(container);
          //drake.cancel(revert)
        }
        else {
          if (this.drake.containers.find(r => r == container)) {
            this.drake.containers = this.drake.containers.filter(f => f != container);
            //console.log("filterDIV "+container.id);
          }
          //console.log("pushDIV " + container.id);
          this.drake.containers.push(container);
        }

        this.timelineThirdParty.forEach(r => { r.dragging.destroy(); r.destroy() });
        this.timelineThirdParty = [];


        this.drake.containers.forEach(r => {
          let thirdParty = new ThirdPartyDraggable(r, {
            itemSelector: itemSelector,
            mirrorSelector: '.gu-mirror',
            eventData: function (eventEl) {
              return {
                id: "999999",
                title: eventEl.innerText
              }
            }
          });
          this.timelineThirdParty.push(thirdParty);
        });
      }
    }
  }

  initDragula(container: HTMLElement) {
    var _that = this;
    this.drake = dragula({
      containers: [container],
      copy: false,
      revertOnSpill: true,
      
      delay: 2000,
      accepts: function (el, target, source, siblings)
      {
        //console.log(target.id);
        if (target.id == "bedContainer" && (<HTMLElement>target).title == "true") return true;
        if (target.id == "sequencePatientList") return true;
        return false;
        //return target !== document.getElementById("patientsList");
      },
      moves: function (el, container, handle) {
        //if (handle.className === 'bedElement') return true;
        if (el.id == "bedHeader") return false;
        if ((<HTMLElement>el).title == "true") return false;
        return true;
      },
    }).on('drop', function (el, target) {
      //this.drake.cancel(true);
      _that.callExtComponentToDrop(el, target)
      target.removeChild(el);

      //el.dataset.drakeFrom
    }).on('drag', function (el) {
    })

  }

  callExtComponentToDrop(el: HTMLElement, target: HTMLElement) {
    let obj: DrakeOBJ = new DrakeOBJ();
    obj.el = el;
    obj.target = target;

    this.componentMethodCallFromDrake.next(obj);
  }

  getHeaders()
  {
    let token: string = this.lservice.UserToken.userToken;
    let userlogin: string = this.lservice.UserToken.userLogin;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('charset', 'UTF-8')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer ' + token)
      .set('userlogin', userlogin);
    return headers;
  }

  getPatient(): Observable<Patient>
  {
    return this.GetIdentity<Patient>("/api/Patient/Get/5");
  }

  getSequences(type: string): Observable<Sequence[]>
  {
    let CompanyGUID: string = null;
    if (this.lservice.SelectedCompany != null)
      CompanyGUID = this.lservice.SelectedCompany.CompanyGUID;

    return this.GetIdentity<Sequence[]>("/api/sequence/GetSecuences/" + type + '/' + CompanyGUID);
  }

  getPatientAccounts(patientRef: number): Observable<Account[]>
  {
    return this.GetIdentity<Account[]>("/api/account/GetPatientAccounts/" + patientRef);
  }

  getTariffPlans(accountTypeRef: number): Observable<TariffPlan[]> {
    return this.GetIdentity<TariffPlan[]>("/api/account/GetTariffPlans/" + accountTypeRef);
  }

  getVenues(): Observable<Venue[]> {
    return this.GetIdentity<Venue[]>("/api/venue");
  }

  getPatientsBySequence(id: number): Observable<BedData[]>
  {
    return this.GetIdentity<BedData[]>("/api/DepartmentData/GetPatientsBySequence/" + id);
  }

  getVenuePatientsBySequence(id: string): Observable<BedData[]>
  {
    return this.GetIdentity<BedData[]>("/api/DepartmentData/GetVenuePatientsBySequence/" + id);
  }

  getDepartmentDataBySequence(id: string, date: string, endDate: string, timeline:boolean): Observable<DepartmentData>
  {
    return this.GetIdentity<DepartmentData>("/api/DepartmentData/GetDepartmentData/" + id + "/" + date + "/" + endDate + "/"+ timeline);
  }

  getBedTypes(): Observable<Dictionary[]>
  {
    return this.GetIdentity<Dictionary[]>("/api/dictionary/GetBedTypes");
  }

  getResidenceCategories(): Observable<ResidenceCategory[]>
  {
    return this.GetIdentity<ResidenceCategory[]>("/api/residence/GetResidenceCategories");
  }

  getPatientsByFilter(text: string): Observable<Patient[]>
  {
    return this.GetIdentity<Patient[]>("/api/patient/GetPatientsByFilter/" + text);
  }

  getJournalData(dateFrom: string, dateTo: string, journalType: string): Observable<JournalData[]> {
    let CompanyGUID: string = null;
    if (this.lservice.SelectedCompany != null)
      CompanyGUID = this.lservice.SelectedCompany.CompanyGUID;

    return this.GetIdentity<JournalData[]>("/api/values/GetJournalData/" + dateFrom + '/' + dateTo + '/' + journalType + '/' + CompanyGUID);
  }

  getStorage(storageName: string): Observable<number> {
    return this.GetIdentity<number>("/api/values/GetStorage/" + storageName);
  }

  setStorage(storageName: string, value: number): Observable<number> {
    return this.GetIdentity<number>("/api/values/SetStorage/" + storageName + '/' + value);
  }

  getUserObjectHistory(startDate: string, endDate: string): Observable<UserObjectHistory[]> {
    let CompanyGUID: string = null;
    if (this.lservice.SelectedCompany != null)
      CompanyGUID = this.lservice.SelectedCompany.CompanyGUID;

    return this.GetIdentity<UserObjectHistory[]>("/api/values/GetUserObjectHistory/" + startDate + '/' + endDate );
  }

  GetIdentity<T>(route: string): Observable<T>
  {
    return this.http.get<T>(route, { headers: this.getHeaders() }).pipe(
      map((response: T) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  getdocuments(patientRef: number, courseRef: number): Observable<Document[]> {
    let route: string;
    route = "/api/Document/GetDocuments/" + patientRef + '/' + courseRef;

    return this.http.get<Document[]>(route, { headers: this.getHeaders() }).pipe(
      map((response: Document[]) => { return response }),
      catchError(err => { return this.authGuard.handleError(err); }));
  }

  //POST need subscription
  //
  //


  updateDocumentJson(body: Document): Observable<Document> {
    let route: string;
    route = "/api/Document/SetDocumentJson";

    return this.http.post<Document>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Document) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  updateresidence(body: Residence): Observable<Residence>
  {
    let route: string;
    route = "/api/residence/UpdateResidence";

    return this.http.post<Residence>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Residence) => { return response }),
      catchError(err =>
      {
        return this.authGuard.handleError(err);
      }));
  }

  updatePatient(body: Patient): Observable<Patient> {
    let route: string;
    route = "/api/patient/UpdatePatient";

    return this.http.post<Patient>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Patient) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  addPatient(body: Patient): Observable<Patient> {
    let route: string;
    route = "/api/patient/AddPatient";

    return this.http.post<Patient>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Patient) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  getPatientByEhealth(body: Patient): Observable<Patient[]> {
    let route: string;
    route = "/api/patient/GetPatientByEhealth";

    return this.http.post<Patient[]>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: Patient[]) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  getUser(userLogin: string): Observable<User> {
    let route: string;
    route = "/api/User/GetUser";

    let body: User = new User(userLogin);

    return this.http.post<User>(route, body, { headers: this.getHeaders() }).pipe(
      map((response: User) => { return response }),
      catchError(err => {
        return this.authGuard.handleError(err);
      }));
  }

  initVenueStat() {

    if (!this.departmentData || !this.departmentData.rooms) return;

    this.roomsCount = this.departmentData.rooms.length;

    this.bedsCount = 0;
    this.departmentData.rooms.forEach(r => this.bedsCount += r.beds.length);

    this.freeBedsCount = 0;
    this.departmentData.rooms.forEach(r => this.freeBedsCount += r.beds.filter(f => f.allowDrop == true).length);

    this.criticalPatientsCount = 0;

    this.departmentData.rooms.forEach(r => this.criticalPatientsCount += r.beds
      .filter(f => f.allowDrop == false && f.residence != null && f.residence.residenceCategoryRow != null && f.residence.residenceCategoryRow.residenceCategorySymbol == "C").length);

    if (!this.venuePatients) return;
    this.venuePatients.forEach(r => r.allowDrop = r.residence.residenceStatusRef == "PLN");

    this.criticalPatientsInDepCount = 0;
    this.criticalPatientsInDepCount = this.venuePatients.filter(f =>  f.residence != null
      && f.residence.residenceStatusRef == "PLN"
      && f.residence.residenceCategoryRow != null && f.residence.residenceCategoryRow.residenceCategorySymbol == "C").length;

    this.criticalPatientsCount += this.criticalPatientsInDepCount;
    
  }

  //////////////////
  //subscribtions


  // Observable string sources
  private componentMethodCallFromDrake = new Subject<DrakeOBJ>();
  // Observable string streams
  componentMethodFromDrakeCalled$ = this.componentMethodCallFromDrake.asObservable();


  //calendar to dayComponent:

  private sequenceSbj = new Subject<number>();
  // call onse for day component subscribtion
  sequenceChanged() { return this.sequenceSbj.asObservable(); }
  // call from calendar
  sequenceChange(id: number) { this.sequenceSbj.next(id); }

  private vaitinglistSbj = new Subject<number>();
  // call onse for day component subscribtion
  vaitinglistChanged() { return this.vaitinglistSbj.asObservable(); }
  // call from calendar
  vaitinglistChange(id: number) { this.vaitinglistSbj.next(id); }

  private bedToDepSubj = new Subject<DropBedData>();
  // call onse for day component subscribtion
  bedToDepChanged() { return this.bedToDepSubj.asObservable(); }
  // call from calendar
  bedToDepChange(id: DropBedData) { this.bedToDepSubj.next(id); }


  //dayComponent to calendar:

  private venuePatientSbj = new Subject<DropBedData>();//
  // call onse for calendar component subscribtion
  venuePatientChanged() { return this.venuePatientSbj.asObservable(); }
  // call from day
  venuePatientChange(b: DropBedData) { this.venuePatientSbj.next(b); }


  private updateSequencesSbj = new Subject();//
  // call onse for calendar component subscribtion
  sequencesChanged() { return this.updateSequencesSbj.asObservable(); }
  // call from day
  sequencesChange() { this.updateSequencesSbj.next(); }

  //////////////////

  //calendar to timeline

  private updateVenuesSbj = new Subject();//
  // call onse for calendar component subscribtion
  updatedVenues() { return this.updateVenuesSbj.asObservable(); }
  // call from day
  updateVenues() { this.updateVenuesSbj.next(); }

  private updateSequencesFromTimelineSbj= new Subject();//
  // call onse for calendar component subscribtion
  updatedSequences() { return this.updateSequencesFromTimelineSbj.asObservable(); }
  // call from day
  async updateSequences() { this.updateSequencesFromTimelineSbj.next(); }


  bed: BedData;

  private getBedSbj = new Subject<number>();//
  // call onse for calendar component subscribtion
  getBedSubscribe() { return this.getBedSbj.asObservable(); }
  // call from day
  getBed(id: number) { this.getBedSbj.next(id); }

  private updateTimelineSbj = new Subject();//
  // call onse for calendar component subscribtion
  updatedTimeline() { return this.updateTimelineSbj.asObservable(); }
  // call from day
  updateTimeline() { this.updateTimelineSbj.next(); }



  /*private dragStartSbj = new Subject();
  // call onse for day component subscribtion
  dragStarted() { return this.dragStartSbj.asObservable(); }
  // call from calendar
  dragStart() { this.dragStartSbj.next(); }


  private dragEndSbj = new Subject();
  // call onse for day component subscribtion
  dragEnded() { return this.dragEndSbj.asObservable(); }
  // call from calendar
  dragEnd() { this.dragEndSbj.next(); }*/

}
