import { OnInit, Component, ChangeDetectorRef, NgZone, PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { Subject, Subscription, Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { responsiveService } from "../../responsiveService";
import { DatePipe } from '@angular/common';
import { LoginService } from 'app/modalWindows/Login/LoginService';
import { Config } from 'app/Config';
import { Sequence } from 'app/models/Sequence';
import { DoctorService } from 'app/doctor/doctor.service';
import { BedData } from 'app/models/BedData';
import { Residence } from 'app/models/Residence';
import { Venue } from 'app/models/Venue';
import { ResidenceCategory } from 'app/models/ResidenceCategory';
import { DataService } from 'app/calendar/calendar.service';
import { Course } from 'app/models/Course';
import { Dictionary } from '@fullcalendar/core';
import { ResidenceCreateService } from 'app/modalWindows/ResidenceCreate/ResidenceCreateService';
import { Patient } from 'app/models/Patient';
import { NurseService } from '../nurse.service';
import { Booking } from 'app/models/Booking';
import { BookingDetailsDialog } from 'app/modalWindows/Nurse/BookingDetails/BookingDetailsDialog';

@Pipe({
    name: 'filterUnique',
    pure: false
})
export class FilterUniquePipe implements PipeTransform {

    transform(value: any, key: string): any {

        // Remove the duplicate elements
        let uniqueArray = value.filter((thing, i, arr) => arr.findIndex(t => t[key] != null && t[key] === thing[key]) === i);

        return uniqueArray;
    }
}

export class BookingDetailsData {
    rezult: boolean;
    booking: Booking;
    newStatus: string;
    comment: string;
    patient: Patient;
}


@Component({
    selector: 'app-nursePatients',
    templateUrl: './nursePatients.component.html',
    styleUrls: ['./nursePatients.component.css']
})

export class NursePatientsComponent implements OnInit {

    myOptionsColor = {
        'theme': 'light'
    }

    query2: string = "";

    isTablet: boolean;

    public selectedSequence: Sequence = null;
    public selectedPatient: BedData = null;
    public selectedCourse: Course = null;

    public venueSequences: Sequence[] = [];
    public venuePatients: BedData[] = [];

    public residenceCategories: ResidenceCategory[] = [];

    public users: Dictionary[];

    public bookings: Booking[] = [];
    public selectedBooking: Booking = null;

    public seqByCompanySub: Subscription;

    //booking type Filters:
    public selectedBookingTypeFilter: number = 0;
    // 0 - Всі послуги

    // 3 - Медикаменти
    // 1 - Забір матеріалів
    // 4 - Стаціонарні послуги
    // 2 - Прийом в кабінеті

    // 5 - Дієта
    // ...

    public selectedStatusTableFilter = "";

    public selectedBookingNameStatusFilter = "";

    bookingSearchText: string = "";
    executorSearchText: string = "";

    constructor(
        private responsiveService: responsiveService,
        private datePipe: DatePipe,
        private _config: Config,
        public dialog: MatDialog, private _ngZone: NgZone, private router: Router,
        private lservice: LoginService,
        private dService: DoctorService,
        private cService: DataService,
        private rService: ResidenceCreateService,
        private nService: NurseService) {
        this.seqByCompanySub = this.lservice.updateSeqByCompanyBind().subscribe(id => this.loadSequences());
    }

    ngOnInit() {
        this.onResize();
        this.responsiveService.checkWidth();

        var _that = this;

        this.cService.getResidenceCategories().subscribe((data: ResidenceCategory[]) => { _that.residenceCategories = data; });
        this.rService.getDictionary("Користувачі").subscribe((data: Dictionary[]) => { _that.users = data; });

        this.loadSequences();
    }

    onResize() {
        this.responsiveService.getTabletStatus().subscribe(isTablet => {
            this.isTablet = isTablet;
        });
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
    }

    async loadSequences() {
        var _that = this;

        this.venueSequences = await this.dService.getSequences("RCH").toPromise();

        if (this.venueSequences.length > 0) {

            let savedSeqID = await this.cService.getStorage("WebResidenceNurseSelectedVenueSeqDep").toPromise();

            if (savedSeqID && this.venueSequences.find(r => r.sequenceID == savedSeqID)) {
                this.sequenceVenueChanged(this.venueSequences.find(r => r.sequenceID == savedSeqID));//select from storage
            } else {
                this.sequenceVenueChanged(this.venueSequences[0]);//select first
            }
        }
    }

    loadPatients(seq: Sequence) {
        var _that = this;
        this.dService.getPatientsForDoctor2(seq.sequenceID.toString())
            .subscribe((data: BedData[]) => {
                _that.venuePatients = data;
                if (_that.venuePatients && _that.venuePatients.length > 0) {
                    this.selectPatient(_that.venuePatients[0]);
                } else {
                    this.selectPatient(null);
                }

            });
    }

    sequenceVenueChanged(seq: Sequence) {
        this.selectedSequence = seq;
        this.cService.setStorage("WebResidenceNurseSelectedVenueSeqDep", seq.sequenceID).subscribe(r => { });
        this.loadPatients(seq);
    }

    selectCourse(d: BedData) {
        if (d == null)
            this.selectCourse = null;
        else {
            var _that = this;
            this.dService.getcources(d.patient.patientID).subscribe((data: Course[]) => {
                _that.selectedCourse = data.find(c => c.courseID == d.residence.residenceCourseRef);
            });
        }
    }

    selectPatient(d: BedData) {
        this.selectedBookingNameStatusFilter = "";
        this.selectedBooking = null;
        this.selectedPatient = d;
        if (d != null) {
            var _that = this;
            this.selectCourse(d);
            let date = new Date();
            let dString: string = this.datePipe.transform(date, "yyyyMMdd");
      this.nService.getBookingsByCourse(d.residence.residenceCourseRef, dString).subscribe((data: Booking[]) =>
      {
                _that.bookings = data;
            });
        }
    }

    clearBookingFilters() {
        this.selectedBookingTypeFilter = 0;
    }

    selectedBookingTypeFilterClick(filterNumber: number) {
        this.selectedBookingTypeFilter = filterNumber;
    }

    filterBookingRow(booking: Booking) {
        if (this.selectedBookingTypeFilter != 0 && this.selectedBookingTypeFilter != booking.bookKind) return false;
        else if (this.selectedStatusTableFilter != "" && this.selectedStatusTableFilter != booking.bookingStatusRef) return false;
        else if (this.selectedBookingNameStatusFilter != "" && this.selectedBookingNameStatusFilter != booking.bookingName) return false;
        else return true;
    }

  editBooking(b: Booking) {;
        this.selectedBooking = b;

        this.openDialogBookingDetails(b).subscribe(result => {
            if (result.rezult) {
                b.bookingStatusRef = result.newStatus;
                b.bookingDesc = result.comment;

                this.dService.updateBooking(b).subscribe((rez => {

                    //save to db and after that
                    b.bookingStatusRef = rez.bookingStatusRef;
                    b.bookingStatusName = rez.bookingStatusName;
                    b.bookingDesc = rez.bookingDesc;
                    b.userName = b.username = rez.username;
                }));
            }
        });
    }

    openDialogBookingDetails(b: Booking): Observable<BookingDetailsData> {
        let d: BookingDetailsData = new BookingDetailsData();
        d.booking = b;
        d.patient = this.selectedPatient.patient;

        const dialogRef = this.dialog.open(BookingDetailsDialog, {
            width: '400px',
            data: d,
            height: '100%',
            hasBackdrop: true,
            disableClose: true,
            position: { right: '0px' },
            panelClass: 'my-panelLeft'
        });

        return dialogRef.beforeClosed();
    }

    getColorForSelectedStatus() {
        if (this.selectedStatusTableFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    getColorForSelectedBookName() {
        if (this.selectedBookingNameStatusFilter != "") return "#3361CF";
        else return "#0D1014";
    }

    formatTime(date: Date) {
        if (date) {
            return this.datePipe.transform(date, "HH:mm")
        }
        return "";
    }

    getBookFilterTypeColor(filterNumber: number, color: string) {
        if (this.selectedBookingTypeFilter != filterNumber) return 'white';
        else return color;
    }

    getBookFilterTypeColorText(filterNumber: number) {
        if (this.selectedBookingTypeFilter != filterNumber) return '#0D1014';
        else return 'white';
    }

    getBookKindText(id: number) {
        switch (id) {
            case 1:
                return "Забір матеріалів";
            case 2:
                return "Прийом в кабінеті";
            case 3:
                return "Медикаменти";
            case 4:
                return "Стаціонарні послуги";
            case 5:
                return "Дієта";
            default:
                return id.toString();
        }
    }

    getStatusText(status: string) {
        switch (status) {
            case "CMP":
                return "Виконані";
            case "CNC":
                return "Скасовані";
            case "PLN":
                return "Заплановані";
            default:
                return "";
        }
    }

    getResidenceInfo() {
        let room: string = "";
        let bed: string = "";
        let seq: string = "";
        if (this.selectedPatient.bed.room != null) room = this.selectedPatient.bed.room;
        if (this.selectedPatient.bed.venueName != null) bed = this.selectedPatient.bed.venueName;
        if (this.selectedSequence != null) seq = this.selectedSequence.sequenceName;

        return seq + ', ' + room + ', ' + bed;
    }

    getDiagnoz(r: Residence) {
        if (r.residenceDiagnosis1 == null && r.residenceDiagnosis1Name == null) return "Діагноз не встановлений";
        let code = ""; if (r.residenceDiagnosis1 != null) code = r.residenceDiagnosis1;
        let name = ""; if (r.residenceDiagnosis1Name != null) name = r.residenceDiagnosis1Name;
        return code + " | " + name;
    }

    getDiadnozColor(r: Residence) {
        if (r.residenceDiagnosis1 == null && r.residenceDiagnosis1Name == null) return "#818795";
        else return "#47505A"
    }

    getVenueInfo(v: Venue) {
        // return v.room + " | " + v.venueName + " | " + v.sequenceName;
        //else if (this.selectedRole == 2)
        return v.room + " | " + v.venueName;
    }

    getCategoryColor(bed: BedData, displayEmpty: boolean = false) {
        let emptycolor: string = "white";
        if (displayEmpty) emptycolor = "#818795";

        if (bed.residence == null) return emptycolor;


        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return emptycolor;
        }

        let cat: ResidenceCategory;

        if (this.residenceCategories != null)
            cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.color;
        }
    }

    getCategoryColorText(bed: BedData, displayEmpty: boolean = false) {
        let emptyText: string = "";
        if (displayEmpty) emptyText = "не встановлено";

        if (bed.residence == null) return emptyText;


        if (bed.residence.residenceCategory == null || bed.residence.residenceCategory == 0) {
            return emptyText
        }

        let cat: ResidenceCategory;

        if (this.residenceCategories != null)
            cat = this.residenceCategories.find(r => r.residenceCategoryID == bed.residence.residenceCategory);

        if (cat != null) {
            return cat.residenceCategoryName;
        }
    }

    getPhoto() {
        if (this.selectedPatient) {
            if (this.selectedPatient.patient.patientSexRef == "MAL") return "url(../../../assets/images/male.svg)";
            else if (this.selectedPatient.patient.patientSexRef == "FEM") return "url(../../../assets/images/female.svg)";
            else
                return "url(../../../assets/images/unknown.svg)";
        }
    }

    getUser() {
        if (!this.users || this.selectedPatient.residence.userRef == "") return "";
        let user = this.users.find(r => r.dictionaryCode == this.selectedPatient.residence.userRef);
        if (user != null) return user.dictionaryName;
        return "";
    }

    getPatientHeaderText() {
        if (this.selectedPatient == null) return "";
        if (this.selectedPatient.patient.patientAge == null) return this.selectedPatient.patient.patientName;
        return this.selectedPatient.patient.patientName + ", " + this.selectedPatient.patient.patientAge + "р.";
    }

    getCourseInfo(c: Course) {
        if (c == null) return "";

        let start = "";
        let end = "";
        let status = "";
        let diagnoz = "";
        if (c.courseStartDate != null)
            start = this.datePipe.transform(c.courseStartDate, "dd.MM.yyyy");
        if (c.courseEndDate != null)
            end = this.datePipe.transform(c.courseEndDate, "dd.MM.yyyy");
        let dateRange = start + "; ";
        if (c.courseEndDate) dateRange = end + " - " + start + "; ";

        if (c.courseStatusRef == "OPN") status = "Відкритий; ";
        else if (c.courseStatusRef == "CLS") status = "Закритий; ";
        else if (c.courseStatusRef == "HZD") status = "Госпіталізований; ";

        if (c.courseDiagnosis1 != null)
            diagnoz = c.courseDiagnosis1;

        return dateRange + status + diagnoz;
    }

    collapsed: boolean = false;
    collapsePat() {
        this.collapsed = true;
    }

    expandPat() {
        this.collapsed = false;
    }

    getMainWidth() {
        if (this.collapsed) {
            return "calc(100% - 76px)";
        } else {
            return "calc(100% - 336px)";
        }
    }
    getHEaderPatientWidth() {
        if (this.isTablet && this.collapsed == false) {
            return "calc(100% - 1px)";
        } else {
            return "calc(100% - 160px)";
        }
    }

    headerCollapsed: boolean = false;
    collapsHeader() { this.headerCollapsed = true; }
    expandHeader() { this.headerCollapsed = false; }

    getMainContentHeight() {
        if (this.headerCollapsed) return " calc(100% - 88px)"; else return " calc(100% - 253px)";
    }

    getPatientId() {
        if (this.selectedPatient == null) return null;
        return this.selectedPatient.patient.patientID;
    }

    getColorForBookingName(booking: Booking) {
        if (booking.bookingStatusRef == 'CMP') return "#3FA013";
        else return "#212529";
    }

    getColorForTime(booking: Booking) {
        var now = new Date();
        var bookingDate = new Date(booking.bookingDate);

        if (booking.bookingStatusRef == 'CMP') return "#3FA013";
        else if (now.getTime() > bookingDate.getTime()) return "#C60000";
        else return "#212529";
    }

    tableFilter(row: Booking) {
        let exist: boolean = true;

        const searchValueBookingName = this.bookingSearchText.toUpperCase();
        const searchValueExecutorName = this.executorSearchText.toUpperCase();

        if (this.bookingSearchText != "" && row != null) {
            if (row.bookingName == null || !(row.bookingName.toUpperCase().indexOf(searchValueBookingName) >= 0)) exist = false;
        }

        if (this.executorSearchText != "" && row != null) {
            if (row.userName == null || !(row.userName.toUpperCase().indexOf(searchValueExecutorName) >= 0)) exist = false;
        }

        return exist;
    }
}


