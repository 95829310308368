

export class EHPatient {

  constructor() { }

  public ehPatientID: number;
  public patientRef: number;
  public ehPatientGuid: string;

  public ehPatientFirstName: string;
  public ehPatientLastName: string;
  public ehPatientBirthDate: Date;
  public ehPatientDeclarationRequestGuid: string;
  public ehPatientDeclarationStatusCode: string;
  public ehPatientDeclarationDate: Date;
  public ehPatientLegalEntityGuid: string;
  public ehPatientSecondName: string;
  public ehPatientTaxId: string;
  public ehPatientEmployeeGuid: string;
  public ehPatientDivisionGuid: string;
  public ehPatientExternalDLC: Date;
  public ehPatientSettingXML: string;
  public ehDeclarationNumber: string;
  public ehEmployeeLogin: string;


  public patientName: string;
  public isPrePerson: boolean;
}
