import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosData } from '../ResidenceCreate/ResidenceCreateDialog';
import { Dictionary } from '../../models/Dictionary';
import $ from 'jquery';

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';


@Pipe({ name: 'search' })



export class searchPipe implements PipeTransform
{
  transform(values: any[], filter: string): any {
    if (!values || !values.length) return [];
    if (!filter || filter == null) return values;
    else
    {
      filter = filter.toUpperCase();
      if (filter && Array.isArray(values)) {
        const keys = Object.keys(values[0]);
        return values.filter(v => v && keys.some(k => v[k] != null && v[k].toUpperCase().indexOf(filter) >= 0));
      }
    }
  }
}

@Component({
  selector: 'app-modalWindows-DiagnosisSelect',
  templateUrl: './DiagnosisSelectDialog.html',
  styleUrls: ['./DiagnosisSelectDialog.css'],
  providers: [DecimalPipe]
})
export class DiagnosisSelectDialog {

  query: string = "";
  p: number = 1;
  diagnosis: Dictionary[];

  constructor(public dialogRef: MatDialogRef<DiagnosisSelectDialog>, @Inject(MAT_DIALOG_DATA) public data: DiagnosData)
  {
    this.data.rezult = true;

    this.diagnosis = data.diagnosis;
  }



  public selectDiagnos(event: any, item: any)
  {
    this.data.newDiagnos = item;
  }

  public doubleClick(item: any) {
    this.data.newDiagnos = item;
    this.data.rezult = true;
    this.dialogRef.close(this.data);
  }

  //to yes click write same event and call result = true; this.dialogRef.close(this.data);
  //if vLIDATION DONT CALL close(this.data);
  onNoClick(): void { this.data.rezult = false; this.dialogRef.close(this.data); }

}

