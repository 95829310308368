import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthGuard } from 'app/auth/auth.guard';


@Injectable()
export class Config {

  private _config: Object;

  constructor(private http: HttpClient, private authGuard: AuthGuard) {
  }

  load() {

    this.http.get('assets/env.json').toPromise().then((response: any) => {
      this._config = response;
    }).catch((response: any) => {
      console.log("error getting file");
    });

  }

  get(key: any) {
    return this._config[key];
  }
};
