import { Component, Inject } from '@angular/core';
import { Dictionary } from '../../../models/Dictionary';
import { ResidenceCategory } from '../../../models/ResidenceCategory';
import { ResidenceCreateService } from '../ResidenceCreateService';
import { DialogData } from '../../../calendar/Day/day.component';
import { Observable, Subscription } from 'rxjs';
import { BedData } from '../../../models/BedData';
import { DiagnosisSelectDialog } from '../../DiagnosisSelect/DiagnosisSelectDialog';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosData } from '../ResidenceCreateDialog';
import { Router } from '@angular/router';
import { Sequence } from '../../../models/Sequence';
import { LoginService } from 'app/modalWindows/Login/LoginService';

@Component({
  selector: 'residence-changeDep',
  templateUrl: './residence.changeDep.html',
  styleUrls: ['./residence.changeDep.css'],
})
export class ResidenceChangeDepartmentComponent {

  sequences: Sequence[];

  currentSequence: Sequence;

  selectedSequence: number = -1;

  subscribtion: Subscription;


  doctors: Dictionary[];
  diagnosis: Dictionary[];
  residenceCategories: ResidenceCategory[];

  users: Dictionary[];
  tempUser: string;


  constructor(private service: ResidenceCreateService,  private lservice: LoginService,private router: Router, public dialog: MatDialog)
  {

    this.service.residenceData.childRoute = "changeDep";


    //this.subscribtion = this.service.mainUpdated().subscribe(r => this.bindData());
    if (this.service.residenceData.openChangeSeq || this.service.residenceData.newSequence != null)
      this.selectedSequence = this.service.residenceData.newSequence;

    var _that = this;

    this.service.getDiagnozis().subscribe((data: Dictionary[]) => { _that.diagnosis = data; });
    this.service.getDoctors().subscribe((data: Dictionary[]) => { _that.doctors = data; });
    this.service.getResidenceCategories().subscribe((data: ResidenceCategory[]) => { _that.residenceCategories = data; });
    this.service.getDictionary("Користувачі").subscribe((data: Dictionary[]) => { _that.users = data; });

    this.tempUser = this.service.userRef;
    this.service.userRef = lservice.UserToken.userLogin;
  }

  navigateToMain() {
    this.service.userRef = this.tempUser;

    this.router.navigate(['.', { outlets: { 'residence': 'main' } }]);
  }

  ngOnInit() {
    this.fillSequences().then(r => {
      this.selectedSequence = this.service.residenceData.newSequence;
    });
  }

  onSeqChange()
  {
    this.service.residenceData.newSequence = this.selectedSequence;
  }


  public async fillSequences() {

    var _that = this;

    await this.service.getSequences("RCE").subscribe((data: Sequence[]) =>
    {
      _that.sequences = data;
      this.service.getSequences("RCH").subscribe((data: Sequence[]) => {
        _that.sequences = _that.sequences.concat(data);
        this.currentSequence = _that.sequences.find(r => r.sequenceID == _that.service.residenceData.patient.sequenceRef);
      });
    });
  }

  //for select diagnosis
  onDiagnosClick(data: BedData) {
    this.openDialog().subscribe(result => {

      if (result != null && result.rezult)//if success in modal dialog
      {
        if (result.newDiagnos.dictionaryCode != "") {
          this.service.CurrentDiagnos.dictionaryID = result.newDiagnos.dictionaryID;
          this.service.CurrentDiagnos.dictionaryCode = result.newDiagnos.dictionaryCode;
          this.service.CurrentDiagnos.dictionaryName = result.newDiagnos.dictionaryName;
        }
      }
      else {
        // something if false (pressed no)
      }
    });
  }


  //for select diagnosis
  openDialog(): Observable<DiagnosData> {

    let d: DiagnosData = new DiagnosData();
    d.diagnosis = this.diagnosis;
    d.oldDiagnos = this.service.CurrentDiagnos;
    d.newDiagnos = new Dictionary("");
    const dialogRef = this.dialog.open(DiagnosisSelectDialog, {
      width: '600px',
      data: d,
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      position: { right: '0px' },
      panelClass: 'my-panelLeft'
    });

    return dialogRef.afterClosed();
  }

}

